import * as ActionTypes from "../actionTypes";
import { Const } from "../../util";

/**
 * @description Redux에 최신 session 정보 추가
 * @param params
 * @return {function(*): boolean}
 */
export const setSession = (params) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.eSESSION, eSESSION: params });
    return true;
  };
};

const setAccessToken = (remember_login = true, access_token) => {
  const storage = remember_login ? localStorage : sessionStorage;
  storage.setItem(Const.LOGIN_TOKEN, access_token);
  storage.setItem(Const.LOGIN_TIME, new Date());
};

/**
 * @description Redux에 session 정보 추가 및 accessToken을 storage에 저장
 *
 * @param params redux session 정보에 추가할 데이터
 * @param {boolean} remember_login '로그인 유지' 여부
 * @param {string} access_token 로그인 회원의 access 토큰 값
 *
 * @return {function(*): boolean}
 */
export const setSessionWithToken = (
  params,
  { remember_login = true, access_token }
) => {
  return (dispatch) => {
    setAccessToken(remember_login, access_token);
    dispatch({ type: ActionTypes.eSESSION, eSESSION: params });
    return true;
  };
};

/**
 * @description Redux에서 session 정보 제거 및 accessToken을 storage에서 제거
 *
 * @return {function(*): boolean}
 */
export const resetSession = () => {
  return (dispatch) => {
    removeAccessToken();
    dispatch({ type: ActionTypes.eSESSION_RESET });
    return true;
  };
};

const removeAccessToken = () => {
  localStorage.removeItem(Const.LOGIN_TOKEN);
  localStorage.removeItem(Const.LOGIN_TIME);
  sessionStorage.removeItem(Const.LOGIN_TOKEN);
  sessionStorage.removeItem(Const.LOGIN_TIME);
};
