import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const LoginSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  & > article.bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      font-weight: 500;
      font-size: 1rem;
      line-height: 14px;
    }
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
