import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const TermAgreeArticle = styled.article`
  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  & > div.top {
    width: 100%;

    & > div.main-title {
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 25px;
      white-space: pre-wrap;
      word-break: keep-all;
      text-align: center;
      margin-bottom: 70px;

      & > span.name {
        color: ${Colors.Primary};
        font-weight: 600;
        font-size: 1.3rem;
        width: 130px;
        height: 30px;
        background-color: ${Colors.Primary10};
        border-radius: 5px;
      }
    }

    & > div.checkbox-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 38px;

      & > div.toggleTerm {
        font-weight: 500;
        font-size: 1rem;
        line-height: 14px;
        color: ${Colors.Gray40};
      }
    }
  }
`;
