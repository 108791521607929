import React from "react";
import { useNavigate } from "react-router";

//Component
import {
  OneButtonModal,
  EmotionButtonModal,
  handlerModal,
} from "../../organism/Modal";
import EmergencyModalContent from "./EmergencyModalContent";
import Icon from "../../../common/component/atom/Icon";

//Styles
import { Footer } from "./styles";
import { yeonseiImages, Icons } from "../../../styles";

//Config
import { footer_config } from "../../../common/util/const";

//api
import * as RestApi from "../../../common/context/api";

const index = ({ isDisplay }) => {
  const navigate = useNavigate();
  const pathname = location.pathname;
  const oneButtonModalHandler = handlerModal();
  const emotionButtonModalHandler = handlerModal();

  const fetchUpdateContact = () => {
    const api_params = {
      contact_hist_typ_cd: "CHTC00002",
      contact_emergency_yn: "Y",
    };
    RestApi.post("eCONTACT_HIST_USER_CREATE", api_params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Footer isDisplay={isDisplay}>
      <ul>
        {footer_config.map((menu, index) => {
          return (
            <li
              key={index}
              onClick={() =>
                menu.center_motion
                  ? null
                  : menu.nm === "긴급 상황 대처"
                  ? oneButtonModalHandler.onToggleModal()
                  : navigate(menu.url)
              }
            >
              <img
                src={
                  pathname.includes(`${menu.url}`)
                    ? yeonseiImages[menu.img_src_selected]
                    : yeonseiImages[menu.img_src_default]
                }
                alt={index === 2 ? "" : "nav menu icon"}
                onClick={() =>
                  menu.center_motion &&
                  emotionButtonModalHandler.onToggleModal()
                }
              />
              {pathname.includes(`${menu.url}`) && <span>{menu.nm}</span>}
            </li>
          );
        })}
      </ul>
      <OneButtonModal label={"닫기"} handler={oneButtonModalHandler}>
        <EmergencyModalContent fetchUpdateContact={fetchUpdateContact} />
      </OneButtonModal>
      <EmotionButtonModal
        handler={emotionButtonModalHandler}
        buttonClick={(e) => {
          navigate(
            "/main/feelingCalendarManage?emotion_status=" + e.target.alt
          );
          emotionButtonModalHandler.onToggleModal();
        }}
      >
        <div className={"emotion-modal__content"}>
          <div className={"emotion-modal__title"}>
            오늘의 기분은 어떠신가요?
          </div>
          <Icon
            icon={Icons.close}
            style={"emotion-modal__close"}
            size={"1.75rem"}
            onClick={(e) => {
              e.stopPropagation();
              emotionButtonModalHandler.onToggleModal();
            }}
          />
        </div>
      </EmotionButtonModal>
    </Footer>
  );
};

export default index;
