import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const SettingSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  .in-modal-box {
    padding: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 1.375rem;
    white-space: pre-wrap;
  }
`;

export const WithdrawalArticle = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;

  .checkbox {
    display: flex;
    flex-direction: row;
    width: 335px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 14px;
    margin-bottom: 40px;
    align-items: center;

    & img {
      margin-right: 10px;
    }

    .modal-button {
      font-weight: 500;
      font-size: 1rem;
      line-height: 14px;
      color: ${Colors.Gray40};
      margin-left: auto;
    }
  }

  .input-title {
    width: 335px;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
    line-height: 14px;
    margin-bottom: 10px;
  }

  & > div.button-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
