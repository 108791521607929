import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const LibrariesMain = styled.div`
  & > section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: Pretendard, serif;

    & > div {
      background-color: white;
      padding: 20px;
      border: 1px solid ${Colors.Gray60};

      & > div,
      & > span {
        margin: 10px;
      }

      & > h4 {
        margin: 10px 0;
      }
    }

    & > .component_column {
      display: flex;
      flex-direction: column;
    }

    & > .component_row {
      margin: 0;
      flex-direction: row;
    }

    & div.mapModalContent {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 300px;
      font-size: 1.375rem;
      color: ${Colors.Black};
    }
  }
`;
