import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  .background-img {
    width: 100%;
  }
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px;
  margin-top: 20px;

  & img {
    width: 28px;
    height: 22px;
  }

  .left-quote {
    margin-right: 239px;
  }

  .right-quote {
    margin-left: 239px;
  }

  .main-title {
    font-weight: 300;
    font-size: 26px;
    line-height: 36px;
    margin: 20px 0;
  }

  .text {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 25px;
    color: ${Colors.Gray80};
    margin-top: 30px;

    & span {
      font-weight: 700;
      font-size: 1.1rem;
      color: ${Colors.Black};
    }
  }
`;
