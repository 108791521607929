import React from "react";
import { css, cx } from "@emotion/css";

import { ButtonStyles, ButtonSizes } from "./styles";
import Icon from "../Icon";
import {yeonseiImages} from "../../../../styles";

const index = (props) => {
  const { style, label, icon, variant, size, value, onClick, img, iconStyle } = props;

  return (
    <button
      className={cx(
        ButtonStyles["default"],
        ButtonStyles[variant || "primary"],
        ButtonSizes[size || "medium"],
        style
      )}
      onClick={onClick}
    >
      {img && <img src={img} alt={"img"}/>}
      {label}
      {icon && (
          <Icon
              icon={icon}
              size={"17px"}
              color={"inherit"}
              style={iconStyle}
          />
      )}
    </button>
  );
};

export default index;
