/**
 *  @author 이규진
 *  @description - 이미지 리스트를 가진 파일 추가 버튼
 *  @param {Object} props - 상위 컴포넌트의 props
 *  @param {Object} [props.label] - 해당 컴포넌트의 제목 및 부제
 *  @param {Array} props.list - 이미지 리스트(<ImgList/>)로 보낼 특정 리스트
 *  @param {string} props.item - 이미지 리스트의 이름 또는 분류 코드(상위 컴포넌트의 한 객체 내에서 관리되는 이미지 리스트들의 키 값)
 *  @param {Object} props.handler - '추가'와 '삭제' handler={add, del}
 *  @param {number} [props.limit] - 해당 이미지 리스트에 담길 수 있는 최대치
 *  @param {Object} [props.help_msg] - 부가적인 추가 메세지
 *  @param {Object} [props.children] - 유동적으로 삽입 가능한 컴포넌트
 *  @param {number} [props.col_limit] - 이미지 리스트 열 개수
 *  @param {number} [props.space_between_lines] - 행간(단위: px)
 *  @returns { {FileBoxWithImgList} }
 *
 */
// Libraries
import React from "react";

// Styles
import { FileUploadSection } from "./styles";
import { cx } from "@emotion/css";

// Components
import FileBox from "../../atom/FileBox";
import ImgList from "../../atom/ImgList";

const index = (props) => {
  const {
    label,
    list,
    item,
    handler,
    limit = 3,
    help_msg,
    children,
    col_limit = 3,
    label_style,
    space_between_lines = 6,
  } = props;
  const length = list?.filter((el) => el.file_loc_se_cd === item).length || 0;

  return (
    <FileUploadSection col_limit={col_limit} marginTop={space_between_lines}>
      <div className={cx("label", label_style?.main)}>
        <strong>{label?.main}</strong>
        {label?.sub && <span>{label?.sub}</span>}
      </div>
      <div className={"img-section"}>
        <ImgList
          list={list}
          item={item}
          deleteImg={handler?.del}
          fileBox={
            length < limit && (
              <FileBox
                keyId={`file-box-${item}`}
                trgt={item}
                onChange={handler?.add}
                marginTop={length >= col_limit && space_between_lines}
              />
            )
          }
        />
      </div>
      {handler?.del && (
        <div className={"help-msg"}>
          {help_msg || `최대 ${limit}장까지 가능`}
        </div>
      )}
      {children || null}
    </FileUploadSection>
  );
};

export default index;
