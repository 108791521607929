import React, { useEffect } from "react";
import { css } from "@emotion/css";

//Component
import InputForm from "../../../../common/component/atom/InputForm";
import Button from "../../../../common/component/atom/Button";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";
import { useNavigate } from "react-router";

//Styles
import { ResetSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Util
import { Utils } from "../../../../common/util";

//Hook
import usePasswordReset from "../../hooks/usePasswordReset";

const index = () => {
  const verify = Utils.getParameterByName("verified") || "";
  const navigate = useNavigate();
  const {
    isSend,
    params,
    errList,
    checkId,
    setCheckId,
    errorMessage,
    changePassword,
    onChangeValue,
    fetchVerifyEmail,
    fetchVerifyNumber,
    fetchChangePassword,
    handleValidation,
  } = usePasswordReset();
  const oneButtonModalHandler = handlerModal();
  const reSendButtonModalHandler = handlerModal();
  const errorPwButtonModalHandler = handlerModal();
  const nonIdButtonModalHandler = handlerModal();

  useEffect(() => {
    if (!checkId) {
      nonIdButtonModalHandler.onToggleModal();
    }
  }, [checkId]);

  return (
    <ResetSection>
      <div className={"icon"}>
        <img
          src={
            params.verified
              ? yeonseiImages.CheckAbleLarge
              : yeonseiImages.ExclamationMarkMiddle
          }
          alt={"large-icon"}
        />
      </div>
      <div className={"title"}>
        {params.verified
          ? "인증이 완료되었습니다"
          : "가입시 입력한 이메일을\n입력하세요."}
      </div>
      {params.verified && (
        <>
          <div className={"sub-title"}>신규 비밀번호 입력</div>
          <InputForm
            placeholder={"영문, 숫자 혼합 8자리 이상 입력(특수문자 불가)"}
            type={"password"}
            value={params.mber_pw}
            maxLength={32}
            err_yn={!errList.mber_pw && "Y"}
            help_msg={!errList.mber_pw && "올바른 비밀번호를 입력해주세요."}
            onChange={(e) => onChangeValue("mber_pw", e.target.value)}
            onBlur={(e) => handleValidation("mber_pw", e.target.value)}
            style={css`
              margin-bottom: 10px;
            `}
          />
          <div className={"describe"}>
            {
              "8자리 이상 입력, 영문 숫자 포함.(특수문자 불가)\n유추가 쉬운 단어는 제외하고 입력하세요\n예) love, happy"
            }
          </div>
          <div className={"sub-title"}>비밀번호 확인</div>
          <InputForm
            placeholder={"비밀번호를 다시 입력해 주세요"}
            type={"password"}
            value={params.pw_check}
            maxLength={32}
            err_yn={!errList.pw_check && "Y"}
            help_msg={!errList.pw_check && "비밀번호가 일치하지 않습니다."}
            onChange={(e) => onChangeValue("pw_check", e.target.value)}
            onBlur={(e) => handleValidation("pw_check", e.target.value)}
          />
        </>
      )}
      {!params.verified && (
        <>
          <InputForm
            placeholder={"이메일을 입력하세요."}
            value={params.mber_id}
            onChange={(e) => onChangeValue("mber_id", e.target.value)}
            disabled={isSend}
          />
          {isSend && (
            <InputForm
              placeholder={"인증번호를 입력하세요."}
              value={params.crtfc_no}
              onChange={(e) => onChangeValue("crtfc_no", e.target.value)}
              style={css`
                margin-top: 10px;
              `}
            />
          )}
        </>
      )}
      <div className="buttonBox">
        <Button
          label={
            params.verified
              ? "비밀번호 재설정"
              : params.crtfc_no
              ? "인증번호 확인"
              : "인증번호 발송"
          }
          variant={"painted"}
          size={"xLarge"}
          style={css`
            margin-bottom: ${isSend ? "0" : "76px"};
            font-size: 1.25rem;
            font-weight: 600;
          `}
          onClick={() => {
            setTimeout(function () {
              if (isSend && params.verified && params.mber_pw === "") {
                errorPwButtonModalHandler.onToggleModal();
              } else if (params.verified) {
                if (!Object.values(errList).includes(false)) {
                  fetchChangePassword();
                }
                errorPwButtonModalHandler.onToggleModal();
              } else if (isSend && params.crtfc_no) {
                fetchVerifyNumber();
                if (params.error) {
                  oneButtonModalHandler.onToggleModal();
                }
              } else if (params.mber_id) {
                fetchVerifyEmail();
              }
            }, 100);
          }}
        />
        {isSend && (
          <div
            className={"resend-button"}
            onClick={() => {
              fetchVerifyEmail();
              reSendButtonModalHandler.onToggleModal();
            }}
          >
            인증번호 재발송
          </div>
        )}
      </div>
      <OneButtonModal
        handler={oneButtonModalHandler}
        label={"닫기"}
        buttonClick={() => {
          oneButtonModalHandler.onToggleModal();
          onChangeValue("error", false);
        }}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkMiddle} alt={"icon"} />
          {params.error_msg}
        </div>
      </OneButtonModal>
      <OneButtonModal handler={reSendButtonModalHandler}>
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.CheckAbleLarge} alt={"icon"} />
          재전송했습니다.
        </div>
      </OneButtonModal>
      <OneButtonModal
        handler={errorPwButtonModalHandler}
        label={changePassword ? "로그인 하기" : "닫기"}
        buttonClick={() => {
          changePassword
            ? navigate("/auth/login")
            : errorPwButtonModalHandler.onToggleModal();
        }}
      >
        <div className={"in-modal-box"}>
          <img
            src={
              changePassword
                ? yeonseiImages.CheckAbleLarge
                : yeonseiImages.ExclamationMarkMiddle
            }
            alt={"icon"}
          />
          {changePassword
            ? "변경완료\n다시 로그인 하십시오."
            : errorMessage !== ""
            ? "기존 비밀번호와 변경 비밀번호가 \n 같습니다."
            : "입력오류\n다시 확인해주세요."}
        </div>
      </OneButtonModal>
      <OneButtonModal
        handler={nonIdButtonModalHandler}
        buttonClick={() => {
          nonIdButtonModalHandler.onToggleModal();
          setCheckId(true);
        }}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkMiddle} alt={"icon"} />
          {"존재하지 않는\n아이디입니다."}
        </div>
      </OneButtonModal>
    </ResetSection>
  );
};

export default index;
