import { useEffect, useRef, useState } from "react";

import * as RestApi from "../../../common/context/api";

import * as Util from "../../../common/util/utils";

export default function (props) {
  const [params, setParams] = useState();
  const mainArticleRef = useRef(null);
  const [beforeList, setBeforeList] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [scrollData, setScrollData] = useState({
    steps: [1, 2, 3, 4, 5],
    refs: [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ],
    currentStep: 1,
  });

  useEffect(() => {
    fetchPlanDetail();
    fetchBeforePlan();
    fetchPeopleList();
    mainArticleRef.current.addEventListener("scroll", handleScroll);
    return () => {
      if (mainArticleRef.current)
        mainArticleRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const { steps, refs, currentStep } = scrollData;
    let newStep = currentStep;

    for (let i = 0; i < steps.length; i++) {
      if (
        refs[i].current.getBoundingClientRect().top <=
        mainArticleRef.current.offsetTop
      ) {
        newStep = steps[i];
      }
    }
    setScrollData({ ...scrollData, currentStep: newStep });
  };

  const onChangeValue = async (plan_no, field, value) => {
    setParams((prevState) => {
      return prevState.map((item) => {
        if (item.plan_no === plan_no) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    });
  };

  const onAddItem = (step) => {
    const newItem = {
      plan_no: Util.generateRandomString(),
      plan_nm: "",
      complete_yn: false,
      plan_step: step,
      del_yn: "N",
      add_yn: "Y",
      disabled: false,
    };
    setParams((prevState) => [...prevState, newItem]);
  };

  const onAddBeforeItem = (step, item) => {
    console.log(item);
    const beforeNewItem = {
      plan_no: Util.generateRandomString(),
      plan_nm: item.plan_nm,
      complete_yn: false,
      plan_step: step,
      del_yn: "N",
      add_yn: "Y",
      disabled: true,
    };
    setParams((prevState) => [...prevState, beforeNewItem]);
  };

  const fetchPlanDetail = () => {
    RestApi.get("ePLAN_USER_LIST")
      .then((res) => {
        const updatedList = res.list.map((item) => ({
          ...item,
          add_yn: "N",
          disabled: true,
          complete_yn: item.complete_yn === "Y",
        }));
        setParams(updatedList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPlanUpdate = (step, setIsClicked, onToggleModal) => {
    const api_params = params.filter(
      (plan) => plan.plan_nm && plan.plan_step === step
    );
    const tempList = api_params.filter((plan) => plan.del_yn === "N");
    if (tempList.length < 3) {
      onToggleModal();
      return;
    }

    RestApi.post("ePLAN_USER_UPDATE", api_params)
      .then((res) => {
        setIsClicked(false);
        fetchPlanDetail();
        fetchBeforePlan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPlanComplete = async (plan_no, value) => {
    await onChangeValue(plan_no, "complete_yn", value);
    const matchingItem = params.find((item) => item.plan_no === plan_no);
    if (matchingItem) {
      const updatedItem = {
        plan_no: matchingItem.plan_no,
        complete_yn: !matchingItem.complete_yn,
      };
      RestApi.post("ePLAN_USER_COMPLETE", updatedItem)
        .then((res) => {
          console.log(res);
          fetchPlanDetail();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchBeforePlan = () => {
    RestApi.get("ePLAN_USER_BEFORE")
      .then((res) => {
        setBeforeList(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPeopleList = () => {
    const api_params = {
      skip: 0,
      limit: 3,
      sort: 2,
    };
    RestApi.get("ePEOPLE_USER_LIST", api_params)
      .then((res) => {
        setPeopleList(res.list);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const fetchUpdateContact = (isModal) => {
    const api_params = {
      contact_hist_typ_cd: "CHTC00004",
      contact_emergency_yn: isModal ? "Y" : "N",
      contact_people_yn: isModal ? "N" : "Y",
    };
    RestApi.post("eCONTACT_HIST_USER_CREATE", api_params)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDeletePeople = (people_no) => {
    {
      RestApi.post("ePEOPLE_USER_DELETE", { people_nos: [people_no] })
        .then((res) => {
          fetchPeopleList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getEventFromAndroid = async (event) => {
    const api_params = event.detail;

    if (event.detail) {
      RestApi.post("ePEOPLE_USER_CREATE", api_params)
        .then((res) => {
          alert(
            "내 소중한 사람들로 " +
              api_params.people_nm +
              " 님의 등록이 완료되었어요."
          );
          fetchPeopleList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return {
    params,
    beforeList,
    peopleList,
    mainArticleRef,
    scrollData,
    fetchPeopleList,
    fetchPlanUpdate,
    fetchPlanComplete,
    onChangeValue,
    onAddItem,
    onAddBeforeItem,
    fetchUpdateContact,
    fetchDeletePeople,
    getEventFromAndroid,
  };
}
