import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const RecordSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  .title {
    width: 100%;
    align-items: flex-start;
    position: relative;
    font-weight: 500;
    font-size: 1.7rem;
    margin: 30px 0;
    padding: 0 10px;
    word-break: keep-all;

    & span {
      font-weight: 500;
      font-size: 1.7rem;
      color: ${Colors.Primary};
    }

    a {
      & > div.img-wrapper {
        margin-left: 10px;
        display: inline;
      }
      .tooltip {
        display: none;
        white-space: pre-wrap;
      }
      &:hover .tooltip,
      &:focus .tooltip {
        display: block;
      }
      .tooltip {
        transform: translate(0%, -2%);
        z-index: 100;
        padding: 20px;
        background: ${Colors.White};
        border-radius: 10px;
        border: 1px solid ${Colors.Primary};
        position: absolute;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.16px;
        text-align: center;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-weight: 500;
      font-size: 1rem;
      color: ${Colors.Gray60};
    }
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
