import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const CallBox = styled.div`
  width: 100%;
  height: 80px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;

  .box {
    font-size: 1.375rem;
    font-weight: 500;
    > span {
      font-size: 1.375rem;
      font-weight: 600;
      color: ${Colors.Gray60};
    }
  }
  & img {
    margin-left: auto;
  }
`;
