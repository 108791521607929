import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const KoreaMap = styled.div`
  width: 100vw;
  height: calc(100vh - 257px);

  & > .card-form {
    padding: 10px;
  }

  & > svg {
    width: 100%;
    height: 100%;
    fill: lightgrey;
    stroke: white;
  }

  & #${(props) => props.selectedId} {
    fill: ${Colors.Primary};
  }

  #north-gyeongsang {
    width: 50%;
  }
`;
