import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const LoadingView = styled.div`
  & > .loading-view-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background-color: ${Colors.Gray20};
    opacity: 0.5;
  }

  & > .loading-view-wrap {
    position: fixed;
    width: 150px;
    top: calc(100vh - 50%);
    left: calc(100vw - 50%);
    transform: translate(-50%, -50%);
    z-index: 99;
    overflow-x: hidden;

    & > img {
      width: 100%;
    }
  }
`;
