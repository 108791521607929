import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0 20px;

  .shadow-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
    background: ${Colors.White};
    border-radius: 10px;
    margin-bottom: 30px;

    .mind-cafe {
      margin-bottom: 20px;
    }

    .row-button {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;

      & img {
        width: 50%;
        height: 50%;
      }
    }

    .coupon-guide {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80px;
      border: 1px solid ${Colors.Gray60};
      border-radius: 10px;
      text-align: center;
      font-weight: 400;
      font-size: 1rem;
      color: ${Colors.Gray60};
    }

    .title {
      font-weight: 600;
      font-size: 1.25rem;
      color: ${Colors.Gray90};
      margin-bottom: 30px;
    }
  }
`;
