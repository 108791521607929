import { Colors } from "../../../../styles";
import styled from "@emotion/styled";

export const PlanManagerBox = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${Colors.Primary10};
  margin-bottom: ${(props) => (props.step === 5 ? "300px" : "60px")};

  .step-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    .step-title {
      font-weight: 600;
      font-size: 1.25rem;
      color: ${Colors.Primary};
      & > span {
        margin-left: 10px;
        font-size: 1.25rem;
        color: ${Colors.Black};
      }
    }
    a {
      display: flex;
      margin-left: 10px;
      justify-content: center;

      .tooltip {
        display: none;
        white-space: pre-wrap;
      }
      &:hover .tooltip,
      &:focus .tooltip {
        display: block;
      }
      .tooltip {
        left: 0;
        top: 40px;
        z-index: 100;
        width: 100%;
        height: auto;
        padding: 8px 10px;
        background: ${Colors.White};
        border-radius: 10px;
        border: 1px solid ${Colors.Primary};
        position: absolute;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.53;
        letter-spacing: -0.16px;
        text-align: center;
      }
    }
  }

  .help-call-box {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px ${Colors.Gray30};
    font-size: 1.375rem;
    font-weight: 500;

    & img {
      margin-left: auto;
    }
  }

  .information-box {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px ${Colors.Gray30};
    font-size: 1.375rem;
    font-weight: 500;
    border-bottom: none;

    & img {
      margin-left: auto;
    }
  }

  .step-4-box {
    .modal-button {
      font-weight: 600;
      font-size: 0.875rem;
      text-decoration-line: underline;
      color: ${Colors.Primary};
    }
  }
`;
