import React from "react";

// Component
import CenterModalLayout from "../../../../template/CenterModalLayout";

// Styles
import { Container } from "./styles";
import { yeonseiImages } from "../../../../../styles";

/**
 * @description 감정 선택 모달
 *
 * @param props
 * @param {object} props.handler 해당 모달에서 사용하는 핸들러(path: client/src/component/organism/Modal/handlerModal.jsx)
 * @param {function} props.buttonClick 감정 클릭시 실행되는 함수
 *
 * @return {JSX.Element}
 */
export const EmotionButtonModal = (props = {}) => {
  const { handler, buttonClick, children } = props;
  const { onToggleModal, onChangeModalValue, onChangeModalParams } = handler;

  return (
    <CenterModalLayout className={"changeLayout"} handler={handler}>
      <Container>
        {children}
        <div className={"emotion-box"}>
          <img
            src={yeonseiImages.CryMedium}
            alt={"CrySmall"}
            onClick={buttonClick}
          />
          <img
            src={yeonseiImages.WorseMedium}
            alt={"WorseSmall"}
            onClick={buttonClick}
          />
          <img
            src={yeonseiImages.UpsetMedium}
            alt={"UpsetSmall"}
            onClick={buttonClick}
          />
          <img
            src={yeonseiImages.SosoMedium}
            alt={"SosoSmall"}
            onClick={buttonClick}
          />
          <img
            src={yeonseiImages.SmileMedium}
            alt={"SmileSmall"}
            onClick={buttonClick}
          />
        </div>
      </Container>
    </CenterModalLayout>
  );
};
