import React from 'react';

import {CallBox} from "./styles";

const index = (props) => {
    const {titleName, phoneNumber, img} = props
    return (
        <CallBox>
            <div className={"box"}>{titleName} <span>{phoneNumber}</span></div>
            <img src={img} alt={"help-call-icon"}/>
        </CallBox>
    );
};

export default index;
