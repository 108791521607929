import React, { useId, useRef } from "react";

import * as RestApi from "../../../context/api";

import Button from "../Button";
import InputForm from "../InputForm";
import { FileBox } from "./styles";
import { css } from "@emotion/css";
import { Colors, yeonseiImages } from "../../../../styles";

const index = (props) => {
  const { value, style, onChange, marginTop } = props;
  const keyId = useId();

  const inputRef = useRef(null);

  const uploadFiles = (files) => {
    RestApi.upload("eFILE_UPLOAD", { file: [files] })
      .then((fileUrls) => {
        console.log(fileUrls[0].original);
        onChange(fileUrls[0].original);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <FileBox className={style} inputId={"file_uploader_" + keyId}>
      <label
        ref={inputRef}
        htmlFor={"file_uploader_" + keyId}
        style={{ marginRight: "8px", flex: 1 }}
        onChange={(e) => {
          uploadFiles(e.target.files[0]);
        }}
      >
        <InputForm id={"file_uploader_" + keyId} type="file" accept="image/*" />
      </label>
      <Button
        style={css`
          min-width: 92px;
          min-height: 92px;
          width: 92px;
          height: 92px;
          box-sizing: border-box;
          border-radius: 15px;
          background: ${value ? `url(${value})` : `${Colors.Gray20}`} no-repeat
            center center;
          background-size: cover;
          margin-top: ${`${marginTop}px` || 0};
        `}
        img={value ? null : yeonseiImages.PlusGray}
        onClick={() => inputRef.current.click()}
      />
    </FileBox>
  );
};

export default index;
