import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  .in-modal-box {
    margin-bottom: 20px;

    .site-box {
      display: flex;
      flex-direction: row;

      .title {
        font-weight: 500;
        font-size: 1.25rem;
        white-space: pre-wrap;
      }

      & img {
        margin-left: auto;
      }
    }

    .sub-site-box {
      display: flex;
      height: 144px;
      flex-direction: column;
      border-top: solid 1px ${Colors.Gray40};
      margin: 25px 0;
      padding-top: 25px;

      .title {
        font-weight: 500;
        font-size: 1.25rem;
        margin-bottom: 20px;
      }

      .main {
        display: flex;
        flex-direction: row;

        .main-detail {
          font-weight: 500;
          font-size: 1rem;
          line-height: 30px;
          white-space: pre-wrap;
          color: ${Colors.Gray90};
        }
        & img {
          margin-left: auto;
        }
      }
    }
  }
`;

export const MapArticle = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .map-wrapper {
    margin-top: 10px;
  }

  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
