import React, { Suspense, useEffect } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { Routes } from "react-router";

// Components
import LoadingView from "./component/template/LoadingView";
import AuthLayout from "./component/template/AuthLayout";
import MainLayout from "./component/template/MainLayout";
import SessionLayout from "./component/template/SessionLayout";
import DashboardLayout from "./component/template/DashboardLayout";
import InformationLayout from "./component/template/InformationLayout";
import ErrorBoundaryView from "./component/template/ErrorBoundaryView";

import DevSample from "./pages/dev/page/sample";
import DevLibraries from "./pages/dev/page/libraries";

import {
  authRoutes,
  mainRoutes,
  settingRoutes,
  myPageRoutes,
  localResourceRoutes,
  informationRoutes,
} from "./routes";

const index = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [pathname]);

  return (
    <div className={"main-container"}>
      <Suspense fallback={<LoadingView />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/auth/Intro" />} />
          {authRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <AuthLayout>
                    {route.isErrorBoundary ? (
                      <ErrorBoundaryView path={"/report/list"}>
                        {route.element}
                      </ErrorBoundaryView>
                    ) : (
                      route.element
                    )}
                  </AuthLayout>
                }
              />
            );
          })}
          {mainRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <MainLayout>
                    {route.isErrorBoundary ? (
                      <ErrorBoundaryView path={"/report/list"}>
                        {route.element}
                      </ErrorBoundaryView>
                    ) : (
                      route.element
                    )}
                  </MainLayout>
                }
              />
            );
          })}
          {myPageRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <MainLayout>
                    {route.isErrorBoundary ? (
                      <ErrorBoundaryView path={"/report/list"}>
                        {route.element}
                      </ErrorBoundaryView>
                    ) : (
                      route.element
                    )}
                  </MainLayout>
                }
              />
            );
          })}
          {settingRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <SessionLayout>
                    {route.isErrorBoundary ? (
                      <ErrorBoundaryView path={"/report/list"}>
                        {route.element}
                      </ErrorBoundaryView>
                    ) : (
                      route.element
                    )}
                  </SessionLayout>
                }
              />
            );
          })}
          {localResourceRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <MainLayout>
                    {route.isErrorBoundary ? (
                      <ErrorBoundaryView path={"/report/list"}>
                        {route.element}
                      </ErrorBoundaryView>
                    ) : (
                      route.element
                    )}
                  </MainLayout>
                }
              />
            );
          })}
          {informationRoutes.map((route) => {
            return (
              <Route
                path={route.path}
                element={
                  <InformationLayout>
                    {route.isErrorBoundary ? (
                      <ErrorBoundaryView path={"/report/list"}>
                        {route.element}
                      </ErrorBoundaryView>
                    ) : (
                      route.element
                    )}
                  </InformationLayout>
                }
              />
            );
          })}

          <Route
            path={"/dev/sample"}
            element={
              <DashboardLayout>
                <DevSample />
              </DashboardLayout>
            }
          />
          <Route
            path={"/dev/libraries"}
            element={
              <DashboardLayout>
                <DevLibraries />
              </DashboardLayout>
            }
          />
        </Routes>
      </Suspense>
    </div>
  );
};

export default index;
