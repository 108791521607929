import styled from "@emotion/styled";
import { css } from "@emotion/css";

export const RadioBox = styled.div`
  display: flex;
  //align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 500;

  & .common-icon {
    margin-right: 4px;
  }

  & img {
    margin-right: 13px;
  }

  & label {
    display: flex;
    align-items: center;
    margin-right: 16px;
    padding: 8px 0;
  }

  & input {
    display: none;
  }
`;

export const RadioBoxSizes = {
  large: css`
    width: 335px;

    font-size: 1rem;
  `,
  medium: css`
    font-size: 0.875rem;

    & .common-icon {
      font-size: 1.1rem;
    }
  `,
  small: css`
    font-size: 0.875rem;

    & .common-icon {
      font-size: 1rem;
    }
  `,
};
