import React from "react";
import Calendar from "react-calendar";

import { CalendarWrapper } from "./styles";
import { yeonseiImages } from "../../../styles";

import * as DateUtil from "../../../common/util/date";
import { useNavigate } from "react-router";

// @description react-calendar component || CustomCalendar가 아닌 Calendar Props
// @description 기본 css 적용 시, import 'react-calendar/dist/Calendar.css' 작성
// @link https://github.com/wojtekmaj/react-calendar
//
// @param {String} minDetail - 캘린더에서 선택할 수 있는 최소 범위 || "month", "year", "decade", "century" 선택 가능
// @param {String} maxDetail - 캘린더에서 선택할 수 있는 최대 범위 || "month", "year", "decade", "century" 선택 가능
// @param {String} prev2Label, prevLabel, nextLabel, next2Label - 캘린더 navigation 에서 사용할 Button 모양 || hide를 원할 시 null 처리
// @param {String} calendarType - 캘린더 type 설정 || "ISO 8601", "US", "Arabic", "Hebrew" 선택 가능
// @param {function} formatDay - 캘린더에 나타나는 날짜 형식 커스텀
// @param {function} onClickMonth - 캘린더에서 월을 클릭한 경우
// @param {function} navigationLabel - 캘린더에서 나타나는 navigation 커스텀

const CustomCalendar = (props) => {
  const {
    list = [],
    onChange = (value, date) => {},
    fetchMonthList,
    value = new Date(),
    children,
  } = props;
  const navigate = useNavigate();

  const onChangeEmotion = (avg) => {
    switch (avg) {
      case 1:
        return yeonseiImages.CrySmall;
      case 2:
        return yeonseiImages.WorseSmall;
      case 3:
        return yeonseiImages.UpsetSmall;
      case 4:
        return yeonseiImages.SosoSmall;
      case 5:
        return yeonseiImages.SmileSmall;
      case 6:
        return yeonseiImages.SmileSmallUnselected;
      default:
        return "";
    }
  };

  const fetchTileData = ({ date, view }) => {
    const selectDate = list.find(
      (el) =>
        DateUtil.format("dash", el.write_dt) === DateUtil.format("dash", date)
    );

    if (view === "month") {
      return (
        <>
          <div className={"tileDate"}>{date.getDate()}</div>
          {selectDate?.emotion_cont === "Y" && <div className={"dot"} />}
          <img
            src={onChangeEmotion(selectDate?.avg_emotion_status || 6)}
            alt={"emotion"}
            onError={(e) => {
              e.target.src = yeonseiImages.SmileSmallUnselected;
            }}
          />
        </>
      );
    }
  };

  return (
    <CalendarWrapper>
      <Calendar
        prev2Label={null}
        prevLabel={null}
        nextLabel={null}
        next2Label={null}
        calendarType={"US"}
        minDetail={"decade"}
        maxDetail={"month"}
        onChange={(value, event) => onChange(value, event)}
        value={value}
        tileContent={fetchTileData}
        formatDay={(locale, date) => {
          date.getDate();
        }}
        onClickMonth={(value) => {
          fetchMonthList(DateUtil.format("yearMonthDash", value));
        }}
        navigationLabel={({ date, label, locale, view }) => (
          <div className={"navigation_custom"}>
            {children}
            <div className={"calendarDate"}>
              {DateUtil.format("yearMonth", date)}
              {view === "month" && (
                <img alt={"arrowDown"} src={yeonseiImages.ArrowDown} />
              )}
            </div>
          </div>
        )}
      />
    </CalendarWrapper>
  );
};

export default CustomCalendar;
