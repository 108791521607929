import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

//Component
import ServiceBox from "../../component/ServiceBox";
import Button from "../../../../common/component/atom/Button";

//Style
import { ItemWrapper, MainSection } from "./styles";

//Config
import { LocalResource_config } from "../../../../common/util/const";

const index = () => {
  const navigate = useNavigate();
  return (
    <MainSection>
      <ItemWrapper>
        {(LocalResource_config || []).map((item, index) => (
          <ServiceBox key={index} item={item} />
        ))}
        <Button
          variant={"painted"}
          size={"xLarge"}
          label={"나의 자원 보러가기"}
          onClick={() => navigate("/myPage/resourceList")}
          style={css`
            width: 100%;
            font-size: 1.25rem;
            margin-bottom: 20px;
          `}
        />
      </ItemWrapper>
    </MainSection>
  );
};

export default index;
