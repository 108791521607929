import React from "react";
import { css } from "@emotion/css";

// Component
import BottomModalLayout from "../../../../template/BottomModalLayout";
import Button from "../../../../../common/component/atom/Button";

// Styles
import { Container } from "./styles";
export const ListBottomModal = (props) => {
  const { handler, button, list, onChange } = props;
  const { onToggleModal } = handler;

  return (
    <BottomModalLayout handler={handler}>
      <Container>
        <div className={"list-wrapper"}>
          {list.map((item) => {
            return (
              <div
                key={item.id}
                className={"list-item"}
                onClick={() => onChange(item)}
              >
                {item.value}
              </div>
            );
          })}
        </div>
        {button && (
          <Button
            label={"취소"}
            variant={"painted"}
            size={"BottomModalButton"}
            style={css`
              margin-top: 20px;
              font-size: 1.25rem;
              font-weight: 600;
            `}
            onClick={onToggleModal}
          />
        )}
      </Container>
    </BottomModalLayout>
  );
};
