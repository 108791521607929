import styled from "@emotion/styled";

export const MainSection = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;

export const ItemWrapper = styled.article`
  width: 100%;
  height: 100%;
  padding: 0 20px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
