import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { SlidingViewContent, SlidingViewOverlayContent } from "./styles";
import { Colors, Icons } from "../../../../styles";
import Icon from "../../../../common/component/atom/Icon";

/**
 * @description 슬라이딩 모달 형태의 컴포넌트
 * @param {boolean} props.isOpen 모달 visible state
 * @param {function} props.setIsOpen 모달 visible control 함수
 */
const index = (props) => {
  const { isOpen, setIsOpen, children } = props;

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(isOpen);
  }, [isOpen]);

  const removeSlidingView = () => {
    setVisible(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <SlidingViewOverlayContent
        visible={visible}
        onClick={removeSlidingView}
      />
      <SlidingViewContent visible={visible}>
        <div className={"sliding-view__close"} onClick={removeSlidingView}>
          <Icon icon={Icons.doubleNext} color={Colors.White} size={"20px"} />
        </div>
        <div className={"sliding-view__content"}>
          {React.cloneElement(children, { removeSlidingView })}
        </div>
      </SlidingViewContent>
    </>,
    document.body
  );
};

export default index;
