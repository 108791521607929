import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

// Styles
import { yeonseiImages } from "../../../styles";
import { Header } from "./styles";

import { Const } from "../../../common/util";

const index = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { headerMenu = Const.headerMenu, fetchResourceDelete } = props;

  const [title, setTitle] = useState("");
  const [backPath, setBackPath] = useState("");

  useLayoutEffect(() => {
    const URLSearch = new URLSearchParams(window.location.search);
    if (pathname in headerMenu) {
      if (URLSearch.has("step") && "step" in headerMenu[pathname]) {
        const step = URLSearch.get("step");
        setTitle(headerMenu[pathname]["step"][step]["title"]);
        setBackPath(headerMenu[pathname]["step"][step]["backPath"]);
      } else {
        setTitle(headerMenu[pathname]["title"]);
        setBackPath(headerMenu[pathname]["backPath"]);
      }
    } else {
      setTitle("");
      setBackPath("");
    }
  }, [location]);

  return (
    <Header isDisplay={title}>
      <div className="header-wrapper">
        <div className={"backPage"}>
          <img
            alt={"왼쪽 화살표"}
            src={yeonseiImages["ArrowLeft"]}
            onClick={() => navigate(backPath)}
          />
        </div>
        <div className={"title"}>{title}</div>
        {pathname === "/myPage/resourceList" ? (
          <div
            className={"delete-button"}
            onClick={() => fetchResourceDelete()}
          >
            <img src={yeonseiImages.Trashcan} alt={"trashcan"} />
          </div>
        ) : (
          <div />
        )}
      </div>
    </Header>
  );
};

export default index;
