import { useState } from "react";
import { useNavigate } from "react-router";

//util
import { Utils } from "../../../common/util";
import { err_pwList } from "../../../common/util/const";

//api
import * as RestApi from "../../../common/context/api";

export default function () {
  const navigate = useNavigate();
  const [params, setParams] = useState({
    mber_id: "", //email 전체부분
    mber_pw: "",
    mber_nickname: "",
    mber_sex_cd: "",
    mber_loc: "",
    mber_age: "",
    pw_check: "",
    email_local: "",
    email_domain: "",
  });

  const [errList, setErrList] = useState({
    verify: false,
    mber_nickname: true,
    mber_pw: true,
    pw_check: true,
    mber_sex_cd: true,
    mber_loc: true,
    mber_id: false,
  });

  const [email, setEmail] = useState({
    verfi_no: "",
    crtfc_no: "",
  });

  const [resultMsg, setResultMsg] = useState({
    error_msg: "",
    error: true,
  });

  const onChangeEmail = (field, value) => {
    setEmail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleValidation = (target, value) => {
    if (target === "verify") {
      setErrList((prev) => ({
        ...prev,
        [target]: value,
      }));
    } else if (target === "pw_check") {
      setErrList((prev) => ({
        ...prev,
        [target]: value !== "" && params.mber_pw === params.pw_check,
      }));
    } else if (target === "mber_pw") {
      for (let err_pw of err_pwList) {
        if (value.includes(err_pw)) {
          setErrList((prev) => ({
            ...prev,
            [target]: false,
          }));
          return;
        }
      }
      setErrList((prev) => ({
        ...prev,
        [target]: !Utils.validatePassword(value),
      }));
    } else {
      setErrList((prev) => ({ ...prev, [target]: value !== "" }));
    }
  };

  const checkMberId = (value) => {
    let flag = true;
    if (Utils.validateEmail(value)) {
      flag = false;
      setParams((prevState) => ({
        ...prevState,
        mber_id: "",
      }));
    } else {
      setErrList((prevState) => ({
        ...prevState,
        mber_id: true,
      }));
      setParams((prevState) => ({
        ...prevState,
        mber_id: value,
      }));
    }
    return flag;
  };

  const checkEmailValidate = () => {
    const mber_email = params.email_local + "@" + params.email_domain;
    setErrList((prevState) => ({
      ...prevState,
      verify: false,
      mber_id: false,
    }));

    if (checkMberId(mber_email)) {
      RestApi.get("eMBER_CHECK_ALEADY_EXIST", {
        mber_email: mber_email,
      })
        .then((res) => {
          if (res.available === true) {
            handleValidation("verify", true);
          } else {
            handleValidation("verify", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchSignUp = () => {
    const api_params = {
      mber_id: params.mber_id.trim(),
      mber_pw: params.mber_pw.trim(),
      mber_nickname: params.mber_nickname,
      mber_sex_cd: params.mber_sex_cd,
      mber_loc: params.mber_loc,
      mber_age: params.mber_age,
    };

    RestApi.post("eMBER_USER_CREATE", api_params)
      .then((res) => {
        console.log(res);
        fetchVerifyEmail();
        navigate("/auth/signup?step=3");
      })
      .catch((err) => {
        const { error = {}, msg } = err;
        console.log(err);
      });
  };

  const fetchVerifyEmail = () => {
    RestApi.post("eVERFI_EMAIL_CREATE", { email_addr: params.mber_id })
      .then((res) => {
        setEmail((prevState) => ({
          ...prevState,
          verify_no: res.verfi_no,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchVerifyNumber = () => {
    const api_params = {
      verfi_no: email.verify_no,
      verfi_code: email.crtfc_no,
    };
    RestApi.get("eVERFI_CHECK", api_params)
      .then((res) => {
        const { available, err, msg } = res;
        if (available) {
          setResultMsg({ error_msg: "인증에 성공했습니다.", error: false });
        } else {
          setResultMsg({ error_msg: msg, error: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    params,
    email,
    errList,
    resultMsg,
    onChangeEmail,
    onChangeValue,
    fetchSignUp,
    fetchVerifyNumber,
    fetchVerifyEmail,
    handleValidation,
    checkEmailValidate,
  };
}
