import styled from "@emotion/styled";
import { css } from "@emotion/css";
import { Colors } from "../../../../styles";

export const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;

  & * {
    box-sizing: border-box;
    border-radius: 4px;
  }

  & > .select__value {
    position: relative;
    z-index: ${(props) => (props.isOpen ? 99 : 1)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 10px;
    border-radius: 10px;
    font-size: 1rem;

    & > .common-icon {
      font-size: 1.5rem;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  & > .select__options {
    position: absolute;
    width: 100%;
    z-index: ${(props) => (props.isOpen ? 90 : "unset")};

    & > .select__options_item {
      padding: 0 12px 0 10px;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }

    & > .select__options_item.extra-option {
      border-top: 1px solid ${Colors.Gray40};
      border-radius: 0;
    }
  }
`;

export const SelectBoxStyles = {
  default: css`
    color: ${Colors.Black};

    & > .select__value {
      border: 1px solid ${Colors.Gray40};
      background-color: ${Colors.White};
      color: ${Colors.Gray90};

      & > .common-icon {
        color: ${Colors.Gray80} !important;
      }
    }

    & > .select__value.opened {
      background-color: ${Colors.Gray20};
    }

    & > .select__options {
      border: 1px solid ${Colors.Gray40};
      background-color: ${Colors.White};

      & > .select__options_item {
        color: ${Colors.Gray60};
        background-color: ${Colors.White};
      }

      & > .select__options_item.selected {
        color: ${Colors.Gray90};
        background-color: ${Colors.PrimaryV04};
      }
    }
  `,
};

export const SelectBoxSizes = {
  large: css`
    width: 335px;
    height: 50px;

    & > .select__value {
      height: 50px;
    }

    & > .select__options {
      top: 42px;
      padding-top: 7px;

      & > .select__options_item {
        height: 50px;
      }
    }
  `,
  medium: css`
    width: 151px;
    height: 50px;

    & > .select__value {
      height: 50px;
    }

    & > .select__options {
      top: 42px;
      padding-top: 7px;

      & > .select__options_item {
        height: 50px;
      }
    }
  `,
  small: css`
    width: 151px;
    height: 50px;

    & > .select__value {
      height: 50px;
    }

    & > .select__options {
      top: 42px;
      padding-top: 7px;

      & > .select__options_item {
        height: 50px;
      }
    }
  `,
};
