import { css } from "@emotion/css";
import { Colors } from "../../../styles";

export const BoxStyles = {
  default: css`
    width: 100%;
    height: 81px;
    border-radius: 10px;
    background-color: ${Colors.White};
    border: solid 1px ${Colors.Gray50};
    padding: 20px;
    display: flex;

    & .text {
      margin-left: 10px;
      line-height: 1.5;
      font-stretch: normal;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: normal;
      text-align: left;
      span {
        font-size: inherit;
        color: ${Colors.Primary};
      }
    }
  `,
  exclamation: css`
    width: 100%;
    height: 81px;
    padding: 20px 20px 19px 20px;
    border-radius: 10px;
    background-color: ${Colors.Primary10};
    display: flex;

    & .text {
      font-size: 1rem;
      margin-left: 10px;
      line-height: 1.5;
      font-weight: 600;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.Gray90};
      white-space: pre-wrap;
    }
  `,
};
