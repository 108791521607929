import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

//Styles
import { MainSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Component
import Button from "../../../../common/component/atom/Button";

const index = () => {
  const navigate = useNavigate();
  const isIos = !(
    navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
    navigator.maxTouchPoints !== 5
  );

  return (
    <MainSection>
      <div className={"shadow-box"}>
        <img
          className={"mind-cafe"}
          src={yeonseiImages.MindCafe}
          alt={"mind-cafe"}
        />
        <div className={"row-button"}>
          {!isIos && (
            <img
              src={yeonseiImages.Google}
              alt={"google"}
              onClick={() =>
                window.open(
                  `https://play.google.com/store/search?q=%EB%A7%88%EC%9D%B8%EB%93%9C%EC%B9%B4%ED%8E%98&c=apps&hl=ko`,
                  "_self"
                )
              }
            />
          )}
          <img
            src={yeonseiImages.Apple}
            alt={"apple"}
            onClick={() =>
              window.open(
                `https://apps.apple.com/kr/app/%EB%A7%88%EC%9D%B8%EB%93%9C%EC%B9%B4%ED%8E%98-%EC%8B%AC%EB%A6%AC%EC%83%81%EB%8B%B4-%EB%A7%88%EC%9D%8C-%EC%B9%98%EC%9C%A0-%ED%94%8C%EB%9E%AB%ED%8F%BC/id1441402766`,
                "_self"
              )
            }
          />
        </div>
        <div className={"coupon-guide"}>
          eRAPPORT를 통해 마인드카페를
          <br />
          이용하시는 분들께 쿠폰을 드립니다.
        </div>
      </div>
      <div className={"shadow-box"}>
        <span className={"title"}>마이페이지 > 쿠폰발급</span>
        <Button
          variant={"painted"}
          size={"mediumSmallLarge"}
          label={"쿠폰발급 페이지 가기"}
          style={css`
            font-size: 1.25rem;
          `}
          onClick={() => navigate("/localResource/couponIssue")}
        />
      </div>
    </MainSection>
  );
};

export default index;
