import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const SlidingViewOverlayContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: ${Colors.Black};
  opacity: 0.2;
`;

export const SlidingViewContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) => (props.visible ? "286px" : "0")};
  transition: all 0.3s ease-in-out;
  height: 100vh;
  z-index: 99;
  display: flex;

  & > .sliding-view__content {
    background-color: ${Colors.White};
    box-shadow: -4px 4px 10px ${Colors.Shadow};
    width: 286px;
    height: 100%;
    max-width: 286px;
    box-sizing: border-box;
    overflow: hidden;
  }

  & > .sliding-view__close {
    display: none;
    width: 32px;
    z-index: 99;
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
