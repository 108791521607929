import React, { useId, useState } from "react";

// Component
import WheelPicker from "react-simple-wheel-picker";

// Styles
import { Colors } from "../../../styles";
import { TimePicker } from "./styles";

// Const
import { Utils } from "../../../common/util";

/**
 * @description 연세대프로젝트에서 사용하는 TimePicker component
 *
 * @param props
 *
 * @returns {JSX.Element}
 */
const index = (props) => {
  const { onChangeValue = (data) => {}, value } = props;
  const [timeArray, setTimeArray] = useState(
    Utils.getHourArray(0, 12).map((time, index) => ({
      id: time,
      value: time,
    }))
  );

  return (
    <TimePicker id={useId() + "_time_picker"}>
      <WheelPicker
        data={timeArray}
        onChange={(data) => {
          onChangeValue(data);
        }}
        height={183}
        width={127}
        itemHeight={61}
        selectedID={timeArray[0].id}
        color={Colors.Gray40}
        activeColor={Colors.Black}
        backgroundColor="#fff"
      />
    </TimePicker>
  );
};

export default index;
