// Libraries
import React from "react";

// Styles
import { Container } from "./styles";
import { cx } from "@emotion/css";

const index = (props) => {
  const { title, titleStyle, children } = props;

  return (
    <Container>
      <div className={cx("label", titleStyle)}>
        <strong>{title.main}</strong>
        {title.sub && <span>{title.sub}</span>}
      </div>
      {children}
    </Container>
  );
};

export default index;
