import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const SignUpInputArticle = styled.article`
  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > div.small-title {
    width: 335px;
    margin-top: 20px;
    margin-bottom: 10px;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
  }

  & > div.warning-text {
    width: 335px;
    margin-left: 20px;
    margin-bottom: 20px;

    font-style: normal;
    color: ${Colors.Gray70};
  }

  & > div.top {
    width: 100%;

    & > div.checkbox-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 38px;

      & > div.toggleTerm {
        font-weight: 500;
        font-size: 1rem;
        line-height: 14px;
        color: ${Colors.Gray40};
      }
    }
  }

  .email-wrapper {
    display: flex;
    flex-direction: row;
    width: 335px;
    align-items: center;

    .at-sign {
      margin: 0 10px;
    }
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
