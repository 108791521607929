import styled from "@emotion/styled";
import { Colors } from "../../../../styles";
import { css } from "@emotion/css";

export const defaultTabBoxStyle = css`
  background-color: ${Colors.Gray20};
  display: flex;

  padding: 10px;
  border-radius: 10px;
`;

export const defaultTabMenuBoxStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  flex: 1;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => (props.tabSize === "large" ? 2.3 : 1.7)};
  letter-spacing: normal;
  color: ${Colors.Gray60};
  cursor: pointer;
  list-style: none;

  &.selected {
    border-bottom-width: 0;
    color: ${Colors.Primary};
    background-color: ${Colors.White};
    border-radius: 10px;
  }
`;

export const TabMenuBoxSizes = {
  small: css`
    height: 32px;

    font-weight: 700;
    font-size: 0.875rem;
    line-height: 12px;

    color: ${Colors.Black};
  `,
  // TimePicker 에서 AM/PM 선택시
  middle: css`
    width: 53px;
    height: 47px;

    font-weight: 600;
    font-size: 1rem;
    line-height: 15px;

    color: ${Colors.Black};

    &.selected {
      color: ${Colors.Black};
    }
  `,
  big: css`
    height: 40px;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 17px;

    color: ${Colors.Black};
  `,
};

export const TabBoxSizes = {
  // TimePicker 에서 AM/PM 선택시
  small: css`
    width: 123px;
    height: 61px;

    padding: 7px 9px;
  `,
  middle: css`
    width: 132px;
    height: 52px;
  `,
  large: css`
    width: 315px;
    height: 60px;
    margin: 0 auto;
  `,
};
