import styled from "@emotion/styled";

export const MyPageSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 20px 20px;

  .input-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 14px;
      margin-bottom: 10px;
    }

    & input {
      margin-top: 10px;
    }
  }
  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;
    white-space: pre-wrap;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
