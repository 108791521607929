import styled from "@emotion/styled";

export const TimePicker = styled.div`
  display: inline-block;
  cursor: pointer;

  & > ul {
    padding: 0 12px;
    box-shadow: rgb(51, 51, 51) 0 0 0 inset;
    background-color: transparent;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    & > li {
      text-align: center;
      & > span {
        display: none;
      }
      & > p {
        padding-left: 0;
        font-weight: 600;
        font-size: 1.875rem !important;
        line-height: 30px;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
`;
