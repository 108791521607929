import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

//Components
import ProgressBar from "../../component/ProgressBar";
import Button from "../../../../common/component/atom/Button";
import {
  handlerModal,
  OneButtonModal,
} from "../../../../component/organism/Modal";
import SafetyPlanContents from "../../component/SafetyPlanContents";

//Styles
import { SafetyPlanSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Util
import { Utils } from "../../../../common/util";

//Hooks
import useSafetyPlan from "../../hooks/useSafetyPlan";

const index = () => {
  const navigate = useNavigate();
  const step = Utils.getParameterByName("step") || "1";
  const [exclamation, setExclamation] = useState(false);
  const oneButtonModalHandler = handlerModal();
  const returnButtonModalHandler = handlerModal();
  const exceptionButtonModalHandler = handlerModal();
  const {
    params,
    onChangeValue,
    onHandleClick,
    checkPlanCount,
    addInputForm,
    deleteInputForm,
    fetchUpdateContact,
  } = useSafetyPlan({
    step,
    setExclamation,
    oneButtonModalHandler,
    returnButtonModalHandler,
  });

  useEffect(() => {
    checkPlanCount().then((result) => {
      result && exceptionButtonModalHandler.onToggleModal();
    });
    step === "5" ? setExclamation(true) : setExclamation(false);
  }, [step]);

  return (
    <SafetyPlanSection>
      <ProgressBar currentStep={parseInt(step)} exclamation={exclamation} />
      <SafetyPlanContents
        step={step}
        params={params}
        onChangeValue={onChangeValue}
        addInputForm={addInputForm}
        deleteInputForm={deleteInputForm}
        fetchUpdateContact={fetchUpdateContact}
      />
      <Button
        variant={"painted"}
        size={"xLarge"}
        label={step === "6" ? "완료" : "다음"}
        style={css`
          margin-top: 20px;
          font-size: 1.25rem;
          font-weight: 600;
        `}
        onClick={onHandleClick}
      />
      <OneButtonModal
        handler={oneButtonModalHandler}
        label={"실천 기록하기"}
        buttonClick={() => navigate("/main/safetyPlanManage")}
        pointerEvent={false}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.Note} alt={"note-picture"} />
          <p>
            나를 아낌 첫 번째
            <br />
            나를 살리는 계획이 작성되었어요.
          </p>
        </div>
      </OneButtonModal>
      <OneButtonModal handler={returnButtonModalHandler} label={"닫기"}>
        <div className={"warning-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkMiddle} alt={"exclamation"} />
          빈칸을 입력해주세요.
        </div>
      </OneButtonModal>
      <OneButtonModal
        handler={exceptionButtonModalHandler}
        pointerEvent={false}
        buttonClick={() => navigate("/main/safetyPlanManage")}
        label={"이동하기"}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkLarge} alt={"exclamation"} />
          <p>
            이미 작성된 계획이 있습니다.
            <br />
            관리페이지로 이동합니다.
          </p>
        </div>
      </OneButtonModal>
    </SafetyPlanSection>
  );
};

export default index;
