import React, { useEffect } from "react";
import { useNavigate } from "react-router";

//styles
import { SettingSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//component
import PasswordSettingForm from "../../../../component/template/PasswordSettingForm";
import {
  OneButtonModal,
  TwoButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//hook
import useAppPassword from "../../hooks/useAppPassword";

const index = () => {
  const {
    params,
    state,
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    onHandleMessage,
    onChangeValue,
    fetchUserDetail,
    fetchCreatePassword,
    fetchDeletePassword,
    fetchCheckPassword,
    fetchUpdatePassword,
  } = useAppPassword();
  const { title, subTitle } = onHandleMessage();
  const navigate = useNavigate();
  const oneButtonModalHandler = handlerModal();
  const twoButtonModalHandler = handlerModal();

  useEffect(() => {
    fetchUserDetail();
  }, []);

  useEffect(() => {
    onHandleMessage();
    if (params.mber_app_pw_use_yn === "N" && confirmPassword.length === 4) {
      password.join("") === confirmPassword.join("")
        ? (fetchCreatePassword(), oneButtonModalHandler.onToggleModal())
        : oneButtonModalHandler.onToggleModal();
    } else if (params.mber_app_pw_use_yn === "Y") {
      if (!state.isClicked && password.length === 4) {
        fetchCheckPassword()
          .then((check) => {
            if (check) {
              twoButtonModalHandler.onToggleModal();
            } else {
              oneButtonModalHandler.onToggleModal();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (state.isClicked && confirmPassword.length === 4) {
        password.join("") === confirmPassword.join("")
          ? (fetchUpdatePassword(), oneButtonModalHandler.onToggleModal())
          : oneButtonModalHandler.onToggleModal();
      }
    }
  }, [password, confirmPassword]);

  return (
    <SettingSection>
      <PasswordSettingForm
        title={title}
        subTitle={subTitle}
        password={password.length < 4 ? password : confirmPassword}
        setPassword={password.length < 4 ? setPassword : setConfirmPassword}
      />
      <OneButtonModal
        handler={oneButtonModalHandler}
        label={"확인"}
        buttonClick={() => {
          state.success ? navigate("/setting/setting") : setPassword([]),
            oneButtonModalHandler.onToggleModal();
          setConfirmPassword([]);
        }}
      >
        <div className={"in-modal-box"}>
          <img
            src={
              state.success
                ? yeonseiImages.CheckAbleLarge
                : yeonseiImages.ExclamationMarkMiddle
            }
            alt={"icon"}
          />
          {state.success
            ? "등록/변경이\n완료되었습니다."
            : "비밀번호가 다릅니다.\n다시 확인해주세요."}
        </div>
      </OneButtonModal>
      <TwoButtonModal
        handler={twoButtonModalHandler}
        topButtonLabel={"변경"}
        topButtonClick={() => {
          onChangeValue("isClicked", true);
          setPassword([]);
          twoButtonModalHandler.onToggleModal();
        }}
        bottomButtonLabel={"해제"}
        bottomButtonClick={() => fetchDeletePassword()}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkMiddle} alt={"icon"} />
          {"비밀번호 설정을 골라주세요."}
        </div>
      </TwoButtonModal>
    </SettingSection>
  );
};

export default index;
