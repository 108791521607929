import React, { useEffect, useState } from "react";

import * as RestApi from "../../../common/context/api";

import * as DateUtil from "../../../common/util/date";

import { Utils } from "../../../common/util";

const index = () => {
  const today = new Date();
  const [list, setList] = useState([]);
  const calendarTabBox = Utils.getParameterByName("calendarTabBox");
  const [params, setParams] = useState({
    calendarTabBox: calendarTabBox || "detail",
    statisticsTabBox: "1week",
  });
  const [dayList, setDayList] = useState([]);

  const addDays = (date, days) => {
    const clone = new Date(date);
    clone.setDate(date.getDate() + days);
    return clone;
  };

  const onHandleDate = (value) => {
    const dateRanges = {
      "1week": [-7, 0],
      "2week": [-14, 0],
      "1month": [-30, 0],
    };

    const [startOffset, endOffset] = dateRanges[value];
    const startDate = DateUtil.format("dash", addDays(today, startOffset));
    const endDate = DateUtil.format("dash", addDays(today, endOffset));

    fetchMonthStatisticList(startDate, endDate);
  };

  useEffect(() => {
    fetchMonthList(DateUtil.format("yearMonthDash", today));
    fetchMonthStatisticList(
      DateUtil.format("dash", addDays(today, -7)),
      DateUtil.format("dash", today)
    );
  }, []);

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchMonthList = (date) => {
    RestApi.get("eCALENDAR_USER_MONTHLY_EMOTION_STATUS_AVG_LIST", {
      date: date,
    })
      .then((res) => {
        setList(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMonthStatisticList = (dateFrom, dateTo) => {
    RestApi.get("eCALENDAR_USER_PERIOD_AVG_LIST", {
      date_from: dateFrom,
      date_to: dateTo,
    })
      .then((res) => {
        setDayList(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    list,
    params,
    dayList,
    onHandleDate,
    onChangeValue,
    fetchMonthList,
    fetchMonthStatisticList,
  };
};
export default index;
