import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import * as RestApi from "../../../common/context/api";

const index = (props) => {
  const mber_no = useSelector((state) => state?.data.eSESSION.account?.mber_no);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const fetchUserDetail = () => {
    RestApi.get("eMBER_USER_DETAIL", { mber_no: mber_no })
      .then((res) => {
        setDetail({
          ...res.detail,
        });
      })
      .catch((err) => console.log(err));
  };

  return {
    detail,
    fetchUserDetail,
  };
};

export default index;
