import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const ResourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 170px;
  background: ${Colors.White};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px 10px 10px;
  margin-bottom: 30px;

  .info-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 10px;

    & img {
      margin-right: 11px;
    }
    .date-box {
      margin-left: auto;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .body-area {
    display: flex;
    flex-direction: row;

    & img {
      width: 116px;
      height: 116px;
      border-radius: 10px;
      margin-right: 16px;
    }

    .title-area {
      min-width: 170px;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 1.25rem;
      color: ${Colors.Gray90};

      .button-area {
        display: flex;
        flex-direction: row;
        margin-top: auto;

        & img {
          width: 21px;
          height: 21px;
          border-radius: 0;
        }

        .middle-img {
          margin-left: auto;
        }

        .last-img {
          margin-right: 0;
        }
      }
    }
  }

  .input-modal-box {
    display: flex;
    flex-direction: column;

    & * {
      margin-bottom: 20px;
    }

    & p {
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 10px;
    }
  }
`;
