import React, { useRef, useState } from "react";

import * as RestApi from "../../../common/context/api";

import Button from "../../../common/component/atom/Button";
import InputForm from "../../../common/component/atom/InputForm";

import { FileBox } from "./styles";
import { css } from "@emotion/css";
import { Colors, yeonseiImages } from "../../../styles";

const index = (props) => {
  const { style, onChange, keyId, trgt, marginTop } = props;

  const inputRef = useRef(null);

  const uploadFiles = (files) => {
    if (files) {
      RestApi.upload("eFILE_UPLOAD", { file: [files] })
        .then((fileUrls) => {
          onChange(trgt, fileUrls[0].original);
        })
        .catch(() => {});
    }
  };

  return (
    <FileBox>
      <label
        ref={inputRef}
        className={style}
        htmlFor={"file_uploader_" + keyId || ""}
        style={{ marginRight: "8px", flex: 1 }}
        onChange={(e) => {
          uploadFiles(e.target.files[0]);
        }}
      >
        <InputForm id={"file_uploader_" + keyId || ""} type="file" />
      </label>
      <Button
        style={css`
          min-width: 92px;
          min-height: 92px;
          width: 92px;
          height: 92px;
          //width: calc((100vw - 100px) / 3);
          //height: calc((100vw - 100px) / 3);
          box-sizing: border-box;
          border-radius: 15px;
          background: ${`${Colors.Gray20} url(${yeonseiImages.PlusGray})`}
            no-repeat center center;
          margin-top: ${`${marginTop}px` || 0};
        `}
        onClick={() => {
          inputRef.current.click();
        }}
      />
    </FileBox>
  );
};

export default index;
