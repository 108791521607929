import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

//styles
import { LoginSection } from "./styles";

//component
import PasswordSettingForm from "../../../../component/template/PasswordSettingForm";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//api
import * as RestApi from "../../../../common/context/api";
import { yeonseiImages } from "../../../../styles";

const index = () => {
  const [password, setPassword] = useState([]);
  const [detail, setDetail] = useState({});
  const navigate = useNavigate();
  const oneButtonModalHandler = handlerModal();

  const fetchCheckPassword = () => {
    return new Promise((resolve, reject) => {
      RestApi.post("eMBER_USER_APP_PASSWORD_CHECK", {
        mber_app_pw: password.join(""),
      })
        .then((res) => {
          const { available, detail } = res;
          setDetail(detail);
          resolve(available);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    if (detail?.mber_app_pw_failed_cnt > 5) {
      navigate(
        "/auth/exceptionVerify?appPw=" +
          detail?.mber_app_pw_failed_cnt +
          "&&mberId=" +
          detail?.mber_id
      );
    }
    if (password.length === 4) {
      fetchCheckPassword()
        .then((check) => {
          if (check) {
            navigate("/main/main");
          } else {
            oneButtonModalHandler.onToggleModal();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [password]);

  return (
    <LoginSection>
      <PasswordSettingForm
        title={"비밀번호 입력"}
        subTitle={"사용중인 비밀번호를 입력해주세요."}
        password={password}
        setPassword={setPassword}
      />
      <OneButtonModal
        handler={oneButtonModalHandler}
        label={"확인"}
        buttonClick={() => {
          setPassword([]);
          oneButtonModalHandler.onToggleModal();
        }}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkMiddle} alt={"icon"} />
          {"비밀번호가 다릅니다.\n다시 확인해주세요."}
        </div>
      </OneButtonModal>
    </LoginSection>
  );
};

export default index;
