import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

//api
import * as RestApi from "../../../common/context/api";

//const
import { Const } from "../../../common/util";

const index = () => {
  const mber_no = useSelector((state) => state?.data.eSESSION.account?.mber_no);
  const navigate = useNavigate();
  const [params, setParams] = useState({});
  const [password, setPassword] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState([]);
  const [state, setState] = useState({
    //password와 confirmPassword가 일치한 경우
    success: false,
    //비밀번호 변경을 누른 경우
    isClicked: false,
  });

  const onChangeValue = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchUserDetail = () => {
    RestApi.get("eMBER_USER_DETAIL", { mber_no: mber_no })
      .then((res) => {
        setParams({
          ...res.detail,
        });
      })
      .catch((err) => console.log(err));
  };

  const fetchCreatePassword = () => {
    RestApi.post("eMBER_USER_APP_PASSWORD_CREATE", {
      mber_app_pw: password.join(""),
    })
      .then(() => {
        onChangeValue("success", true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUpdatePassword = () => {
    RestApi.post("eMBER_USER_APP_PASSWORD_UPDATE", {
      mber_app_pw: password.join(""),
    })
      .then((res) => {
        onChangeValue("success", true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDeletePassword = () => {
    RestApi.post("eMBER_USER_APP_PASSWORD_NOT_USE_UPDATE")
      .then(() => {
        alert("비밀번호 해제가 완료되었습니다.");
        navigate("/setting/setting");
      })
      .catch(() => {});
  };

  const fetchCheckPassword = () => {
    return new Promise((resolve, reject) => {
      RestApi.post("eMBER_USER_APP_PASSWORD_CHECK", {
        mber_app_pw: password.join(""),
      })
        .then((res) => {
          const { available } = res;
          resolve(available);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const findMessageTypeByType = (type) => {
    return Const.messageType.find((message) => message.type === type);
  };

  const onHandleMessage = () => {
    if (params.mber_app_pw_use_yn === "N") {
      if (password.length === 4) {
        return findMessageTypeByType("repeat");
      }
      return findMessageTypeByType("create");
    } else {
      if (state.isClicked) {
        if (password.length === 4) {
          return findMessageTypeByType("repeat");
        }
        return findMessageTypeByType("change");
      }
      return findMessageTypeByType("confirm");
    }
  };

  return {
    params,
    state,
    password,
    confirmPassword,
    setConfirmPassword,
    setPassword,
    onChangeValue,
    onHandleMessage,
    fetchUserDetail,
    fetchCreatePassword,
    fetchDeletePassword,
    fetchCheckPassword,
    fetchUpdatePassword,
  };
};

export default index;
