import Main from "../pages/main/page/main";
import SafetyPlan from "../pages/main/page/safetyPlan";
import SafetyPlanManage from "../pages/main/page/safetyPlanManage";
import FeelingCalendar from "../pages/main/page/feelingCalendar";
import FeelingCalendarManage from "../pages/main/page/feelingCalendarManage";
import FeelingCalendarStatistics from "../pages/main/page/feelingCalendarStatistics";

export const mainRoutes = [
  {
    path: "/main/main",
    element: <Main />,
  },
  {
    path: "/main/safetyPlan",
    element: <SafetyPlan />,
  },
  {
    path: "/main/safetyPlanManage",
    element: <SafetyPlanManage />,
  },
  {
    path: "/main/feelingCalendar",
    element: <FeelingCalendar />,
  },
  {
    path: "/main/feelingCalendarManage",
    element: <FeelingCalendarManage />,
  },
  {
    path: "/main/feelingCalendarStatistics",
    element: <FeelingCalendarStatistics />,
  },
];
