import React from "react";
import { useNavigate } from "react-router";

// Styles
import { FeelingCalendar } from "./styles";

// Components
import FeelingStatistics from "../../component/FeelingStatistics";
import TabMenuBox from "../../../../common/component/molecule/TabMenuBox";
import CustomCalendar from "../../../../component/molecule/Calendar";

// Const
import { DateUtil } from "../../../../common/util";

const index = (props) => {
  const {
    list,
    params,
    statisticList,
    onHandleDate,
    onChangeValue,
    fetchMonthList,
  } = props;
  const navigate = useNavigate();

  return (
    <FeelingCalendar length={params.calendarTabBox}>
      <CustomCalendar
        onChange={(value, date) => {
          const selectDate = list.find(
            (el) =>
              DateUtil.format("dash", el.write_dt) ===
              DateUtil.format("dash", value)
          );
          if (selectDate) {
            params.calendarTabBox === "detail"
              ? navigate(
                  "/main/feelingCalendarManage?timeNo=" +
                    selectDate?.calendar_no
                )
              : navigate(
                  "/main/feelingCalendarStatistics?dateNo=" +
                    DateUtil.format("dash", value)
                );
          }
        }}
        fetchMonthList={fetchMonthList}
        list={list}
      >
        <TabMenuBox
          tabSize={"middle"}
          size={"small"}
          tabs={[
            { label: "상세", value: "detail" },
            { label: "통계", value: "statistics" },
          ]}
          value={params.calendarTabBox}
          onChangeValue={(data, e) => {
            e.stopPropagation();
            onChangeValue("calendarTabBox", data.value);
          }}
        />
      </CustomCalendar>
      <FeelingStatistics
        statisticList={statisticList}
        params={params}
        onHandleDate={onHandleDate}
        onChangeValue={onChangeValue}
      />
    </FeelingCalendar>
  );
};

export default index;
