import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { LoginSection } from "./styles";
import { Colors, yeonseiImages } from "../../../../styles";

// Components
import Button from "../../../../common/component/atom/Button";
import Checkbox from "../../../../component/atom/Checkbox";
import InputForm from "../../../../common/component/atom/InputForm";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

// Hooks
import useAuthLogin from "../../hooks/useAuthLogin";

import React, { useEffect } from "react";
const index = () => {
  const navigate = useNavigate();
  const errorModalHandler = handlerModal();
  const { params, errorMsg, onChangeValue, fetchLogin } = useAuthLogin();

  useEffect(() => {
    if (errorMsg.error === true) {
      errorModalHandler.onToggleModal();
    }
  }, [errorMsg]);

  return (
    <LoginSection>
      <InputForm
        placeholder={"ID(이메일)를 입력해 주세요."}
        value={params.mber_id}
        onChange={(e) => onChangeValue("mber_id", e.target.value)}
        style={css`
          margin-bottom: 10px;
        `}
      />
      <InputForm
        placeholder={"비밀번호를 입력해 주세요."}
        type={"password"}
        value={params.mber_pw}
        onChange={(e) => onChangeValue("mber_pw", e.target.value)}
        style={css`
          margin-bottom: 10px;
        `}
      />
      <Button
        label={"로그인"}
        variant={"painted"}
        size="xLarge"
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 10px;
          background-color: ${params.mber_id && params.mber_pw
            ? Colors.Primary
            : Colors.Disable};
        `}
        onClick={async () => await fetchLogin()}
      />
      <article className={"bottom"}>
        <Checkbox
          label={"자동로그인"}
          variant={"small"}
          checked={params.remember_login}
          onChange={() =>
            onChangeValue("remember_login", !params.remember_login)
          }
        />
        <div onClick={() => navigate("/auth/passwordReset")}>
          아이디/비밀번호 찾기
        </div>
      </article>
      <OneButtonModal handler={errorModalHandler} label={"확인"}>
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.ExclamationMarkMiddle} alt={"exclamation"} />
          {errorMsg.msg}
        </div>
      </OneButtonModal>
    </LoginSection>
  );
};

export default index;
