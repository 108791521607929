import React from "react";
import { css } from "@emotion/css";

// Component
import CenterModalLayout from "../../../../template/CenterModalLayout";
import Button from "../../../../../common/component/atom/Button";

// Styles
import { Container } from "./styles";

/**
 * @description 중앙정렬 모달
 * @param props
 * @param {object} props.handler 해당 모달에서 사용하는 핸들러(path: client/src/component/organism/Modal/handlerModal.jsx)
 * @param {function} props.topButtonClick 버튼 클릭시 실행되는 함수 미입력시 모달 닫기
 * @param {function} props.bottomButtonClick 버튼 클릭시 실행되는 함수 미입력시 모달 닫기
 * @param {string} props.topButtonLabel 위 버튼 안 텍스트
 * @param {string} props.bottomButtonLabel 위 버튼 안 텍스트
 *
 * @return {JSX.Element}
 */
export const TwoButtonModal = (props = {}) => {
  const {
    handler,
    topButtonClick = () => {},
    bottomButtonClick = () => {},
    topButtonLabel = "해제",
    bottomButtonLabel = "취소",
    children,
  } = props;
  const { onToggleModal, onChangeModalValue, onChangeModalParams } = handler;

  return (
    <CenterModalLayout handler={handler}>
      <Container>
        {children}
        <Button
          label={topButtonLabel}
          variant={"painted"}
          size={"modalButton"}
          style={css`
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 10px;
          `}
          onClick={topButtonClick || onToggleModal}
        />

        <Button
          label={bottomButtonLabel}
          variant={"unpainted"}
          size={"modalButton"}
          style={css`
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 10px;
          `}
          onClick={bottomButtonClick || onToggleModal}
        />
      </Container>
    </CenterModalLayout>
  );
};
