import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const Container = styled.div`
  width: 100%;

  & .label {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    line-height: 1.6em;
    font-size: 1rem;
    color: ${Colors.Black};
    font-weight: 500;
    margin-top: 22px;
    margin-bottom: 14px;

    strong {
      font-size: 1rem;
      color: ${Colors.Black};
      font-weight: 500;
      &.str {
        font-weight: bold;
      }
    }
    span {
      margin-left: 4px;
      font-size: 1rem;
      color: ${Colors.Red};
      font-weight: 500;
    }
    a {
      margin-left: 8px;
      display: flex;
      & .tooltip02 {
        display: none;
        &:after {
          content: "";
          position: absolute;
          border-style: solid;
          border-width: 0px 12px 20px 12px;
          border-color: ${Colors.Black} transparent;
          display: block;
          width: 0;
          z-index: 1;
          top: -12px;
          left: 131px;
        }
      }
      &:hover & .tooltip02 {
        display: block;
      }
      & .tooltip02 {
        left: -10px;
        top: 40px;
        z-index: 100;
        width: calc(215px - 20px);
        height: auto;
        padding: 8px 10px;
        background: ${Colors.Black};
        border-radius: 6px;
        position: absolute;
        font-size: 0.9rem;
        color: ${Colors.White};
        font-weight: 500;
        line-height: 1.23em;
        letter-spacing: -0.1px;
      }
    }
  }
`;
