import React from "react";
import { MainText } from "./styles";

const index = (props) => {
  const { isDisplay, step, mainTitle, text, img, secondTitle, secondText } =
    props;
  return (
    <MainText isDisplay={isDisplay} step={step}>
      <div className={"main-title"}>{mainTitle}</div>
      <div className={"text"}>{text}</div>
      <img className={"img"} src={img} alt={"img"} />
      {step === "5" && (
        <>
          <div className={"main-title"}>{secondTitle}</div>
          <div className={"text"}>{secondText}</div>
        </>
      )}
    </MainText>
  );
};

export default index;
