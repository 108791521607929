import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

//Styles
import { VerifiSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Components
import InputForm from "../../../../common/component/atom/InputForm";
import Button from "../../../../common/component/atom/Button";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//Hooks
import useVerification from "../../hooks/useVerification";

const index = () => {
  const {
    params,
    resultMsg,
    sendEmail,
    onChangeValue,
    fetchVerifyEmail,
    fetchVerifyNumber,
  } = useVerification();
  const oneButtonModalHandler = handlerModal();
  const navigate = useNavigate();

  return (
    <VerifiSection>
      <div className={"icon"}>
        <img src={yeonseiImages.ExclamationMarkMiddle} alt={"large-icon"} />
      </div>
      <div className={"title"}>
        {"비밀번호 입력오류 5회\n본인인증을 진행하세요."}
      </div>

      <div className={"sub-title"}>가입시 입력한 이메일을 입력하세요.</div>
      <InputForm
        placeholder={"이메일을 입력하세요."}
        value={params.mber_id}
        onChange={(e) => onChangeValue("mber_id", e.target.value)}
        disabled={sendEmail}
      />
      {sendEmail && (
        <InputForm
          placeholder={"인증번호를 입력하세요."}
          value={params.crtfc_no}
          onChange={(e) => onChangeValue("crtfc_no", e.target.value)}
          style={css`
            margin-top: 10px;
          `}
        />
      )}

      <div className="buttonBox">
        <Button
          label={params.crtfc_no ? "인증번호 확인" : "인증번호 발송"}
          variant={"painted"}
          size={"xLarge"}
          style={css`
            margin-bottom: ${sendEmail ? "0" : "46px"};
            font-size: 1.25rem;
            font-weight: 600;
          `}
          onClick={() => {
            if (sendEmail && params.crtfc_no) {
              fetchVerifyNumber();
              oneButtonModalHandler.onToggleModal();
            } else if (params.mber_id) {
              fetchVerifyEmail();
            }
          }}
        />
        {sendEmail && (
          <div className={"resend-button"} onClick={() => fetchVerifyEmail()}>
            인증번호 재발송
          </div>
        )}
      </div>

      <OneButtonModal
        handler={oneButtonModalHandler}
        label={resultMsg.error ? "닫기" : "비밀번호 변경하기"}
        buttonClick={() => {
          resultMsg.error === false
            ? navigate("/auth/passwordReset?verified=" + params.verify_no)
            : oneButtonModalHandler.onToggleModal();
        }}
      >
        <div className={"in-modal-box"}>
          <img
            src={
              resultMsg.error
                ? yeonseiImages.ExclamationMarkMiddle
                : yeonseiImages.CheckAbleLarge
            }
            alt={"icon"}
          />
          {resultMsg.error_msg}
        </div>
      </OneButtonModal>
    </VerifiSection>
  );
};

export default index;
