import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import * as RestApi from "../../../common/context/api";
import * as ActionSession from "../../../common/store/action/eSESSION";
import { LOGIN_TIME, LOGIN_TOKEN } from "../../../common/util/const";

const index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState({
    mber_del: false,
  });
  const mber_no = useSelector((state) => state?.data.eSESSION.account?.mber_no);

  useEffect(() => {}, [params]);

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const fetchUserDetail = () => {
    RestApi.get("eMBER_USER_DETAIL", { mber_no: mber_no })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserDelete = () => {
    RestApi.post("eMBER_USER_DELETE", { mber_pw: params.mber_pw })
      .then((res) => {
        localStorage.removeItem(LOGIN_TOKEN);
        localStorage.removeItem(LOGIN_TIME);

        dispatch(ActionSession.setSession({}));
        navigate("/auth/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkUserPassword = () => {
    RestApi.post("eMBER_CHECK_PASSWORD", { mber_pw: params.mber_pw })
      .then((res) => {
        if (res.available === true) {
          setParams((prevState) => ({
            ...prevState,
            mber_del: true,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeValue = (field, value) => {
    const temp_p = JSON.parse(JSON.stringify(params));
    temp_p[field] = value;
    setParams(temp_p);
  };

  return {
    params,
    onChangeValue,
    checkUserPassword,
    fetchUserDelete,
  };
};

export default index;
