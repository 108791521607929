import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MultiFormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & Button {
    margin-top: 20px;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .item-box {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    width: 100%;
    height: 50px;
    background: ${Colors.PrimaryOpacity};
    border: 1px solid ${Colors.Primary};
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
  }

  > * {
    margin-bottom: 20px;
  }

  .before-list-box {
    width: 335px;
    display: flex;
    flex-direction: row;

    .before-title {
      background-color: ${Colors.Gray60};
      width: 68px;
      height: 22px;
      border-radius: 5px;
      color: ${Colors.White};
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 25px;
      text-align: center;
      margin-right: 10px;
    }

    .before-item-box {
      .before-item {
        color: ${Colors.Primary};
        font-weight: 500;
        font-size: 1rem;
        line-height: 25px;
        text-decoration: underline;
      }
    }
  }
`;
