import React from "react";
import DatePicker from "react-datepicker";
import { cx } from "@emotion/css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { ko } from "date-fns/esm/locale";

//Styles
import { DatepickerStyles } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = (props) => {
  const {
    format,
    placeholder,
    value,
    showTime,
    showYear,
    readOnly,
    disabled,
    style,
    onChange,
    timeNo,
    maxDate,
    minDate,
    filterTime,
    timeIntervals,
    showTimeSelectOnly = false,
  } = props;

  const renderDayClassNames = (date) => {
    return date > maxDate ? "disabled" : "";
  };

  return (
    <div className={cx(DatepickerStyles, style)}>
      <DatePicker
        dateFormat={format || "yy/mm/dd"}
        placeholderText={placeholder || "test"}
        onChange={onChange}
        selected={value ? new Date(value) : null}
        showYearDropdown={showYear}
        showTimeSelect={showTime}
        readOnly={readOnly}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        filterTime={filterTime}
        dayClassName={renderDayClassNames}
        closeOnScroll={true}
        locale={ko}
        timeIntervals={timeIntervals}
        showTimeSelectOnly={showTimeSelectOnly}
      />
      <img
        src={timeNo ? yeonseiImages.ArrowDown : yeonseiImages.Write}
        alt={"writeButton"}
      />
    </div>
  );
};

export default index;
