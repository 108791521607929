import React from "react";

import { TextAreaForm } from "./styles";

const index = (props) => {
  const { style } = props;
  return <TextAreaForm {...props} className={style} style={{}} />;
};

export default index;
