import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const ErrorBoundaryViewContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > .common-icon {
    margin-right: 50px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    color: ${Colors.PrimaryV01};
    font-size: 35px;

    & > h1 {
      color: ${Colors.PrimaryV01};
      font-size: 50px;
      margin-bottom: 20px;
    }

    & > h2 {
      display: flex;
      color: ${Colors.Gray60};
      font-size: 1.375rem;
      margin-bottom: 10px;
      align-items: flex-end;

      & > p {
        color: ${Colors.Gray60};
        font-size: 0.875rem;
        margin-left: 10px;
      }
    }
  }
`;
