import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;

  background-color: ${Colors.Primary10};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  .title {
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 3;
    font-weight: 600;
    font-size: 1.625rem;
    padding: 0 32px;
  }

  .source {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 18px;
    text-align: center;
    color: ${Colors.Gray80};
    background-color: ${Colors.Gray20};
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 30px;

  .main-title {
    white-space: pre-wrap;
    font-weight: 300;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 50px;
  }

  .question {
    height: 45px;
    border-bottom: solid 3px ${Colors.Primary};
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 3;
    color: ${Colors.Primary};
    margin-bottom: 20px;
  }

  .answer {
    word-break: keep-all;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 25px;
    color: ${Colors.Gray80};
    margin-bottom: 70px;
    & span {
      font-size: 1.1rem;
      font-weight: 600;
      color: ${Colors.Gray80};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
`;
