import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const EmailSection = styled.div`
  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  font-family: "Pretendard", sans-serif;
  font-style: normal;

  .warning {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
  }

  & > img {
    margin-top: ${(props) => (props.appPw ? "90px" : "114px")};
    margin-bottom: 33px;
  }

  & .title {
    margin-bottom: 10px;

    font-weight: 700;
    font-size: 1.375rem;
    line-height: 20px;
  }

  & .sub-title {
    margin-bottom: 10px;
    text-align: center;

    font-size: 1rem;
    font-weight: 500;
    color: ${Colors.Gray60};
    white-space: pre-wrap;
  }

  & .email-box {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 335px;
    height: 40px;
    margin-bottom: 20px;

    background: ${Colors.Gray20};
    border: 1px solid ${Colors.Gray40};

    font-weight: 500;
    font-size: 1rem;
    line-height: 30px;
    color: ${Colors.Gray90};
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
