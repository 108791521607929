import React from "react";

import { ImgBox } from "./styles";

import File from "../../../../common/component/atom/File";

const index = (props) => {
  const { params, onChangeValue } = props;

  return (
    <ImgBox>
      <File
        value={params.inqry_img_url1}
        onChange={(fileUrl) => onChangeValue("inqry_img_url1", fileUrl)}
      />
      {params.inqry_img_url1 && (
        <File
          value={params.inqry_img_url2}
          onChange={(fileUrl) => onChangeValue("inqry_img_url2", fileUrl)}
        />
      )}
      {params.inqry_img_url2 && (
        <File
          value={params.inqry_img_url3}
          onChange={(fileUrl) => onChangeValue("inqry_img_url3", fileUrl)}
        />
      )}
    </ImgBox>
  );
};

export default index;
