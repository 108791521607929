/**
 * 유틸리티 함수
 */

/**
 * @params {string} search
 */
const parse = (search) => {
  const vars = search.substring(1, search.length - 1).split("&");
  const queryString = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");

    const queryValue = decodeURIComponent(pair[1]);
    if (queryValue.includes(",")) {
      queryString[pair[0]] = queryValue.split(",").map((item) => {
        return {
          value: item,
          is_checked: true,
        };
      });
    } else if (pair[0]) {
      queryString[pair[0]] = queryValue;
    }
  }
  return queryString;
};

/**
 * @description query string 을 object 로 변경해주는 함수
 * @param search
 *
 * @returns {ParsedQuery}
 */
export const buildParamsFromUrl = (search) => {
  return parse(search);
};

/**
 * @description 전달된 parameter를 query string 으로 변환해주는 함수
 *
 * @param temp_p
 * @param except
 * @returns {string}
 */
export const buildUrlFromParams = (temp_p, except) => {
  let q = "?";
  for (let key of Object.keys(temp_p)) {
    if (except && except.indexOf(key) < 0) {
      if (temp_p[key] instanceof Object) {
        //체크박스 일 경우와 배열일 경우 둘다 커버
        const checked = (temp_p[key] || [])
          .filter((item) => {
            return item instanceof Object && item.is_checked;
          })
          .map((item) => {
            return item instanceof Object ? item.value : item;
          });
        if (checked.length > 0) {
          q += key + "=" + checked.join(",") + "&";
        }
      } else {
        if (temp_p[key] !== null && temp_p[key] !== "") {
          q += key + "=" + temp_p[key] + "&";
        }
      }
    }
  }

  return q;
};

/**
 * @description 이메일 형식 체크 function
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
  if (email) {
    const regexp =
      /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return !regexp.test(email.trim());
  } else return true;
}

/**
 * @description 비밀번호 형식 체크 function : 영어+숫자 조합, 8~32자
 * @param value
 * @returns {boolean}
 */
export const validatePassword = (value) => {
  if (value) {
    const regexp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,32}$/;
    return !regexp.test(value.trim());
  } else return true;
};

/**
 * @description 전화번호 format : ex. 010-0000-0000
 * @param moblphon
 * @returns {string}
 */
export const moblphonFormatter = (moblphon) => {
  return (moblphon || "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

/**
 * @description 이름으로 쿼리 가져오는 함수
 * @param name {string} 찾으려는 쿼리 필드명
 * @param url {string} 찾으려는 주소
 *
 * @returns {string}
 */
export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * @description 범위로 시간 string 리턴해주는 함수
 * @param from {number} 찾으려는 쿼리 필드명 || default = 0;
 * @param to {number} 찾으려는 주소
 *
 * @returns {array}
 */
export const getHourArray = (from = 0, to) => {
  return Array.from(
    { length: to - from + 1 },
    (v, i) => (from + i < 10 ? "0" : "") + String(from + i) + " : 00"
  );
};

/**
 * @description 임의의 문자열을 만드는 함수
 *
 * @return {string}
 */

export const generateRandomString = () => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};
