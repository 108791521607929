import { REHYDRATE } from "redux-persist/es/constants";
import * as ActionTypes from "../actionTypes";

const initialState = {};

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.eSESSION:
      return Object.assign({}, state, { ...action.eSESSION });
    case ActionTypes.eSESSION_RESET:
      return Object.assign({}, state, { account: {}, isLogin: false });
    case REHYDRATE:
      console.log("REHYDRATE", action?.payload?.eSESSION);
      return Object.assign({}, state, action?.payload?.eSESSION);
    default:
      return state;
  }
};
