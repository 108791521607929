import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

// Styles
import { FeelingManageBox } from "./styles";
import { Colors, yeonseiImages } from "../../../../styles";

// Components
import Button from "../../../../common/component/atom/Button";
import Select from "../../../../common/component/atom/Select";
import TextArea from "../../../../common/component/atom/TextArea";
import InputForm from "../../../../common/component/atom/InputForm";
import Emoticons from "../../../../component/molecule/Emoticons";
import FeelingSlider from "./FeelingSlider";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";
import Sliders from "../../../../component/atom/Slider";

const index = (props) => {
  const { timeNo, params, onChangeValue, onHandleButton } = props;
  const navigate = useNavigate();
  const oneButtonModalHandler = handlerModal();
  const recordTimeText = (timeState) => {
    switch (timeState) {
      case "ATC00001":
        return { time: "기상시간", status: params?.record_dt };
      case "ATC00002":
        return { time: "취침시간", status: params?.record_dt };
      case "ATC00003":
        return {
          time: "하루 중 정서적으로 가장 힘들 때",
          status: params?.record_dt,
        };
      case "ATC00004":
        return { time: "그외 시간", status: params?.etc_time };
    }
  };
  const record = recordTimeText(params.timeState || {});
  console.log(params.emoticonImageSrc);
  return (
    <FeelingManageBox timeNo={timeNo}>
      <div className={"title"}>
        {timeNo ? "그날 그때, 나의 감정" : "오늘 기분은 어떤가요?"}
      </div>
      <div className={"profile"}>
        <div className={"left"}>
          <img
            alt={"smileEmoticon"}
            src={
              yeonseiImages[params.emoticonImageSrc || "SosoSmallUnselected"]
            }
            onError={(e) => {
              e.target.src = yeonseiImages.SosoMedium;
            }}
          />
          <div className={"emoticonDescription"}>
            {params.emotion_status_nm}
          </div>
        </div>
        <div className={"right"}>
          <div className={"title"}>기록시간</div>
          <div className={"recordTimeState"}>
            {record?.time || ""}
            {"   "}
            {record?.status || ""}
          </div>
        </div>
      </div>
      <div className={"emoticons"}>
        <Emoticons
          showDescription
          selected={params.emotion_status}
          onClick={(e) => onChangeValue("emotion_status", e.target.alt)}
        />
      </div>
      <div className={"recordTime"}>
        <div className={"title"}>기록 시간</div>
        <Select
          value={params.timeState}
          placeholder={"선택"}
          options={[
            { label: "기상 시간", value: "ATC00001" },
            { label: "취침 시간", value: "ATC00002" },
            { label: "하루 중 정서적으로 가장 힘들 때", value: "ATC00003" },
            { label: "그 외 시간", value: "ATC00004" },
          ]}
          size={"large"}
          onChange={(value) => onChangeValue("timeState", value)}
        />
        {params.timeState === "ATC00004" && (
          <InputForm
            value={params.etc_time}
            placeholder={"어떤 시간대인가요?"}
            style={css`
              display: ${params.timeState === "etc" ? "block" : "none"};
              margin-top: 20px;
            `}
            onChange={(e) => onChangeValue("etc_time", e.target.value)}
          />
        )}
      </div>
      {params.timeState === "ATC00002" && (
        <div className={"sleep-time-select-box"}>
          <div className={"slider-title"}>오늘 자살 생각 정도</div>
          <div className={"trialSlider"}>
            <Sliders
              labelMin={"0%"}
              labelMax={"100%"}
              onChangeValue={onChangeValue}
              value={params.suicide}
              field={"suicide"}
              disabled={!!timeNo}
            />
          </div>
          <div className={"selectTrial"}>
            <div className={"title"}>오늘 자살 시도 행위</div>
            <Button
              label={params.suicide_try === "Y" ? "있다" : "없다"}
              variant={"painted"}
              size={"medium"}
              style={css`
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 50px;
              `}
            />
            <div className={"title"}>오늘 자살 시도 계획</div>
            <Button
              label={params.suicide_plan === "Y" ? "있다" : "없다"}
              variant={"painted"}
              size={"medium"}
              style={css`
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 50px;
              `}
            />
          </div>
        </div>
      )}
      <FeelingSlider
        timeNo={timeNo}
        params={params}
        field={"happiness"}
        onChangeValue={onChangeValue}
        disabled={!!timeNo}
      />
      <FeelingSlider
        timeNo={timeNo}
        params={params}
        field={"depression"}
        onChangeValue={onChangeValue}
        disabled={!!timeNo}
        labelMin={"매우 그러함"}
        labelMax={"전혀 아님"}
      />
      <FeelingSlider
        timeNo={timeNo}
        params={params}
        field={"anxiety"}
        onChangeValue={onChangeValue}
        disabled={!!timeNo}
        labelMin={"매우 그러함"}
        labelMax={"전혀 아님"}
      />
      <FeelingSlider
        timeNo={timeNo}
        params={params}
        field={"emotion_stblty"}
        onChangeValue={onChangeValue}
        disabled={!!timeNo}
      />
      <FeelingSlider
        timeNo={timeNo}
        params={params}
        field={"stress"}
        onChangeValue={onChangeValue}
        disabled={!!timeNo}
        labelMin={"매우 그러함"}
        labelMax={"전혀 아님"}
      />
      <div className={"freeWrite"}>
        <div className={"title"}>자유 작성</div>
        <TextArea
          value={params.emotion_cont}
          onChange={(e) => onChangeValue("emotion_cont", e.target.value)}
          placeholder={
            "오늘 기분, 감정을 자유롭게 작성해보세요.\n" +
            "\n" +
            "예) 시원한 바람이 불어서 평소보다 기분이 들떴다.\n"
          }
          maxLength={500}
          style={css`
            flex: 1;
            width: 100%;
            height: 146px;
            resize: none;
          `}
        />
      </div>
      <div className={"buttonBox"}>
        {!timeNo && (
          <Button
            label={"저장"}
            variant={"painted"}
            size={"xLarge"}
            style={css`
              font-size: 1.25rem;
              font-weight: 600;
              background-color: ${Colors.Primary};
            `}
            onClick={() => {
              if (onHandleButton() === true) {
                onChangeValue("post_yn", true);
              }
              oneButtonModalHandler.onToggleModal();
            }}
          />
        )}
      </div>
      <OneButtonModal
        label={"확인"}
        handler={oneButtonModalHandler}
        buttonClick={() => {
          params.cnt > 10 || params.post_yn === true
            ? navigate("/main/feelingCalendar")
            : oneButtonModalHandler.onToggleModal();
        }}
      >
        <div className={"in-modal-box"}>
          <img
            src={
              params.cnt > 10
                ? yeonseiImages.ExclamationMarkMiddle
                : params.post_yn === true
                ? yeonseiImages.CheckAble
                : yeonseiImages.ExclamationMarkMiddle
            }
            alt={"exclamation"}
          />
          {params.post_yn ? "저장이 완료되었습니다." : params.errMsg}
          {params.errMsg === "필수값을 입력해주세요." && !params.post_yn && (
            <span className={"error"}>
              필수값: 오늘 기분은 어떤가요, 기록시간
            </span>
          )}
        </div>
      </OneButtonModal>
    </FeelingManageBox>
  );
};

export default index;
