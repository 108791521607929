import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const FeelingCalendar = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 20px;

  width: 100%;
  min-height: 100%;
`;
