import React, { useEffect } from "react";

//style
import { ManageCallListBox } from "./styles";
import { yeonseiImages } from "../../../../styles";

//component
import Button from "../../../../common/component/atom/Button";

const index = (props) => {
  const {
    list,
    isClicked,
    fetchDeletePeople,
    fetchUpdateContact,
    getEventFromAndroid,
  } = props;

  useEffect(() => {
    window.addEventListener("getContactFunction", getEventFromAndroid);

    return () => {
      window.removeEventListener("getContactFunction", getEventFromAndroid);
    };
  }, []);

  return (
    <ManageCallListBox>
      {list.map((el) => (
        <>
          {!isClicked && (
            <div className={"item-box"}>
              {el.people_nm}
              <img
                src={yeonseiImages.CallIcon}
                alt={"call-icon"}
                onClick={() => {
                  location.href = "tel:" + el.people_contact;
                  fetchUpdateContact(false);
                }}
              />
            </div>
          )}
          {isClicked && (
            <>
              <div className={"item-box"}>
                {el.people_nm}
                <img
                  src={yeonseiImages.Cancel}
                  alt={"remove"}
                  onClick={() => {
                    fetchDeletePeople(el.people_no);
                  }}
                />
              </div>
            </>
          )}
        </>
      ))}
      {list.length < 3 && isClicked && (
        <Button
          variant={"plusIcon"}
          size={"midIcon"}
          img={yeonseiImages.PlusCircleGray}
          onClick={() => {
            if (/android/i.test(navigator.userAgent)) {
              window.android.getInfoFromContact();
            } else if (
              !(
                navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
                navigator.maxTouchPoints !== 5
              )
            ) {
              if (
                window.webkit &&
                window.webkit?.messageHandlers &&
                window.webkit?.messageHandlers?.iosHandler
              ) {
                webkit.messageHandlers.iosHandler.postMessage(
                  "getInfoFromContact"
                );
              }
            }
          }}
        />
      )}
    </ManageCallListBox>
  );
};

export default index;
