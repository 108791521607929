import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const HelpLinePopUp = styled.div`
  & > div.topDescription {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 25px;
    white-space: pre-line;
    padding-bottom: 24px;

    border-bottom: solid 1px ${Colors.Gray30};
  }

  & > div.modalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    padding-bottom: 20px;
    margin-top: 20px;

    border-bottom: solid 1px ${Colors.Gray30};

    & > div.center {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 25px;

      & > span {
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;

        color: #535353;
      }
    }

    &:nth-of-type(1) {
      margin-top: 0;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 24px;
    }
  }

  & > div.bottomDescription {
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    white-space: pre-line;
    padding: 24px 0;

    border-bottom: solid 1px ${Colors.Gray30};
  }
`;
