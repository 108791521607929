import { css } from "@emotion/css";

export const Colors = {
  Shadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
  /* CORE-SYSTEM COLOR SET - START  */
  Black: "#0a0a0a",
  Black40: "#404040",

  Gray20: "#f5f5f5",
  Gray30: "#ededed",
  Gray40: "#e0e0e0",
  Gray50: "#c2c2c2",
  Gray60: "#9e9e9e",
  Gray70: "#757575",
  Gray80: "#616161",
  Gray90: "#404040",
  White: "#FFFFFF",

  Primary: "#f2a93c",
  PrimaryOpacity: "rgba(242, 169, 60, 0.1)",
  Primary10: "#fff7e3", // 임의 추가

  Beige: "#FDF6E8",
  Disable: "rgba(242, 169, 60, 0.3)",
  Error: "#DA1E28",
  Confirmed: "#35D47D",
  Focus: "#6CD9B6",
  Period: "#F2F5FF",
  Background: "#F5F5F5",
  Link: "#466EEC",
  Toggle: "#00C787",
  /* CORE-SYSTEM COLOR SET - END  */

  /* SLIDER-COMPONENT COLOR SET - START*/
  Rail: "#D9D9D9",
  Depression: "#83A3FC",
  Anxiety: "#F0799D",
  EmotionalStability: "#5DB4BF",
  Stress: "#F09379",
  /* SLIDER-COMPONENT COLOR SET - END*/
};

/**
 * @description 연세대 프로젝트 service 에서 사용하는 이미지 assets
 */
export const yeonseiImages = {
  Logo: "/assets/client/yeonsei/bg/auth-intro-logo.webp",
  RecommendLoading: "/assets/client/yeonsei/ico/ic-recommend-loading.gif",

  CheckAble: "/assets/client/yeonsei/ico/ic-check-abled.svg",
  CheckAbleLarge: "/assets/client/yeonsei/ico/ic-check-able-large.svg",
  CheckDisable: "/assets/client/yeonsei/ico/ic-check-disabled.svg",
  Cancel: "/assets/client/yeonsei/ico/ic-cancel.svg",
  Backspace: "/assets/client/yeonsei/ico/ic-backspace.svg",
  Plus: "/assets/client/yeonsei/ico/ic-plus.svg",
  PlusGray: "/assets/client/yeonsei/ico/ic-plus-gray.svg",
  PlusBlack: "/assets/client/yeonsei/ico/ic-plus-black.svg",
  Arrow: "/assets/client/yeonsei/ico/ic-arrow.svg",
  ArrowLeft: "/assets/client/yeonsei/ico/ic-arrow-left.svg",
  ArrowRight: "/assets/client/yeonsei/ico/ic-arrow-right.svg",
  ArrowRightGray: "/assets/client/yeonsei/ico/ic-arrow-right-gray.svg",
  ArrowRightThin: "/assets/client/yeonsei/ico/ic-arrow-right-thin.svg",
  ArrowUpThin: "/assets/client/yeonsei/ico/ic-arrow-up-thin.svg",
  ArrowDown: "/assets/client/yeonsei/ico/ic-arrow-down.svg",
  ArrowDownThin: "/assets/client/yeonsei/ico/ic-arrow-down-thin.svg",
  Calendar: "/assets/client/yeonsei/ico/ic-calendar.svg",
  CalendarHover: "/assets/client/yeonsei/ico/ic-calendar-hover.svg",
  Call: "/assets/client/yeonsei/ico/ic-call.svg",
  CallBtn: "/assets/client/yeonsei/ico/ic-call-btn.svg",
  Camera: "/assets/client/yeonsei/ico/ic-camera.svg",
  CheckBorderAble: "/assets/client/yeonsei/ico/ic-check-border-able.svg",
  CheckBorderDisable: "/assets/client/yeonsei/ico/ic-check-border-disable.svg",
  CheckBorderDisableDark:
    "/assets/client/yeonsei/ico/ic-check-border-disable_ver2.svg",
  Close: "/assets/client/yeonsei/ico/ic-close.svg",
  Copy: "/assets/client/yeonsei/ico/ic-copy.svg",
  CustomerService: "/assets/client/yeonsei/ico/ic-customer-service.svg",
  ExclamationMark: "/assets/client/yeonsei/ico/ic-exclamation-mark.svg",
  ExclamationMarkMiddle:
    "/assets/client/yeonsei/ico/ic-exclamation-mark-middle.svg",
  ExclamationMarkLarge:
    "/assets/client/yeonsei/ico/ic-exclamation-mark-large.svg",
  Export: "/assets/client/yeonsei/ico/ic-export.svg",
  Hamburger: "/assets/client/yeonsei/ico/ic-hamburger.svg",
  HeartRate: "/assets/client/yeonsei/ico/ic-heart-rate.svg",
  Home: "/assets/client/yeonsei/ico/ic-home.svg",
  HospitalBuilding: "/assets/client/yeonsei/ico/ic-hospital-building.svg",
  Link: "/assets/client/yeonsei/ico/ic-link.svg",
  Notification: "/assets/client/yeonsei/ico/ic-notification.svg",
  NotificationHover: "/assets/client/yeonsei/ico/ic-notification-hover.svg",
  PlusHeart: "/assets/client/yeonsei/ico/ic-plus-heart.svg",
  Search: "/assets/client/yeonsei/ico/ic-search.svg",
  Setting: "/assets/client/yeonsei/ico/ic-setting.svg",
  SettingHover: "/assets/client/yeonsei/ico/ic-setting-hover.svg",
  Share: "/assets/client/yeonsei/ico/ic-share.svg",
  User: "/assets/client/yeonsei/ico/ic-user.svg",
  Write: "/assets/client/yeonsei/ico/ic-write.svg",
  HomeHover: "/assets/client/yeonsei/ico/ic-home-hover.svg",
  Email: "/assets/client/yeonsei/ico/ic-email.svg",
  SafeBanner: "/assets/client/yeonsei/ico/ic-safe-banner.svg",
  FeelingBanner: "/assets/client/yeonsei/ico/ic-feeling-banner.svg",
  OldPhone: "/assets/client/yeonsei/ico/ic-old-phone.png",
  BackgroundSample: "/assets/client/yeonsei/ico/ic-main-image-sample.png",
  CallList: "/assets/client/yeonsei/ico/ic-call-list.svg",
  CallListAdded: "/assets/client/yeonsei/ico/ic-call-list-added.svg",
  PlusCircleGray: "/assets/client/yeonsei/ico/ic-plus-circle-gray.svg",
  Note: "/assets/client/yeonsei/ico/ic-note-picture.svg",
  QuestionMark: "/assets/client/yeonsei/ico/ic-question-mark.png",
  WritePrimary: "/assets/client/yeonsei/ico/ic-write-primary.svg",
  Trashcan: "/assets/client/yeonsei/ico/ic-trashcan.svg",
  PhoneCall: "/assets/client/yeonsei/ico/ic-phoneCall-primary.svg",
  ShareBox: "/assets/client/yeonsei/ico/ic-shareBox.svg",
  MindCafe: "/assets/client/yeonsei/ico/ic-mindCafe.png",
  Google: "/assets/client/yeonsei/ico/ic-google.png",
  Apple: "/assets/client/yeonsei/ico/ic-apple.png",
  QuoteLeft: "/assets/client/yeonsei/ico/ic-quote-left.svg",
  QuoteRight: "/assets/client/yeonsei/ico/ic-quote-right.svg",
  YeonseiNurse: "/assets/client/yeonsei/ico/ic-yeonseiNurse-logo.png",
  RadioAble: "/assets/client/yeonsei/ico/ic-radio-able.png",
  RadioDisable: "/assets/client/yeonsei/ico/ic-radio-disable.png",
  CallIcon: "/assets/client/yeonsei/ico/ic-call-icon.png",
  SilderArrowLeft: "/assets/client/yeonsei/ico/ic-middle-arrow-left.png",
  SilderArrowRight: "/assets/client/yeonsei/ico/ic-middle-arrow-right.png",

  // Help Call
  SuicidePrevision: "/assets/client/yeonsei/ico/ic-suicide-prevision.svg",
  MentalHealth: "/assets/client/yeonsei/ico/ic-mental-health.svg",
  Lifeline: "/assets/client/yeonsei/ico/ic-lifeline.svg",
  EmergencyRescue: "/assets/client/yeonsei/ico/ic-emergency-rescue.svg",
  SuicidePrevisionIcon:
    "/assets/client/yeonsei/ico/ic-suicide-prevision-small.png",
  MentalHealthIcon: "/assets/client/yeonsei/ico/ic-mental-health-small.png",
  LifelineIcon: "/assets/client/yeonsei/ico/ic-lifeline-small.png",
  EmergencyRescueIcon:
    "/assets/client/yeonsei/ico/ic-emergency-rescue-small.png",

  // Emotion
  SmileLarge: "/assets/client/yeonsei/ico/ic-emotion-smile-large.svg",
  SmileMedium: "/assets/client/yeonsei/ico/ic-emotion-smile-medium.svg",
  SosoMedium: "/assets/client/yeonsei/ico/ic-emotion-soso-medium.svg",
  UpsetMedium: "/assets/client/yeonsei/ico/ic-emotion-upset-medium.svg",
  WorseMedium: "/assets/client/yeonsei/ico/ic-emotion-worse-medium.svg",
  CryMedium: "/assets/client/yeonsei/ico/ic-emotion-cry-medium_ver2.svg",
  SmileMediumUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-smile-medium-unselected.svg",
  SosoMediumUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-soso-medium-unselected.svg",
  UpsetMediumUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-upset-medium-unselected.svg",
  WorseMediumUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-worse-medium-unselected.svg",
  CryMediumUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-cry-medium-unselected.svg",
  SmileSmall: "/assets/client/yeonsei/ico/ic-emotion-smile-small.svg",
  SosoSmall: "/assets/client/yeonsei/ico/ic-emotion-soso-small.svg",
  UpsetSmall: "/assets/client/yeonsei/ico/ic-emotion-upset-small.svg",
  WorseSmall: "/assets/client/yeonsei/ico/ic-emotion-worse-small.svg",
  CrySmall: "/assets/client/yeonsei/ico/ic-emotion-cry-small_ver2.svg",
  SmileSmallUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-smile-small-unselected.svg",
  SosoSmallUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-soso-small-unselected.svg",
  UpsetSmallUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-upset-small-unselected.svg",
  WorseSmallUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-worse-small-unselected.svg",
  CrySmallUnselected:
    "/assets/client/yeonsei/ico/ic-emotion-cry-small-unselected.svg",

  LifeCall: "/assets/client/yeonsei/ico/ic-life-call.svg",
  MentalHealthSmall: "/assets/client/yeonsei/ico/ic-mental-health-small.svg",
  PreventCall: "/assets/client/yeonsei/ico/ic-prevent-call.svg",
  EmergencyRescueSmall:
    "/assets/client/yeonsei/ico/ic-emergency-rescue-small.svg",

  // BackGround
  KnowSuicide: "/assets/client/yeonsei/bg/bg-knowSuicide.svg",
  MisUnderStandingTruth:
    "/assets/client/yeonsei/bg/bg-misunderstanding-truth.svg",
  Body: "/assets/client/yeonsei/bg/bg-wtkm-body4x.png",
  Eat: "/assets/client/yeonsei/bg/bg-wtkm-eat4x.png",
  Drug: "/assets/client/yeonsei/bg/bg-wtkm-drug4x.png",
  Sleep: "/assets/client/yeonsei/bg/bg-wtkm-sleep4x.png",
  Exercise: "/assets/client/yeonsei/bg/bg-wtkm-exercise4x.png",
  SuicidePrevisionBackGround:
    "/assets/client/yeonsei/bg/bg-suicidePrevision.svg",
};

/**
 * @description pages/blog, pages/introduce 에서 사용하는 이미지 assets
 */
// prettier-ignore
// prettier-ignore
export const Icons = {
  error: { src: "error" },
  confirm: { src: "check" },
  add: { src: "add", type: "material-icons-round" },
  remove: { src: "remove", type: "material-icons-outlined" },

  navigationBefore: { src: "navigate_before" },
  navigationNext: { src: "navigate_next" },
  doubleBefore: { src: "keyboard_double_arrow_left", type: "material-icons-outlined" },
  doubleNext: { src: "keyboard_double_arrow_right", type: "material-icons-outlined" },

  datePicker: { src: "date_range", type: "material-icons-outlined" },
  settings: { src: "settings", type: "material-icons-outlined" },
  fileDownload: { src: "file_download", type: "material-icons-outlined" },

  reportProblem: { src: "report_problem", type: "material-icons-outlined" },

  checkbox: { src: "check_box" },
  checkboxBlank: { src: "check_box_outline_blank" },
  checkboxDisabled: { src: "indeterminate_check_box", type: "material-icons-outlined" },
  checkboxIndeterminate: { src: "indeterminate_check_box" },

  radio: { src: "radio_button_checked", type: "material-icons-outlined" },
  radioBlank: { src: "radio_button_unchecked", type: "material-icons-outlined" },
  radioDisabled: { src: "remove_circle_outline", type: "material-icons-outlined" },

  expandMore: { src: "expand_more" },
  expandLess: { src: "expand_less" },
  unfoldMore: { src: "unfold_more" },
  doubleArrowDown: { src: "keyboard_double_arrow_down", type: "material-icons-outlined" },
  doubleArrowUp: { src: "keyboard_double_arrow_up", type: "material-icons-outlined" },

  close: { src: "close", type: "material-icons-outlined" },
  close_fill: { src: "cancel" },
  search: { src: "search" },
  refresh: { src: "refresh", type: "material-icons-outlined" },
  link: { src: "link", type: "material-icons-outlined" },
  openInNew: { src: "open_in_new", type: "material-icons-outlined" },

  menu: { src: "menu", type: "material-icons-outlined" },
  email: { src: "email", type: "material-icons-outlined" },
  lock: { src: "lock", type: "material-icons-outlined" },

  desktop: { src: "desktop_windows", type: "material-icons-outlined" },
  people: { src: "people", type: "material-icons-outlined" },
  home: { src: "home", type: "material-icons-outlined" },
  apps: { src: "apps", type: "material-icons-outlined" },

  edit: { src: "edit", type: "material-icons-outlined" },
  addPhoto: { src: "add_a_photo", type: "material-icons-outlined" },
  addPhotoAlternate: { src: "add_photo_alternate", type: "material-icons-outlined" },
  wallpaper: { src: "wallpaper", type: "material-icons-outlined" },

  logout: { src: "logout", type: "material-icons-outlined" },

  addCircle: { src: "add_circle", type: "material-icons-outlined" },
  starOutlined: { src: "star_outlined", type: "material-icons-outlined" },
  star: { src: "star", type: "material-icons-outlined" },
  more: { src: "more_vert", type: "material-icons-sharp" },
  notes: { src: "notes", type: "material-icons-round" },
  schedule: { src: "schedule", type: "material-icons-outlined" },
  cached: { src: "cached", type: "material-icons-outlined" },

  arrowDropUp: { src: "arrow_drop_up", type: "material-icons-outlined" },
  arrowDropDown: { src: "arrow_drop_down", type: "material-icons-outlined" },

  sentimentDissatisfied: { src: "sentiment_very_dissatisfied", type: "material-icons-outlined" },
};

export const globalStyle = {
  header1: css`
    font-size: 40px;
    line-height: 48px;
  `,
  header2: css`
    font-size: 32px;
    line-height: 40px;
  `,
  header3: css`
    font-size: 1.625rem;
    line-height: 32px;
  `,
  header4: css`
    font-size: 1.375rem;
    line-height: 24px;
  `,
  header5: css`
    font-size: 1.1rem;
    line-height: 20px;
  `,
  bodyPoint: css`
    font-size: 1.375rem;
    line-height: 28px;
  `,
  body1: css`
    font-size: 1.1rem;
    line-height: 24px;
  `,
  body2: css`
    font-size: 1rem;
    line-height: 20px;
  `,
  caption: css`
    font-size: 0.875rem;
    line-height: 16px;
  `,
};
