import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const FileUploadSection = styled.div`
  width: 100%;

  & .img-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 6px;

    & .imgItem {
      width: calc((100vw - 100px) / 3);
      height: calc((100vw - 100px) / 3);
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      margin-right: 6px;

      &:nth-of-type(n + ${(props) => props.col_limit + 1}) {
        margin-top: ${(props) => `${props.marginTop}px`};
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  & .help-msg {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 4px;
    font-size: 0.9rem;
    color: ${Colors.Gray};
    font-weight: 500;
    line-height: 1.85em;
  }
`;
