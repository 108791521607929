import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const LoginSection = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
