import styled from "@emotion/styled";

export const SafetyPlanSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  .in-modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
      margin-top: 30px;
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 1.375rem;
      text-align: center;
      line-height: 1.5;
    }
  }

  .warning-modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 20px;

    & img {
      margin-bottom: 20px;
    }
  }
`;
