import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 13px 20px 50px 20px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
  background: ${Colors.White};
  border-radius: 10px;
  margin-bottom: 20px;

  .info-zone {
    display: flex;
    flex-direction: column;

    .answer-type {
      display: flex;
      align-items: center;
      width: 70px;
      height: 24px;
      border: solid 1px
        ${(props) =>
          props.isAnswered === true ? Colors.Primary : Colors.Gray60};
      border-radius: 5px;
      padding: 6px 8px 6px 10px;
      font-weight: 400;
      font-size: 1rem;
      line-height: 12px;
      color: ${(props) =>
        props.isAnswered === true ? Colors.Primary : Colors.Gray60};
      margin-bottom: 10px;
      text-align: center;
    }
    .title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;
      color: ${Colors.Gray90};
      margin-bottom: 10px;
    }
    .date {
      font-weight: 400;
      font-size: 1rem;
      line-height: 14px;
    }
  }

  .detail-zone {
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .answer-zone {
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
  }

  .img-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    & img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
  }
`;

export const BorderLine = styled.line`
  border: solid 1px ${Colors.Gray40};
  margin-top: 18px;
  margin-bottom: 20px;
`;
