import { Colors } from "../../../styles";

export const test_data = [
  {
    id: "test",
    color: "hsl(8, 70%, 50%)",
    data: [
      {
        x: "08:23",
        y: 0,
      },
      {
        x: "15::00",
        y: 75,
      },
      {
        x: "23:00",
        y: 100,
      },
    ],
  },
];
