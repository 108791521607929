import styled from "@emotion/styled";

export const ExceptionVerifySection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
