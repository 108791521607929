import React, { useState } from "react";
import { css } from "@emotion/css";

// styles
import { LibrariesMain } from "./styles";
import { Colors, Icons, yeonseiImages } from "../../../../styles";

// Common
import Icon from "../../../../common/component/atom/Icon";
import SlidingView from "../../../../common/component/template/SlidingView";

// Component
import KoreaMap from "../../../../component/molecule/KoreaMap";
import TimePicker from "../../../../component/atom/TimePicker";
import {
  handlerModal,
  OneButtonModal,
} from "../../../../component/organism/Modal";
import Chart from "../../../../component/atom/StatisticChart";
import CustomCalendar from "../../../../component/molecule/Calendar";

// Config
import { test_data } from "../../../../component/atom/StatisticChart/config";

const index = () => {
  const oneButtonModalHandler = handlerModal();
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState("00 : 00");

  return (
    <LibrariesMain>
      <section>
        <div>
          <h4>왼쪽 슬라이딩 뷰</h4>
          <Icon
            icon={Icons.menu}
            size={"24px"}
            color={Colors.Gray50}
            style={css`
              cursor: pointer;
            `}
            onClick={() => setIsOpen(true)}
          />
        </div>
        <div>
          <h4>시간 설정</h4>
          {/*<TimePicker onChangeValue={(data) => setTime(data.value)} />*/}
        </div>
        <div>
          <h4>캘린더</h4>
          <CustomCalendar />
        </div>
      </section>
      <section>
        <div className="component_row">
          <h4>한국지도</h4>
          <KoreaMap
            onChangeValue={(e) => {
              if (e.target.id) {
                oneButtonModalHandler.onChangeModalValue(
                  "district",
                  e.target.id,
                  true
                );
              }
            }}
          />
          <OneButtonModal
            handler={oneButtonModalHandler}
            label={"닫기"}
            buttonClick={(e) => oneButtonModalHandler.onToggleModal()}
          >
            <div className={"mapModalContent"}>
              {oneButtonModalHandler?.params?.district}
            </div>
          </OneButtonModal>
        </div>
        <div>
          <h4>차트</h4>
          <Chart data={test_data} />
        </div>
      </section>
      <SlidingView isOpen={isOpen} setIsOpen={setIsOpen}>
        <div
          className={css`
            overflow: hidden;
            height: 100vh;
            width: 286px;
            padding: 15px;
          `}
        >
          햄버거 버튼 클릭시 슬라이딩뷰 내에서 사용하는 children 입니다.
          <br />
          overflow: hidden;
          <br />
          width: 286px;
          <br />
          조건이 있어야 슬라이딩이 열고 닫힐때 children이 어그러지지 않습니다.
        </div>
      </SlidingView>
    </LibrariesMain>
  );
};

export default index;
