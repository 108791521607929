import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const FileBox = styled.div`
  display: inline-flex;
  align-items: center;
  flex: 1;

  & > label {
    height: 32px;
    padding: 0 10px;
    border: solid 1px ${Colors.Gray40};
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    display: none;
    align-items: center;
    margin-right: 8px;

    & > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & > div {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      left: 0;
      overflow: hidden;
    }
  }

  & input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: none;
  }
`;
