import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MyPageSection = styled.section`
  display: flex;
  flex-direction: column;

  padding: 20px;

  .profile-box {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 20px;

    .profile-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 0;
    }
    & img {
      margin-left: auto;
    }
  }

  .navigate-button {
    height: 64px;
    display: flex;
    align-items: center;
    flex-direction: row;
    line-height: 3.5;
    font-weight: 600;

    border-bottom: solid 1px ${Colors.Gray20};

    & > div {
      font-size: 1.25rem;
    }

    & img {
      margin-left: auto;
      width: 19px;
      height: 19px;
    }
  }
`;
