import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;

  background-color: ${Colors.Primary10};

  & > div.titleBox {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 30px 0;

    & > div.title {
      font-weight: 600;
      font-size: 1.25rem;
    }

    & > img {
      width: 21px;
      height: 21px;

      margin-left: 10px;
    }

    .date-selector {
      font-weight: 600;
      font-size: 1.25rem;

      & > img {
        margin-left: 20px;
      }
    }
  }

  .in-modal-list {
    width: 100%;
    max-height: 240px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    .item-box {
      text-align: center;
      font-weight: 500;
      font-size: 1.375rem;
      height: 60px;
      padding: 20px;

      border-bottom: solid 1px ${Colors.Gray40};
    }
  }
`;
