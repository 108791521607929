import React, { useState } from "react";

//Styles
import { TimePickerWrapper } from "./styles";

//Components
import TabMenuBox from "../../../../common/component/molecule/TabMenuBox";
import TimePicker from "../../../../component/atom/TimePicker";

//Hooks
import useOutsideClick from "../../../../hooks/useOutsideClick";

const index = (props) => {
  const { params, index, onChangeValue } = props;
  const [isClicked, setIsClicked] = useState(false);
  const alarmRef = useOutsideClick((e) => setIsClicked(false));
  const onHandleClick = () => {
    setIsClicked((preClicked) => preClicked === false);
  };

  return (
    <TimePickerWrapper ref={alarmRef} size={isClicked} onClick={onHandleClick}>
      <div className={"time-title"}>{params.title}</div>
      {!isClicked && (
        <div className={"selected-time"}>
          <div className={"meridiem"}>{params.meridiem}</div>
          <div className={"time"}>{params.time}</div>
        </div>
      )}
      {isClicked && (
        <div className={"time-select-zone"}>
          <TabMenuBox
            tabSize={"small"}
            size={"middle"}
            tabs={[
              { label: "AM", value: "AM" },
              { label: "PM", value: "PM" },
            ]}
            value={params.meridiem}
            onChangeValue={(data, e) => {
              e.stopPropagation();
              onChangeValue("meridiem", index, data.value);
            }}
          />
          <TimePicker
            onChangeValue={(data) => onChangeValue("time", index, data.value)}
            value={params.time}
          />
        </div>
      )}
    </TimePickerWrapper>
  );
};

export default index;
