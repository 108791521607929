import * as ActionTypes from "../actionTypes";

export const setCommCd = (params) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.eCOMM_CD, eCOMM_CD: params });
    return true;
  };
};

export const setCommCdType = (params) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.eCOMM_CD_TYPE, eCOMM_CD_TYPE: params });
    return true;
  };
};
