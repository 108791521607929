import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const SliderContainer = styled.div`
  & .MuiSlider-root {
    margin-bottom: 0;
    position: relative;
  }

  & .MuiSlider-thumb {
    width: 23px;
    height: 23px;
    color: ${(props) =>
      props.value === 0
        ? Colors.Rail
        : props.target === "happiness"
        ? Colors.Primary
        : props.target === "depression"
        ? Colors.Depression
        : props.target === "anxiety"
        ? Colors.Anxiety
        : props.target === "emotion_stblty"
        ? Colors.EmotionalStability
        : props.target === "stress"
        ? Colors.Stress
        : Colors.Primary};

    &.Mui-active {
      box-shadow: 0 0 0 10px
        ${(props) =>
          props.target === "happiness"
            ? Colors.Primary
            : props.target === "depression"
            ? Colors.Depression
            : props.target === "anxiety"
            ? Colors.Anxiety
            : props.target === "emotion_stblty"
            ? Colors.EmotionalStability
            : props.target === "stress"
            ? Colors.Stress
            : Colors.Primary};
    }

    &:before {
      box-shadow: unset;
    }
  }

  & .MuiSlider-rail {
    height: 10px;
    color: ${Colors.Rail};
    opacity: 1;
  }

  & .MuiSlider-track {
    height: 10px;
    color: ${(props) =>
      props.target === "happiness"
        ? Colors.Primary
        : props.target === "depression"
        ? Colors.Depression
        : props.target === "anxiety"
        ? Colors.Anxiety
        : props.target === "emotion_stblty"
        ? Colors.EmotionalStability
        : props.target === "stress"
        ? Colors.Stress
        : Colors.Primary};
  }

  & span.MuiSlider-markLabel {
    top: 60px;
    font-family: Pretendard, "sans-serif";
    font-size: 1rem;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0;

    transform: translateX(0%);

    &[data-index="2"] {
      transform: translateX(-100%);
    }
  }

  & span.MuiSlider-mark[data-index="2"] {
    background-color: transparent;
  }
`;
