import React, { useEffect, useLayoutEffect, useState } from "react";

import * as RestApi from "../common/context/api";

const index = () => {
  const fetchFirebaseToken = (event) => {
    let apiParams = {};
    const tempParams = event.detail || {};

    if (tempParams.fcm_token) {
      if (/android/i.test(navigator.userAgent)) {
        apiParams = {
          firebase_token: tempParams.fcm_token,
          firebase_os_cd: "android",
        };
      } else if (
        !(
          navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
          navigator.maxTouchPoints !== 5
        )
      ) {
        apiParams = {
          firebase_token: tempParams.fcm_token,
          firebase_os_cd: "ios",
        };
      }
      if (Object.keys(apiParams).length > 0) {
        RestApi.post("eMBER_USER_UPDATE", apiParams)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      console.log("파이어베이스 토큰 값이 가져와지지 않았습니다.");
    }
  };

  const refreshFirebaseToken = () => {
    if (/android/i.test(navigator.userAgent)) {
      if (window.android) {
        window.android?.getFirebaseToken();
      }
    } else if (
      !(
        navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
        navigator.maxTouchPoints !== 5
      )
    ) {
      if (
        window.webkit &&
        window.webkit?.messageHandlers &&
        window.webkit?.messageHandlers?.iosHandler
      ) {
        window.webkit.messageHandlers.iosHandler?.postMessage(
          "getFirebaseToken"
        );
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("firebaseTokenCallback", fetchFirebaseToken);

    return () => {
      window.removeEventListener("firebaseTokenCallback", fetchFirebaseToken);
    };
  }, [fetchFirebaseToken, refreshFirebaseToken]);

  return {
    refreshFirebaseToken,
  };
};
export default index;
