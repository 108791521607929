import React from "react";
import { LoadingView } from "./styles";
import { yeonseiImages } from "../../../styles";

const index = () => {
  return (
    <LoadingView>
      <div className={"loading-view-overlay"} />
      <div className={"loading-view-wrap"}>
        <img src={yeonseiImages.RecommendLoading} alt={"loading view image"} />
      </div>
    </LoadingView>
  );
};

export default index;
