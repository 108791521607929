import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import * as RestApi from "../../../common/context/api";
import * as ActionSession from "../../../common/store/action/eSESSION";

export default function () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState({});
  const [errorMsg, setErrorMsg] = useState({
    error: false,
    msg: "",
  });

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchLogin = async () => {
    const api_params = {
      mber_id: params.mber_id,
      mber_pw: params.mber_pw,
      remember_login: params.remember_login,
    };

    await RestApi.post("eMBER_LOGIN", api_params)
      .then((res) => {
        if (res.detail) {
          if (res.detail.mber_pw_failed_cnt >= 5) {
            navigate("/auth/verification");
          } else {
            dispatch(
              ActionSession.setSessionWithToken(
                { account: res.detail, isLogin: true },
                {
                  remember_login: params.remember_login,
                  access_token: res.access_token,
                }
              )
            );
            res.detail.verfi_yn === "Y"
              ? res.detail.mber_app_pw_use_yn === "Y"
                ? navigate("/auth/pin")
                : navigate("/main/main")
              : navigate("/auth/exceptionVerify?mberId=" + api_params.mber_id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        const { error = {}, msg = "" } = err;
        if (error.mber_pw_failed_cnt >= 5) {
          navigate("/auth/verification");
        }
        if (error.type === "USER_MISMATCH") {
          setErrorMsg({ error: true, msg: msg });
        } else if (error.type === "PASSWORD_MISMATCH") {
          setErrorMsg({ error: true, msg: msg });
        } else if (error.type === "EMAIL_PARAM_ERROR") {
          setErrorMsg({ error: true, msg: msg });
        }
      });
  };

  return { params, errorMsg, onChangeValue, fetchLogin };
}
