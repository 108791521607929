import axios from "axios";
import { Const, DateUtil } from "../util";

let API_URL = "/api/v1/";
if (
  location.origin.indexOf("localhost:") !== -1 ||
  location.origin.indexOf("127.0.0.1:") !== -1
) {
  API_URL = "http://localhost:11001/api/v1/";
}

export { API_URL };

const checkAccessToken = () => {
  return (
    localStorage.getItem(Const.LOGIN_TOKEN) ||
    sessionStorage.getItem(Const.LOGIN_TOKEN) ||
    ""
  );
};

const instance = axios.create({
  baseURL: API_URL,
  timeout: 1000,
});

/**
 * @description axios(instance) interceptors request 추가 (access_token)
 */
instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer " + checkAccessToken();
  return config;
});

/**
 * @description axios(instance) interceptors response 추가
 * - 서버의 error response 형태를 return
 * - 서버의 error code 값에 따른 처리 로직을 작성
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorData = error?.response?.data;
    if (errorData.code === 404) console.log("에러코드 404");
    if (errorData.code === 500) console.log("에러코드 500");
    return Promise.reject(errorData);
  }
);

/**
 * @description GET method
 * @param path {string} API path 정보
 * @param parameters {object} API 파라미터 객체
 *
 * @return {Promise<unknown>}
 */
export const get = (path, parameters = {}) => {
  return instance
    .get(path, { params: parameters })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      console.log("/Lib/Api/index.js :: GET :: " + path + " :: Failed!");
      return Promise.reject(err);
    });
};

/**
 * @description POST method
 * @param path {string} API path 정보
 * @param parameters {object} API 파라미터 객체
 *
 * @return {Promise<unknown>}
 */
export const post = (path, parameters = {}) => {
  const api_params = DateUtil.convertDateForParams(parameters);
  return instance
    .post(path, api_params)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      console.log("/Lib/Api/index.js :: POST :: " + path + " :: Failed!");
      return Promise.reject(err);
    });
};

/**
 * @description POST with parameter & file method
 * @param path {string} API path 정보
 * @param parameters {object} API 파라미터 객체
 *
 * @return {Promise<unknown>}
 */
export const postWithFile = (path, parameters = {}) => {
  const api_params = DateUtil.convertDateForParams(parameters);

  const body = new FormData();
  for (const [key, value] of Object.entries(api_params)) {
    if (key === "file") {
      for (let file of value) body.append("file", file);
    } else {
      body.append(key, value);
    }
  }

  return instance
    .post(path, body)
    .then((response) => {
      return Promise.resolve(response.request);
    })
    .catch((err) => {
      console.log("/Data/Api/index.js :: POST :: " + path + " :: Failed!");
      return Promise.reject(err);
    });
};

/**
 * @description PUT method
 * @param path {string} API path 정보
 * @param parameters {object} API 파라미터 객체
 *
 * @return {Promise<unknown>}
 */
export const put = (path, parameters = {}) => {
  const api_params = DateUtil.convertDateForParams(parameters);

  return instance
    .put(path, api_params)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log("/Data/Api/index.js :: PUT :: " + path + " :: Failed!");
      return Promise.reject(err);
    });
};

/**
 * @description DELETE method
 * @param path {string} API path 정보
 * @param parameters {object} API 파라미터 객체
 *
 * @return {Promise<unknown>}
 */
export const del = (path, parameters = {}) => {
  const api_params = DateUtil.convertDateForParams(parameters);

  return instance
    .delete(path, { data: api_params })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log("/Data/Api/index.js :: DELETE :: " + path + " :: Failed!");
      return Promise.reject(err);
    });
};

/**
 * @description POST with file method
 * @param path {string} API path 정보
 * @param parameters {object} API 파라미터 객체
 *
 * @return {Promise<unknown>}
 */
export const upload = (path, parameters = {}) => {
  const body = new FormData();
  for (let file of parameters.file) {
    body.append("file", file);
  }

  return instance
    .post(path, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer ",
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      console.log("/Lib/Api/index.js :: UPLOAD :: " + path + " :: Failed!");
      return Promise.reject(err);
    });
};
