import styled from "@emotion/styled";
import { Colors, globalStyle } from "../../../../../styles";
export const Container = styled.div`
  width: 335px;
  padding: 25px 24px 20px 24px;
  background-color: ${Colors.White};

  .emotion-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
