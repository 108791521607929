import React, { useRef, useState } from "react";
import { cx } from "@emotion/css";

// Styles
import { SelectBoxSizes, SelectBoxStyles, SelectContainer } from "./styles";
import { Colors, Icons } from "../../../../styles";

import Icon from "../Icon";
import useClickOutside from "../../../../hooks/useClickOutside";

/**
 * @description html의 select tag를 대체하는 UI 컴포넌트
 * @param props
 * @param {object} props.options select의 option 목록
 * @example props.options 의 예시
 * {
 *     [
 *         {
 *             label: "오렌지", // field 노출 text
 *             value: "orange" // select value
 *         }
 *     ]
 * }
 * @param {object} props.extraOption select option 목록 이외의 기능 목록
 * @example props.extraOption 의 예시
 * {
 *     [
 *         {
 *             label: "" || React.Element, // field 노출 text || UI
 *             onClick: () => {} // extra option 클릭 시, 동작
 *         }
 *     ]
 * }
 * @param {string} props.placeholder select의 placeholder 값 (default : "선택")
 * @param {string} props.value select의 value 값 (state)
 * @param {function} props.onChange select 값 변경 함수 (state)
 * @param {string} props.variant select UI 표현 형식 ("default" or "ghost")
 * @param {string} props.size select size UI 표현 형식 ("large", "medium", "small")
 *
 * @return {JSX.Element}
 */
const index = (props) => {
  const {
    options,
    extraOptions,
    placeholder,
    value,
    onChange,
    variant,
    size,
    style,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();
  useClickOutside({
    targetRef: selectRef,
    setIsOpen,
  });
  const selectedLabel = options?.find(
    (option) => option.value === value
  )?.label;

  return (
    <SelectContainer
      ref={selectRef}
      isOpen={isOpen}
      className={cx(
        SelectBoxStyles[variant || "default"],
        SelectBoxSizes[size || "large"],
        style
      )}
    >
      <div
        className={cx("select__value", isOpen && "opened")}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedLabel || placeholder || "select"}
        <Icon icon={Icons.expandMore} color={Colors.Gray80} />
      </div>
      {isOpen ? (
        <div className={"select__options"}>
          {options.map((option) => {
            const selected = option.value === value;
            return (
              <div
                key={`select_option_${option.value}`}
                className={cx("select__options_item", selected && "selected")}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            );
          })}
          {extraOptions?.map((option, idx) => {
            const firstExtraOption = idx ? "" : "extra-option";
            return (
              <div
                className={cx("select__options_item", firstExtraOption)}
                onClick={() => {
                  option.onClick();
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      ) : null}
    </SelectContainer>
  );
};

export default index;
