import React from "react";

//Styles
import { HeaderArticle, MainArticle, SafetyPlannerSection } from "./styles";

//Components
import ProgressClickBar from "../../component/ProgressClickBar";
import PlanManager from "../../component/PlanManager";

//hooks
import useSafetyPlanManage from "../../hooks/useSafetyPlanManage";

const index = () => {
  const {
    params,
    beforeList,
    peopleList,
    mainArticleRef,
    scrollData,
    onChangeValue,
    onAddItem,
    fetchPeopleList,
    fetchPlanUpdate,
    fetchPlanComplete,
    onAddBeforeItem,
    fetchUpdateContact,
    fetchDeletePeople,
    getEventFromAndroid,
  } = useSafetyPlanManage();

  return (
    <SafetyPlannerSection>
      <HeaderArticle>
        <div className={"main-title"}>나를 살리는 계획</div>
        <ProgressClickBar data={scrollData} />
      </HeaderArticle>
      <MainArticle ref={mainArticleRef}>
        <PlanManager
          ref={scrollData.refs[0]}
          step={1}
          stepTitle={"STEP1."}
          subTitle={"나의 위험징후"}
          tooltip={"자살 생각이나 기분 또는\n충동을 불러 일으키는 것은?"}
          params={params}
          beforeList={beforeList}
          onAddItem={onAddItem}
          onAddBeforeItem={onAddBeforeItem}
          onChange={onChangeValue}
          onUpdate={fetchPlanUpdate}
          onCheck={fetchPlanComplete}
          fetchUpdateContact={fetchUpdateContact}
        />
        <PlanManager
          ref={scrollData.refs[1]}
          step={2}
          stepTitle={"STEP2."}
          subTitle={"나의 대처 전략"}
          tooltip={"자살생각으로부터\n멀어지게 하는 행동은?"}
          params={params}
          beforeList={beforeList}
          onAddItem={onAddItem}
          onAddBeforeItem={onAddBeforeItem}
          onChange={onChangeValue}
          onUpdate={fetchPlanUpdate}
          onCheck={fetchPlanComplete}
          fetchUpdateContact={fetchUpdateContact}
        />
        <PlanManager
          ref={scrollData.refs[2]}
          step={3}
          stepTitle={"STEP3."}
          subTitle={"나의 사회적 활동"}
          tooltip={
            "나를 편안하게 하고\n자살 생각으로부터\n멀어지게 하는 사람 혹은\n장소는 무엇인가요?"
          }
          params={params}
          beforeList={beforeList}
          onAddItem={onAddItem}
          onAddBeforeItem={onAddBeforeItem}
          onChange={onChangeValue}
          onUpdate={fetchPlanUpdate}
          onCheck={fetchPlanComplete}
          fetchUpdateContact={fetchUpdateContact}
        />
        <PlanManager
          ref={scrollData.refs[3]}
          step={4}
          stepTitle={"STEP4."}
          subTitle={"나를 도와주는 사람들"}
          params={params}
          peopleList={peopleList}
          fetchUpdateContact={fetchUpdateContact}
          fetchDeletePeople={fetchDeletePeople}
          getEventFromAndroid={getEventFromAndroid}
          onUpdate={fetchPeopleList}
        />
        <PlanManager
          ref={scrollData.refs[4]}
          step={5}
          stepTitle={"STEP5."}
          subTitle={"환경안전 개선계획"}
          tooltip={
            "위험한 물건이나 도구로부터\n나를 지킬 수 있는 방법을\n적어주세요"
          }
          params={params}
          beforeList={beforeList}
          onAddItem={onAddItem}
          onAddBeforeItem={onAddBeforeItem}
          onChange={onChangeValue}
          onUpdate={fetchPlanUpdate}
          onCheck={fetchPlanComplete}
          fetchUpdateContact={fetchUpdateContact}
        />
      </MainArticle>
    </SafetyPlannerSection>
  );
};

export default index;
