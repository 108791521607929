import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Colors } from "../../../../styles";

export const FeelingManageBox = styled.article`
  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 32px 0;
  overflow-y: auto;

  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & > div {
    padding: 0 31px;
  }

  & > div.title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 18px;
    margin-bottom: 30px;
  }

  & > div.profile {
    //기분달력 수정일때만 표시
    display: ${(props) => (props.timeNo ? "flex" : "none")};
    margin-bottom: 68px;
    & > div.left {
      width: 118px;

      & > img {
        height: 82px;
        width: 82px;
        margin-bottom: 20px;
      }
      & > div.emoticonDescription {
        width: 82px;
        font-weight: 600;
        font-size: 1.15rem;
        line-height: 18px;
        text-align: center;
      }
    }
    & > div.right {
      width: calc(100% - 118px);
      padding-top: 12px;

      & > div {
        font-weight: 600;
        font-size: 1.15rem;
        line-height: 18px;
      }
      & > div.title {
        margin-bottom: 22px;
      }
      & > div.recordTimeState {
      }
    }
  }

  & > div.emoticons {
    //기분달력 추가일때만 표시
    margin-bottom: 50px;
    display: ${(props) => (props.timeNo ? "none" : "block")};
  }

  & > div.recordTime {
    //기분달력 추가일때만 표시
    padding: 0 20px;
    display: ${(props) => (props.timeNo ? "none" : "block")};

    & > div.title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;

      margin-bottom: 30px;
    }
  }

  & > div.freeWrite {
    padding: 50px 20px 0;

    & > div.title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;

      margin-bottom: 30px;
    }

    width: 100%;
    height: 244px;
  }

  & > div.buttonBox {
    display: flex;
    justify-content: center;
    padding: 50px 0 65px 0;
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;
    white-space: pre-wrap;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }

    .error {
      font-size: 1rem;
      text-align: center;
      color: red;
    }
  }

  .sleep-time-select-box {
    display: ${(props) => (props.timeNo ? "default" : "none")};
    .slider-title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;
      margin-bottom: 30px;
    }

    & > div.trialSlider {
      padding-bottom: 67px;
    }

    & > div.selectTrial {
      width: 100%;

      & > div.title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 18px;

        margin-bottom: 30px;
      }
    }
  }
`;
export const FeelingSlider = styled.div`
  width: 100%;
  height: 170px;
  border-bottom: 1px solid ${Colors.Gray40};

  & > div.title {
    ${(props) =>
      props.timeNo
        ? css`
            margin: 30px 0 18px;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 18px;
          `
        : css`
            margin: 40px 0 38px;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 18px;
            text-align: center;
          `}

    & > span.gray {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;
      color: ${Colors.Gray50};
    }
  }
`;
