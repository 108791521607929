import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// Styles
import { MenuRow } from "./styles";
import { yeonseiImages } from "../../../styles";

// Config

const index = (props) => {
  const {
    field = "",
    link = "",
    imageSrc = yeonseiImages.ArrowRightThin,
  } = props;
  const navigate = useNavigate();

  return (
    <MenuRow onClick={() => navigate(link)}>
      <div className={"field"}>{field}</div>
      <img alt={"rightArrowThin"} src={imageSrc} />
    </MenuRow>
  );
};

export default index;
