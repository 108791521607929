import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

//Component
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";
import InputForm from "../../../../common/component/atom/InputForm";
import File from "../../../../common/component/atom/File";

//Styles
import { ResourceWrapper } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Util
import { format } from "../../../../common/util/date";

const index = (props) => {
  const { item, index, onChangeListValue, fetchResourceUpdate } = props;
  const oneButtonHandlerModal = handlerModal();

  const onHandleShare = () => {
    navigator
      .share({
        title: item.resource_nm,
        url: item.resource_url,
        imageUrl: item.resource_img_url,
      })
      .then((res) => console.log("공유 완료", res))
      .catch((error) => console.error("공유 실패", error));
  };

  return (
    <ResourceWrapper>
      <div className={"info-area"}>
        <img
          src={
            item.is_checked
              ? yeonseiImages.CheckAble
              : yeonseiImages.CheckDisable
          }
          alt={"check-icon"}
          onClick={() =>
            onChangeListValue("is_checked", index, !item.is_checked)
          }
        />
        선택
        <div className={"date-box"}>{format("L", item.inst_dt)}</div>
      </div>
      <div className={"body-area"}>
        <img src={item.resource_img_url} alt={"url-image"} />
        <div className={"title-area"}>
          {item.resource_nm}
          <div className={"button-area"}>
            <img
              src={yeonseiImages.WritePrimary}
              alt={"write-image"}
              onClick={() => oneButtonHandlerModal.onToggleModal()}
            />
            <CopyToClipboard
              text={item.resource_url}
              onCopy={() => alert("클립보드에 복사되었습니다.")}
            >
              <img
                className={"middle-img"}
                src={yeonseiImages.Copy}
                alt={"copy-image"}
              />
            </CopyToClipboard>
            <img
              className={"last-img"}
              src={yeonseiImages.Share}
              alt={"share-image"}
              onClick={onHandleShare}
            />
          </div>
        </div>
      </div>
      <OneButtonModal
        handler={oneButtonHandlerModal}
        label={"수정하기"}
        buttonClick={() => {
          fetchResourceUpdate(item);
          oneButtonHandlerModal.onToggleModal();
        }}
      >
        <div className={"input-modal-box"}>
          <p>제목</p>
          <InputForm
            placeholder={item.resource_nm}
            isModal={"Y"}
            value={item.resource_nm}
            onChange={(e) =>
              onChangeListValue("resource_nm", index, e.target.value)
            }
          />
          <p>URL</p>
          <InputForm
            placeholder={item.resource_url}
            isModal={"Y"}
            value={item.resource_url}
            onChange={(e) =>
              onChangeListValue("resource_url", index, e.target.value)
            }
          />
          <p>썸네일 선택</p>
          <File
            value={item.resource_img_url}
            onChange={(fileUrl) =>
              onChangeListValue("resource_img_url", index, fileUrl)
            }
          />
        </div>
      </OneButtonModal>
    </ResourceWrapper>
  );
};

export default index;
