import React from 'react';
import { css , cx } from "@emotion/css";

import { Colors,yeonseiImages} from "../../../styles";
import {BoxStyles} from "./styles";

const index = (props) => {
    const { style, variant, text, img } = props
    return (
        <div className={cx(
            BoxStyles[variant || "default"],
            style
        )}>
            {img && <img className={"common-img"} src={img} alt={"icon"} />}
            <div className={"text"}>
                {text}
            </div>

        </div>
    );
};

export default index;
