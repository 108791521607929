import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const ImgList = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & .imgItem {
    min-width: 92px;
    min-height: 92px;
    width: calc((100vw - 100px) / 3);
    height: calc((100vw - 100px) / 3);
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 6px;
    margin-bottom: 6px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
