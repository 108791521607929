import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const SafetyPlannerSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0 0 30px;
`;
export const HeaderArticle = styled.article`
  position: sticky;
  width: 100%;
  height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  .main-title {
    margin: 30px;
    width: 90%;
    font-weight: 600;
    font-size: 1.625rem;
  }
`;
export const MainArticle = styled.article`
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

// export const TestComponent = styled.div`
//   width: 100%;
//   padding: 20px;
//   background-color: ${Colors.Primary10};
//   margin-bottom: 60px;
//
//   .step-head {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     position: relative;
//     margin-bottom: 20px;
//     .step-title{
//       font-weight: 600;
//       font-size: 1.25rem;
//       color: ${Colors.Primary};
//       & > span{
//         margin-left: 10px;
//         font-size: 1.25rem;
//         color: ${Colors.Black};
//       }
//     }
//     a {
//       display: flex;
//       margin-left: 10px;
//       justify-content: center;
//
//       .tooltip {
//         display: none;
//       }
//       &:hover .tooltip,
//       &:focus .tooltip {
//         display: block;
//       }
//       .tooltip {
//         left: 0;
//         top: 40px;
//         z-index: 100;
//         width: 100%;
//         height: auto;
//         padding: 8px 10px;
//         background: ${Colors.White};
//         border-radius: 10px;
//         border: 1px solid ${Colors.Primary};
//         position: absolute;
//         font-size: 1rem;
//         font-weight: 500;
//         line-height: 1.53;
//         letter-spacing: -0.16px;
//         text-align: center;
//       }
//     }
//   }
// `
//
// export const MultiFormBox = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//
//   & Button {
//     margin-top: 20px;
//   }
// `
// export const FormWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//
//   .item-box {
//     display: flex;
//     align-items: center;
//     padding: 18px 20px;
//     width: 100%;
//     height: 50px;
//     background: ${Colors.PrimaryOpacity};
//     border: 1px solid ${Colors.Primary};
//     border-radius: 10px;
//     font-size: 1rem;
//     font-weight: 600;
//   }
//
//   > * {
//     margin-bottom: 20px;
//   }
// `
