// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Poppins-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n\tfont-family: 'Poppins';\r\n\tfont-weight: 700;\r\n\tfont-display: block;\r\n\tsrc: local('Poppins Bold'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\r\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts/poppins.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,gBAAgB;CAChB,mBAAmB;CACnB,sFAAwE;AACzE","sourcesContent":["@font-face {\r\n\tfont-family: 'Poppins';\r\n\tfont-weight: 700;\r\n\tfont-display: block;\r\n\tsrc: local('Poppins Bold'), url('./Poppins-Bold.ttf') format('truetype');\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
