import React, { useId, useRef } from "react";
import * as RestApi from "../../../common/context/api";
import { css } from "@emotion/css";

import Button from "../../../common/component/atom/Button";
import InputForm from "../../../common/component/atom/InputForm";

import { ImgBox } from "./styles";
import { Colors } from "../../../styles";

const index = (props) => {
  const {
    value,
    style,
    onChange,
    marginTop,
    buttonSize,
    disabled = false,
  } = props;
  const keyId = useId();
  const inputRef = useRef(null);

  const uploadFiles = (files) => {
    RestApi.upload("eFILE_UPLOAD", { file: [files] })
      .then((fileUrls) => {
        console.log(fileUrls[0].original);
        onChange(fileUrls[0].original);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ImgBox
      className={style}
      inputId={"file_uploader_" + keyId}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <label
        ref={inputRef}
        htmlFor={"file_uploader_" + keyId}
        style={{ marginRight: "8px", flex: 1 }}
        onChange={(e) => {
          uploadFiles(e.target.files[0]);
        }}
      >
        <InputForm id={"file_uploader_" + keyId} type="file" accept="image/*" />
      </label>
      <Button
        variant={"profile"}
        size={buttonSize || "profile"}
        style={css`
          background: ${value ? `url(${value})` : `${Colors.Gray20}`} no-repeat
            center center;
          background-size: cover;
          margin-top: ${`${marginTop}px` || 0};
        `}
        onClick={() => {
          if (!disabled) {
            inputRef.current.click();
          }
        }}
      />
    </ImgBox>
  );
};

export default index;
