import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const ServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 143px;
  padding: 20px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
  background: ${Colors.White};
  border-radius: 10px;
  margin-bottom: 20px;

  .title {
    color: ${Colors.Gray90};
    font-weight: 600;
    font-size: 1.25rem;
  }

  .button-area {
    display: flex;
    flex-direction: row;
    margin-top: auto;

    .call-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration-line: none;
      color: ${Colors.Gray90};
      & p {
        font-weight: 600;
        font-size: 1.25rem;
      }
      & img {
        margin-right: 10px;
      }
    }

    & button {
      margin-left: auto;
    }
  }

  .in-modal-box {
    width: 280px;
    display: flex;
    padding: 5px;
    flex-direction: column;
    align-items: center;

    & img {
      margin-left: auto;
      margin-bottom: 30px;
    }
    .detail {
      font-size: 1rem;
      font-weight: 700;
      color: ${Colors.Gray90};
      white-space: pre-wrap;
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;
