import styled from "@emotion/styled";

export const MyPageSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .image-box {
    position: relative;
    margin-bottom: 30px;

    .profile-img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
    .icon {
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }

  .input-box {
    margin-bottom: 20px;
    .title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 14px;
      margin-bottom: 10px;
    }
  }

  .warning-text {
    width: 335px;
    text-align: left;
    margin-bottom: 10px;
    color: red;
    padding-left: 5px;
  }
`;
