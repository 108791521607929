import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const QnaSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 20px;

  .title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 14px;
    margin-bottom: 20px;
  }
  .sub-title {
    font-weight: 400;
    font-size: 1rem;
    line-height: 14px;
    margin-top: 10px;
    color: ${Colors.Gray60};
  }

  .select-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    & img {
      margin-right: 10px;
    }

    & span {
      margin-right: 30px;
      font-weight: 400;
      font-size: 1rem;
    }
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    text-align: center;
    margin: 20px 0;

    & img {
      margin-bottom: 20px;
    }
  }
`;
