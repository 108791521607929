import React from "react";

import { MainSection, MainText } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = () => {
  return (
    <MainSection>
      <img
        className={"background-img"}
        src={yeonseiImages.KnowSuicide}
        alt={"background"}
      />
      <MainText>
        <img
          src={yeonseiImages.QuoteLeft}
          alt={"quote-left"}
          className={"left-quote"}
        />
        <div className={"main-title"}>
          자살은 더 이상
          <br />
          개인만의 문제가 아닙니다.
        </div>
        <img
          className={"right-quote"}
          src={yeonseiImages.QuoteRight}
          alt={"quote-right"}
        />
        <div className={"text"}>
          자살은 개인의 정신건강과 관련되어 있기도 하지만 <br />
          한 사회가 얼마나 행복하고 <br />
          건강한 사회인지를 보여주는 <br />
          지표이기도 합니다.
          <br />
          <br />
          자살은 국가에서도 적극적으로 도움을 주고자 하는, <br />
          해결 가능한 문제입니다.
          <br /> <br />
          <span>혼자서 아파하지 마세요.</span>
          <br /> <br />
          자살은 더 이상 개인의 문제가 아닙니다. <br />
          힘들 때 도움을 줄 수 있는 <br />
          많은 전문가들이 있습니다.
          <br /> <br />
          저희는 당신이 이 앱을 통해 당신의 삶을 다시 바라보며 도움을 얻을 수
          있기를 바랍니다.
        </div>
      </MainText>
    </MainSection>
  );
};

export default index;
