import styled from "@emotion/styled";
import { Colors, yeonseiImages } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    `url(${props.detail.mber_bg_img_url || yeonseiImages.BackgroundSample})`};
  background-size: contain;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderArticle = styled.article`
  padding-top: 53px;

  .main-image {
    position: absolute;
    z-index: 1;
  }

  .hamburger {
    margin-left: 20px;
    background-color: transparent;

    & > img {
      width: 24px;
    }
    width: 25px;
  }

  .invisible-profile-box {
    margin: 42px 20px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .name-text {
      margin-right: auto;
      font-weight: 400;
      font-size: 30px;
      color: ${Colors.Black};
    }
    & button {
      margin-left: auto;
    }
  }
  .test {
    display: none;
  }

  & div.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BodyArticle = styled.article`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: ${Colors.White};
  z-index: 3;
  font-style: normal;

  .safe-banner {
    width: 335px;
    height: 108px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #ed9b75 57.29%, #f0b58f 100%);

    & img {
      margin: 10px;
    }
    & div {
      font-weight: 500;
      font-size: 1.375rem;
      color: ${Colors.White};
    }
  }

  .feeling-banner {
    width: 335px;
    height: 108px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #fdf2c9 57.29%, #fcf5e1 100%);

    & img {
      margin: 10px;
    }
    & div {
      left: 40%;
      font-weight: 600;
      font-size: 1rem;
      > span {
        margin-top: 10px;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }

  .protect-text {
    margin: 10px;
    font-weight: 600;
    font-size: 1rem;

    > span {
      font-size: inherit;
      color: ${Colors.Primary};
    }
  }

  .sos-box {
    width: 335px;
    padding: 10px;
    background: ${Colors.Beige};
    border-radius: 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .invisible-call-box-title {
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      .old-phone {
        width: 88px;
        height: 88px;
      }
      .title-text {
        margin-left: 20px;
        font-weight: 600;
        font-size: 1rem;
        & span {
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
    }

    .invisible-call-box {
      & img {
        margin: 8px;
      }
    }
  }
  .in-modal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-bottom: 60px;
    margin-top: 30px;

    & img {
      margin-bottom: 30px;
    }
  }
`;
