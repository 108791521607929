import React, { useEffect } from "react";

//Components
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";
import InputForm from "../../../../common/component/atom/InputForm";
import ResourceBox from "../../component/ResourceBox";
import Header from "../../../../component/template/Header";
import File from "../../../../common/component/atom/File";

// Styles
import { MainSection, ResourceListBox } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Hooks
import useResourceList from "../../hooks/useResourceList";

const index = () => {
  const oneButtonModalHandler = handlerModal();
  const {
    params,
    list,
    onChangeValue,
    onChangeListValue,
    fetchResourceCreate,
    fetchResourceUpdate,
    fetchResourceDelete,
  } = useResourceList();

  return (
    <>
      <Header
        headerMenu={{
          "/myPage/resourceList": {
            title: "나의 자원 목록",
            backPath: -1,
          },
        }}
        list={list}
        fetchResourceDelete={fetchResourceDelete}
      />
      <MainSection>
        <div className={"title"}>
          나의 자원 목록
          <img
            src={yeonseiImages.PlusBlack}
            alt={"plus"}
            onClick={oneButtonModalHandler.onToggleModal}
          />
        </div>
        <ResourceListBox>
          {(list || []).map((item, index) => (
            <ResourceBox
              item={item}
              index={index}
              onChangeListValue={onChangeListValue}
              fetchResourceUpdate={fetchResourceUpdate}
            />
          ))}
          <div className={"space"} />
        </ResourceListBox>
        <OneButtonModal
          handler={oneButtonModalHandler}
          label={"등록하기"}
          buttonClick={() => {
            fetchResourceCreate();
            oneButtonModalHandler.onToggleModal();
          }}
        >
          <div className={"input-modal-box"}>
            <p>제목</p>
            <InputForm
              placeholder={"URL제목 입력"}
              isModal={"Y"}
              value={params.resource_nm}
              onChange={(e) => onChangeValue("resource_nm", e.target.value)}
            />
            <p>URL</p>
            <InputForm
              placeholder={"URL 입력"}
              isModal={"Y"}
              value={params.resource_url}
              onChange={(e) => onChangeValue("resource_url", e.target.value)}
            />
            <p>썸네일 선택</p>
            <File
              value={params.resource_img_url}
              onChange={(fileUrl) => onChangeValue("resource_img_url", fileUrl)}
            />
          </div>
        </OneButtonModal>
      </MainSection>
    </>
  );
};

export default index;
