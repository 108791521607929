import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// react-query
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./index.css";
import "./assets/fonts/pretendard.css";
import "./assets/fonts/notosanscjkkr.css";
import "./assets/fonts/poppins.css";

import Root from "./Root";

import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./common/store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      staleTime: 10 * 60 * 1000, // 10분
      useErrorBoundary: true,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          <Root />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
