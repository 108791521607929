import React from "react";
import { cx } from "@emotion/css";

const index = (props) => {
  const { icon, size, color, style, onClick } = props;

  return (
    <span
      className={cx(`common-icon ${icon?.type || "material-icons"}`, style)}
      style={{
        fontSize: size,
        width: size,
        color: color,
      }}
      aria-disabled
      onClick={onClick}
    >
      {icon?.src}
    </span>
  );
};

export default index;
