import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Api
import * as RestApi from "../../../common/context/api";

//Util
import { Utils } from "../../../common/util";
import { err_pwList } from "../../../common/util/const";

export default function () {
  const mber_no = useSelector((state) => state?.data.eSESSION.account?.mber_no);
  const [params, setParams] = useState({});
  const [errList, setErrList] = useState({
    verified: false,
    new_mber_pw: true,
    pw_check: true,
  });
  const [changePassword, setChangePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handlePress = (field, value) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(value)) {
      setParams((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const fetchUserDetail = () => {
    RestApi.get("eMBER_USER_DETAIL", { mber_no: mber_no })
      .then((res) => {
        const tempUser = { ...res.detail };
        const memberPhone = (tempUser.mber_phone || "").replace(
          /(\d{3})(\d{3})(\d{4})/,
          "$1-$2-$3"
        );

        setParams(
          Object.assign(tempUser, {
            mber_phone: memberPhone,
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const fetchUserUpdate = () => {
    RestApi.post("eMBER_USER_UPDATE", params)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidation = (target, value) => {
    if (target === "new_mber_pw") {
      for (let err_pw of err_pwList) {
        if (value.includes(err_pw)) {
          setErrList((prev) => ({
            ...prev,
            [target]: false,
          }));
          return;
        }
      }
      setErrList((prev) => ({
        ...prev,
        [target]: !Utils.validatePassword(value),
      }));
    } else if (target === "pw_check") {
      setErrList((prev) => ({
        ...prev,
        [target]: value !== "" && params.new_mber_pw === params.pw_check,
      }));
    } else {
      setErrList((prev) => ({ ...prev, [target]: value !== "" }));
    }
  };

  const checkUserPassword = () => {
    RestApi.post("eMBER_CHECK_PASSWORD", { mber_pw: params.mber_pw })
      .then((res) => {
        if (res.available) {
          setErrList((prev) => ({
            ...prev,
            verified: true,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchChangePassword = () => {
    RestApi.post("eMBER_CHANGE_PASSWORD", { mber_pw: params.new_mber_pw })
      .then((res) => {
        console.log(res);
        setChangePassword(true);
      })
      .catch((err) => {
        const { error, msg, code } = err;
        setErrorMessage(error.err);
      });
  };

  return {
    errList,
    params,
    setParams,
    changePassword,
    errorMessage,
    handlePress,
    onChangeValue,
    checkUserPassword,
    handleValidation,
    fetchUserUpdate,
    fetchChangePassword,
  };
}
