import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ResponsiveLine } from "@nivo/line";

//styles
import { ChartWrapper, theme, customStyle } from "./styles";
import { Colors } from "../../../styles";

// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const Chart = (props) => {
  const { data, year = "", ableClick = false } = props;
  const [isMounted, setIsMounted] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <ChartWrapper>
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 20, bottom: 25, left: 40 }}
        // 차트 축 설정
        xScale={{ type: "point" }}
        xFormat={(value) => `${value}`}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
          tickValues: [0, 25, 50, 75, 100],
        }}
        yFormat={(value) => `${value}%`}
        // 차트 line 설정
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendOffset: 26,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickValues: [0, 25, 50, 75, 100],
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legend: "",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        enableGridX={false}
        gridYValues={[0, 25, 50, 75, 100]}
        // 차트 Data 부분
        pointSize={10}
        pointColor={Colors.Primary}
        pointBorderWidth={2}
        pointBorderColor={Colors.Primary}
        pointLabel="yFormatted"
        pointLabelYOffset={-12}
        // 차트 세부사항 emotion isInteractive: true/false
        isInteractive={true}
        onClick={(point, event) => {
          ableClick
            ? navigate(
                `/main/feelingCalendarStatistics?dateNo=${
                  year + "-" + point.data.x
                }`
              )
            : null;
        }}
        enableCrosshair={false}
        tooltip={() => null}
        useMesh={true}
        legends={[]}
        motionConfig="slow"
        animate={isMounted ? { duration: 1000 } : false}
        motionStiffness={120}
        motionDamping={50}
        transitionDuration={0.5}
        transitionDelay={0.5}
        // 차트 추가설정
        theme={theme}
        customStyle={customStyle}
      />
    </ChartWrapper>
  );
};

export default Chart;
