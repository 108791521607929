import React, { useEffect, useState } from "react";

//styled
import { StepWrapper, StepLabelContainer, StepContainer } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = (props) => {
  const { currentStep, exclamation } = props;
  const [activeStep, setActiveStep] = useState(1);
  const steps = Array.from({ length: 5 }, (_, index) => ({ step: index + 1 }));
  const totalSteps = steps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  useEffect(() => {
    if (currentStep === 5) {
      return;
    }
    if (currentStep > activeStep) {
      setActiveStep(activeStep + 1);
    } else if (currentStep < activeStep) {
      setActiveStep(activeStep - 1);
    }
  }, [currentStep]);

  return (
    <>
      <StepContainer width={width}>
        {steps.map(({ step }) => (
          <StepWrapper key={step}>
            <StepLabelContainer>
              {activeStep === step ? (
                activeStep === 4 && exclamation === true ? (
                  <img
                    src={yeonseiImages.ExclamationMark}
                    alt={"step-4-Label"}
                  />
                ) : (
                  <img src={yeonseiImages.CheckBorderAble} alt={"stepLabel"} />
                )
              ) : activeStep > step ? (
                <img src={yeonseiImages.CheckAble} alt={"stepLabel"} />
              ) : (
                <img src={yeonseiImages.CheckDisable} alt={"stepLabel"} />
              )}
            </StepLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
    </>
  );
};

export default index;
