import AlarmSetting from "../pages/setting/page/alarmSetting";
import Setting from "../pages/setting/page/setting";
import AboutApp from "../pages/setting/page/aboutApp";
import HowToUse from "../pages/setting/page/howToUse";
import Withdrawal from "../pages/setting/page/withdrawal";
import PrivacyPolicy from "../pages/setting/page/privacyPolicy";
import AppPassword from "../pages/setting/page/appPassword";

export const settingRoutes = [
  {
    path: "/setting/setting",
    element: <Setting />,
  },
  {
    path: "/setting/alarm",
    element: <AlarmSetting />,
  },
  {
    path: "/setting/aboutApp",
    element: <AboutApp />,
  },
  {
    path: "/setting/howToUse",
    element: <HowToUse />,
  },
  {
    path: "/setting/withdrawal",
    element: <Withdrawal />,
  },
  {
    path: "/setting/privacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/setting/appPassword",
    element: <AppPassword />,
  },
];
