import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { yeonseiImages } from "../../../../styles";
import { ButtonWrapper, ContentWrapper, MainSection } from "./styles";

// Components
import Button from "../../../../common/component/atom/Button";
import MainText from "../../component/MainText";

// Util
import { Utils } from "../../../../common/util";

const index = () => {
  const step = Utils.getParameterByName("step") || "1";
  const navigate = useNavigate();

  return (
    <MainSection>
      <div className={"title"}>나를 돌보는 방법</div>
      <ContentWrapper>
        <ButtonWrapper>
          <Button
            variant={step === "1" ? "painted" : "unpainted"}
            size={"small"}
            label={"신체"}
            onClick={() => navigate("?step=1")}
          />
          <Button
            variant={step === "2" ? "painted" : "unpainted"}
            size={"small"}
            label={"식사"}
            onClick={() => navigate("?step=2")}
          />
          <Button
            variant={step === "3" ? "painted" : "unpainted"}
            size={"small"}
            label={"약물"}
            onClick={() => navigate("?step=3")}
          />
          <Button
            variant={step === "4" ? "painted" : "unpainted"}
            size={"small"}
            label={"수면"}
            onClick={() => navigate("?step=4")}
          />
          <Button
            variant={step === "5" ? "painted" : "unpainted"}
            size={"small"}
            label={"운동 & 활동"}
            style={css`
              width: 88px;
              height: 32px;
            `}
            onClick={() => navigate("?step=5")}
          />
        </ButtonWrapper>
        <MainText
          isDisplay={step === "1"}
          mainTitle={"신체적으로 불편함을 느낀다면\n치료를 받아보세요"}
          step={step}
          text={
            "내 몸을 잘 살펴보세요.\n신체적인 불편함을 억지로 참아보려고 하지마세요.\n내 몸이 불편하다는 신호를 보내면,\n병원에 방문해서 의학적인 치료를 받아보세요."
          }
          img={yeonseiImages.Body}
        />
        <MainText
          isDisplay={step === "2"}
          mainTitle={"균형있는 식사를 권장해요"}
          step={step}
          text={
            "·  적당한 양의 음식을, 규칙적으로 드시는 게 좋아요.\n" +
            "·  지나치게 자극적이거나, 소화를 어렵게 하는 음식은 피해주세요."
          }
          img={yeonseiImages.Eat}
        />
        <MainText
          isDisplay={step === "3"}
          mainTitle={"건강에 영향을 주는 약물은 자제하세요."}
          step={step}
          text={
            " ·  술은 절제하고, 불법적인 약물은 사용하면 안 돼요.\n" +
            " ·  커피나 에너지 드링크 등도 제한하는 것이 좋아요."
          }
          img={yeonseiImages.Drug}
        />
        <MainText
          isDisplay={step === "4"}
          mainTitle={"잘 자는게 중요해요."}
          step={step}
          text={
            "·  잘 잤다는 느낌을 줄 만큼 충분히 자야해요.\n" +
            "·  일정한 시간에 잠자리에 들면 좋은 수면 습관을\n" +
            "   가질 수 있어요.\n" +
            "·  자기 전에는 핸드폰은 멀리 두세요."
          }
          img={yeonseiImages.Sleep}
        />
        <MainText
          isDisplay={step === "5"}
          mainTitle={"적절한 운동을 해보세요."}
          step={step}
          text={
            "·  하루 10분을 걸어도 좋으니\n" +
            "   매일 조금이라도 운동해보세요.\n" +
            "   처음보다 점점 운동량을 늘려볼 수 있답니다.\n" +
            "·  나중에는 호흡이 적당히 가빠지고\n" +
            "   땀이 날 정도의 운동을 해보세요."
          }
          img={yeonseiImages.Exercise}
          secondTitle={"나의 기분이 나아지는 활동을 해보세요."}
          secondText={
            "·  내 기분이 나아지는 일 또는\n" +
            "   사소하더라도 내가 잘 할 수 있는 활동을\n" +
            "   하루에 한 번씩 해보세요.\n" +
            "   예) 침구를 깨끗하게 개기, 개운하게 목욕하기,\n" +
            "   티비 보며 크게 웃기 등이 있어요."
          }
        />
      </ContentWrapper>
      <div className={"source"}>
        출처: 보건복지부 & 중앙자살예방센터. (2021). 자살위기상담 서비스 매뉴얼.
        p.44
      </div>
    </MainSection>
  );
};

export default index;
