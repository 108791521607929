import styled from "@emotion/styled";

export const CallList = styled.div`
  margin: 15px 0 30px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0;

    font-weight: 500;
    font-size: 1rem;
    text-align: center;

    & img {
      margin-bottom: 10px;
    }

    & .text {
      text-align: center;
    }
  }
`;

export const CallDetail = styled.div`
  display: flex;
  flex-direction: column;

  .contact-name {
    margin-bottom: 15px;
  }
`;
