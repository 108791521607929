import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const Footer = styled.footer`
  width: 100%;
  height: auto;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;
  padding-top: 18px;
  display: ${(props) => (props.isDisplay ? "default" : "none")};

  & ~ main {
    padding-bottom: ${(props) => (props.isDisplay ? "105px" : "inherit")};
  }

  ul {
    background-color: ${Colors.White};
    box-sizing: border-box;
    border-radius: 20px 20px 1px 1px;
    border: 1px solid ${Colors.Gray40};
    border-top: 1px solid #eee;
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & li {
      width: 20%;
      height: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 24px;

      & span {
        font-size: 0.8rem;
        color: ${Colors.Primary};
        font-weight: 500;
        display: flex;
        margin-top: 10px;
        line-height: 18px;
      }

      &:nth-of-type(4) {
        position: absolute;
        width: 58px;
        height: 58px;
        overflow: hidden;
        margin-top: 13px;
        border-radius: 50%;
        border: 3px solid ${Colors.Gray30};
        background-image: ${Colors.Primary};
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        & span {
          color: #fff !important;
          margin-top: 0;
        }
      }
    }
  }

  .emotion-modal__content {
    position: relative;
    line-height: 15px;
    text-align: center;
    margin-bottom: 15px;
    .emotion-modal__title {
      font-weight: 500;
      font-size: 1rem;
    }
    .emotion-modal__close {
      position: absolute;
      top: -5px;
      right: 0;
    }
  }
`;

export const EmergencyModalContent = styled.div`
  & > div.modalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    padding-bottom: 20px;
    margin-top: 20px;

    border-bottom: solid 1px ${Colors.Gray30};

    & > div.center {
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 20px;
    }

    &:nth-of-type(1) {
      margin-top: 0;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 24px;
    }
  }
`;
