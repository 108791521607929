import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
// Styles
import { AuthLayoutMain } from "./styles";

// Component
import Header from "../Header";
import { Const } from "../../../common/util";
import * as RestApi from "../../../common/context/api";
import * as ActionSession from "../../../common/store/action/eSESSION";

// Hooks

/**
 * @description 로그인/회원가입/비밀번호찾기 서비스의 레이아웃 구성을 위한 컴포넌트
 * @param props
 * @return {JSX.Element}
 */
const index = (props) => {
  const { children, eSESSION = {} } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    if (localStorage.getItem(Const.LOGIN_TOKEN)) {
      RestApi.get("eSESSION", {})
        .then((res) => {
          if (res.account) {
            if (res.account.mber_pw_failed_cnt > 5) {
              navigate("/auth/verification");
            }
            dispatch(ActionSession.setSession(res));
            res.account.mber_app_pw_use_yn === "Y"
              ? navigate("/auth/pin")
              : navigate("/main/main");
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <Header />
      {!isLoading && <AuthLayoutMain>{children}</AuthLayoutMain>}
    </>
  );
};

export default connect((state) => {
  return {
    eSESSION: state.data.eSESSION.eSESSION,
    eCOMM_CD: state.data.eCONST.eCOMM_CD,
  };
})(index);
