/**
 *  @author 이규진
 *  @description - 이미지 리스트
 *  @param {Object} props - 상위 컴포넌트의 props
 *  @param {Array} props.list - 이미지 리스트
 *  @param {string} props.item - 이미지 리스트의 이름 또는 분류 코드
 *  @param {function} [props.deleteImg] - 리스트 내 아이템 삭제 기능
 *  @param {Object} [props.fileBox] - 상위 컴포넌트가 <FileBoxWithImgList/>일 경우 전달되는 파일 추가 버튼
 *  @returns { {ImgList} }
 *
 */
// Libraries
import React from "react";

// Styles
import { ImgList } from "./styles";
import { css } from "@emotion/css";
import { Icons } from "../../../styles";

// Components
import Icon from "../../../common/component/atom/Icon";

const index = (props) => {
  const { list, item, deleteImg, fileBox } = props;

  return (
    <ImgList>
      {list
        ?.filter((el) => el.file_loc_se_cd === item)
        ?.map((url) => {
          return (
            <div
              className={"imgItem"}
              onClick={
                deleteImg ? () => deleteImg(item, url["file_url"]) : null
              }
            >
              {deleteImg && (
                <Icon
                  icon={Icons.close_fill}
                  style={css`
                    position: absolute;
                    top: 5px;
                    right: 5px;
                  `}
                />
              )}
              <img src={url["file_url"] || ""} alt={`upload image: ${item}`} />
            </div>
          );
        })}
      {fileBox || null}
    </ImgList>
  );
};

export default index;
