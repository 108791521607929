import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { format } from "../../../common/util/date";

// Api
import * as RestApi from "../../../common/context/api";

export default function (props) {
  const [params, setParams] = useState({
    resource_nm: "",
    resource_url: "",
    resource_img_url: "",
  });
  const [list, setList] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchResourceList().then(() => {});
  }, []);

  const fetchResourceList = async () => {
    let api_params = {
      skip: 0,
      limit: 100,
      my_yn: "Y",
    };

    await RestApi.get("eRESOURCE_USER_LIST", api_params)
      .then((res) => {
        setList(
          (res.list || []).map((item) =>
            Object.assign(item, {
              is_checked: false,
            })
          )
        );
      })
      .catch((err) => {});
  };

  const fetchResourceCreate = () => {
    const api_params = {
      resource_nm: params.resource_nm,
      resource_url: params.resource_url,
      resource_img_url: params.resource_img_url,
    };

    RestApi.post("eRESOURCE_USER_CREATE", api_params)
      .then(() => {
        fetchResourceList().then(() => {});
        setParams({ resource_nm: "", resource_url: "", resource_img_url: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // LIST의 초기화
  const fetchResourceDelete = () => {
    if (confirm("정말 삭제하시겠습니까?")) {
      let isChecked = [];
      (list || []).forEach((item) => {
        if (item.is_checked === true) {
          isChecked.push(item.resource_no);
        }
      });

      RestApi.post("eRESOURCE_USER_DELETE", { resource_nos: isChecked })
        .then(() => {
          fetchResourceList().then(() => {});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchResourceUpdate = (item) => {
    const api_params = {
      resource_no: item.resource_no,
      resource_nm: item.resource_nm,
      resource_url: item.resource_url,
      resource_img_url: item.resource_img_url,
    };

    RestApi.post("eRESOURCE_USER_UPDATE", api_params)
      .then(() => {
        alert("수정이 완료되었습니다.");
        fetchResourceList().then(() => {});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onChangeListValue = (field, index, val) => {
    setList((prevList) => {
      let temp_l = [...prevList];
      temp_l[index][field] = val;
      return temp_l;
    });
  };

  const deleteItem = () => {};

  return {
    list,
    params,
    onChangeValue,
    onChangeListValue,
    fetchResourceCreate,
    fetchResourceDelete,
    fetchResourceUpdate,
  };
}
