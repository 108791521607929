import React, { useState } from "react";

//api
import * as RestApi from "../../../common/context/api";

//util
import { Utils } from "../../../common/util";
import { err_pwList } from "../../../common/util/const";

const index = () => {
  const verify = Utils.getParameterByName("verified") || "";
  const [params, setParams] = useState({
    mber_id: "",
    crtfc_no: "",
    verify_no: verify,
    verified: !!verify,
    error_msg: "",
    error: "",
    mber_pw: "",
    pw_check: "",
  });
  const [errList, setErrList] = useState({
    mber_pw: true,
    pw_check: true,
  });
  const [isSend, setIsSend] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [checkId, setCheckId] = useState(true);
  const [errorMessage, SetErrorMessage] = useState("");

  const fetchVerifyEmail = () => {
    RestApi.post("eVERFI_EMAIL_CREATE", { email_addr: params.mber_id })
      .then((res) => {
        setIsSend(true);
        setParams((prevState) => ({
          ...prevState,
          verify_no: res.verfi_no,
        }));
      })
      .catch((err) => {
        console.log(err);
        setCheckId(false);
      });
  };

  const fetchVerifyNumber = () => {
    const api_params = {
      verfi_no: params.verify_no,
      verfi_code: params.crtfc_no,
    };
    RestApi.get("eVERFI_CHECK", api_params)
      .then((res) => {
        const { available, msg } = res;
        if (available) {
          setParams((prevState) => ({
            ...prevState,
            verified: true,
            error: false,
          }));
        } else {
          setParams((prevState) => ({
            ...prevState,
            error_msg: msg,
            error: true,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleValidation = (target, value) => {
    if (target === "pw_check") {
      setErrList((prev) => ({
        ...prev,
        [target]: value !== "" && params.mber_pw === params.pw_check,
      }));
    } else if (target === "mber_pw") {
      for (let err_pw of err_pwList) {
        if (value.includes(err_pw)) {
          setErrList((prev) => ({
            ...prev,
            [target]: false,
          }));
          return;
        }
      }
      setErrList((prev) => ({
        ...prev,
        [target]: !Utils.validatePassword(value),
      }));
    } else {
      setErrList((prev) => ({ ...prev, [target]: value !== "" }));
    }
  };

  const fetchChangePassword = () => {
    const api_params = {
      verfi_no: verify || params.verify_no,
      mber_pw: params.mber_pw,
    };
    RestApi.post("eMBER_CHANGE_PASSWORD", api_params)
      .then((res) => {
        console.log("success:::", res);
        setChangePassword(true);
      })
      .catch((err) => {
        const { error, code, msg } = err;
        SetErrorMessage(error.err);
        console.log("failed:::", err);
      });
  };

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  return {
    isSend,
    params,
    errList,
    checkId,
    setCheckId,
    errorMessage,
    changePassword,
    onChangeValue,
    fetchVerifyEmail,
    fetchVerifyNumber,
    handleValidation,
    fetchChangePassword,
  };
};

export default index;
