import React, { useState } from "react";
import { css } from "@emotion/css";

import { QnaSection, BoxWrapper } from "./styles";

import ServiceBox from "../../component/ServiceBox";
import Button from "../../../../common/component/atom/Button";
import DetailBox from "../../component/DetailBox";

import useQna from "../../hooks/useQna";

const index = () => {
  const { list, fetchReadInqry } = useQna();
  const [selectedItem, setSelectedItem] = useState({
    Clicked: false,
    index: null,
  });

  const onHandleClick = (index) => {
    setSelectedItem({ Clicked: true, index: index });
  };

  return (
    <QnaSection>
      <BoxWrapper>
        {(list || [])?.map((el, index) =>
          selectedItem.Clicked === false ? (
            <ServiceBox
              key={index}
              params={el}
              onClick={() => {
                onHandleClick(index);
                if (el.inqry_sts_cd === true) {
                  fetchReadInqry(el.inqry_no);
                }
              }}
            />
          ) : selectedItem.index === index ? (
            <>
              <DetailBox params={el} />
              <Button
                variant={"painted"}
                label={"목록"}
                size={"xLarge"}
                onClick={() => setSelectedItem({ Clicked: false, index: null })}
                style={css`
                  font-size: 1.25rem;
                  font-weight: 600;
                  width: 100%;
                `}
              />
            </>
          ) : null
        )}
      </BoxWrapper>
    </QnaSection>
  );
};

export default index;
