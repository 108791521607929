import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const QnaSection = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 30px 15px 10px 15px;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
