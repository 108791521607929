import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const ViewDetail = styled.div`
  width: 100%;
  height: 472px;
  padding: 20px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
  background: ${Colors.White};
  border-radius: 10px;
  margin-bottom: 20px;

  .title {
    height: 40px;
    font-weight: 600;
    font-size: 1.25rem;
    color: ${Colors.Gray90};
    border-bottom: solid 1px ${Colors.Gray40};
    margin-bottom: 20px;
  }

  .main {
    white-space: pre-wrap;
    font-weight: 500;

    font-size: 1rem;

    & span {
      font-weight: 700;
      font-size: 1rem;
    }
  }
`;
