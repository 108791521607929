import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const DashboardLayoutMain = styled.main`
  min-width: 1280px;
  min-height: 860px;
  max-height: 100vh;
  display: flex;
  

  & > .dashboard-layout__content {
    width: 100%;
    height: inherit;
    overflow-y: auto;

    border: 1px solid white;
    background-color: ${Colors.Background};
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 0 24px;
    box-sizing: border-box;
  }
`;
