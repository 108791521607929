import { css } from "@emotion/css";
import { Colors } from "../../../../styles";

export const ButtonStyles = {
  default: css`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
  `,

  painted: css`
    border-radius: 10px;
    color: ${Colors.White};
    background-color: ${Colors.Primary};
  `,
  unpainted: css`
    border-radius: 10px;
    color: ${Colors.Primary};
    border: solid 2px ${Colors.Primary};
    background-color: ${Colors.White};
  `,

  lookText: css`
    border-radius: 10px;
    color: ${Colors.Gray80};

    background-color: ${Colors.White};
  `,

  keypad: css`
    padding: 24px 52px;
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    background-color: ${Colors.White};

    border-radius: 0;
  `,

  keypadString: css`
    padding: 0;
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    background-color: ${Colors.White};
    color: ${Colors.Gray90};
    border-radius: 0;
  `,

  checkTitle: css`
    padding: 20px 110px 19px 20px;
    border-radius: 10px;
    background-color: ${Colors.White};
  `,

  emergency: css`
    border-radius: 10px;
    color: ${Colors.White};
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(to right, #f09278 0%, #ee7c5b 87%);
  `,

  call: css`
    margin: 10px;
  `,

  select: css`
    padding-left: 10px;
    border-radius: 10px;
    border: 1px solid ${Colors.Gray40};
    justify-content: flex-start;
    background-color: ${Colors.White};
    color: ${Colors.Gray60};
  `,

  hamburger: css`
    background: ${Colors.White};
  `,

  profile: css`
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    & img {
      width: 76px;
      height: 76px;
      border-radius: inherit;
    }
  `,
  mainImage: css`
    & img {
      width: 100%;
      height: 250px;
    }
  `,
  plusIcon: css`
    border-radius: 50%;
  `,
};

export const ButtonSizes = {
  xLarge: css`
    width: 335px;
    height: 55px;
  `,
  mediumLarge: css`
    width: 335px;
    height: 50px;
  `,
  mediumSmallLarge: css`
    width: 100%;
    height: 55px;
  `,
  large: css`
    width: 256px;
    height: 55px;
  `,
  smallLarge: css`
    width: 105px;
    height: 40px;
  `,
  medium: css`
    width: 90px;
    height: 40px;
  `,
  small: css`
    width: 51px;
    height: 32px;
  `,
  keypad: css`
    width: 30%;
    height: 74px;
  `,
  profile: css`
    width: 76px;
    height: 76px;
  `,
  profileLarge: css`
    width: 100px;
    height: 100px;
  `,
  call: css`
    width: 141px;
    height: 139px;
  `,
  icon: css`
    width: 20px;
    height: 20px;
  `,
  midIcon: css`
    width: 29px;
    height: 29px;
  `,
  mainImage: css`
    width: 100%;
    height: 250px;
  `,
  modalButton: css`
    width: 280px;
    height: 50px;
  `,
  BottomModalButton: css`
    width: 100%;
    height: 55px;
  `,
  selectButton: css`
    width: 150px;
    height: 50px;
  `,
};
