import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const StepContainer = styled.div`
  display: flex;
  width: 85%;
  justify-content: space-between;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: ${Colors.Gray40};
    height: 7px;
    width: 100%;
    top: 15px;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: ${Colors.Primary};
    height: 7px;
    width: ${(props) => props.width};
    top: 15px;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;
export const StepWrapper = styled.div`
  position: relative;
  top: 15px;
  z-index: 1;
`;

export const StepLabelContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StepTagContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 0.9rem;
  color: ${Colors.Gray40};
`;
