import React from "react";
import { css } from "@emotion/css";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

//Styles
import { RecordSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Components
import Button from "../../../../common/component/atom/Button";
import TimePickerBox from "../../component/TimePickerBox";
import {
  TwoButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//Hooks
import useAlarmSetting from "../../hooks/useAlarmSetting";

const index = (props) => {
  const { eSESSION } = props;
  const { first, params, onChangeValue, fetchAlarmCreate, fetchAlarmUpdate } =
    useAlarmSetting();
  const twoButtonModalHandler = handlerModal();
  const navigate = useNavigate();

  return (
    <RecordSection>
      <div className={"title"}>
        <span>하루 3번</span> 기분을 기록 할 수 있게 도와드릴게요
        <a onClick={(e) => e.preventDefault()}>
          <div className={"img-wrapper"}>
            <img src={yeonseiImages.QuestionMark} alt={"tooltip"} />
          </div>
          <div className={"tooltip"}>
            동일한 시간을 설정한 경우, 한번만 알람이 가게 됩니다.
          </div>
        </a>
        {first && <div className={"sub-title"}>설정에서 수정이 가능해요</div>}
      </div>
      {(Array.isArray(params) ? params : Object.values(params)).map(
        (el, index) => (
          <TimePickerBox
            id={index}
            params={el}
            index={index}
            onChangeValue={onChangeValue}
          />
        )
      )}
      <Button
        variant={"painted"}
        size={"xLarge"}
        label={"저장"}
        style={css`
          width: 100%;
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 20px;
        `}
        onClick={() => {
          twoButtonModalHandler.onToggleModal();
          params.every((param) => param.hasOwnProperty("alarm_no"))
            ? fetchAlarmUpdate()
            : fetchAlarmCreate();
        }}
      />
      <TwoButtonModal
        topButtonLabel={"작성하기"}
        topButtonClick={() => navigate("/main/feelingCalendarManage")}
        bottomButtonLabel={"달력보기"}
        handler={twoButtonModalHandler}
        bottomButtonClick={() => navigate("/main/feelingCalendar")}
      >
        <div className={"in-modal-box"}>
          <img src={yeonseiImages.SmileLarge} alt={"Smile-large"} />
          지금의 감정도 놓치기 싫다면?
        </div>
      </TwoButtonModal>
    </RecordSection>
  );
};

export default connect((state) => {
  return {
    eSESSION: state.data.eSESSION.eSESSION,
    eCOMM_CD: state.data.eCONST.eCOMM_CD,
    eCOMM_CD_TYPE: state.data.eCONST.eCOMM_CD_TYPE,
  };
})(index);
