import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";

import { MyPageSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

import InputForm from "../../../../common/component/atom/InputForm";
import Button from "../../../../common/component/atom/Button";

import useProfileManage from "../../hooks/useProfileManage";
import ImgFile from "../../../../component/atom/ImgFile";
const index = () => {
  const [isClicked, setIsClicked] = useState(false);
  const { params, setParams, handlePress, onChangeValue, fetchUserUpdate } =
    useProfileManage();

  const checkPhoneNumberFormat = (value) => {
    const phoneNumberRegex = /^\d{3}-\d{4}-\d{4}$/;
    return phoneNumberRegex.test(value);
  };

  const handleButtonClick = () => {
    if (!isClicked) setIsClicked(!isClicked);
    if (isClicked && checkPhoneNumberFormat(params.mber_phone)) {
      fetchUserUpdate();
      setIsClicked(!isClicked);
    }
  };

  useEffect(() => {
    if (params?.mber_phone?.length === 10) {
      setParams((prevState) => ({
        ...prevState,
        mber_phone: params.mber_phone.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "$1-$2-$3"
        ),
      }));
    }
    if (params?.mber_phone?.length === 13) {
      setParams((prevState) => ({
        ...prevState,
        mber_phone: params.mber_phone
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
      }));
    }
  }, [params.mber_phone]);

  return (
    <MyPageSection>
      <div className={"image-box"}>
        <ImgFile
          value={params.mber_img_url}
          onChange={(fileUrl) =>
            isClicked && onChangeValue("mber_img_url", fileUrl)
          }
          buttonSize={"profileLarge"}
          disabled={!isClicked}
        />
        {isClicked && (
          <img
            className={"icon"}
            src={yeonseiImages.Camera}
            alt={"camera-img"}
          />
        )}
      </div>
      <div className={"input-box"}>
        <div className={"title"}>아이디</div>
        <InputForm
          placeholder={params.mber_id}
          disabled={true}
          value={params.mber_id}
          onChange={(e) => onChangeValue("mber_id", e.target.value)}
        />
      </div>
      <div className={"input-box"}>
        <div className={"title"}>닉네임</div>
        <InputForm
          placeholder={params.mber_nickname}
          disabled={!isClicked}
          value={params.mber_nickname}
          onChange={(e) => onChangeValue("mber_nickname", e.target.value)}
        />
      </div>
      <div className={"input-box"} style={{ marginBottom: "10px" }}>
        <div className={"title"}>핸드폰 번호</div>
        <InputForm
          type={"text"}
          placeholder={params.mber_phone}
          disabled={!isClicked}
          value={params.mber_phone}
          onChange={(e) => handlePress("mber_phone", e.target.value)}
          maxLength={13}
        />
      </div>
      <div className={"warning-text"}>
        {isClicked && "핸드폰 번호는 숫자(11자리)만 입력해주세요."}
      </div>
      <Button
        variant={isClicked ? "unpainted" : "painted"}
        label={isClicked ? "저장" : "수정"}
        size={"xLarge"}
        style={css`
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 17px;
        `}
        onClick={() => handleButtonClick()}
      />
    </MyPageSection>
  );
};

export default index;
