import MyPage from "../pages/myPage/page/myPage";
import ResourceList from "../pages/myPage/page/resourceList";
import ProfileManage from "../pages/myPage/page/profileManage";
import PasswordManage from "../pages/myPage/page/passwordManage";
import Qna from "../pages/myPage/page/qna";
import QnaList from "../pages/myPage/page/qnaList";

export const myPageRoutes = [
  {
    path: "/myPage/myPage",
    element: <MyPage />,
  },
  {
    path: "/myPage/resourceList",
    element: <ResourceList />,
  },
  {
    path: "/myPage/profileManage",
    element: <ProfileManage />,
  },
  {
    path: "/myPage/passwordManage",
    element: <PasswordManage />,
  },
  {
    path: "/myPage/qna",
    element: <Qna />,
  },
  {
    path: "/myPage/qnaList",
    element: <QnaList />,
  },
];
