import React from "react";
import { ServiceBox } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = (props) => {
  const { params, onClick } = props;
  return (
    <ServiceBox isAnswered={params.inqry_sts_cd} onClick={onClick}>
      <div className={"info-zone"}>
        <div className={"answer-type"}>
          {params.inqry_sts_cd === false ? "답변대기" : "답변완료"}
        </div>
        <div className={"title"}>{params.inqry_nm}</div>
        <div className={"date"}>{params.inst_dt}</div>
      </div>
      <img src={yeonseiImages.ArrowRight} alt={"arrow-right"} />
    </ServiceBox>
  );
};

export default index;
