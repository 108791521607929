import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

// Styles
import { InformationLayout } from "./styles";

// Component
import Header from "../Header";
import Footer from "../Footer";

// Const
import { Const } from "../../../common/util";
import { yeonseiImages } from "../../../styles";

/**
 * @description 정보 바로알기의 레이아웃 구성을 위한 컴포넌트
 * @param props
 * @return {JSX.Element}
 */

const index = (props) => {
  const { children, middleArrowMenu = Const.middleArrow } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [path, setPath] = useState({
    backPath: "",
    frontPath: "",
  });

  useLayoutEffect(() => {
    if (pathname in middleArrowMenu) {
      setPath({
        backPath: middleArrowMenu[pathname]["backPath"],
        frontPath: middleArrowMenu[pathname]["frontPath"],
      });
    }
  }, [location]);

  return (
    <>
      <Header headerMenu={Const.headerMenu} />
      <Footer isDisplay={true} />
      <InformationLayout>
        <div className={"slider-button"}>
          <img
            src={yeonseiImages.SilderArrowLeft}
            alt={"slider"}
            onClick={() => navigate(path.backPath)}
          />
          <img
            src={yeonseiImages.SilderArrowRight}
            alt={"slider"}
            onClick={() => navigate(path.frontPath)}
          />
        </div>
        {children}
      </InformationLayout>
    </>
  );
};

export default index;
