import styled from "@emotion/styled";

export const MainText = styled.div`
  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  flex-direction: column;

  .main-title {
    padding-left: 30px;
    padding-right: 30px;
    white-space: pre-wrap;
    font-weight: 300;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 30px;
    word-break: keep-all;
  }

  .text {
    padding-left: 30px;
    padding-right: 30px;
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 30px;
    margin-bottom: 30px;
  }

  & img {
    margin-bottom: ${(props) => (props.step === "2" ? "70px" : "50px")};
  }
`;
