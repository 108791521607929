import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const Header = styled.header`
  width: 100%;
  height: 82px;
  position: fixed;
  border-bottom: 1px solid ${Colors.Gray40};
  background-color: ${Colors.White};
  z-index: 10;

  display: ${(props) => (props.isDisplay ? "flex" : "none")};
  align-items: flex-end;
  justify-content: space-between;

  & ~ main {
    padding-top: ${(props) => (props.isDisplay ? "82px" : "inherit")};
  }

  & > div.header-wrapper {
    width: 100%;
    min-height: 24px;
    display: flex;
    align-items: flex-end;
    & > div {
      width: calc(100% / 3);
      display: inline-block;
      font-weight: 600;
      font-size: 1.1rem;
      line-height: 20px;
      color: 1px solid ${Colors.Black};
      white-space: pre-wrap;
      min-height: 24px;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        width: calc(100% / 10);
      }
      &:nth-of-type(2) {
        min-height: 26px;
        width: calc((100% / 10) * 8);
      }
    }

    & > div.backPage {
      & > img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
      }
    }
    & > div.title {
      display: ${(props) =>
        props.isDisplay.includes("생략") ? "none" : "default"};
      text-align: center;
      font-size: 14px;
    }

    & > div.delete-button {
      text-align: end;
      & > img {
        margin-right: 16px;
      }
    }
  }
`;
