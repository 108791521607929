import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const PinNumberInputForm = styled.div`
  font-family: "Pretendard", sans-serif;
  font-style: normal;
  width: 100%;
  height: 100%;

  & .main-title {
    white-space: pre-line;
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 10px;
  }

  .sub-title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 14px;
    text-align: center;
    color: ${Colors.Gray60};
    margin-bottom: 30px;
  }

  & .pin-counter {
    display: flex;
    justify-content: center;
    align-items: center;

    & .pin-ball {
      width: 18px;
      height: 18px;
      margin-right: 7px;
      margin-left: 7px;

      border-radius: 50%;

      background-color: ${Colors.Gray30};
    }
    & .pin-ball.active {
      background-color: ${Colors.Primary};
    }
  }
`;
export const KeyPad = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  bottom: 0;
  margin-bottom: 40px;

  background-color: ${Colors.White};
`;
