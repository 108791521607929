import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const LeftSlidingView = styled.div`
  overflow: hidden;
  min-height: 100vh;
  height: 100%;
  width: 286px;

  & > div.header {
    height: 121px;
    padding: 15px;
    & > div.controlButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      margin-bottom: 18px;

      & > img.home {
        width: 24px;
        height: 21px;
        margin-right: 18px;
      }

      & > img.close {
        width: 21px;
        height: 21px;
      }
    }

    & > div.profile {
      & > div.greeting {
        font-size: 1rem;
        line-height: 14px;
        margin-bottom: 3px;
      }
      .dot {
        top: 21px;
        right: 0;
        width: 6px;
        height: 6px;
        background: linear-gradient(90deg, #f09278 0%, #ee7c5b 86.98%);
        border-radius: 50%;
        margin-left: 70px;
      }
      .space {
        height: 10px;
      }

      & > div.loginButton {
        width: max-content;
        display: flex;
        align-items: center;
        height: 20px;
        & > div {
          font-weight: 500;
          font-size: 1.375rem;
          line-height: 20px;
          margin-right: 5px;
        }

        & > img {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  & > div.content {
    padding: 15px;
    background-color: ${Colors.Gray20};
    height: calc(100% - 121px);
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    & > div.menuBox {
      background-color: ${Colors.White};
      border-radius: 10px;
      margin-bottom: 15px;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);

      & > div.upperTitle {
        display: flex;
        align-items: center;
        margin: 0 15px;

        height: 55px;
        line-height: 15px;

        font-weight: 600;
        font-size: 1rem;
        border-bottom: 1px solid ${Colors.Gray30};
      }
    }
  }
`;

export const MenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;

  height: 57px;
  line-height: 14px;

  border-bottom: 1px solid ${Colors.Gray30};

  & > div.field {
    text-indent: 15px;
    font-weight: 500;
    font-size: 1rem;
  }

  & > img {
    width: 17px;
    height: 17px;
  }

  &:nth-last-of-type(1) {
    border-bottom: none;
  }
`;

export const SubMenuRow = styled.div`
  & > div.menuRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;

    height: 57px;
    line-height: 14px;

    border-bottom: ${(props) =>
      props.isOpen
        ? `1px solid ${Colors.Gray20}`
        : `1px solid ${Colors.Gray30}`};

    & > div.field {
      text-indent: 15px;
      font-weight: 500;
      font-size: 1rem;
      color: ${(props) => (props.isOpen ? Colors.Primary : Colors.Black)};
    }

    & > img {
      width: 17px;
      height: 17px;
    }

    &:nth-last-of-type(1) {
      border-bottom: none;
    }
  }

  & > div.subMenu {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    background-color: ${Colors.Gray20};
    padding: 20px 30px;

    & > div.row {
      font-weight: 400;
      font-size: 1rem;
      line-height: 12px;
      margin-bottom: 30px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }
`;

export const EmergencyModalContent = styled.div`
  & > div.modalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    padding-bottom: 20px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 1.375rem;

    border-bottom: solid 1px ${Colors.Gray30};

    & > div.center {
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 20px;
    }

    &:nth-of-type(1) {
      margin-top: 0;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 24px;
    }
  }
`;
