import React, { useEffect, useState } from "react";

import * as RestApi from "../../../common/context/api";

import { EmoticonImages } from "../../../common/util/const";
import { DateUtil, Utils } from "../../../common/util";

const index = (props) => {
  const emotion_status = Utils.getParameterByName("emotion_status") || "";
  const clear = Utils.getParameterByName("clear") || "false";
  const [dayList, setDayList] = useState([]);
  const initParams = {
    emotion_status: emotion_status || "3",
    timeState: "",
    etc_time: "",
    happiness: 0,
    depression: 0,
    anxiety: 0,
    emotion_stblty: 0,
    stress: 0,
    emotion_cont: "",
    suicide: 0,
    suicide_plan: "",
    suicide_try: "",
    write_dt: new Date(),

    // 취침시간 설정되고 저장되었을때를 가정한 변수
    saveNightTime: false,
    post_yn: false,
    cnt: 0,
    helpline_yn: "N",
  };
  const [params, setParams] = useState(initParams);

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchCalendarCreate = () => {
    const { write_dt, emotion_status, timeState, ...restParams } = params;
    const api_params = {
      ...restParams,
      emotion_status:
        EmoticonImages.findIndex((el) => el.selected === emotion_status) + 1,
      alarm_typ_cd: timeState,
      write_dt: DateUtil.format("fullTime", write_dt),
    };

    RestApi.post("eCALENDAR_USER_CREATE", api_params)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCalendarCount = () => {
    RestApi.get("eCALENDAR_USER_DAY_MAX_CHECK")
      .then((res) => {
        setParams((prevState) => ({
          ...prevState,
          cnt: res.count,
          maxInstDt: DateUtil.format("fullTime", res.inst_dt),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCalendarDetail = (calendar_no) => {
    RestApi.get("eCALENDAR_USER_DETAIL", {
      calendar_no: calendar_no,
    })
      .then((res) => {
        let emoticonImageSrc =
          parseInt(res.detail.emotion_status) > 0 &&
          parseInt(res.detail.emotion_status) <= 5
            ? EmoticonImages[parseInt(res.detail.emotion_status) - 1]?.selected
            : "";

        const detail = {
          ...res.detail,
          emoticonImageSrc: emoticonImageSrc,
          emotion_status_nm: EmoticonImages.find(
            (item) => item.selected === emoticonImageSrc
          ).description,
          timeState: res.detail.alarm_typ_cd,
          record_dt: DateUtil.format("onlyTime", res.detail.write_dt),
          saveNightTime: false,
        };
        setParams(detail);
        fetchCalendarDayList(DateUtil.format("dash", res.detail.write_dt));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCalendarDayList = (date) => {
    const api_date = DateUtil.format("dash", date);
    RestApi.get("eCALENDAR_USER_DAY_LIST", { date: api_date })
      .then((res) => {
        setDayList(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHandleButton = () => {
    if (params.emotion_status === "" || params.timeState === "") {
      onChangeValue("errMsg", "필수값을 입력해주세요.");
      return false;
    } else if (params.maxInstDt) {
      onChangeValue(
        "errMsg",
        "아직 이전 기분을 저장하고 있어요\n 조금만 기다려주세요 :)"
      );
      const now = new Date();
      const beforeCreateDate = new Date(params.maxInstDt);

      const timeDifference = Math.abs(now - beforeCreateDate);

      if (timeDifference < 5000) {
        return false;
      }
    } else if (params.cnt > 10) {
      onChangeValue(
        "errMsg",
        "하루에 작성할 수 있는\n기록을 초과했습니다.\n(최대 10개)"
      );
      return false;
    }

    if (params.timeState === "ATC00002") {
      onChangeValue("saveNightTime", true);
      window.scrollTo({
        top: 0,
      });
    } else {
      fetchCalendarCreate();
      return true;
    }
  };

  const resetParams = () => {
    setParams(initParams);
  };

  return {
    params,
    resetParams,
    dayList,
    onChangeValue,
    onHandleButton,
    fetchCalendarCreate,
    fetchCalendarCount,
    fetchCalendarDetail,
    fetchCalendarDayList,
  };
};
export default index;
