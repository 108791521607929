import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

// Component
import FeelingManageBox from "../../component/FeelingManageBox";
import FeelingNightManageBox from "../../component/FeelingNightManageBox";
import Datepicker from "../../../../common/component/atom/Datepicker";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

// styles
import { MainSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

// Utils
import { Utils } from "../../../../common/util";
import * as DateUtil from "../../../../common/util/date";

// Hooks
import useFeelingCalendarManage from "../../hooks/useFeelingCalendarManage";

const index = (props) => {
  const { eSESSION } = props;
  const { search } = useLocation();
  // 해당 값을 저장한 id 가 쿼리로 넘어왔다면 수정 페이지 아니라면 추가페이지
  const timeNo = Utils.getParameterByName("timeNo") || "";
  const oneButtonModalHandler = handlerModal();
  const navigate = useNavigate();
  const today = moment().endOf("day");
  const now = moment().startOf("minute");
  const {
    params,
    resetParams,
    dayList,
    onChangeValue,
    onHandleButton,
    fetchCalendarCount,
    fetchCalendarCreate,
    fetchCalendarDetail,
    fetchCalendarDayList,
  } = useFeelingCalendarManage();

  useEffect(() => {
    resetParams();
  }, [search]);

  useEffect(() => {
    if (timeNo !== "") {
      fetchCalendarDetail(timeNo);
    } else {
      fetchCalendarCount();
    }
  }, [timeNo]);

  const filterPassedTime = (time) => {
    const timeToCheck = moment(time);
    return timeToCheck.isAfter(now);
  };

  return (
    <MainSection>
      <div className={"titleBox"}>
        {timeNo === "" ? (
          <Datepicker
            format={"yyyy년 MMMM dd일 HH:mm"}
            placeholder={params.write_dt}
            showTime={true}
            maxDate={today.toDate()}
            minDate={now.toDate()}
            value={params.write_dt}
            onChange={(value) => {
              onChangeValue("write_dt", value);
            }}
            timeIntervals={1}
            filterTime={filterPassedTime}
            showTimeSelectOnly={true}
          />
        ) : (
          <div
            className={"date-selector"}
            onClick={() => {
              fetchCalendarDayList(params.write_dt);
              oneButtonModalHandler.onToggleModal();
            }}
          >
            {DateUtil.format("LLL", params.write_dt)}
            <img src={yeonseiImages.ArrowDown} alt={"arrow-down"} />
          </div>
        )}
      </div>
      {!params.saveNightTime ? (
        <FeelingManageBox
          timeNo={timeNo}
          params={params}
          onChangeValue={onChangeValue}
          onHandleButton={onHandleButton}
        />
      ) : (
        <FeelingNightManageBox
          timeNo={timeNo}
          params={params}
          onChangeValue={onChangeValue}
          fetchCalendarCreate={fetchCalendarCreate}
        />
      )}
      <OneButtonModal handler={oneButtonModalHandler}>
        <div className={"in-modal-list"}>
          {dayList.map((el, index) => (
            <div
              key={index}
              className={"item-box"}
              onClick={() => {
                navigate(
                  "/main/feelingCalendarManage?timeNo=" + el?.calendar_no
                );
                oneButtonModalHandler.onToggleModal();
              }}
            >
              {DateUtil.format("LLL", el.write_dt)}
            </div>
          ))}
        </div>
      </OneButtonModal>
    </MainSection>
  );
};

export default connect((state) => {
  return {
    eSESSION: state.data.eSESSION.eSESSION,
    eCOMM_CD: state.data.eCONST.eCOMM_CD,
    eCOMM_CD_TYPE: state.data.eCONST.eCOMM_CD_TYPE,
  };
})(index);
