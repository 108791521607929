import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

import Button from "../../../../common/component/atom/Button";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

import { ServiceBox } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = (props) => {
  const { item } = props;
  const navigate = useNavigate();
  const oneButtonModalHandler = handlerModal();
  console.log(item);
  return (
    <ServiceBox>
      <div className={"title"}>{item.title}</div>
      <div className={"button-area"}>
        {item.phone_nm ? (
          <a
            href={`tel:${item.phone_nm}`}
            className={"call-button"}
            onClick={() => console.log(item.phone_nm)}
          >
            <img src={yeonseiImages.PhoneCall} alt={"phone-icon"} />
            <p>{item.phone_nm}</p>
          </a>
        ) : item.url ? (
          <img
            src={yeonseiImages.ShareBox}
            alt={"share-button"}
            onClick={() => window.open(item.url, "_self")}
          />
        ) : null}
        <Button
          variant={"unpainted"}
          size={"smallLarge"}
          label={"더보기"}
          style={css`
            font-size: 1.25rem;
            font-weight: 600;
          `}
          onClick={() =>
            item.hasOwnProperty("isDescription")
              ? navigate(`/localResource/governmentService?step=${item.step}`)
              : oneButtonModalHandler.onToggleModal()
          }
        />
        <OneButtonModal handler={oneButtonModalHandler} label={"닫기"}>
          <div className={"in-modal-box"}>
            {item.url && (
              <img
                src={yeonseiImages.ShareBox}
                alt={"share-button"}
                onClick={() => window.open(item.url, "_self")}
              />
            )}
            <div className={"detail"}>{item.detail}</div>
          </div>
        </OneButtonModal>
      </div>
    </ServiceBox>
  );
};

export default index;
