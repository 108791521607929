import { Colors } from "../../../../styles";
import styled from "@emotion/styled";

export const MainSection = styled.section`
  width: 100%;
  min-height: 100vh;
  height: 100%;

  background-color: ${Colors.Primary10};
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  .title {
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 3;
    font-weight: 600;
    font-size: 1.625rem;
    padding: 0 32px;
  }

  .source {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 18px;
    text-align: center;
    color: ${Colors.Gray80};
    background-color: ${Colors.Gray20};
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 20px 30px 20px;
`;
