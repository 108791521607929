import React from "react";

/**
 * @description 헤더 메뉴 설정값
 * @params {String} title - title에 "생략" 포함 시, title display:none
 */
export const headerMenu = {
  // Auth
  "/auth/login": {
    title: "로그인",
    backPath: "/auth/intro",
  },
  "/auth/signup": {
    title: "회원가입",
    backPath: "/auth/intro",
    step: {
      1: {
        title: "이용약관 및 개인정보 수집 동의",
        backPath: "/auth/intro",
      },
      2: {
        title: "회원가입",
        backPath: "/auth/signup?step=1",
      },
      3: {
        title: "이메일 인증",
        backPath: "/auth/signup?step=2",
      },
    },
  },
  "/auth/termAgree": {
    title: "이용약관",
    backPath: "/auth/intro",
  },
  "/auth/findIdPw": {
    title: "아이디/비밀번호 찾기",
    backPath: "/auth/intro",
  },
  "/auth/email": {
    title: "이메일 인증",
    backPath: "/auth/intro",
  },
  "/auth/pin": {
    title: "로그인",
    backPath: "/auth/login",
  },
  "/auth/passwordReset": {
    title: "비밀번호 재설정",
    backPath: "/auth/login",
  },
  "/auth/verification": {
    title: "본인 인증",
    backPath: "/auth/login",
  },
  "/auth/exceptionVerify": {
    title: "이메일 인증",
    backPath: "/auth/login",
  },

  // Main
  "/main/safetyPlan": {
    title: "생략",
    backPath: "/main/main",
    step: {
      1: {
        title: "생략1번",
        backPath: "/main/main",
      },
      2: {
        title: "생략2번",
        backPath: "/main/safetyPlan?step=1",
      },
      3: {
        title: "생략3번",
        backPath: "/main/safetyPlan?step=2",
      },
      4: {
        title: "생략4번",
        backPath: "/main/safetyPlan?step=3",
      },
      5: {
        title: "생략4.5번",
        backPath: "/main/safetyPlan?step=4",
      },
      6: {
        title: "생략5번",
        backPath: "/main/safetyPlan?step=4",
      },
    },
  },

  "/main/safetyPlanManage": {
    title: "생략",
    backPath: "/main/main",
  },

  "/main/feelingCalendar": {
    title: "기분 달력",
    backPath: "/main/main",
  },
  "/main/feelingCalendarManage": {
    title: "기분 기록",
    backPath: "/main/feelingCalendar",
  },
  "/main/feelingCalendarStatistics": {
    title: "기분 달력",
    backPath: "/main/feelingCalendar",
  },

  // MyPage
  "/myPage/myPage": {
    title: "마이페이지",
    backPath: "/main/main",
  },
  "/myPage/profileManage": {
    title: "프로필 관리",
    backPath: "/myPage/myPage",
  },
  "/myPage/passwordManage": {
    title: "비밀번호 변경",
    backPath: "/myPage/myPage",
  },
  "/myPage/qna": {
    title: "QnA",
    backPath: "/main/main",
  },
  "/myPage/qnaList": {
    title: "나만의 QnA",
    backPath: "/myPage/myPage",
  },

  //LocalResource
  "/localResource/availableService": {
    title: "이용 가능한 서비스",
    backPath: "/main/main",
  },
  "/localResource/governmentService": {
    title: "국가제공지원서비스",
    backPath: "/main/main",
    step: {
      2: {
        title: "국가제공지원서비스",
        backPath: "/localResource/governmentService",
      },
      3: {
        title: "국가제공지원서비스",
        backPath: "/localResource/governmentService",
      },
      4: {
        title: "국가제공지원서비스",
        backPath: "/localResource/governmentService",
      },
    },
  },
  "/localResource/preventSuicide": {
    title: "전국 자살예방센터",
    backPath: "/main/main",
  },
  "/localResource/appGuide": {
    title: "심리상담 어플 안내",
    backPath: "/main/main",
  },
  "/localResource/couponIssue": {
    title: "쿠폰발급",
    backPath: "/localResource/appGuide",
  },

  // Information
  "/information/knowSuicide": {
    title: "자살 바로 이해하기",
    backPath: "/main/main",
  },
  "/information/misunderstandingTruth": {
    title: "자살, 오해와 진실",
    backPath: "/main/main",
  },
  "/information/wayToKeepMe": {
    title: "나를 돌보는 방법",
    backPath: "/main/main",
    step: {
      1: {
        title: "나를 돌보는 방법",
        backPath: "/main/main",
      },
      2: {
        title: "나를 돌보는 방법",
        backPath: "/main/main",
      },
      3: {
        title: "나를 돌보는 방법",
        backPath: "/main/main",
      },
      4: {
        title: "나를 돌보는 방법",
        backPath: "/main/main",
      },
      5: {
        title: "나를 돌보는 방법",
        backPath: "/main/main",
      },
    },
  },
  "/information/treatmentMethod": {
    title: "치료방법 및 과정",
    backPath: "/main/main",
  },
  "/information/suicidePrevisionTips": {
    title: "자살예방 수칙",
    backPath: "/main/main",
  },

  // Setting
  "/setting/setting": {
    title: "설정",
    backPath: "/main/main",
  },

  "/setting/alarm": {
    title: "생략",
    backPath: -1,
  },

  "/setting/appPassword": {
    title: "생략",
    backPath: "/setting/setting",
  },

  "/setting/aboutApp": {
    title: "앱에 관해서",
    backPath: "/setting/setting",
  },

  "/setting/howToUse": {
    title: "앱 사용 설명서",
    backPath: "/setting/setting",
  },

  "/setting/withdrawal": {
    title: "회원탈퇴",
    backPath: "/setting/setting",
  },

  "/setting/privacyPolicy": {
    title: "개인정보처리방침",
    backPath: "/setting/setting",
  },
};

export const middleArrow = {
  "/information/knowSuicide": {
    backPath: "/information/suicidePrevisionTips",
    frontPath: "/information/misunderstandingTruth",
  },
  "/information/misunderstandingTruth": {
    backPath: "/information/knowSuicide",
    frontPath: "/information/wayToKeepMe",
  },
  "/information/wayToKeepMe": {
    backPath: "/information/misunderstandingTruth",
    frontPath: "/information/treatmentMethod",
  },
  "/information/treatmentMethod": {
    backPath: "/information/wayToKeepMe",
    frontPath: "/information/suicidePrevisionTips",
  },
  "/information/suicidePrevisionTips": {
    backPath: "/information/treatmentMethod",
    frontPath: "/information/knowSuicide",
  },
};

/**
 * @description localStorage 의 key
 * @type {string}
 */
export const LOGIN_TOKEN = "yeonsei-medical-user-token";

/**
 * @description localStorage 의 key
 * @type {string}
 */
export const LOGIN_TIME = "yeonsei-medical-user-login-time";

/**
 * @TODO 오늘의 기분 어떠신가요? 기능 구현 및 url 삽입.
 *       기획팀 large size 제작 요청
 */
export const footer_config = [
  {
    nm: "홈",
    url: "/main/main",
    img_src_default: "Home",
    img_src_selected: "HomeHover",
  },
  {
    nm: "나를 아낌",
    url: "/main/feelingCalendar?calendarTabBox=statistics",
    img_src_default: "Calendar",
    img_src_selected: "CalendarHover",
  },
  {
    // 자리잡는 용도의 객체
    nm: "",
    url: "",
    img_src_default: "",
    img_src_selected: "",
  },
  {
    img_src_default: "SmileLarge",
    img_src_selected: "SosoMedium",
    center_motion: "true",
  },
  {
    nm: "긴급 상황 대처",
    url: "/test/test", // url 사용 안함. 임의의 값 삽입.
    img_src_default: "Notification",
    img_src_selected: "NotificationHover",
  },
  {
    nm: "설정",
    url: "/setting/setting",
    img_src_default: "Setting",
    img_src_selected: "SettingHover",
  },
];

/**
 * @description 마이페이지 navigator
 */

export const MyPageNavigateTitle = [
  {
    nm: "프로필 관리",
    nav: "/myPage/profileManage",
  },
  {
    nm: "비밀번호 변경",
    nav: "/myPage/passwordManage",
  },
  {
    nm: "나의 자원 목록",
    nav: "/myPage/resourceList",
  },
  {
    nm: "나만의 QnA",
    nav: "/myPage/qnaList",
  },
  {
    nm: "쿠폰발급",
    nav: "/localResource/couponIssue",
  },
];

export const SettingNavigateTitle = [
  {
    nm: "마이페이지",
    nav: "/myPage/myPage",
  },
  {
    nm: "알람 시간 설정",
    nav: "/setting/alarm",
  },
  {
    nm: "간편 비밀번호 설정",
    nav: "/setting/appPassword",
  },
  {
    nm: "회원탈퇴",
    nav: "/setting/withdrawal",
  },
  {
    nm: "로그아웃",
    nav: "/setting/alarm",
  },
  {
    nm: "앱에 관해서",
    nav: "/setting/aboutApp",
  },
  {
    nm: "앱 사용 설명서",
    nav: "/setting/howToUse",
  },
  // {
  //   nm: "개인정보처리방침",
  //   nav: "/setting/privacyPolicy",
  // },
];

/**
 * @description 비밀번호 쉬운 단어 리스트
 */
export const err_pwList = [
  "love",
  "happy",
  "password",
  "qwert",
  "asdfq",
  "apple",
];

/**
 * @description 바텀모달에 들어가는 우리나라 지역
 */
export const cityList_config = [
  {
    id: "seoul",
    value: "서울특별시",
    detail: [
      { title: "서울시 자살예방센터", url: "http://www.suicide.or.kr" },
      {
        title: "[ 서울시민 대상 서비스 ]",
        main: "챗봇 '누구나'\n챗봇을 카카오톡 친구로 추가하여 사용",
        url: "https://pf.kakao.com/_cWWKK",
      },
      {
        title: "[ 자살예방 생명이음청진기 ]",
        main: "동네 병원, 의원에서 우울검사 진행 후\n상담 연계",
        url: "https://blutouch.net/service/stethoscope",
      },
    ],
  },
  {
    id: "busan",
    value: "부산광역시",
    title: "부산 광역자살예방센터",
    url: "http://suicide.busaninmaum.com",
  },
  {
    id: "incheon",
    value: "인천광역시",
    title: "인천광역시 자살예방센터",
    url: "http://ispc.or.kr",
  },
  {
    id: "daejeon",
    value: "대전광역시",
    title: "대전광역 정신건강 복지센터",
    url: "http://djpmhc.or.kr",
  },
  {
    id: "daegu",
    value: "대구광역시",
    title: "대구광역 정신건강 복지센터",
    url: "http://dgmhc.or.kr/index.php",
  },
  {
    id: "gwangju",
    value: "광주광역시",
    title: "광주광역 정신건강 복지센터",
    url: "http://gmhc.or.kr",
  },
  {
    id: "ulsan",
    value: "울산광역시",
    title: "울산광역 자살예방센터",
    url: "http://usspc.or.kr",
  },
  {
    id: "sejong",
    value: "세종특별자치시",
    title: "세종시 정신건강 복지센터",
    url: "http://www.sjcmhc.com/",
  },
  {
    id: "gyeonggi",
    value: "경기도",
    title: "경기도 정신건강 복지센터",
    url: "http://mindsave.org",
  },
  {
    id: "gangwon",
    value: "강원특별자치도",
    title: "강원도광역 정신건강 복지센터",
    url: "http://gwmh.or.kr",
  },
  {
    id: "north-chungcheong",
    value: "충청북도",
    title: "충북광역 정신건강 복지센터",
    url: "http://cbmind.or.kr",
  },
  {
    id: "south-chungcheong",
    value: "충청남도",
    title: "충남광역 정신건강 복지센터",
    url: "http://chmhc.or.kr",
  },
  {
    id: "north-jeolla",
    value: "전라북도",
    title: "전라북도 정신건강 복지센터",
    url: "http://www.jbmhc.or.kr/",
  },
  {
    id: "south-jeolla",
    value: "전라남도",
    title: "전라남도광역 정신건강 복지센터",
    url: "http://www.061mind.or.kr/",
  },
  {
    id: "north-gyeongsang",
    value: "경상북도",
    title: "경상북도 정신건강 복지센터",
    url: "http://www.gbmhc.or.kr/",
  },
  {
    id: "south-gyeongsang",
    value: "경상남도",
    title: "경상남도광역 정신건강 복지센터",
    url: "http://www.gnmhc.or.kr/",
  },
  {
    id: "jeju",
    value: "제주특별자치도",
    title: "제주특별자치도\n광역정신건강 복지센터",
    url: "http://www.jejumind.or.kr/",
  },
];

export const email_config = [
  {
    id: "naver.com",
    value: "naver.com",
  },
  {
    id: "gmail.com",
    value: "gmail.com",
  },
  {
    id: "daum.net",
    value: "daum.net",
  },
  {
    id: "hanmail.net",
    value: "hanmail.net",
  },
  {
    id: "codecrain.com",
    value: "codecrain.com",
  },
];

export const safetyPlan_config = {
  step1: {
    title: "자살 생각이나 기분 또는\n충동을 불러 일으키는 것은?",
    example: "예) 비가 오는 날",
    repeat: "나의 위험 징후를 입력해주세요.",
  },
  step2: {
    title: "자살 생각으로부터\n멀어지게 하는 행동들은?",
    example: "예) 집 근처 산책가기",
    repeat: "나의 대처 전략을 입력해주세요.",
    exclamation: "위기상황일 때 내가 정말\n실천할 수 있는 활동들을 적어주세요.",
  },
  step3: {
    title:
      "나를 편안하게 하고\n자살 생각으로부터\n멀어지게 하는 사람 혹은\n장소는 무엇인가요?",
    example: "예) 광화문 대형서점 가기",
    repeat: "나의 사회적 활동을 입력해주세요.",
  },
  step4: {
    title:
      "혼자서 해결할 수 없는\n자살 위험에 처했을 때\n연락 할 수 있는 사람들을\n추가 해주세요.",
  },
  step5: {
    title: "위기 상황에서 도움을\n받을 수 있는 전문 기관 번호를\n알려드릴게요.",
    exclamation:
      "아래 연락처는 메인페이지에서도\n확인 및 통화를 진행할 수 있습니다.",
  },
  step6: {
    title:
      "마지막으로,\n위험한 물건이나 도구로부터\n나를 지킬 수 있는 방법을\n적어주세요",
    example: "예) 날카로운 물건 치우기",
    repeat: "주변 환경을 안전하게 만들 계획을 입력해주세요.",
  },
};

export const FeelingTitleManage = {
  happiness: "행복함",
  depression: "우울함",
  anxiety: "불안함",
  emotion_stblty: "마음이 편안함",
  stress: "스트레스",
  suicide: "오늘 자살 생각정도",
};

export const FeelingTitleCreate = {
  happiness: "행복해요.",
  depression: "우울해요.",
  anxiety: "불안해요.",
  emotion_stblty: "마음이 편안해요.",
  stress: "스트레스 받아요.",
};

export const LocalResource_config = [
  {
    title: "국가정신건강정보포털",
    detail:
      "자가검진, 질환 별 정보, 정신건강관련 기관\n\n통계, 약 정보 등을 제공",
    url: "http://www.mentalhealth.go.kr/portal/main/index.do",
  },
  {
    title: "국립정신센터 국가트라우마센터",
    detail: "자살과 자살예방에 대한 정보 제공",
    url: "http://www.nct.go.kr/",
  },
  {
    title: "블루터치",
    detail: "정신건강정보, 자가검진, 자기관리\n서비스를 제공",
    url: "http://blutouch.net/",
  },
  {
    title: "학생 청소년 정신과적 상담",
    phone_nm: "1388",
    detail:
      "청소년 모바일 상담센터 다들어줄개\n\n(카카오톡/페이스북/앱)\n(1661-5004 문자상담)",
  },
  {
    title: "취업 지원 상담",
    phone_nm: "1350",
    detail: "고용노동부 고객상담센터(1350)",
  },
  {
    title: "여성 지원 상담(취업)",
    phone_nm: "1544-1199",
    detail: "여성 긴급 전화(1366)\n\n여성새로일하기센터(1544-1199)",
  },
];

export const GvrnmntSrvc_config = [
  {
    step: "2",
    title: "정신건강 치료비 지원 서비스",
    isDescription: true,
    detail: (
      <>
        <span>대상</span> 정신건강의학과에서 진단을 받고, 중위소득 120%이하
        <br />
        <br />
        <span>내용:</span> 응급/행정입원 치료비, 발병 초기(최초진단 5년
        이내)정신질환 치료비, 외래치료지원 치료비(정신건강심사위원회의 행정명령)
        중본인 일부부담금 지원
        <br />
        <br />
        <span>방법:</span>
        <br />
        <span>1.</span>치료비 발생일로부터 180일 이내인지 확인
        <br />
        <span>2.</span> 인터넷 포털 또는 한국 생명존중희망재단 홈페이지
        <br />
        (www.kfsp.org)
        <br />
        국립정신건강센터(www.ncmh.go.kr)
        <br />
        <span>3.</span> 주민등록 주소지 관할 보건소 및 정신건강복지센터 확인
        <br />
        <span>4.</span>관할 보건소 및 정신건강복지센터 전화 문의 및 방문
        <br />
        <br />
        <span>문의:</span> 관내보건소 및 정신건강복지센터
        <br />
        (1577-0199)
      </>
    ),
  },
  {
    step: "3",
    title: "자살시도자 치료비 지원 서비스",
    isDescription: true,
    detail: (
      <>
        <span>대상:</span> 응급실 기반 자살시도자 사후관리사업
        수행병원(한국생명존중희망재단 홈페이지 확인)에 자살 시도로 내원한 환자
        중,
        <br />
        <span>1.</span> 사후관리서비스에 서면 동의(필수)
        <br />
        <span>2.</span> 의료급여 수급자 혹은 건강보험 가입자 중 자살
        재시도자/자살 유족/경제적 위기자)
        <br />
        <br />
        <span>내용:</span> 응급실 치료비, 입원비, 외래 치료비
        <br />
        (1인 최대 100만원)
        <br />
        <br />
        <span>방법:</span> 응급실사업 담당 사례관리자와 면담 후 신청 <br />
        <br />
        <span>문의:</span>
        한국생명존중희망재단 사업운영팀
        <br />
        (02-3706-0516, 0517)
      </>
    ),
  },
  {
    step: "4",
    title: "자살 위기지원 서비스",
    isDescription: true,
    detail: (
      <>
        <span>대상:</span> 자살위험자중 자살 위기지원 서비스를 신청하거나
        발굴/의뢰된 자
        <br />
        <br />
        <span>내용: </span>
        <br />
        <span>1.</span> 자살 위기상담 서비스: 자살에 대한 부정적 또는 비합리적
        사고, 정서와 행동의 변화 다루기, 심리 안정 취하기, 스트레스 관리,
        안전대처 전략수립 등
        <br />
        <span>2.</span> 정신과적 치료 연계: 정신과 치료 의뢰, 약물치료 관리,
        치료비 지원 등
        <br />
        <br />
        <span>문의:</span>
        <br />
        <span>1.</span> 자살예방상담전화 109
        <br />
        <span>2.</span> 전국 보건소, 정신건강복지센터 및 자살예방센터
        <br />
        (1577-0199)
      </>
    ),
  },
];

export const EmoticonImages = [
  {
    selected: "CrySmall",
    unselected: "CrySmallUnselected",
    description: "매우\n우울함",
  },
  {
    selected: "WorseSmall",
    unselected: "WorseSmallUnselected",
    description: "우울한\n편임",
  },
  {
    selected: "UpsetSmall",
    unselected: "UpsetSmallUnselected",
    description: "그저\n그러함",
  },
  {
    selected: "SosoSmall",
    unselected: "SosoSmallUnselected",
    description: "좋은\n편임",
  },
  {
    selected: "SmileSmall",
    unselected: "SmileSmallUnselected",
    description: "매우\n좋음",
  },
];

export const messageType = [
  {
    type: "create",
    title: "비밀번호 등록",
    subTitle: "새 비밀번호를 입력해주세요.",
  },
  {
    type: "repeat",
    title: "비밀번호 다시 입력",
    subTitle: "비밀번호를 한 번 더 확인합니다.",
  },
  {
    type: "confirm",
    title: "비밀번호 입력",
    subTitle: "사용중인 비밀번호를 입력해주세요.",
  },
  {
    type: "change",
    title: "비밀번호 변경",
    subTitle: "변경할 비밀번호를 입력해주세요.",
  },
];
