import styled from "@emotion/styled";
import { Colors, globalStyle } from "../../../styles";

export const BottomModalLayout = styled.dialog`
  position: fixed;
  transform: translate(-50%, -100%);
  top: 100%;
  left: 50%;
  
  width: 100%;
  max-width: 100vw;
  max-height: 50%;
  height: 50%;
  overflow: hidden;
  
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  
  ::backdrop {
    background: ${Colors.Black};
    opacity: 0.6;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
`;
