import styled from "@emotion/styled";

export const InformationLayout = styled.main`
  width: 100%;
  min-height: 100vh;
  height: 100%;

  .slider-button {
    display: flex;
    flex-direction: row;

    width: 100%;
    position: fixed;
    top: 50%;
    z-index: 9999;

    & > img:nth-child(1) {
      margin-left: 5px;
    }

    & > img:nth-child(2) {
      margin-left: auto;
      margin-right: 5px;
    }
  }
`;
