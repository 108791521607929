import React from "react";

import Button from "../../../../common/component/atom/Button";
import InputForm from "../../../../common/component/atom/InputForm";

import { yeonseiImages } from "../../../../styles";
import { FormWrapper, MultiFormBox } from "./styles";
import Checkbox from "../../../../component/atom/Checkbox";

const index = (props) => {
  const {
    step,
    params,
    list,
    isClicked,
    onAddItem,
    onChange,
    onAddBeforeItem,
    onCheck,
  } = props;

  return (
    <MultiFormBox>
      <FormWrapper>
        {params
          ?.filter((item) => item.plan_step === step && item.del_yn === "N")
          .map((item, index) => (
            <>
              {!isClicked && (step === 1 || step === 5) && (
                <div className={"item-box"}>{item.plan_nm}</div>
              )}
              {!isClicked && (step === 2 || step === 3) && (
                <Checkbox
                  label={item.plan_nm}
                  variant={"paintedXlarge"}
                  checked={item.complete_yn}
                  onChange={() => {
                    onCheck(item.plan_no, !item.complete_yn);
                  }}
                  darkness={true}
                />
              )}
              {isClicked && (
                <InputForm
                  key={index}
                  placeholder={item.plan_nm}
                  value={item.plan_nm}
                  disabled={item.disabled}
                  remove_yn={
                    params?.filter(
                      (item) => item.plan_step === step && item.del_yn === "N"
                    ).length > 3
                      ? "Y"
                      : "N"
                  }
                  onChange={(e) =>
                    onChange(item.plan_no, "plan_nm", e.target.value)
                  }
                  onRemove={() => onChange(item.plan_no, "del_yn", "Y")}
                />
              )}
            </>
          ))}
        {list && list.length > 0 && isClicked && step !== 4 && (
          <div className={"before-list-box"}>
            <div className={"before-title"}>이전 기록</div>
            <div className={"before-item-box"}>
              {list.map((el) => (
                <div
                  className={"before-item"}
                  onClick={() => onAddBeforeItem(step, el)}
                >
                  {el.plan_nm}
                </div>
              ))}
            </div>
          </div>
        )}
        {isClicked && step !== 4 && (
          <Button
            variant={"plusIcon"}
            size={"midIcon"}
            img={yeonseiImages.PlusCircleGray}
            onClick={() => onAddItem(step)}
          />
        )}
      </FormWrapper>
    </MultiFormBox>
  );
};

export default index;
