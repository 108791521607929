import styled from "@emotion/styled";

export const LoginSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0 30px 0;

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;
    white-space: pre-wrap;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
