import { useEffect } from "react";

export default function ({ targetRef, setIsOpen }) {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (targetRef.current && !targetRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
}
