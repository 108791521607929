import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const TimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.size === false ? "118px" : "303px")};
  background: ${Colors.White};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;

  .time-title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 18px;
    color: ${Colors.Gray90};
    margin-bottom: 30px;
  }

  .selected-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .meridiem {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;

      color: ${Colors.Gray80};
    }

    & > .time {
      font-size: 30px;
      font-weight: 600;
      line-height: 30px;
      margin-left: auto;
      margin-right: 14px;
      color: ${Colors.Gray80};
    }
  }

  .time-select-zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
