import React from "react";
import { DetailBox, BorderLine } from "./styles";

const index = (props) => {
  const { params } = props;

  return (
    <DetailBox isAnswered={params.inqry_sts_cd}>
      <div className={"info-zone"}>
        <div className={"answer-type"}>
          {params.inqry_sts_cd === false ? "답변대기" : "답변완료"}
        </div>
        <div className={"title"}>{params.inqry_nm}</div>
        <div className={"date"}>{params.inst_dt}</div>
      </div>
      <BorderLine />
      <div className={"img-box"}>
        {params.inqry_img_url1 && (
          <img src={params.inqry_img_url1} alt={"img"} />
        )}
        {params.inqry_img_url2 && (
          <img src={params.inqry_img_url2} alt={"img"} />
        )}
        {params.inqry_img_url3 && (
          <img src={params.inqry_img_url3} alt={"img"} />
        )}
      </div>
      <div className={"detail-zone"}>{params.inqry_cont}</div>
      {params.inqry_sts_cd === true && (
        <div className={"answer-zone"}>
          [답변]
          <br />
          <br />
          {params?.answer_cont}
        </div>
      )}
    </DetailBox>
  );
};

export default index;
