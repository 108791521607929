import React from "react";
import { css, cx } from "@emotion/css";

// Components
import Datepicker from "../../Datepicker";

// Utils
import { DateUtil } from "../../../../util";

const index = (props) => {
  const { params, from, to, onChange, style } = props;

  const changeDate = (field, date) => {
    const temp_p = { ...params };
    temp_p[field] = date ? DateUtil.format("dash", date) : null;

    if (
      temp_p[from.field] &&
      temp_p[to.field] &&
      temp_p[from.field] > temp_p[to.field]
    ) {
      alert("종료일이 시작일보다 커야 합니다. 종료일을 다시 선택해주세요.");
    } else {
      onChange(field, date ? DateUtil.format("dash", date) : null);
    }
  };

  return (
    <div
      className={cx(
        css`
          display: flex;
        `,
        style
      )}
    >
      <Datepicker
        format={from.format}
        placeholder={from.placeholder}
        value={params[from.field]}
        style={css`
          width: 126px;
          & input {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        `}
        onChange={(date) => {
          changeDate(from.field, date);
        }}
      />
      <Datepicker
        format={to.format}
        placeholder={to.placeholder}
        value={params[to.field]}
        style={css`
          width: 126px;
          & .common-icon {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        `}
        onChange={(date) => {
          changeDate(to.field, date);
        }}
      />
    </div>
  );
};

export default index;
