import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const ManageCallListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px;
    width: 100%;
    height: 50px;
    background: ${Colors.PrimaryOpacity};
    border: 1px solid ${Colors.Primary};
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 20px;
  }

  & > Button {
    margin-top: 20px;
  }
`;
