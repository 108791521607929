import styled from "@emotion/styled";
import { Colors } from "../../../styles";
import { css } from "@emotion/react";

export const ChartWrapper = styled.div`
  width: calc(100% + 8px);
  height: 222px;

  & text {
    font-family: Pretendard !important;
  }

  //@media screen and (min-width: 1024px) {
  //  height: 280px;
  //}
  //
  //@media screen and (min-width: 768px) and (max-width: 1023px) {
  //  height: 260px;
  //}
  //
  //@media screen and (min-width: 480px) and (max-width: 767px) {
  //  height: 240px;
  //}
  //
  //@media screen and (max-width: 479px) {
  //  height: 220px;
  //}
`;

/**
 * @description nivo library가 가지고 있는 자체적인 theme
 * @description axis, domain, grid, legends, annotations, tooltip 수정가능.
 * @description https://nivo.rocks/guides/theming/
 */

export const theme = {
  axis: {
    ticks: {
      text: {
        fill: Colors.Black,
        fontSize: 12,
      },
    },
  },
  grid: {
    line: {
      stroke: Colors.Gray30,
      strokeWidth: 3,
    },
  },
};

/**
 * @description theme 으로 해결 안되는 css 설정
 */
export const customStyle = css``;
