import React from "react";
import { SettingSection } from "./styles";

const index = () => {
  return (
    <SettingSection>
      <div className={"explain-box"}>
        마음이 힘들고 삶에 대한 의지를 다시 갖고자 하는 사람이 본 앱을 사용하여
        자살을 예방하기 위한 유용한 정보를 얻고 위기상황에 도움을 요청할 수
        있습니다.
        <br />
        <br />
        또한, 계획된 앱의 프로그램에 따라 자신 스스로를 안전하게 지킬 수 있고
        매일의 감정을 정해진 시간에 기록하여 나에게 위험한 순간이 오지 않도록
        도울 수 있습니다.
        <br />
        <br />
        설정한 앱의 알람과 안내대로 자신의 상태를 기록해보세요.
      </div>
    </SettingSection>
  );
};

export default index;
