import styled from "@emotion/styled";
import { Colors } from "../../../../../styles";

export const PeriodWideBox = styled.div`
  display: flex;
`;

export const PeriodSelectBox = styled.div`
  margin-left: 8px;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;

  & > span {
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
  }

  & > span:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & > span:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }
  & .selected + span {
    border-left-width: 0;
  }
`;

export const PeriodSelectItem = styled.span`
  color: ${(props) => (props.selected ? Colors.PrimaryV01 : Colors.Gray80)};
  background-color: ${(props) =>
    props.selected ? Colors.PrimaryV04 : Colors.White};
  border: solid 1px
    ${(props) => (props.selected ? Colors.PrimaryV01 : Colors.Gray40)};
  border-right-width: ${(props) => (props.selected ? "1px" : 0)};
  min-width: 35px;
  text-align: center;
`;
