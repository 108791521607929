import React, {useEffect, useState} from 'react';

//styled
import {StepWrapper, StepLabelContainer, StepContainer, StepTagContainer} from "./styles";
import {yeonseiImages} from "../../../../styles";


const index = (data) => {
    const [activeStep, setActiveStep] = useState(1)
    const currentStep = data.data.currentStep
    const steps = [
        {
            label: 'STEP1',
            step: 1,
        },
        {
            label: 'STEP2',
            step: 2,
        },
        {
            label: 'STEP3',
            step: 3,
        },
        {
            label: 'STEP4',
            step: 4,
        },
        {
            label: 'STEP5',
            step: 5,
        },
    ]
    const totalSteps = steps.length
    const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`

    useEffect(() => {
        if (currentStep > activeStep) {
            setActiveStep(activeStep + 1);
        } else if (currentStep < activeStep) {
            setActiveStep(activeStep - 1)
        }
    },[currentStep]);

    const handleLabelClick = (step) => {
        const ref = data.data.refs[step-1]
        ref.current.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <>
            <StepContainer width={width}>
                {steps.map(({step, label}) => (
                    <StepWrapper key={step}>
                        <StepLabelContainer>
                            <div onClick={() => handleLabelClick(step)}>
                                {(activeStep === step)
                                    ? <img src={yeonseiImages.CheckBorderAble} alt={"stepLabelImage"}/>
                                    : (activeStep > step) ? <img src={yeonseiImages.CheckAble} alt={"stepLabelImage"}/>
                                        : <img src={yeonseiImages.CheckDisable} alt={"stepLabelImage"}/>
                                }
                            </div>
                        </StepLabelContainer>
                        <StepTagContainer>
                            {label}
                        </StepTagContainer>
                    </StepWrapper>
                ))}
            </StepContainer>
        </>
    );
};

export default index;
