import React, { useEffect, useState } from "react";

import * as RestApi from "../../../common/context/api";

const index = () => {
  const [params, setParams] = useState([
    {
      title: "기상 시간",
      alarm_typ_cd: "ATC00001",
      meridiem: "AM",
      time: "00:00",
    },
    {
      title: "취침 시간",
      alarm_typ_cd: "ATC00002",
      meridiem: "AM",
      time: "00:00",
    },
    {
      title: "하루 중 정서적으로 가장 힘들 때",
      alarm_typ_cd: "ATC00003",
      meridiem: "AM",
      time: "00:00",
    },
  ]);
  const [first, setFirst] = useState(false);

  useEffect(() => {
    fetchAlarmList();
  }, []);

  const onChangeValue = (field, index, val) => {
    setParams((prevState) => {
      let temp_l = [...prevState];
      temp_l[index][field] = val;
      return temp_l;
    });
  };

  const fetchAlarmCreate = () => {
    const api_params = params.map((el) => {
      return {
        alarm_typ_cd: el.alarm_typ_cd,
        alarm_dt: convertTime(el.meridiem, el.time),
      };
    });

    RestApi.post("eALARM_USER_CREATE", api_params)
      .then((res) => {
        fetchAlarmList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAlarmList = () => {
    RestApi.get("eALARM_USER_LIST")
      .then((res) => {
        if (res.list.length > 0) {
          const FormattedParams = res.list.map((el) => {
            const [hours, minutes, seconds] = el.alarm_dt
              .split(":")
              .map(Number);
            let formattedHour = hours % 12;
            if (formattedHour === 0) {
              formattedHour = 12;
            }
            let meridiem = hours < 12 ? "AM" : "PM";
            return {
              title:
                el.alarm_typ_cd === "ATC00001"
                  ? "기상 시간"
                  : el.alarm_typ_cd === "ATC00002"
                  ? "취침 시간"
                  : "하루 중 정서적으로 가장 힘들 때",
              time: `${formattedHour.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}`,
              meridiem: meridiem,
              alarm_typ_cd: el.alarm_typ_cd,
              alarm_no: el.alarm_no,
            };
          });
          FormattedParams.sort((a, b) =>
            a.alarm_typ_cd.localeCompare(b.alarm_typ_cd)
          );
          setParams(FormattedParams);
        } else {
          setFirst(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAlarmUpdate = () => {
    const api_params = params.map((el) => {
      return {
        alarm_no: el.alarm_no,
        alarm_typ_cd: el.alarm_typ_cd,
        alarm_dt: convertTime(el.meridiem, el.time),
      };
    });
    RestApi.post("eALARM_USER_UPDATE", api_params)
      .then((res) => {
        fetchAlarmList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertTime = (meridiem, time) => {
    let time24Hours = "";

    if (meridiem === "AM") {
      const [hour, minute] = time.split(":");
      const formattedHour = hour.padStart(2, "0").replace(/\s/g, "");
      const formattedMinute = minute.padStart(2, "0").replace(/\s/g, "");
      time24Hours = `${formattedHour}:${formattedMinute}`;
    } else if (meridiem === "PM") {
      const [hour, minute] = time.split(":");
      const formattedHour = hour.padStart(2, "0").replace(/\s/g, "");
      const formattedMinute = minute.padStart(2, "0").replace(/\s/g, "");
      time24Hours = `${(
        Number(formattedHour) + 12
      ).toString()}:${formattedMinute}`;
    }
    return time24Hours;
  };

  return {
    first,
    params,
    onChangeValue,
    fetchAlarmCreate,
    fetchAlarmUpdate,
  };
};

export default index;
