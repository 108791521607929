import React, { useState } from "react";
import { css } from "@emotion/css";

import { InputWrapper, InputForm } from "./styles";
import { Colors, Icons, yeonseiImages } from "../../../../styles";

import Icon from "../Icon";

/**
 * @description 연세대프로젝트에서 사용하는 체크박스 component
 *
 * @param props
 * @param {LegacyRef<HTMLInputElement>} props.inputRef - react ref
 * @param {String} props.help_msg - input help message
 * @param {String} props.err_yn - 입력 에러시 빨간색 표시 여부
 * @param {String} props.success_yn - 입력 성공시 초록색 표시 여부
 * @param {String} props.timer_yn - 타이머 사용 여부
 * @param {String} props.timer_text - 타이머 사용시 텍스트
 * @param {String} props.remove_yn - 클릭시 input 삭제 가능
 * @param {String} props.style - 해당 체크박스의 커스텀 디자인 (import { css } from "@emotion/react" 필요) * @param {String} props.onChange - 체크박스 옆에 있는 글
 * @param {Boolean} props.readOnly - input read only
 * @param {Boolean} props.disabled - input disabled
 * @param {String} props.placeholder - inpuit placeholder
 * @param {function} props.onChange - input value 수정 함수
 * @param {String} props.value - input value
 * @param {String} props.type - input type || default : "text"
 * @param {Number} props.maxLength - input maxLength || default : 255
 * @param {String} props.isDisplay - input display 여부
 * @param {function} props.onRemove - remove버튼 클릭 시 진행하는 함수
 * @param {String} props.isModal - 모달 내부에 위치한 Input 판별
 * @param {String} props.isHalf - Input의 크기 조절(style로 해결 불가능 할 경우)
 * @returns {JSX.Element}
 */
const index = (props) => {
  const {
    id,
    inputRef,
    help_msg = "",
    err_yn = "N",
    success_yn = "N",
    timer_yn = "N",
    timer_text = "",
    remove_yn = "N",
    style = css``,
    // input 에 필요한 props 들
    onChange = (e) => {},
    value = "",
    type = "text",
    maxLength = 255,
    readOnly = false,
    disabled = false,
    placeholder = "",
    isDisplay = "Y",
    onRemove = (e) => {},
    isModal = "N",
    isHalf = "N",
    onBlur = (e) => {},
  } = props;

  return (
    <InputWrapper isDisplay={isDisplay} className={style} err_yn={err_yn}>
      <InputForm
        id={id}
        ref={inputRef}
        type={type}
        onChange={onChange}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        success_yn={success_yn}
        remove_yn={remove_yn}
        timer_text={timer_text}
        readOnly={readOnly}
        disabled={disabled}
        isModal={isModal}
        onBlur={onBlur}
        isHalf={isHalf}
      />
      {timer_yn === "Y" && (
        <span className={"common-icon timer"}>{timer_text}</span>
      )}
      {success_yn === "Y" && (
        <Icon icon={Icons.confirm} color={Colors.Confirmed} />
      )}
      {help_msg && <span className={"help-msg"}>{help_msg}</span>}
      {remove_yn === "Y" && (
        <img
          src={yeonseiImages.Cancel}
          className={"common-icon"}
          alt={""}
          onClick={onRemove}
        />
      )}
    </InputWrapper>
  );
};

export default index;
