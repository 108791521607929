import { REHYDRATE } from "redux-persist/es/constants";
import * as ActionTypes from "../actionTypes";

const initialState = {
  eCOMM_CD: null,
  eCOMM_CD_TYPE: null,
};

export const constReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.eCOMM_CD:
      return Object.assign({}, state, { eCOMM_CD: action.eCOMM_CD });
    case ActionTypes.eCOMM_CD_TYPE:
      return Object.assign({}, state, { eCOMM_CD_TYPE: action.eCOMM_CD_TYPE });
    case REHYDRATE:
      return Object.assign({}, state, action?.payload?.eCONST);
    default:
      return state;
  }
};
