import styled from "@emotion/styled";
import { Colors, globalStyle } from "../../../../../styles";
export const Container = styled.div`
  width: 335px;
  height: 542px;
  padding: 25px 24px 20px 24px;
  flex-grow: 0;
  background-color: ${Colors.White};

  ::-webkit-scrollbar {
    display: none;
  }
  & > div.headerBox {
    margin-bottom: 29px;

    & > div.header {
      height: 17px;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1;
      color: ${Colors.Black};
      margin-bottom: 21px;
    }
    & > div.subHeader {
      height: 14px;
      font-size: 1rem;
      font-weight: 500;
      color: ${Colors.Black};
      word-break: keep-all;
    }
  }

  & > div.contentTitle {
    height: 14px;
    font-size: 1rem;
    font-weight: 500;
    color: ${Colors.Black};
    margin-bottom: 18px;
    word-break: keep-all;
  }

  & > div.content {
    overflow-y: auto;
    min-height: 126px;
    max-height: 172px;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.38;
    color: ${Colors.Gray60};
    margin-bottom: 20px;
    word-break: keep-all;
  }

  & > div.confirmButton {
    font-size: 1rem;
    padding-bottom: 20px;
    height: 32px;
    font-weight: 500;
    cursor: pointer;
    text-align: right;
    color: ${Colors.Primary};
  }
`;
