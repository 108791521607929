import { createStore, combineReducers, compose, applyMiddleware } from "redux";
/**
 * @description redux-thunk
 * 리덕스를 사용하는 어플리케이션에서 비동기 작업을 처리 할 때 가장 기본적인 방법
 */
import thunkMiddleware from "redux-thunk";
/**
 * @description redux-persist
 * redux 의 initialStore 는 페이지를 새로고침 할 경우 state 가 날아가는 것을 보실 수 있습니다.
 * 이것에 대한 대응 방안으로 localStorage 또는 session 에 저장하고자 하는 reducer state 를 저장하여,
 * 새로고침 하여도 저장공간에 있는 데이터를 redux 에 불러오는 형식으로 이루어집니다.
 */
import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import { reducer as dataReducer } from "./reducer";

const persistConfig = {
  key: "yeonsei-medical-client",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, dataReducer);

const devTools =
  process.env.NODE_ENV !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null;

const composeEnhancers = devTools || compose;

const appReducer = combineReducers({
  data: persistedReducer,
});

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

export const store = createStore(appReducer, enhancer);

export const persistor = persistStore(store);
