import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Colors } from "../../../../styles";

export const FeelingNightManageBox = styled.article`
  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 32px 0;
  overflow-y: auto;

  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & > div {
    padding: 0 31px;
  }

  & > div.title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 26px;
    margin-bottom: 30px;
    white-space: pre-line;
  }

  & > div.description {
    width: 335px;
    height: 100px;
    margin: 0 20px 37px;
    padding: 20px;
    background: ${Colors.Primary10};
    border-radius: 10px;

    font-weight: 600;
    font-size: 1rem;
    line-height: 30px;

    & > span {
      display: inline-block;
      width: 40px;
      margin-right: 30px;

      font: inherit;
    }
  }

  & > div.trialSlider {
    padding-bottom: 67px;
    border-bottom: 1px solid ${Colors.Gray40};
  }

  & > div.selectTrial {
    padding: 50px 20px 0;
    width: 100%;

    & > div.title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;

      margin-bottom: 30px;
    }

    & > div.buttonBox {
      display: flex;
      align-items: center;
    }
  }

  & > div.buttonBox {
    display: flex;
    justify-content: center;
    padding: 50px 0 65px 0;
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }
`;
