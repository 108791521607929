import React, { useEffect, useState } from "react";

//api
import * as RestApi from "../../../common/context/api";

//util
import { DateUtil } from "../../../common/util";

const index = () => {
  const [params, setParams] = useState({});
  const [list, setList] = useState([]);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    fetchQnaList();
  }, []);

  useEffect(() => {}, [params]);

  const onChangeValue = (field, value) => {
    setParams((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchQnaCreate = () => {
    if (params.inqry_nm && params.inqry_cont) {
      setCheck(true);
      const api_params = {
        inqry_typ_cd: params.inqry_typ_cd === false ? "ITC00001" : "ITC00002",
        inqry_nm: params.inqry_nm,
        inqry_cont: params.inqry_cont,
        inqry_img_url1: params.inqry_img_url1,
        inqry_img_url2: params.inqry_img_url2,
        inqry_img_url3: params.inqry_img_url3,
      };

      RestApi.post("eINQRY_USER_CREATE", api_params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchQnaList = () => {
    RestApi.get("eINQRY_USER_LIST")
      .then((res) => {
        console.log(res);
        setList(
          res.list.map((el, index) => {
            el.inqry_sts_cd = el.inqry_sts_cd !== "ISC00001";
            el.inst_dt = DateUtil.format("point", el.inst_dt);
            return el;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchReadInqry = (inqry_no) => {
    RestApi.post("eINQRY_USER_ANSWER_READ_UPDATE", { inqry_no: inqry_no })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    list,
    check,
    params,
    onChangeValue,
    fetchQnaCreate,
    fetchReadInqry,
  };
};

export default index;
