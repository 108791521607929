import AvailableService from "../pages/localResource/page/availableService";
import GovernmentService from "../pages/localResource/page/governmentService";
import PreventSuicide from "../pages/localResource/page/preventSuicide";
import AppGuide from "../pages/localResource/page/appGuide";
import CouponIssue from "../pages/localResource/page/couponIssue";

export const localResourceRoutes = [
  {
    path: "/localResource/availableService",
    element: <AvailableService />,
  },
  {
    path: "/localResource/governmentService",
    element: <GovernmentService />,
  },
  {
    path: "/localResource/preventSuicide",
    element: <PreventSuicide />,
  },
  {
    path: "/localResource/appGuide",
    element: <AppGuide />,
  },
  {
    path: "/localResource/couponIssue",
    element: <CouponIssue />,
  },
];
