import React, { useState } from "react";
import { css } from "@emotion/css";

//component
import Button from "../../../../common/component/atom/Button";
import CheckConfirmBox from "../../../../component/atom/CheckConfirmBox";
import MultiFormBox from "../MultiFormBox";
import {
  handlerModal,
  OneButtonModal,
} from "../../../../component/organism/Modal";
import ManageCallListBox from "../ManageCallListBox";

//style
import { PlanManagerBox } from "./styles.js";
import { yeonseiImages } from "../../../../styles";

const index = React.forwardRef((props, ref) => {
  const {
    step,
    params,
    beforeList,
    peopleList,
    stepTitle,
    subTitle,
    tooltip,
    onDeleteItem,
    onAddItem,
    onChange,
    onUpdate,
    onCheck,
    onAddBeforeItem,
    fetchUpdateContact = () => {},
    fetchDeletePeople = () => {},
    getEventFromAndroid = () => {},
  } = props;
  const oneButtonModalHandler = handlerModal();
  const alertModalHandler = handlerModal();
  const [isClicked, setIsClicked] = useState(false);
  const list = (beforeList || [])?.filter((el) => el.plan_step === step);

  return (
    <PlanManagerBox ref={ref} step={step}>
      <div className={"step-head"}>
        <div className={"step-title"}>
          {stepTitle}
          <span>{subTitle}</span>
        </div>
        {tooltip && (
          <a onClick={(e) => e.preventDefault()}>
            <div>
              <img src={yeonseiImages.QuestionMark} alt={"tooltip"} />
            </div>
            <div className={"tooltip"}>{tooltip}</div>
          </a>
        )}
        <Button
          variant={isClicked ? "unpainted" : "painted"}
          size={"medium"}
          label={isClicked ? "저장" : "수정"}
          style={css`
            font-weight: 600;
            font-size: 1.25rem;
            margin-left: auto;
          `}
          onClick={() => {
            if (isClicked) {
              onUpdate(step, setIsClicked, alertModalHandler.onToggleModal);
            } else {
              setIsClicked(true);
            }
          }}
        />
      </div>
      {(step === 2 || step === 3) && (
        <CheckConfirmBox
          img={yeonseiImages.CheckAbleLarge}
          text={
            <>
              오늘 수행한 전략이 있다면
              <br />
              <span>체크버튼</span>을 눌러주세요.
            </>
          }
          style={css`
            margin-bottom: 20px;
          `}
        />
      )}
      {step === 4 ? (
        <div className={"step-4-box"}>
          <div
            className={"modal-button"}
            onClick={() => oneButtonModalHandler.onToggleModal()}
          >
            전문기관 정보 >
          </div>
          <ManageCallListBox
            list={peopleList}
            isClicked={isClicked}
            fetchUpdateContact={fetchUpdateContact}
            fetchDeletePeople={fetchDeletePeople}
            getEventFromAndroid={getEventFromAndroid}
          />
        </div>
      ) : (
        <MultiFormBox
          step={step}
          params={params}
          list={list}
          isClicked={isClicked}
          onDeleteItem={onDeleteItem}
          onAddItem={onAddItem}
          onAddBeforeItem={onAddBeforeItem}
          onChange={onChange}
          onCheck={onCheck}
        />
      )}
      <OneButtonModal handler={oneButtonModalHandler} label={"닫기"}>
        <>
          <div className={"help-call-box"} style={{ height: "60px" }}>
            자살상담 예방전화
            <img
              src={yeonseiImages.SuicidePrevisionIcon}
              alt={"자살 예방"}
              onClick={() => {
                location.href = "tel:109";
                fetchUpdateContact(true);
              }}
            />
          </div>
          <div className={"help-call-box"}>
            정신건강 상담전화
            <img
              src={yeonseiImages.MentalHealthIcon}
              alt={"정신건강"}
              onClick={() => {
                location.href = "tel:1577-0199";
                fetchUpdateContact(true);
              }}
            />
          </div>
          <div className={"help-call-box"}>
            한국 생명의 전화
            <img
              src={yeonseiImages.LifelineIcon}
              alt={"생명의전화"}
              onClick={() => {
                location.href = "tel:1588-9191";
                fetchUpdateContact(true);
              }}
            />
          </div>
          <div className={"help-call-box"} style={{ marginBottom: "20px" }}>
            응급구조요청
            <img
              src={yeonseiImages.EmergencyRescueIcon}
              alt={"응급요청"}
              onClick={() => {
                location.href = "tel:119";
                fetchUpdateContact(true);
              }}
            />
          </div>
        </>
      </OneButtonModal>
      <OneButtonModal handler={alertModalHandler} label={"닫기"}>
        <div className={"information-box"} style={{ marginBottom: "20px" }}>
          {"스텝별로 최소 3개의 항목이 \n작성되어야 저장이 가능해요"}
        </div>
      </OneButtonModal>
    </PlanManagerBox>
  );
});

export default index;
