import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 82px;

  background-color: ${Colors.Primary10};

  .title {
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 3;
    font-weight: 600;
    font-size: 1.625rem;
    padding: 0 32px;

    & img {
      margin-left: auto;
    }
  }

  .input-modal-box {
    display: flex;
    flex-direction: column;

    & * {
      margin-bottom: 20px;
    }

    & p {
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 10px;
    }

    & img {
      margin-bottom: 0;
    }
  }
`;

export const ResourceListBox = styled.article`
  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 32px 20px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .space {
    height: 20px;
    margin-bottom: 20px;
  }
`;
