import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const Emoticons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  & > div.emoticonBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > img {
      width: 45px;
      height: 45px;
      margin-bottom: 15px;
    }

    & > div.description {
      font-weight: 400;
      font-size: 1rem;
      line-height: 16px;
      text-align: center;
      white-space: pre-line;
    }
  }
`;
