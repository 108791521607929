import React from "react";
import { MainSection, MainText } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = () => {
  return (
    <MainSection>
      <img
        src={yeonseiImages.SuicidePrevisionBackGround}
        alt={"background"}
        style={{ width: "100%" }}
      />
      <MainText>
        <div className={"main-title"}>
          죽음을 생각하거나
          <br />
          자살 충동이 느껴지신다면
          <br />
          다음을 실시하세요.
        </div>
        <div className={"text-box"}>
          <span>1. </span>
          <div>지금, 어떤 결정도 하지 말고 멈추세요.</div>
        </div>
        <div className={"text-box"}>
          <span>2. </span>
          <div>지금, 자살할 때 쓰려고 했던 물건을 치워버려요.</div>
        </div>
        <div className={"text-box"}>
          <span>3. </span>
          <div>
            오늘은 술을 마시지 말아요.
            <br />
            (수면제가 필요하면, 정해진 만큼만 사용해요)
          </div>
        </div>
        <div className={"text-box"}>
          <span>4. </span>
          <div>
            지금 당장의 문제에만 집중하세요. 한 번에 전부
            <br />
            해결할 필요는 없어요.
          </div>
        </div>
        <div className={"text-box"}>
          <span>5. </span>
          <div>
            ‘잘 될 거야', '괜찮아', '나도 괜찮은 사람이야’
            <br />
            라고 스스로에게 힘을 주는 주문을 외워요.
          </div>
        </div>
        <div className={"text-box"}>
          <span>6. </span>
          <div>나를 사랑하는 가까운 사람들을 기억하세요.</div>
        </div>
        <div className={"text-box"}>
          <span>7. </span>
          <div>
            당신을 도울 수 있는 가까운 사람에게 전화하세요.
            <br />
            그리고 말 하세요.
          </div>
        </div>
        <div className={"text-box"}>
          <span>8. </span>
          <div>혼자 있지 말아요. 누군가와 함께해요.</div>
        </div>
        <div className={"text-box"}>
          <span>9. </span>
          <div>
            지금, 바로 가까운 병원 응급실에 가거나
            <br />
            자살예방상담전화 (109)에 전화하세요.
          </div>
        </div>
        <div className={"text-box"}>
          <span>10. </span>
          <div>정신건강전문가를 만날 약속을 지금 예약하세요.</div>
        </div>
      </MainText>
      <div className={"source"}>
        출처: 국립정신센터 국가트라우마센터
        <br />
        (https://www.nct.go.kr/distMental/rating/rating01_7_4.do)
      </div>
    </MainSection>
  );
};

export default index;
