export const Config = {
  nearResource: {
    title: "주변자원활용",
    subLinks: [
      { title: "이용 가능한 서비스", link: "/localResource/availableService" },
      { title: "전국 자살 예방센터", link: "/localResource/preventSuicide" },
      {
        title: "국가 제공 지원서비스",
        link: "/localResource/governmentService",
      },
      { title: "심리상담 어플 안내", link: "/localResource/appGuide" },
    ],
  },
  correctInfo: {
    title: "정보 바로알기",
    subLinks: [
      { title: "자살 바로 이해하기", link: "/information/knowSuicide" },
      {
        title: "자살, 오해와 진실",
        link: "/information/misunderstandingTruth",
      },
      { title: "나를 돌보는 방법", link: "/information/wayToKeepMe" },
      { title: "치료방법 및 과정", link: "/information/treatmentMethod" },
      {
        title: "자살예방 10가지 수칙",
        link: "/information/suicidePrevisionTips",
      },
    ],
  },
};
