import React from "react";

// Component
import { ReactComponent as SouthKorea } from "@svg-maps/south-korea/south-korea.svg";

// Styles
import { KoreaMap } from "./styles";

/**
 *  @author 박병훈
 *  @description - 한국 지도맵 || 호버, 온클릭 기능 가능
 *  @param {Object} props - 상위 컴포넌트의 props
 *  @param {Object} props.selectedId - params 중 선택된 id
 *  @param {function} props.onChangeValue - 클릭시 실행되는 함수 || e.target.id 에 해당 지역 이름이 영어로 표기
 *
 *  @returns {JSX.Element} *
 */
const index = (props) => {
  const { onChangeValue, selectedId } = props;

  return (
    <KoreaMap selectedId={selectedId}>
      <SouthKorea viewBox={"34 0 390 631"} onClick={onChangeValue} />
    </KoreaMap>
  );
};

export default index;
