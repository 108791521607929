import React, { useId } from "react";
import { cx, css } from "@emotion/css";

// Styles
import { yeonseiImages } from "../../../styles";
import { defaultCheckBoxStyle, CheckBoxSizes } from "./styles";

/**
 * @description 연세대프로젝트에서 사용하는 체크박스 component
 *
 * @param props
 * @param {String} props.keyId - checkBox id
 * @param {String} props.label - 체크박스 옆에 있는 글
 * @param {Boolean} props.checked - 체크박스의 체크 여부
 * @param {String} props.variant - 체크박스에서 미리 정해놓은 디자인
 * @param {css} props.style - 해당 체크박스의 커스텀 디자인 (import { css } from "@emotion/react" 필요)
 * @param {function} props.onChange - 체크박스 클릭시 실행되는 함수
 * @param {String} props.checkImage - checked = true 일시 적용되는 이미지
 * @param {String} props.unCheckImage - checked = false 일시 적용되는 이미지
 *
 * @returns {JSX.Element}
 */
const index = (props) => {
  const {
    keyId = "",
    label = "",
    checked = false,
    variant = "small",
    style = css``,
    onChange = (e) => {},
    checkImage = "CheckBorderAble",
    unCheckImage = "CheckBorderDisable",
    darkness = false,
  } = props;
  const checkKey = useId();

  return (
    <label
      htmlFor={`${checkKey}_${keyId || ""}`}
      className={cx(defaultCheckBoxStyle, CheckBoxSizes[variant], style)}
    >
      <div className={"checkbox-container"}>
        <img
          alt={label + "_check"}
          src={
            checked
              ? yeonseiImages[checkImage]
              : yeonseiImages[
                  darkness ? "CheckBorderDisableDark" : unCheckImage
                ]
          }
        />
        <input
          id={`${checkKey}_${keyId || ""}`}
          type={"checkbox"}
          checked={checked}
          onChange={onChange}
        />
        {label && <div>{label}</div>}
      </div>
    </label>
  );
};

export default index;
