import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const InputWrapper = styled.div`
  display: ${(props) => (props.isDisplay ? "inline-flex" : "none")};
  flex-direction: column;
  position: relative;

  & .help-msg {
    margin-top: 10px;
    color: ${(props) => (props.err_yn === "Y" ? Colors.Error : Colors.Primary)};
  }

  & .common-icon {
    position: absolute;
    right: 12px;
    font-size: 1.1rem;
    transform: translate(0%, 50%);
  }

  & .timer {
    font-size: 0.875rem;
    line-height: 16px;
    color: ${Colors.Primary};
  }
`;

export const InputForm = styled.input`
  width: ${(props) =>
    props.isModal === "Y" ? "280px" : props.isHalf === "Y" ? "150px" : "335px"};
  height: 50px;
  padding: 0 ${(props) => (props.timer_text ? "44px" : "32px")} 0 12px;
  border: solid 1px
    ${(props) => (props.err_yn === "Y" ? Colors.Error : Colors.Gray40)};
  box-sizing: border-box;
  border-radius: 10px;

  color: ${(props) =>
    props.err_yn === "Y"
      ? Colors.Error
      : props.readOnly || props.disabled
      ? Colors.Gray60
      : Colors.Gray90};

  &::placeholder {
    color: ${Colors.Gray60};
  }

  &:focus {
    outline: none;
    border: solid
      ${(props) => (props.readOnly || props.disabled ? `1px` : `2px`)}
      ${(props) =>
        props.err_yn === "Y"
          ? Colors.Error
          : props.readOnly || props.disabled
          ? Colors.Gray40
          : Colors.Primary};
  }

  &:hover {
    color: ${(props) => (!props.disabled ? Colors.Gray60 : Colors.Black)};
  }

  &:disabled {
    background-color: ${Colors.White};
  }
`;
