import React, { useState } from "react";

import Button from "../../../common/component/atom/Button";

import { PinNumberInputForm, KeyPad } from "./styles";
import { yeonseiImages } from "../../../styles";

/**
 * @description 간편 비밀번호 component
 * @param props
 * @params {String} title 제목
 * @params {String} subTitle 소제목
 * @params {Array} password 비밀번호 (4자리 기준)
 * @params {function} setPassword 비밀번호를 관리하는 State
 * @returns {JSX.Element}
 */

const index = (props) => {
  const { title, subTitle, password, setPassword } = props;
  const [nums, setNums] = useState(Array.from({ length: 9 }, (v, k) => k + 1));

  const shuffleArray = () => {
    const shuffledNums = [...nums];
    for (let i = shuffledNums.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledNums[i], shuffledNums[j]] = [shuffledNums[j], shuffledNums[i]];
    }
    setNums(shuffledNums);
  };

  const handleNumberClick = (num) => {
    if (password.length < 4) {
      setPassword([...password, num]);
    }
  };

  const handleImageClick = () => {
    if (password.length > 0) {
      const updatedPassword = [...password];
      updatedPassword.pop();
      setPassword(updatedPassword);
    }
  };

  return (
    <PinNumberInputForm>
      <div className={"main-title"}>{title}</div>
      <div className={"sub-title"}>{subTitle}</div>
      <div className={"pin-counter"}>
        {Array.from({ length: 4 }, (_, index) => (
          <div
            key={index}
            className={`pin-ball ${index < password.length ? "active" : ""}`}
          />
        ))}
      </div>
      <KeyPad>
        {nums.map((n) => (
          <Button
            key={n}
            label={n.toString()}
            size={"keypad"}
            variant={"keypad"}
            value={n}
            onClick={() => handleNumberClick(n)}
          />
        ))}
        <Button
          label={"재배열"}
          size={"keypad"}
          variant={"keypadString"}
          onClick={shuffleArray}
        />
        <Button
          label={0}
          size={"keypad"}
          variant={"keypad"}
          value={0}
          onClick={() => handleNumberClick(0)}
        />
        <Button
          size={"keypad"}
          variant={"keypad"}
          img={yeonseiImages.Backspace}
          onClick={handleImageClick}
        />
      </KeyPad>
    </PinNumberInputForm>
  );
};

export default index;
