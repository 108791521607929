import React from "react";

//Styles
import { SignUpSection } from "./styles";

//Components
import AuthSignUpTermAgree from "../../component/AuthSignUpTermAgree";
import AuthSignUpInputForm from "../../component/AuthSignUpInputForm";
import AuthSignUpEmail from "../../component/AuthSignUpEmail";

//Hooks
import useAuthSignup from "../../hooks/useAuthSignup";

//util
import { Utils } from "../../../../common/util";

const index = () => {
  const step = Utils.getParameterByName("step") || "1";

  const {
    email,
    params,
    errList,
    resultMsg,
    finalCheck,
    setFinalCheck,
    onChangeEmail,
    onChangeValue,
    handleValidation,
    fetchSignUp,
    fetchVerifyEmail,
    fetchVerifyNumber,
    checkEmailValidate,
  } = useAuthSignup();

  return (
    <SignUpSection step={step}>
      <AuthSignUpTermAgree isDisplay={step === "1"} />
      <AuthSignUpInputForm
        isDisplay={step === "2"}
        params={params}
        errList={errList}
        finalCheck={finalCheck}
        setFinalCheck={setFinalCheck}
        onChange={onChangeValue}
        handleValidation={handleValidation}
        fetchSignUp={fetchSignUp}
        checkEmailValidate={checkEmailValidate}
      />
      <AuthSignUpEmail
        isDisplay={step === "3"}
        params={params}
        onChange={onChangeEmail}
        email={email}
        resultMsg={resultMsg}
        fetchVerifyEmail={fetchVerifyEmail}
        fetchVerifyNumber={fetchVerifyNumber}
      />
    </SignUpSection>
  );
};

export default index;
