import React, { useState } from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { FeelingSlider } from "./styles";

// Components
import Sliders from "../../../../component/atom/Slider";

// Hooks
import useOutsideClick from "../../../../hooks/useOutsideClick";

// Const
import { Const } from "../../../../common/util";
const index = (props) => {
  const {
    timeNo,
    params,
    field,
    onChangeValue,
    disabled,
    labelMin = "전혀 아님",
    labelMax = "매우 그러함",
  } = props;
  const { FeelingTitleManage, FeelingTitleCreate } = Const;
  return (
    <FeelingSlider timeNo={timeNo}>
      <div className={"title"}>
        {!timeNo && <span className={"gray"}>나는 지금 </span>}
        {timeNo ? FeelingTitleManage[field] : FeelingTitleCreate[field]}
      </div>
      <Sliders
        labelMin={labelMin}
        labelMax={labelMax}
        onChangeValue={onChangeValue}
        value={params[field]}
        field={field}
        disabled={disabled}
      />
    </FeelingSlider>
  );
};

export default index;
