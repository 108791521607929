import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { TermAgreeArticle } from "./styles";
import { Colors } from "../../../../styles";

// Components
import Button from "../../../../common/component/atom/Button";
import Checkbox from "../../../../component/atom/Checkbox";
import { handlerModal, TermModal } from "../../../../component/organism/Modal";

const index = (props) => {
  const navigate = useNavigate();
  const termModalHandler = handlerModal();
  const personalInformationModalHandler = handlerModal();
  const researchModalHandler = handlerModal();
  const { isDisplay } = props;

  const [agreements, setAgreements] = useState({
    isAgree: false,
    isAgreeSecond: false,
    isAgreeThird: false,
  });
  const [deviceType, setDeviceType] = useState("");

  const onHandleCheckbox = (name) => {
    setAgreements((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const detectDeviceType = () => {
    const userAgent = navigator.userAgent;
    console.log(userAgent);

    if (userAgent.match(/Android/i)) {
      setDeviceType("Android");
    } else if (userAgent.match(/iPhone | iPad/i)) {
      setDeviceType("IOS");
    } else {
      setDeviceType("PC");
    }
  };

  useEffect(() => {
    detectDeviceType();
  }, []);

  return (
    <TermAgreeArticle isDisplay={isDisplay}>
      <div className={"top"}>
        <div className={"main-title"}>
          <span className={"name"}>eRAPPORT앱</span>에 오신 것을 환영합니다.
          eRAPPORT 이용약관과 개인정보 처리방침 및 연구목적 ▪ 개인정보
          수집안내를 확인해주세요.
        </div>
        <div className={"checkbox-row"}>
          <Checkbox
            label={"(필수) 이용약관"}
            variant={"small"}
            checked={agreements.isAgree}
            onChange={() => onHandleCheckbox("isAgree")}
          />
          <div
            className={"toggleTerm"}
            onClick={() => termModalHandler.onToggleModal()}
          >
            보기
          </div>
        </div>
        <div className={"checkbox-row"}>
          <Checkbox
            label={"(필수) 개인정보 처리방침"}
            variant={"small"}
            checked={agreements.isAgreeSecond}
            onChange={() => onHandleCheckbox("isAgreeSecond")}
          />
          <div
            className={"toggleTerm"}
            onClick={() => personalInformationModalHandler.onToggleModal()}
          >
            보기
          </div>
        </div>
        <div className={"checkbox-row"}>
          <Checkbox
            label={"(필수) 연구목적 개인정보 수집"}
            variant={"small"}
            checked={agreements.isAgreeThird}
            onChange={() => onHandleCheckbox("isAgreeThird")}
          />
          <div
            className={"toggleTerm"}
            onClick={() => researchModalHandler.onToggleModal()}
          >
            보기
          </div>
        </div>
      </div>
      <Button
        label={"다음"}
        variant={"painted"}
        size="xLarge"
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 10px;
          background-color: ${agreements.isAgree &&
          agreements.isAgreeSecond &&
          agreements.isAgreeThird
            ? Colors.Primary
            : Colors.Disable};
        `}
        onClick={() =>
          agreements.isAgree &&
          agreements.isAgreeSecond &&
          agreements.isAgreeThird &&
          navigate("/auth/signup?step=2")
        }
      />
      <TermModal handler={termModalHandler} type={"term"} />
      <TermModal handler={personalInformationModalHandler} type={"personal"} />
      <TermModal
        handler={researchModalHandler}
        type={"research"}
        deviceType={deviceType}
      />
    </TermAgreeArticle>
  );
};

export default index;
