import KnowSuicide from "../pages/information/pages/knowSuicide";
import MisunderstandingTruth from "../pages/information/pages/misunderstandingTruth";
import WayToKeepMe from "../pages/information/pages/wayToKeepMe";
import TreatmentMethod from "../pages/information/pages/treatmentMethod";
import SuicidePrevisionTips from "../pages/information/pages/suicidePrevisionTips";

export const informationRoutes = [
  {
    path: "/information/knowSuicide",
    element: <KnowSuicide />,
  },
  {
    path: "/information/misunderStandingTruth",
    element: <MisunderstandingTruth />,
  },
  {
    path: "/information/wayToKeepMe",
    element: <WayToKeepMe />,
  },
  {
    path: "/information/treatmentMethod",
    element: <TreatmentMethod />,
  },
  {
    path: "/information/suicidePrevisionTips",
    element: <SuicidePrevisionTips />,
  },
];
