import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const FeelingStatisticsManage = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.White};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 20px;

  width: 100%;
  height: 100%;

  & > div.title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 18px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 50px;
  }

  & > div.dateBox {
    margin-top: 80px;
    & > div.dateBlock {
      width: 335px;
      height: 80px;
      margin-bottom: 20px;

      box-shadow: ${Colors.Shadow};
      border-radius: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 500;
      font-size: 1.375rem;
      line-height: 20px;
    }
  }
`;
