import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { MainSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

import FeelingStatisticsManage from "../../component/FeelingStatisticsManage";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

import { DateUtil, Utils } from "../../../../common/util";

import useFeelingCalendarStatistics from "../../hooks/useFeelingCalendarStatistics";

const index = () => {
  const {
    dayList,
    nearData,
    dayUnChangeList,
    fetchBeforeData,
    fetchAfterData,
    fetchCalendarDayList,
    fetchCalendarDayStatistic,
  } = useFeelingCalendarStatistics();
  const dateNo = Utils.getParameterByName("dateNo") || "";
  const oneButtonModalHandler = handlerModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (dateNo) {
      fetchCalendarDayStatistic(dateNo);
      fetchBeforeData(dateNo);
      fetchAfterData(dateNo);
    }
  }, [dateNo]);

  return (
    <MainSection>
      <div className={"titleBox"}>
        <div className={"date-selector"}>
          {nearData.before !== DateUtil.format("dash", new Date()) ? (
            <img
              className={"arrow-left"}
              src={yeonseiImages.ArrowLeft}
              alt={"arrow-left"}
              onClick={() =>
                navigate(
                  "/main/feelingCalendarStatistics?dateNo=" + nearData?.before
                )
              }
            />
          ) : (
            <div className={"space-left"} />
          )}
          <div
            className={"main-title"}
            onClick={() => {
              fetchCalendarDayList(dateNo);
              oneButtonModalHandler.onToggleModal();
            }}
          >
            {DateUtil.format("ll", dateNo)}
            <img src={yeonseiImages.ArrowDown} alt={"arrow-down"} />
          </div>
          {nearData.after !== DateUtil.format("dash", new Date()) ? (
            <img
              className={"arrow-right"}
              src={yeonseiImages.ArrowRight}
              alt={"arrow-right"}
              onClick={() => {
                navigate(
                  "/main/feelingCalendarStatistics?dateNo=" + nearData?.after
                );
              }}
            />
          ) : (
            <div className={"space-right"} />
          )}
        </div>
      </div>
      <FeelingStatisticsManage list={dayList} />
      <OneButtonModal handler={oneButtonModalHandler}>
        <div className={"in-modal-list"}>
          {dayUnChangeList.map((el, index) => (
            <div
              key={index}
              className={"item-box"}
              onClick={() => {
                navigate(
                  "/main/feelingCalendarManage?timeNo=" + el?.calendar_no
                );
                oneButtonModalHandler.onToggleModal();
              }}
            >
              {DateUtil.format("LLL", el.write_dt)}
            </div>
          ))}
        </div>
      </OneButtonModal>
    </MainSection>
  );
};

export default index;
