import React from "react";

// Component
import CenterModalLayout from "../../../../template/CenterModalLayout";

// Styles
import { Container } from "./styles";

/**
 * @description 약관 전용 모달
 * @param props
 * @param {object} props.handler 해당 모달에서 사용하는 핸들러(path: client/src/component/organism/Modal/handlerModal.jsx)
 *
 * @return {JSX.Element}
 */
export const TermModal = (props = {}) => {
  const { handler, type, deviceType } = props;
  const { onToggleModal } = handler;

  return (
    <CenterModalLayout handler={handler}>
      <Container>
        {type === "term" ? (
          <>
            <div className={"headerBox"}>
              <div className={"header"}>이용약관(필수)</div>
              <div className={"subHeader"}>총칙</div>
            </div>

            <div className={"contentTitle"}>제1조 [목적]</div>
            <div className={"content"}>
              이 약관은 {"eRAPPORT"}(이하 "사이트"라 합니다)에서 제공하는
              인터넷서비스(이하 "서비스"라 합니다)의 이용 조건 및 절차에 관한
              기본적인 사항을 규정함을 목적으로 합니다.
            </div>

            <div className={"contentTitle"}>제2조 [약관의 효력 및 변경]</div>
            <div className={"content"}>
              ① 이 약관은 서비스 화면이나 기타의 방법으로 이용고객에게
              공지함으로써 효력을 발생합니다.
              <br />② 사이트는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은
              제1항과 같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다.
            </div>
            <div className={"contentTitle"}>제3조 [용어의 정의]</div>
            <div className={"content"}>
              이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
              <br />
              ① 회원: 사이트와 서비스 이용계약을 체결하거나 이용자 아이디(ID)를
              부여받은 개인 또는 단체를 말합니다.
              <br />
              ② 신청자: 회원가입을 신청하는 개인 또는 단체를 말합니다.
              <br />
              ③ 닉네임: 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고
              회사가 승인하는 문자열을 말합니다.
              <br />
              ④ 비밀번호: 회원이 부여 받은 아이디(ID)와 일치된 회원임을
              확인하고, 회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와
              숫자의 조합을 말합니다.
              <br />
              ⑤ 만나이: 회원이 가입 연도에 스스로 보고한 나이를 말합니다.
              <br />
              ⑥ 성별: 회원이 자발적으로 보고한 남/녀의 상태를 말합니다.
              <br />
              ⑦ 지역 (시/도): 회원가입 시 본인이 거주 중이라고 보고한 지역
              (광역시 이상의 거주 시 정보 혹은 도 정보) 을 말합니다.
              <br />
              ⑧ 이메일: 회원의 식별과 서비스 이용을 위하여 회원이 인증 절차를
              통해 등록한 이메일 주소를 말합니다.
              <br />
              ⑨ 해지: 회원 또는 회사가 서비스 개통 후 통합 서비스 이용계약을
              해약하는 것을 말합니다.
              <br />
              ⑩ 콘텐츠: 회사가 제작 또는 회원이 업로드 하거나, 제휴사가 제공하는
              동영상, 이미지, 텍스트 등 전부를 말합니다.
              <br />
            </div>
            <div className={"contentTitle"}>제2장 서비스 이용계약</div>
            <div className={"contentTitle"}>제4조 [이용계약의 성립]</div>
            <div className={"content"}>
              ① 이용약관 하단의 동의 버튼을 누르면 이 약관에 동의하는 것으로
              간주됩니다.
              <br />② 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용
              신청에 대하여 사이트가 승낙함으로써 성립합니다.
            </div>
            <div className={"contentTitle"}>제5조 [이용신청]</div>
            <div className={"content"}>
              ① 신청자가 본 서비스를 이용하기 위해서는 사이트 소정의 가입신청
              양식에서 요구하는 이용자 정보를 기록하여 제출해야 합니다.
              <br />② 가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제
              데이터인 것으로 간주됩니다. 본인 개인의 실제 정보를 입력하지 않은
              사용자는 법적인 보호를 받을 수 없으며, 서비스의 제한을 받을 수
              있습니다.
            </div>
            <div className={"contentTitle"}>제6조 [약관의 효력 및 변경]</div>
            <div className={"content"}>
              ① 사이트는 신청자에 대하여 제2항, 제3항의 경우를 예외로 하여
              서비스 이용신청을 승낙합니다.
              <br />
              ② 사이트는 다음에 해당하는 경우에 그 신청에 대한 승낙 제한사유가
              해소될 때까지 승낙을 유보할 수 있습니다.
              <br />
              가. 서비스 관련 설비에 여유가 없는 경우
              <br />
              나. 기술상 지장이 있는 경우
              <br />
              다. 기타 사이트가 필요하다고 인정되는 경우
              <br />
              ③ 사이트는 신청자가 다음에 해당하는 경우에는 승낙을 거부할 수
              있습니다.
              <br />
              가. 다른 개인(사이트)의 명의를 사용하여 신청한 경우
              <br />
              나. 이용자 정보를 허위로 기재하여 신청한 경우
              <br />
              다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
              <br />
              라. 기타 사이트 소정의 이용신청요건을 충족하지 못하는 경우
            </div>
            <div className={"contentTitle"}>제7조 [이용자정보의 변경]</div>
            <div className={"content"}>
              회원은 이용 신청시에 기재했던 회원정보가 변경되었을 경우에는,
              온라인으로 수정하여야 하며 변경하지 않음으로 인하여 발생되는 모든
              문제의 책임은 회원에게 있습니다.
            </div>
            <div className={"contentTitle"}>제3장 계약당사자의 의무</div>
            <div className={"contentTitle"}>제8조 [사이트의 의무]</div>
            <div className={"content"}>
              ① 사이트는 회원에게 각 호의 서비스를 제공합니다.
              <br />
              가. 자살 예방 관련 안전 계획 및 정신건강 서비스 제공
              <br />
              나. 자살 예방을 위한 감정 모니터링 및 관리 정보 제공
              <br />
              다. 자살 예방 관련 정보 및 연결 가능한 지역사회 서비스 내용 제공
              <br />
              ② 사이트는 서비스 제공과 관련하여 취득한 회원의 개인정보를 회원의
              동의없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스관련
              업무 이외의 상업적 목적으로 사용할 수 없습니다. 단, 다음 각 호의
              1에 해당하는 경우는 예외입니다.
              <br />
              가. 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는
              경우
              <br />
              나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의
              요청이 있는 경우
              <br />
              다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
              <br />
              ③ 사이트는 이 약관에서 정한 바에 따라 지속적, 안정적으로 서비스를
              제공할 의무가 있습니다.
              <br />
              ④ 사이트는 서비스의 향상과 신규 서비스의 개발, 심리 상담, 인공지능
              등 관련 연구를 위하여 회원이 입력한 감정 모니터링 자료 등 서비스
              제공과정에서 작성된 모든 자료와 정보를 익명화하여 수집하거나
              수정하여 추후 회사의 서비스 관련 연구 및 개발 등에 활용할 수
              있습니다.
              <br />⑤ 사이트는 서비스 향상과 홍보를 위하여 서비스 내에 작성된
              이용 후기 등의 콘텐츠를 기타 포털사이트 등에 제휴와 협약을 통해
              게시하거나 전송할 수 있습니다.
            </div>
            <div className={"contentTitle"}>제9조 [회원의 의무]</div>
            <div className={"content"}>
              ① 회원은 서비스 이용 시 다음 각 호의 행위를 하지 않아야 합니다.
              <br />
              가. 다른 회원의 ID를 부정하게 사용하는 행위
              <br />
              나. 서비스에서 얻은 정보를 사이트의 사전승낙 없이 회원의 이용
              이외의 목적으로 복제하거나 이를 변경, 출판 및 방송 등에 사용하거나
              타인에게 제공하는 행위
              <br />
              다. 사이트의 저작권, 타인의 저작권 등 기타 권리를 침해하는 행위
              <br />
              라. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을
              타인에게 유포하는 행위
              <br />
              마. 범죄와 결부된다고 객관적으로 판단되는 행위
              <br />
              바. 기타 관계법령에 위배되는 행위
              <br />
              ② 회원은 관계법령, 이 약관에서 규정하는 사항, 서비스 이용 안내 및
              주의 사항을 준수하여야 합니다.
              <br />③ 회원은 내용별로 사이트가 서비스 공지사항에 게시하거나
              별도로 공지한 이용 제한 사항을 준수하여야 합니다.
            </div>
            <div className={"contentTitle"}>제4장 서비스 제공 및 이용</div>
            <div className={"contentTitle"}>
              제10조 [회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무]
            </div>
            <div className={"content"}>
              ① 아이디(ID)와 비밀번호에 대한 모든 관리는 회원에게 책임이
              있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀,
              부정사용에 의하여 발생하는 모든 결과에 대한 전적인 책임은 회원에게
              있습니다.
              <br />② 자신의 아이디(ID)가 부정하게 사용된 경우 또는 기타 보안
              위반에 대하여, 회원은 반드시 사이트에 그 사실을 통보해야 합니다.
            </div>
            <div className={"contentTitle"}>제11조 [서비스 제한 및 정지]</div>
            <div className={"content"}>
              ① 사이트는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가
              발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한
              기간통신 사업자가 전기통신서비스를 중지하는 등 기타 불가항력적
              사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할
              수 있습니다.
              <br />② 사이트는 제1항의 규정에 의하여 서비스의 이용을 제한하거나
              정지할 때에는 그 사유 및 제한기간 등을 지체없이 회원에게 알려야
              합니다.
            </div>
            <div className={"contentTitle"}>제5장 계약사항의 변경, 해지</div>
            <div className={"contentTitle"}>제12조 [정보의 변경]</div>
            <div className={"content"}>
              회원이 닉네임, 비밀번호 등 고객정보를 변경하고자 하는 경우에는
              홈페이지의 회원정보 변경 서비스를 이용하여 변경할 수 있습니다.
            </div>
            <div className={"contentTitle"}>제13조 [계약사항의 해지]</div>
            <div className={"content"}>
              회원은 서비스 이용계약을 해지할 수 있으며, 해지할 경우에는 본인이
              직접 서비스를 통하거나 전화 또는 온라인 등으로 사이트에 해지신청을
              하여야 합니다. 사이트는 해지 신청이 접수된 당일부터 해당 회원의
              서비스 이용을 제한합니다. 사이트는 회원이 다음 각 항의 1에
              해당하여 이용계약을 해지하고자 할 경우에는 해지 조치 7일전까지 그
              뜻을 이용고객에게 통지하여 소명할 기회를 주어야 합니다.
              <br />
              ① 이용고객이 이용제한 규정을 위반하거나 그 이용제한 기간 내에 제한
              사유를 해소하지 않는 경우
              <br />
              ② 정보통신윤리위원회가 이용 해지를 요구한 경우
              <br />
              ③ 이용고객이 정당한 사유 없이 의견 진술에 응하지 아니한 경우
              <br />④ 타인 명의로 신청을 하였거나 신청서 내용의 허위 기재 또는
              허위서류를 첨부하여 이용계약을 체결한 경우
              <br />
              사이트는 상기 규정에 의하여 해지된 이용고객에 대해서는 별도로 정한
              기간동안 가입을 제한할 수 있습니다.
            </div>
            <div className={"contentTitle"}>제6장 손해배상</div>
            <div className={"contentTitle"}>제14조 [면책조항]</div>
            <div className={"content"}>
              ① 사이트는 회원이 서비스 제공으로부터 기대되는 이익을 얻지
              못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해
              등에 대해서는 책임이 면제됩니다.
              <br />
              ② 사이트는 회원의 귀책사유나 제3자의 고의로 인하여 서비스에 장애가
              발생하거나 회원의 데이터가 훼손된 경우에 책임이 면제됩니다.
              <br />
              ③ 사이트는 회원이 게시 또는 전송한 자료의 내용에 대해서는 책임이
              면제됩니다.
              <br />④ 상표권이 있는 도메인의 경우, 이로 인해 발생할 수도 있는
              손해나 배상에 대한 책임은 구매한 회원 당사자에게 있으며, 사이트는
              이에 대한 일체의 책임을 지지 않습니다.
            </div>
            <div className={"contentTitle"}>제15조 [관할법원]</div>
            <div className={"content"}>
              서비스와 관련하여 사이트와 회원간에 분쟁이 발생할 경우 사이트의
              본사 소재지를 관할하는 법원을 관할법원으로 합니다.
            </div>
            <div className={"contentTitle"}>[부칙]</div>
            <div className={"content"}>
              (시행일) 이 약관은 2023년 8월부터 시행합니다.
            </div>
          </>
        ) : type === "personal" ? (
          <>
            <div className={"headerBox"}>
              <div className={"header"}>개인정보 처리방침(필수)</div>
            </div>
            <div className={"content"}>
              eRAPPORT('erapport.co.kr'이하 'eRAPPORT')는 개인정보보호법에 따라
              이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
              이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을
              두고 있습니다.
              <br />
              ‘eRAPPORT’는 개인정보처리방침을 개정하는 경우 웹사이트
              공지사항(또는 개별공지)을 통하여 공지할 것입니다.
              <br />본 방침은 2023년 8월 1일부터 시행됩니다.
            </div>
            <div className={"contentTitle"}>1. 개인정보의 처리 목적</div>
            <div className={"content"}>
              ‘eRAPPORT’는 개인정보를 다음의 목적을 위해 처리합니다. 처리한
              개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이
              변경될 시에는 사전동의를 구할 예정입니다.
              <br />
              ① 홈페이지 회원가입 및 관리
              <br />
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
              회원자격 유지·관리, 제한적 본인 확인제 시행에 따른 본인확인,
              서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한
              기록 보존 등을 목적으로 개인정보를 처리합니다.
              <br />
              ② 민원사무 처리
              <br />
              민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
              처리결과 통보 등을 목적으로 개인정보를 처리합니다.
              <br />
              ③ 서비스 제공
              <br />
              자살 예방 서비스 및 콘텐츠 제공 등을 목적으로 개인정보를
              처리합니다.
              <br />
              ④ 마케팅 및 광고에의 활용
              <br />
              신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보
              제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및
              광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스
              이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
            </div>
            <div className={"contentTitle"}>2. 개인정보 파일 현황</div>
            <div className={"content"}>
              ‘eRAPPORT’가 개인정보 보호법 제32조에 따라 등록·공개하는
              개인정보파일에 대한 정보는 다음과 같습니다.
              <br />
              - 개인정보 파일명: eRAPORRT 회원명부
              <br />
              - 운영근거: 개인정보보호법 제 15 및 정보주체의 동의
              <br />
              - 운영근거 / 처리목적 수집항목: 이용약관 / 홈페이지 가입의사 확인
              및 회원제 서비스
              <br />
              - 개인정보파일에 기록되는 개인정보의 항목: [필수]이메일(아이디),
              비밀번호, 만나이, 성별
              <br />- 보유기간: 2년
            </div>
            <div className={"contentTitle"}>
              3. 개인정보의 처리 및 보유 기간
            </div>
            <div className={"content"}>
              ① ‘eRAPPORT’는 법령에 따른 개인정보 보유·이용기간 또는
              정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유,
              이용기간 내에서 개인정보를 처리, 보유합니다.
              <br />
              ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              <br />
              가. 홈페이지 회원가입 및 관리: 홈페이지 회원가입 및 관리와 관련한
              개인정보는 수집·이용에 관한 동의일로부터 "2년"까지 위 이용목적을
              위하여 보유·이용됩니다. 단, 회원탈퇴 시 개인정보를 보유하지
              아니합니다.
              <br />
              - 보유 근거: 이용 약관
              <br />
              - 개인정보 보유 목적: 홈페이지 회원 가입 및 관리
              <br />
              - 관련법령: 개인정보 보호법 제 32조
              <br />- 예외사유: 없음
            </div>
            <div className={"contentTitle"}>
              4. 개인정보의 제 3 자 제공에 관한 사항
            </div>
            <div className={"content"}>
              ① ‘eRAPPORT’는 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로
              명시한 범위 내에서 처리하며, 다음의 경우를 제외하고는 정보주체의
              사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제 3자에게
              제공하지 않습니다.
              <br />
              가. 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보
              제공에 동의를 한 경우
              <br />
              나. 법령에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
              따라 수사기관 또는 정보기관 등의 요구가 있는 경우
              <br />
              다. 이용자 또는 제 3자의 생명이나 안전에 급박한 위험이 확인되어
              이를 해소하기 위한 경우
              <br />
              라. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정
              개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
            </div>
            <div className={"contentTitle"}>5. 개인정보처리 위탁</div>
            <div className={"content"}>
              ‘eRAPPORT’는 개인정보의 처리 업무를 위탁하는 경우 다음의 내용이
              포함된 문서에 의하여 처리하고 있습니다.
              <br />
              ① 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항
              <br />
              ② 개인정보의 기술적·관리적 보호조치에 관한 사항
              <br />③ 그 밖에 개인정보의 안전한 관리를 위하여 대통령령으로 정한
              사항
            </div>
            <div className={"contentTitle"}>
              6. 정보주체의 권리, 의무 및 그 행사방법
            </div>
            <div className={"content"}>
              이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
              <br />
              ① 정보주체는 ‘eRAPPORT’에 대해 언제든지 다음 각 호의 개인정보 보호
              관련 권리를 행사할 수 있습니다.
              <br />
              가. 개인정보 열람요구
              <br />
              나. 오류 등이 있을 경우 정정 요구
              <br />
              다. 삭제요구
              <br />
              라. 처리정지 요구
              <br />
              ② 제1항에 따른 권리 행사는 회사 'eRAPPORT' 에 대해 개인정보 보호법
              시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을
              통하여 하실 수 있으며 'eRAPPORT'는 이에 대해 지체 없이
              조치하겠습니다.
              <br />
              ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
              경우에는 'eRAPPORT'는 정정 또는 삭제를 완료할 때까지 당해
              개인정보를 이용하거나 제공하지 않습니다.
              <br />④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
              받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
              보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
            </div>
            <div className={"contentTitle"}>
              7. 처리하는 개인정보의 항목 작성
            </div>
            <div className={"content"}>
              ① 'eRAPPORT'는 다음의 개인정보 항목을 처리하고 있습니다.
              <br />
              가. 홈페이지 회원가입 및 관리
              <br />
              - 필수항목 : 닉네임, 만나이, 이메일, 비밀번호, 성별, 지역(시/도)
              <br />
              나. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로
              생성되어 수집될 수 있습니다.
              <br />- 접속 IP 정보, 접속로그, 커뮤니티 게시판 게시글 및 댓글에
              기재된 정보
            </div>
            <div className={"contentTitle"}>8. 개인정보의 파기</div>
            <div className={"content"}>
              'eRAPPORT'는 원칙적으로 개인정보 처리 목적이 달성된 경우에는
              지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은
              다음과 같습니다.
              <br />
              가. 파기절차
              <br />
              - 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의
              경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간
              저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는
              법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
              <br />
              나. 파기기한
              <br />
              - 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는
              보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성,
              해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을
              때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일
              이내에 그 개인정보를 파기합니다.
              <br />
              다. 파기방법
              <br />- 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적
              방법을 사용합니다.
            </div>
            <div className={"contentTitle"}>9. 개인정보의 안전성 확보 조치</div>
            <div className={"content"}>
              'eRAPPORT'는 개인정보보호법 제29조에 따라 다음과 같이 안전성
              확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
              <br />
              ① 개인정보의 암호화
              <br />
              이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
              본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화
              하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고
              있습니다.
              <br />
              ② 해킹 등에 대비한 기술적 대책
              <br />
              'eRAPPORT'는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
              훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을
              하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
              기술적/물리적으로 감시 및 차단하고 있습니다.
              <br />
              ③ 개인정보에 대한 접근 제한
              <br />
              개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여,
              변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
              조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
              접근을 통제하고 있습니다.
              <br />
              ④ 접속기록의 보관 및 위변조 방지
              <br />
              개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고
              있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능
              사용하고 있습니다.
            </div>
            <div className={"contentTitle"}>10. 개인정보 보호책임자 작성</div>
            <div className={"content"}>
              ① 'eRAPPORT'는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
              개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
              아래와 같이 개인정보 보호책임자 및 담당부서를 지정하고 있습니다.
              <br />
              ▣ 개인정보 보호책임자
              <br />
              - 성명: 김희정
              <br />
              - 직책: 연구책임자
              <br />
              - 직급: 연세대학교 간호대학 부교수
              <br />
              - 연락처: 보호책임자 전화번호 (T. 02-2228-3273), 보호책임자 이메일
              (hkim80@yuhs.ac), 보호책임자 팩스번호 (Fax. 02-2227-8303)
              <br />
              ▣ 개인정보 보호 담당부서
              <br />
              - 부서명: 연세대학교 간호대학 김희정 교수팀
              <br />
              - 담당자: 정현우
              <br />
              - 연락처: 02-2228-3271
              <br />② 정보주체께서는 'eRAPPORT'의 서비스를 이용하시면서 발생한
              모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
              개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
              'eRAPPORT'는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴
              것입니다.
            </div>
            <div className={"contentTitle"}>11. 개인정보 열람청구</div>
            <div className={"content"}>
              ① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
              아래의 부서에 할 수 있습니다. 'eRAPPORT'는 정보주체의 개인정보
              열람청구가 신속하게 처리되도록 노력하겠습니다.
              <br />
              ▣ 개인정보 열람청구 접수·처리 부서
              <br />
              - 부서명: 연세대학교 간호대학 김희정 교수팀
              <br />
              - 담당자: 정현우
              <br />
              - 연락처: 02-2228-3271
              <br />
              ② 정보주체께서는 제1항의 열람청구 접수·처리부서 이외에,
              행정안전부의 ‘개인정보보호 종합지원 포털’
              웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실
              수 있습니다.
              <br />▣ 행정안전부 개인정보보호 종합지원 포털 → 개인정보 민원 →
              개인정보 열람등 요구 (본인확인을 위하여 아이핀(I-PIN)이 있어야 함)
            </div>
            <div className={"contentTitle"}>12. 개인정보 열람청구</div>
            <div className={"content"}>
              아래의 기관은 'eRAPPORT'와는 별개의 기관으로서, 'eRAPPORT'의
              자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나
              보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
              <br />
              ▣ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <br />
              - 소관업무: 개인정보 침해사실 신고, 상담 신청
              <br />
              - 홈페이지: privacy.kisa.or.kr
              <br />
              - 전화: (국번없이) 118
              <br />
              - 주소: (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              ▣ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
              <br />
              - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
              <br />
              - 홈페이지: privacy.kisa.or.kr
              <br />
              - 전화: (국번없이) 118
              <br />
              - 주소: (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원
              개인정보침해신고센터
              <br />
              ▣ 대검찰청 사이버범죄수사단: 02-3480-3573 (www.spo.go.kr)
              <br />▣ 경찰청 사이버범죄수사단: 1566-0112 (www.netan.go.kr)
            </div>
            <div className={"contentTitle"}>13. 개인정보 처리방침 변경</div>
            <div className={"content"}>
              ① 이 개인정보처리방침은 2023년 8월 1일 시행일로부터 적용되며, 법령
              및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는
              변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
            </div>
          </>
        ) : deviceType === "IOS" ? (
          <>
            <div className={"headerBox"}>
              <div className={"header"}>연구목적 개인정보 수집안내</div>
            </div>
            <div className={"content-title"}>1. 연구의 배경과 목적</div>
            <div className={"content"}>
              내 성인의 자살 취약성은 심각한 사회 문제로 특히, 성인은 일과
              가정생활을 병행하며 생산적인 활동이 두드러진 계층이므로 자살이라는
              사회적 문제는 중요하게 다뤄지고 있습니다. 자살 위험성이 있는 성인
              대상자에게 적극적이고 선제적인 관리가 이뤄지지 않는다면 노년기까지
              영향을 미칠 수 있습니다. 또한 이전에 자살 생각이나 자살 시도를 한
              경험이 있는 경우, 자살 위험이 더욱 높으므로 지역사회에서의
              지속적인 추적 관리가 요구됩니다. eRAPPORT 앱은 자살 위험성이 높은
              사용자가 자살 생각 및 정신 건강 상태를 스스로 기록하고 확인할 수
              있도록 도움을 줍니다. 추후 연세대학교 간호대학 김희정 교수
              연구팀은 eRAPPORT 앱을 활용하여 자살 위험군의 자살 생각 및 대처,
              정신 건강 상태를 기록한 자료를 분석함으로써 지역사회 자살 위험군의
              자살 예방에 기여하는 연구를 진행할 예정입니다.
            </div>
            <div className={"content-title"}>2. 연구 절차 및 기간</div>
            <div className={"content"}>
              귀하는 App Store에서 다운 받은 후 eRAPPORT 앱을 다운 받은 후 본
              앱의 약관, 개인정보 처리방침, 연구목적 개인정보 수집안내의 내용을
              확인 및 동의한 후 가입할 수 있습니다. 가입이 완료된 이후 귀하는 본
              앱을 자유롭게 사용할 수 있습니다. 귀하가 응답하는 자료는
              비식별화되어 Amazon Web Services(AWS)에 저장될 것입니다. 귀하의
              응답 자료는 상업적 목적이 아닌 연구 목적으로만 사용될 것입니다.
              귀하가 본 앱을 사용하며 응답하는 자료는 추후 연구에 활용될 수
              있으므로, 귀하의 연구 참여 기간은 본 앱을 사용하는 기간입니다.
            </div>
            <div className={"content-title"}>
              3. 연구에 참여하여 예상되는 이익 및 위험
            </div>
            <div className={"content"}>
              (1) 연구에 참여하여 예상되는 이익
              <br />
              귀하는 연구에 참여함으로써 자신의 자살 생각에 대한 직접적인 치료
              이익을 얻지 못할 수 있습니다. 그러나 우울, 불안, 스트레스 등
              다양한 정신 건강 문제가 함께 발생할 가능성이 높기 때문에 포괄적
              건강 평가를 받을 수 있는 기회를 가질 수 있습니다. 또한, 본 연구에
              참여함으로써 잠재적 정신 건강 문제(예: 우울, 불안 등)를 발견하고
              현재의 정신 건강상태를 반영한 중재나 치료를 적극적으로 알아볼 수
              있는 기회가 될 것입니다.
              <br />
              (2) 연구에 참여하여 예상되는 위험
              <br />이 연구의 결과를 바탕으로 지역사회 자살 위험군의 자살 예방을
              위한 지표를 개발하는 데 과학적으로 기여할 수 있습니다. 따라서,
              앞으로 자살 예방 어플리케이션을 기반으로 한 지역사회 자살 위험군
              케어 서비스 개발에 사회적으로 기여하여 이들을 위한 지역 사회
              서비스나 정신간호중재 개발에 기여할 수 있는 기초자료를 제공할
              것으로 기대됩니다.
            </div>
            <div className={"content-title"}>
              4. 개인정보 및 기록에 대한 비밀보장
            </div>
            <div className={"content"}>
              본 연구에 참여하는 동안에 수집된 연구대상자의 기록은 비밀로 보장될
              것이며, 연구의 결과가 학술보고서로 작성되거나 발표되는 경우에도 각
              대상자의 신원을 파악할 수 있는 기록은 비밀 상태로 유지한다. 또한,
              자료의 보안과 익명성을 유지하며 연구대상자의 신원을 보호하기 위해
              다음과 같은 절차를 준수하고, 다음과 같은 내용에 대한 동의를
              획득하여 보관하도록 한다.
              <br />
              (1) 본 연구를 위하여 수집하는 개인정보는 다음과 같다: 이메일,
              비밀번호, 만나이, 성별, 접속 IP 정보, 접속 로그
              <br />
              (2) 개인정보 보호조치 차원의 자료 보안을 위해 동의서를 받은 순서에
              의해 일렬 번호를 부여하여 개인정보를 비식별화한다.
              <br />
              (3) 자료의 익명성을 유지하기 위하여 본 연구에서 대상자로부터 얻은
              자료는 비식별화 하여 이차분석을 포함하여 모두 연구의 목적으로만
              사용한다.
              <br />
              (4) 연구대상자의 질환과 관련된 정보는 자가 보고에 의해서 확인할
              것이며 의무기록 열람이나 진단서 등의 개인정보가 필요하지 않다.
              <br />
              (5) 본 연구에서 수집된 자료는 코드화하여 비밀번호로 잠금장치가 된
              연구원의 컴퓨터 내 데이터 파일에 저장하여 자료의 보안을 유지한다.
            </div>
            <div className={"content-title"}>
              5. 개인정보 및 기록에 대한 비밀보장
            </div>
            <div className={"content"}>
              귀하가 응답하는 자료는 개인정보보호법 제29조에 따라 다음과 같이
              안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
              개인정보 및 데이터의 비밀보장에 대한 자세한 사항은 본 앱의
              ‘개인정보처리방침’에서 확인할 수 있습니다.
            </div>
            <div className={"content-title"}>6. 연락처</div>
            <div className={"content"}>
              귀하는 언제든지 본 앱 사용 및 연구 참여에 관하여 궁금한 점이
              있거나 연구와 관련이 있는 상해가 발생한 경우에는 아래의 연구자에게
              연락하여 주십시오.
              <br />
              - 부서명: 연세대학교 간호대학 김희정 교수팀
              <br />
              - 담당자: 정현우
              <br />- 연락처: 02-2228-3271
            </div>
            <div className={"content-title"}>7. 참여/철회의 자발성</div>
            <div className={"content"}>
              귀하는 언제든지 연구 참여에 대해 동의를 철회할 수 있으며, 이 경우,
              연구 참여는 종료되고 연구원은 귀하에게 연구와 관련하여 추가적인
              정보를 수집하지 않을 것입니다. 만일, 동의를 철회하는 경우 이전까지
              수집된 정보를 폐기하여 연구에 이용되지 않기를 원한다면 연구자(앱
              관리자)에게 귀하의 의사를 전달해 주시기 바랍니다. 귀하는 본 연구에
              참여하지 않을 자유가 있습니다. 또한, 귀하가 본 연구에 참여하지
              않아도 귀하에게는 어떠한 불이익도 없습니다. 귀하는 본 앱의 서비스
              이용계약을 해지함으로써 연구 참여를 철회할 수 있습니다. 본 앱의
              서비스 이용계약 해지에 대한 자세한 사항은 본 앱의 ‘약관’에서
              확인할 수 있습니다.
            </div>
          </>
        ) : (
          <>
            <div className={"headerBox"}>
              <div className={"header"}>연구목적 개인정보 수집안내</div>
            </div>
            <div className={"content-title"}>1. 연구의 배경과 목적</div>
            <div className={"content"}>
              국내 성인의 자살 취약성은 심각한 사회 문제로 특히, 성인은 일과
              가정생활을 병행하며 생산적인 활동이 두드러진 계층이므로 자살이라는
              사회적 문제는 중요하게 다뤄지고 있습니다. 자살 위험성이 있는 성인
              대상자에게 적극적이고 선제적인 관리가 이뤄지지 않는다면 노년기까지
              영향을 미칠 수 있습니다. 또한 이전에 자살 생각이나 자살 시도를 한
              경험이 있는 경우, 자살 위험이 더욱 높으므로 지역사회에서의
              지속적인 추적 관리가 요구됩니다. eRAPPORT 앱은 자살 위험성이 높은
              사용자가 자살 생각 및 정신 건강 상태를 스스로 기록하고 확인할 수
              있도록 도움을 줍니다. 추후 연세대학교 간호대학 김희정 교수
              연구팀은 eRAPPORT 앱을 활용하여 자살 위험군의 자살 생각 및 대처,
              정신 건강 상태를 기록한 자료를 분석함으로써 지역사회 자살 위험군의
              자살 예방에 기여하는 연구를 진행할 예정입니다.
            </div>
            <div className={"content-title"}>2. 연구 절차 및 기간</div>
            <div className={"content"}>
              귀하는 Google Play Store 에서 다운 받은 후 eRAPPORT 앱을 다운 받은
              후 본 앱의 약관, 개인정보 처리방침, 연구목적 개인정보 수집안내의
              내용을 확인 및 동의한 후 가입할 수 있습니다. 가입이 완료된 이후
              귀하는 본 앱을 자유롭게 사용할 수 있습니다. 귀하가 응답하는 자료는
              비식별화되어 Amazon Web Services(AWS)에 저장될 것입니다. 귀하의
              응답 자료는 상업적 목적이 아닌 연구 목적으로만 사용될 것입니다.
              귀하가 본 앱을 사용하며 응답하는 자료는 추후 연구에 활용될 수
              있으므로, 귀하의 연구 참여 기간은 본 앱을 사용하는 기간입니다.
            </div>
            <div className={"content-title"}>
              3. 연구에 참여하여 예상되는 이익 및 위험
            </div>
            <div className={"content"}>
              (1) 연구에 참여하여 예상되는 이익
              <br />
              귀하는 연구에 참여함으로써 자신의 자살 생각에 대한 직접적인 치료
              이익을 얻지 못할 수 있습니다. 그러나 우울, 불안, 스트레스 등
              다양한 정신 건강 문제가 함께 발생할 가능성이 높기 때문에 포괄적
              건강 평가를 받을 수 있는 기회를 가질 수 있습니다. 또한, 본 연구에
              참여함으로써 잠재적 정신 건강 문제(예: 우울, 불안 등)를 발견하고
              현재의 정신 건강상태를 반영한 중재나 치료를 적극적으로 알아볼 수
              있는 기회가 될 것입니다.
              <br />
              (2) 연구에 참여하여 예상되는 위험
              <br />이 연구의 결과를 바탕으로 지역사회 자살 위험군의 자살 예방을
              위한 지표를 개발하는 데 과학적으로 기여할 수 있습니다. 따라서,
              앞으로 자살 예방 어플리케이션을 기반으로 한 지역사회 자살 위험군
              케어 서비스 개발에 사회적으로 기여하여 이들을 위한 지역 사회
              서비스나 정신간호중재 개발에 기여할 수 있는 기초자료를 제공할
              것으로 기대됩니다.
            </div>
            <div className={"content-title"}>
              4. 개인정보 및 기록에 대한 비밀보장
            </div>
            <div className={"content"}>
              본 연구에 참여하는 동안에 수집된 연구대상자의 기록은 비밀로 보장될
              것이며, 연구의 결과가 학술보고서로 작성되거나 발표되는 경우에도 각
              대상자의 신원을 파악할 수 있는 기록은 비밀 상태로 유지한다. 또한,
              자료의 보안과 익명성을 유지하며 연구대상자의 신원을 보호하기 위해
              다음과 같은 절차를 준수하고, 다음과 같은 내용에 대한 동의를
              획득하여 보관하도록 한다.
              <br />
              (1) 본 연구를 위하여 수집하는 개인정보는 다음과 같다: 이메일,
              비밀번호, 만나이, 성별, 접속 IP 정보, 접속 로그
              <br />
              (2) 개인정보 보호조치 차원의 자료 보안을 위해 동의서를 받은 순서에
              의해 일렬 번호를 부여하여 개인정보를 비식별화한다.
              <br />
              (3) 자료의 익명성을 유지하기 위하여 본 연구에서 대상자로부터 얻은
              자료는 비식별화 하여 이차분석을 포함하여 모두 연구의 목적으로만
              사용한다.
              <br />
              (4) 연구대상자의 질환과 관련된 정보는 자가 보고에 의해서 확인할
              것이며 의무기록 열람이나 진단서 등의 개인정보가 필요하지 않다.
              <br />
              (5) 본 연구에서 수집된 자료는 코드화하여 비밀번호로 잠금장치가 된
              연구원의 컴퓨터 내 데이터 파일에 저장하여 자료의 보안을 유지한다.
            </div>
            <div className={"content-title"}>
              5. 개인정보 및 기록에 대한 비밀보장
            </div>
            <div className={"content"}>
              귀하가 응답하는 자료는 개인정보보호법 제29조에 따라 다음과 같이
              안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
              개인정보 및 데이터의 비밀보장에 대한 자세한 사항은 본 앱의
              ‘개인정보처리방침’에서 확인할 수 있습니다.
            </div>
            <div className={"content-title"}>6. 연락처</div>
            <div className={"content"}>
              귀하는 언제든지 본 앱 사용 및 연구 참여에 관하여 궁금한 점이
              있거나 연구와 관련이 있는 상해가 발생한 경우에는 아래의 연구자에게
              연락하여 주십시오.
              <br />
              - 부서명: 연세대학교 간호대학 김희정 교수팀
              <br />
              - 담당자: 정현우
              <br />- 연락처: 02-2228-3271
            </div>
            <div className={"content-title"}>7. 참여/철회의 자발성</div>
            <div className={"content"}>
              귀하는 언제든지 연구 참여에 대해 동의를 철회할 수 있으며, 이 경우,
              연구 참여는 종료되고 연구원은 귀하에게 연구와 관련하여 추가적인
              정보를 수집하지 않을 것입니다. 만일, 동의를 철회하는 경우 이전까지
              수집된 정보를 폐기하여 연구에 이용되지 않기를 원한다면 연구자(앱
              관리자)에게 귀하의 의사를 전달해 주시기 바랍니다. 귀하는 본 연구에
              참여하지 않을 자유가 있습니다. 또한, 귀하가 본 연구에 참여하지
              않아도 귀하에게는 어떠한 불이익도 없습니다. 귀하는 본 앱의 서비스
              이용계약을 해지함으로써 연구 참여를 철회할 수 있습니다. 본 앱의
              서비스 이용계약 해지에 대한 자세한 사항은 본 앱의 ‘약관’에서
              확인할 수 있습니다.
            </div>
          </>
        )}
        <div className={"confirmButton"} onClick={onToggleModal}>
          확인
        </div>
      </Container>
    </CenterModalLayout>
  );
};
