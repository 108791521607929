import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const ServiceBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  height: 104px;
  padding: 13px 20px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  background: ${Colors.White};
  border-radius: 10px;
  margin-bottom: 20px;

  .info-zone {
    display: flex;
    flex-direction: column;

    .answer-type {
      display: flex;
      align-items: center;
      width: 70px;
      height: 24px;
      border: solid 1px
        ${(props) =>
          props.isAnswered === true ? Colors.Primary : Colors.Gray60};
      border-radius: 5px;
      padding: 6px 8px 6px 10px;
      font-weight: 400;
      font-size: 1rem;
      line-height: 12px;
      color: ${(props) =>
        props.isAnswered === true ? Colors.Primary : Colors.Gray60};
      margin-bottom: 10px;
      text-align: center;
    }
    .title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 18px;
      color: ${Colors.Gray90};
      margin-bottom: 10px;
    }
    .date {
      font-weight: 400;
      font-size: 1rem;
      line-height: 14px;
    }
  }

  & img {
    width: 22px;
    height: 22px;

    margin-left: auto;
  }
`;
