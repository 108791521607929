import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { AuthIntroButtons } from "./styles";

// Components
import Button from "../../../../common/component/atom/Button";

const index = () => {
  const navigate = useNavigate();

  return (
    <AuthIntroButtons>
      <Button
        label={"로그인"}
        variant={"painted"}
        size="large"
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 10px;
        `}
        onClick={() => navigate("/auth/login")}
      />
      <Button
        label={"회원가입"}
        variant={"unpainted"}
        size="large"
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
        `}
        onClick={() => {
          navigate("/auth/signup?step=1");
        }}
      />
    </AuthIntroButtons>
  );
};

export default index;
