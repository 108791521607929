import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

//components
import Button from "../../../../common/component/atom/Button";
import InputForm from "../../../../common/component/atom/InputForm";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//styles
import { EmailSection } from "./styles";
import { Colors, yeonseiImages } from "../../../../styles";

const index = (props) => {
  const navigate = useNavigate();
  const {
    appPw = "",
    email,
    params,
    onChange,
    exception = false,
    isDisplay,
    resultMsg,
    fetchVerifyEmail,
    fetchVerifyNumber,
  } = props;
  const oneButtonModalHandler = handlerModal();

  return (
    <EmailSection isDisplay={isDisplay} appPw={appPw}>
      {appPw && <div className={"warning"}>앱 비밀번호 5회 입력 오류</div>}
      <img alt={"이메일 이미지"} src={yeonseiImages.Email} />
      <div className={"title"}>인증 메일이 발송되었습니다.</div>
      <div className={"sub-title"}>
        {appPw
          ? "앱 비밀번호를 5회 틀리셨습니다.\n이메일 인증을 하시면 앱 비밀번호가 초기화 됩니다."
          : "서비스 이용을 위해 본인인증을 진행해주세요.\n이메일의 인증 버튼을 선택하면 회원가입이 완료돼요."}
      </div>
      <div className={"email-box"}>{params.mber_id || ""}</div>
      <InputForm
        placeholder={"인증번호를 입력하세요"}
        value={email.crtfc_no}
        onChange={(e) => onChange("crtfc_no", e.target.value)}
      />
      <Button
        label={"인증하기"}
        variant={"painted"}
        size="xLarge"
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
          margin-top: 30px;
          margin-bottom: 10px;
          background-color: ${Colors.Primary};
        `}
        onClick={() => {
          fetchVerifyNumber();
          oneButtonModalHandler.onToggleModal();
        }}
      />
      <Button
        label={"메일 재발송"}
        variant={"lookText"}
        size="xLarge"
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
        `}
        onClick={() => fetchVerifyEmail()}
      />
      <OneButtonModal
        handler={oneButtonModalHandler}
        label={resultMsg.error ? "닫기" : "로그인하기"}
        buttonClick={() => {
          if (resultMsg.error_msg && resultMsg.error === false) {
            exception ? navigate("/main/main") : navigate("/auth/login");
          } else {
            oneButtonModalHandler.onToggleModal();
          }
        }}
      >
        <div className={"in-modal-box"}>
          <img
            src={
              resultMsg.error
                ? yeonseiImages.ExclamationMarkMiddle
                : yeonseiImages.CheckAbleLarge
            }
            alt={"icon"}
          />
          {resultMsg.error_msg || "인증번호를 입력하세요."}
        </div>
      </OneButtonModal>
    </EmailSection>
  );
};

export default index;
