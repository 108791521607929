import React from "react";
import { css } from "@emotion/css";

import { MainSection } from "./styles";
import { Colors } from "../../../../styles";

import Button from "../../../../common/component/atom/Button";

const index = () => {
  return (
    <MainSection>
      <div className={"shadow-box"}>
        <span className={"title"}>마인드카페 30% 할인 쿠폰</span>
        <Button
          variant={"painted"}
          size={"mediumSmallLarge"}
          label={"쿠폰 발급"}
          style={css`
            font-size: 1.25rem;
            background-color: ${Colors.Primary};
          `}
          onClick={() =>
            window.open("https://mindcafe.onelink.me/RXps/erapport", "_self")
          }
        />
      </div>
    </MainSection>
  );
};

export default index;
