import React from "react";

// Styles
import { HelpLinePopUp } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = (props) => {
  const { onChangeValue } = props;
  return (
    <HelpLinePopUp>
      <div className="topDescription">
        {`위기상황에서 도움을 받을 수 있는
          전문 기관 목록입니다.
          도움이 필요할 때 이용해보세요.`}
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:109";
            onChangeValue("helpline_yn", "Y");
          }}
        >
          자살예방 상담전화 <span>109</span>
        </div>
        <img
          alt={"자살예방"}
          src={yeonseiImages.PreventCall}
          onClick={() => {
            location.href = "tel:109";
            onChangeValue("helpline_yn", "Y");
          }}
        />
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:1577-0199";
            onChangeValue("helpline_yn", "Y");
          }}
        >
          정신건강 상담전화 <span>1577-0199</span>
        </div>
        <img
          alt={"정신건강"}
          src={yeonseiImages.MentalHealthSmall}
          onClick={() => {
            location.href = "tel:1577-0199";
            onChangeValue("helpline_yn", "Y");
          }}
        />
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:1588-9191";
            onChangeValue("helpline_yn", "Y");
          }}
        >
          한국 생명의 전화 <span>1588-9191</span>
        </div>
        <img
          alt={"생명의 전화"}
          src={yeonseiImages.LifelineIcon}
          onClick={() => {
            location.href = "tel:1588-9191";
            onChangeValue("helpline_yn", "Y");
          }}
        />
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:119";
            onChangeValue("helpline_yn", "Y");
          }}
        >
          응급구조요청 <span>119</span>
        </div>
        <img
          alt={"응급구조전화"}
          src={yeonseiImages.EmergencyRescueSmall}
          onClick={() => {
            location.href = "tel:119";
            onChangeValue("helpline_yn", "Y");
          }}
        />
      </div>
      <div className="bottomDescription">
        {`당신은 위험에 처해있을 때,
          당신에게 도움을 줄 수 있는 기관과
          사람이 있다는 것을 알고 있습니까?`}
      </div>
    </HelpLinePopUp>
  );
};

export default index;
