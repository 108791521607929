import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

// Styles
import { SubMenuRow } from "./styles";
import { yeonseiImages } from "../../../styles";

// Config

const index = (props) => {
  const { config = {} } = props;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <SubMenuRow
      isOpen={isOpen}
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div className="menuRow">
        <div className={"field"}>{config.title}</div>
        <img
          alt={"rightArrowThin"}
          src={isOpen ? yeonseiImages.ArrowUpThin : yeonseiImages.ArrowDownThin}
        />
      </div>

      <div className={"subMenu"}>
        {(config.subLinks || []).map((subLink = {}) => {
          return (
            <div className={"row"} onClick={() => navigate(subLink.link)}>
              {subLink.title}
            </div>
          );
        })}
      </div>
    </SubMenuRow>
  );
};

export default index;
