import React from "react";

import { MainSection, MainText } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = () => {
  return (
    <MainSection>
      <img src={yeonseiImages.MisUnderStandingTruth} alt={"background-img"} />
      <MainText>
        <div className={"main-title"}>
          자살, 자살징후에 대한 다양한 오해가 있다는 걸 아시나요?
          <br />
          <br />
          오해를 정정하여 자살을 예방하는데 활용하고 주변에 알려보아요.
        </div>
        <div className={"misunderstanding"}>
          오해
          <div className={"text"}>
            자살에 대해 말하는 사람은 정말로 죽을 생각이 없을 것이다.
          </div>
        </div>
        <div className={"truth"}>
          사실
          <div className={"text"}>
            자살에 대해 말하는 사람은{" "}
            <span>도움이나 지원을 구하려 하는 것</span>일 수도 있습니다.
            <br />
            자살을 생각하는 많은 사람이 불안, 우울, 절망을 경험하며
            <span> 다른 선택이 없다고 느낄 수 있습니다.</span>
          </div>
        </div>
        <div className={"misunderstanding"}>
          오해
          <div className={"text"}>
            대부분의 자살은 예고 없이 갑자기 발생한다.
          </div>
        </div>
        <div className={"truth"}>
          사실
          <div className={"text"}>
            대부분의 자살은 대개 언어나 행동으로
            <span> 사전에 경고신호가 주어집니다.</span>
            <br />
            물론 어떤 경우에는 예고 없이 발생하기도 하지만
            <span> 경고신호가 무엇인지 파악하고 주의를 기울이는 것</span>이
            중요합니다.
          </div>
        </div>
        <div className={"misunderstanding"}>
          오해
          <div className={"text"}>
            자살 충동이 있는 사람은 이미 죽을 결심을 한 것이다.
          </div>
        </div>
        <div className={"truth"}>
          사실
          <div className={"text"}>
            이와 반대로, 자살 생각이 있는 사람은 살거나 죽는 것에 대해
            양가기분을 가지는 경우가 많습니다.
            <br />
            적절한 때에 <span>정서적 지원을 받을 수 있다면 자살을 예방</span>할
            수 있습니다.
          </div>
        </div>
        <div className={"misunderstanding"}>
          오해
          <div className={"text"}>
            자살 충동이 시작되면 그 충동은 지속될 것이다.
          </div>
        </div>
        <div className={"truth"}>
          사실
          <div className={"text"}>
            자살 생각은 단기적이며 상황에 따라 달라질 수 있습니다. <br />
            자살 생각이 다시 들 수도 있지만,{" "}
            <span>자살 생각은 영원한 것이 아닙니다.</span> <br />
            자살 생각과 시도의 과거가 있는 사람도 이후 긴 삶을 살기 위해 나아갈
            수 있습니다.
          </div>
        </div>
        <div className={"misunderstanding"}>
          오해
          <div className={"text"}>정신 질환자들만이 자살 충동을 느낀다.</div>
        </div>
        <div className={"truth"}>
          사실
          <div className={"text"}>
            자살 충동을 갖고 있다는 것이{" "}
            <span>반드시 정신질환을 의미하는 것은 아닙니다.</span> <br />
            정신질환을 앓고 있는 많은 사람은 자살행위에 영향을 받지 않으며,
            <span>
              {" "}
              자살하는 모든 사람이 정신질환을 앓고 있는 것도 아닙니다.
            </span>
          </div>
        </div>
        <div className={"misunderstanding"}>
          오해
          <div className={"text"}>
            자살에 대해 말하는 것은 좋은 생각이 아니며, 오히려 자살을 장려하는
            것으로 해석될 수 있다.
          </div>
        </div>
        <div className={"truth"} style={{ marginBottom: "50px" }}>
          사실
          <div className={"text"}>
            자살에 대한 공공연한 낙인 때문에 대부분의 자살 생각하는 사람들은
            누구에게 자신의 속마음을 이야기해야 할지 모르는 경우가 많습니다.
            <br />
            자살에 대해 솔직한 대화를 나누는 것은 자살행위를 장려하는 것이
            아니라, 오히려{" "}
            <span>
              자살 생각을 하는 사람에게 더 나은 선택의 길을 열어주거나
            </span>{" "}
            자살을 하고자 하는 결정에 대해{" "}
            <span>다시 생각할 기회를 줍니다.</span>
          </div>
        </div>
      </MainText>
      <div className={"source"}>
        출처: 보건복지부 & 중앙심리부검센터. (2019). WHO 자살예방 문헌집.
        <br />
        Retrieved from (https://apps.who.int/iris/bitstream/handle/10665/
        <br />
        131056/9789241564779-kor.pdf?ua=1)
      </div>
    </MainSection>
  );
};

export default index;
