import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";

// Components
import Datepicker from "../../Datepicker";

// Utils
import { DateUtil } from "../../../../util";
import { PeriodSelectBox, PeriodSelectItem, PeriodWideBox } from "./styles";

/**
 * @class date-period-wide
 * @param {Object} props.from - 검색시작 날짜
 * @param {Object} props.to - 검색종료 날짜
 * @param {Object} props.from_to - 우측에 들어가는 기간지정 값 (예를들어 오늘, 일주일, 15일 등)
 * @example props.from_to 의 예시
 * {
 *    field: "date_from_to",
 *    chk_opt: [
 *      { name: "오늘", value: 0, unit: "days" },
 *      { name: "내일", value: 1, unit: "days" },
 *      { name: "일주일", value: 7, unit: "days" },
 *      { name: "한달", value: 1, unit: "month" },
 *    ]
 * }
 */
const index = (props) => {
  const { params, setParams, from, to, from_to, onChangeValue } = props;
  const [chkOpt, setChkOpt] = useState(from_to.chk_opt);
  const [prevIndex, setPrevIndex] = useState(null);

  useEffect(() => {
    if (params[from.field] === undefined && params[to.field] === undefined) {
      setInitialValue();
    }
  }, [params[from.field], params[to.field]]);

  const setInitialValue = () => {
    setChkOpt(from_to.chk_opt);
    setPrevIndex(null);
  };

  const onChangeDate = (index) => {
    const temp_p = { ...params };
    setChkOpt(
      chkOpt.map((chk, idx) => {
        return { ...chk, is_checked: idx === index ? !chk.is_checked : false };
      })
    );

    if (prevIndex === index) {
      temp_p[to.field] = null;
      temp_p[from.field] = null;
      setPrevIndex(null);
    } else {
      temp_p[to.field] = DateUtil.format("dash", new Date());
      temp_p[from.field] = DateUtil.format(
        "dash",
        DateUtil.subtractDays(
          new Date(),
          chkOpt[index].value,
          chkOpt[index].unit
        )
      );
      setPrevIndex(index);
    }
    setParams(temp_p);
  };

  const changeDate = (field, date) => {
    const temp_p = { ...params };
    temp_p[field] = date ? DateUtil.format("dash", date) : null;

    if (
      temp_p[from.field] &&
      temp_p[to.field] &&
      temp_p[from.field] > temp_p[to.field]
    ) {
      alert("종료일이 시작일보다 커야 합니다. 종료일을 다시 선택해주세요.");
    } else {
      onChangeValue(field, date ? DateUtil.format("dash", date) : null);
    }
  };

  return (
    <PeriodWideBox>
      <Datepicker
        format={from.format}
        placeholder={from.placeholder}
        value={params[from.field]}
        style={css`
          width: 174px;
          & input {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        `}
        onChange={(date) => {
          changeDate(from.field, date);
        }}
      />
      <Datepicker
        format={to.format}
        placeholder={to.placeholder}
        value={params[to.field]}
        style={css`
          width: 174px;
          & .common-icon {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        `}
        onChange={(date) => {
          changeDate(to.field, date);
        }}
      />
      <PeriodSelectBox>
        {(chkOpt || []).map((item, index) => {
          return (
            <PeriodSelectItem
              key={`date_from_to_${item.name}`}
              selected={item.is_checked}
              className={item.is_checked ? "selected" : ""}
              onClick={() => onChangeDate(index)}
            >
              {item.name}
            </PeriodSelectItem>
          );
        })}
      </PeriodSelectBox>
    </PeriodWideBox>
  );
};

export default index;
