import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${Colors.Gray20};
  ::-webkit-scrollbar {
    display: none;
  }

  .background-img {
    width: 100%;
  }

  .source {
    width: 100%;
    height: 118px;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 18px;
    text-align: center;
    color: ${Colors.Gray80};
  }
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: ${Colors.White};

  .main-title {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 25px;
    color: ${Colors.Gray80};
    margin-bottom: 50px;
    word-break: keep-all;
  }

  .misunderstanding {
    display: flex;
    flex-direction: row;
    line-height: 3;
    border-top: solid 3px ${Colors.Primary};
    color: ${Colors.Primary};
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 20px;
    word-break: keep-all;

    > .text {
      word-break: keep-all;
      margin-top: 10px;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 25px;
      color: ${Colors.Primary};
      margin-left: 20px;
    }
  }

  .truth {
    display: flex;
    flex-direction: row;
    line-height: 3;
    color: ${Colors.Gray80};
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 70px;
    word-break: keep-all;

    > .text {
      word-break: keep-all;
      margin-top: 10px;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 25px;
      color: ${Colors.Gray80};
      margin-left: 20px;
      & span {
        font-weight: 600;
        font-size: 1.1rem;
        color: ${Colors.Gray80};
      }
    }
  }
`;
