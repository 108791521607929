import React from "react";
import { useNavigate } from "react-router";

// Styles
import { FeelingStatistics } from "./styles";

// Components
import TabMenuBox from "../../../../common/component/molecule/TabMenuBox";
import Chart from "../../../../component/atom/StatisticChart";

//Util
import { DateUtil } from "../../../../common/util";
const index = (props) => {
  const { params, statisticList, onHandleDate, onChangeValue } = props;
  const navigate = useNavigate();
  const formatList = statisticList?.map((el) => ({
    x: DateUtil.format("onlyDate", el.write_dt),
    y: parseInt(el.avg),
  }));
  const inputData = [
    { id: "date", color: "hsl(8, 70%, 50%)", data: formatList || [] },
  ];
  const year = DateUtil.format("year", statisticList[0]?.write_dt);

  return (
    <FeelingStatistics isdisplay={params.calendarTabBox}>
      <TabMenuBox
        tabSize={"large"}
        size={"big"}
        tabs={[
          { label: "일주일", value: "1week" },
          { label: "2주", value: "2week" },
          { label: "한달", value: "1month" },
        ]}
        value={params.statisticsTabBox}
        onChangeValue={(data, e) => {
          e.stopPropagation();
          onChangeValue("statisticsTabBox", data.value);
          onHandleDate(data.value);
        }}
      />
      <div className={"title"}>감정 달력 통계</div>
      <Chart data={inputData} year={year} ableClick={true} />
      <div className={"dateBox"}>
        {statisticList?.map((el) => (
          <div
            className={"dateBlock"}
            onClick={() =>
              navigate(
                "/main/feelingCalendarStatistics?dateNo=" +
                  DateUtil.format("dash", el.write_dt)
              )
            }
          >
            {DateUtil.format("ll", el.write_dt)}
          </div>
        ))}
        <div className={"space"} />
      </div>
    </FeelingStatistics>
  );
};

export default index;
