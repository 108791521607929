import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const TextAreaForm = styled.textarea`
  padding: 16px;
  border: solid 1px ${Colors.Gray40};
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: solid 2px ${Colors.Gray40};
  }
`;
