import styled from "@emotion/styled";
import { Colors, globalStyle } from "../../../styles";

export const CenterModalLayout = styled.dialog`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: ${(props) =>
    props.className === "changeLayout"
      ? "translate(-50%, 150%)"
      : "translate(-50%, -50%)"};

  border: none;
  border-radius: 30px;
  overflow-x: hidden;

  ::backdrop {
    background: ${Colors.Black};
    opacity: 0.6;
    pointer-events: ${(props) => (props.pointerEvent ? "default" : "none")};
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
