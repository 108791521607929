import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const SettingSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px;

  .explain-box {
    padding: 20px;
    width: 100%;
    border: 1px solid ${Colors.Gray60};
    border-radius: 10px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: ${Colors.Gray60};
    margin-bottom: 40px;
    word-break: keep-all;
  }

  & img {
    height: 49px;
  }
`;
