import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const AuthIntroDescription = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 112px;

  & > img {
    width: 128px;
    height: 129px;
    object-fit: contain;
    margin-bottom: 33px;
  }

  & > h1 {
    height: 20px;
    font-size: 1.625rem;
    line-height: 1;
    color: ${Colors.Primary};
    margin-bottom: 10px;
  }

  & > h2 {
    height: 18px;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.2;
    width: 320px;
    text-align: center;
    word-break: keep-all;
    color: ${Colors.Gray60};
  }
`;
