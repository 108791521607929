import { useNavigate } from "react-router";

// Styles
import { LoginSection } from "./styles";

// Components
import AuthIntroDescription from "../../component/AuthIntroDescription";
import AuthIntroButtons from "../../component/AuthIntroButtons";

const index = () => {
  const navigate = useNavigate();

  return (
    <LoginSection>
      {/*인트로 소개 화면*/}
      <AuthIntroDescription />
      {/*인트로 로그인 / 회원가입 이동화면*/}
      <AuthIntroButtons />
    </LoginSection>
  );
};

export default index;
