import React from "react";

// Styles
import { EmergencyModalContent } from "./styles";
import { yeonseiImages } from "../../../styles";

//api
import * as RestApi from "../../../common/context/api";

const index = () => {
  const fetchUpdateContact = () => {
    const api_params = {
      contact_hist_typ_cd: "CHTC00001",
      contact_emergency_yn: "Y",
    };
    RestApi.post("eCONTACT_HIST_USER_CREATE", api_params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <EmergencyModalContent>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:109";
            fetchUpdateContact();
          }}
        >
          자살예방 상담전화
        </div>
        <img
          alt={"자살예방"}
          src={yeonseiImages.PreventCall}
          onClick={() => {
            location.href = "tel:109";
            fetchUpdateContact();
          }}
        />
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:1577-0199";
            fetchUpdateContact();
          }}
        >
          정신건강 상담전화
        </div>
        <img
          alt={"정신건강"}
          src={yeonseiImages.MentalHealthSmall}
          onClick={() => {
            location.href = "tel:1577-0199";
            fetchUpdateContact();
          }}
        />
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:1588-9191";
            fetchUpdateContact();
          }}
        >
          한국 생명의 전화
        </div>
        <img
          alt={"생명의 전화"}
          src={yeonseiImages.LifelineIcon}
          onClick={() => {
            location.href = "tel:1588-9191";
            fetchUpdateContact();
          }}
        />
      </div>
      <div className="modalRow">
        <div
          className="center"
          onClick={() => {
            location.href = "tel:119";
            fetchUpdateContact();
          }}
        >
          응급구조요청
        </div>
        <img
          alt={"응급구조전화"}
          src={yeonseiImages.EmergencyRescueSmall}
          onClick={() => {
            location.href = "tel:119";
            fetchUpdateContact();
          }}
        />
      </div>
    </EmergencyModalContent>
  );
};

export default index;
