import React, { useEffect } from "react";
import { css } from "@emotion/css";

//component
import KoreaMap from "../../../../component/molecule/KoreaMap";
import Button from "../../../../common/component/atom/Button";
import {
  handlerModal,
  ListBottomModal,
  OneButtonModal,
} from "../../../../component/organism/Modal";

//style
import { Colors, Icons, yeonseiImages } from "../../../../styles";
import { MainSection, MapArticle } from "./styles";

//util
import { cityList_config } from "../../../../common/util/const";

const index = () => {
  const ListBottomModalHandler = handlerModal();
  const oneButtonModalHandler = handlerModal();
  const {
    params = {},
    onChangeModalParams = (e) => {},
    onToggleModal = () => {},
  } = ListBottomModalHandler;
  const location = {
    title: cityList_config.find((config) => config.id === params.id)?.title,
    url: cityList_config.find((config) => config.id === params.id)?.url,
    detail: cityList_config.find((config) => config.id === params.id)?.detail,
  };

  useEffect(() => {}, [params]);

  return (
    <MainSection>
      <MapArticle>
        <Button
          label={params.mber_loc || "선택옵션"}
          variant={"select"}
          size={"mediumLarge"}
          icon={params.mber_loc ? null : Icons.expandMore}
          iconStyle={css`
            margin-left: 250px;
          `}
          style={css`
            color: ${params.mber_loc ? Colors.Black : Colors.Gray60};
          `}
          onClick={() => onToggleModal()}
        />
        <div className={"map-wrapper"}>
          <KoreaMap
            selectedId={params.id}
            onChangeValue={(e) => {
              if (e.target.id) {
                const selectedItem = cityList_config.find(
                  (config) => config.id === e.target.id
                );
                onChangeModalParams({
                  mber_loc: selectedItem.value,
                  id: selectedItem.id,
                });
              }
            }}
          />
        </div>
        <Button
          label={"확인"}
          variant={"painted"}
          size={"xLarge"}
          style={css`
            font-size: 1.25rem;
            margin-top: 20px;
          `}
          onClick={() => oneButtonModalHandler.onToggleModal()}
        />
      </MapArticle>
      <ListBottomModal
        handler={ListBottomModalHandler}
        button
        list={cityList_config}
        onChange={(item) =>
          onChangeModalParams(
            {
              mber_loc: item.value,
              id: item.id,
            },
            true
          )
        }
      />
      <OneButtonModal handler={oneButtonModalHandler} label={"닫기"}>
        <div className={"in-modal-box"}>
          {params.id !== "seoul" && (
            <div className={"site-box"}>
              <div className={"title"}>{location.title}</div>
              <img
                src={yeonseiImages.ShareBox}
                alt={"ShareBox"}
                onClick={() => window.open(location.url, "_self")}
              />
            </div>
          )}
          {params.id === "seoul" && (
            <>
              <div className={"site-box"}>
                <div className={"title"}>{location.detail[0].title}</div>
                <img
                  src={yeonseiImages.ShareBox}
                  alt={"ShareBox"}
                  onClick={() => window.open(location.detail[0].url, "_self")}
                />
              </div>
              <div className={"sub-site-box"}>
                <div className={"title"}>{location.detail[1].title}</div>
                <div className={"main"}>
                  <span className={"main-detail"}>
                    {location.detail[1].main}
                  </span>
                  <img
                    src={yeonseiImages.ShareBox}
                    alt={"ShareBox"}
                    onClick={() => window.open(location.detail[1].url, "_self")}
                  />
                </div>
              </div>
              <div className={"sub-site-box"}>
                <div className={"title"}>{location.detail[2].title}</div>
                <div className={"main"}>
                  <span className={"main-detail"}>
                    {location.detail[2].main}
                  </span>
                  <img
                    src={yeonseiImages.ShareBox}
                    alt={"ShareBox"}
                    onClick={() => window.open(location.detail[2].url, "_self")}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </OneButtonModal>
    </MainSection>
  );
};

export default index;
