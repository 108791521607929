import styled from "@emotion/styled";
import { Colors } from "../../../../../styles";

export const Container = styled.div`
  z-index: 1050;
  outline: 0;
  background-color: ${Colors.White};
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
  text-align: center;
  padding: 20px 22px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > div.list-wrapper {
    overflow: scroll;
    height: calc(100% - 80px);

    & > div.list-item {
      width: 100%;
      height: 65px;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 4;
      color: ${Colors.Black};
      border-bottom: 1px solid ${Colors.Gray50};
    }
  }

  & > div.list-wrapper::-webkit-scrollbar {
    display: none;
  }
`;
