import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationStyles = {
  pagination: css`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  paginationItem: css`
    display: inline-block;
    cursor: pointer;
    line-height: 24px;
    width: 24px;
    height: 24px;
    margin: 0 2px;
    border-radius: 4px;

    a {
      color: ${Colors.Gray90};
    }
  `,
  previousArrow: css`
    display: inline-block;
    margin-right: 13px;
  `,
  nextArrow: css`
    display: inline-block;
    margin-left: 13px;
  `,
  active: css`
    background-color: ${Colors.PrimaryV01};
    border-radius: 4px;

    a {
      color: ${Colors.White};
    }
  `,
};
