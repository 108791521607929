import React, { useEffect } from "react";
import { css as emotionCss } from "@emotion/react";

import { CenterModalLayout } from "./styles";

/**
 * @description 중앙정렬 모달 레이아웃
 * @param props
 * @param {object} props.data 해당 모달에서 사용하는 데이터들 (path: client/src/component/organism/Modal/handlerModal.jsx)
 * @param {object} props.funcs 해당 모달을 조작하는 함수들 (path: client/src/component/organism/Modal/handlerModal.jsx)
 * @param {String} props.className 해당 모달의 클래스 네임 지정
 * @param {emotionCss} props.css 해당 모달의 커스텀 디자인 (import { css } from "@emotion/react" 필요)
 *
 * @return {JSX.Element}
 */
const index = (props) => {
  const {
    handler,
    className = "",
    css = emotionCss``,
    children,
    pointerEvent = true,
  } = props;
  const { modalRef } = handler;

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener("click", (event) => {
        console.log(event.target.nodeName);
        if (event.target.nodeName === "DIALOG") {
          modalRef.current.close();
        }
      });
    }
  }, [modalRef]);

  return (
    <CenterModalLayout
      className={className}
      css={css}
      ref={modalRef}
      pointerEvent={pointerEvent}
    >
      {children}
    </CenterModalLayout>
  );
};

export default index;
