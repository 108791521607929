import styled from "@emotion/styled";

export const ImgBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & button {
    margin-right: 20px;
  }
`;
