import React from "react";
import { SettingSection } from "./styles";

const index = () => {
  return (
    <SettingSection>
      <div className={"explain-box"}>adfasdfasdfasdfasdfasdfasdf</div>
    </SettingSection>
  );
};

export default index;
