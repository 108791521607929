import React, { useEffect } from "react";

//styles
import { CallDetail, CallList } from "./styles";
import { yeonseiImages } from "../../../styles";

/**
 * @description 통화목록 (이미지 미포함, 고정(phone_nm 유무))
 * @params
 */

//Hooks
import useCallListFunction from "./hooks/useCallListFunction";
import InputForm from "../../../common/component/atom/InputForm";

const index = () => {
  const path = location.pathname;
  const {
    getEventFromAndroid,
    fetchContactPeopleList,
    list,
    fetchUpdateContact,
  } = useCallListFunction();

  useEffect(() => {
    window.addEventListener("getContactFunction", getEventFromAndroid);

    return () => {
      window.removeEventListener("getContactFunction", getEventFromAndroid);
    };
  }, []);

  useEffect(() => {
    fetchContactPeopleList();
  }, []);

  return (
    <>
      <CallList>
        {(list || []).map((item, index) => {
          return (
            <div
              className={"contact-info"}
              onClick={() => {
                if (item.people_no) {
                  location.href = "tel:" + item.people_contact;
                  fetchUpdateContact();
                } else {
                  if (/android/i.test(navigator.userAgent)) {
                    window.android.getInfoFromContact();
                  } else if (
                    !(
                      navigator.userAgent.match(/iPhone|iPad|iPod/i) === null &&
                      navigator.maxTouchPoints !== 5
                    )
                  ) {
                    if (
                      window.webkit &&
                      window.webkit?.messageHandlers &&
                      window.webkit?.messageHandlers?.iosHandler
                    ) {
                      webkit.messageHandlers.iosHandler.postMessage(
                        "getInfoFromContact"
                      );
                    }
                  }
                }
              }}
            >
              <img
                src={
                  yeonseiImages[item.people_no ? "CallListAdded" : "CallList"]
                }
                alt={"call-list"}
              />
              <p className={"text"}>{item.people_nm}</p>
            </div>
          );
        })}
      </CallList>
      {path.includes("/main/safetyPlan") && (
        <CallDetail>
          <p className={"contact-name"}>{list[0].people_nm || ""}</p>
          <InputForm
            placeholder={list[0].people_contact || ""}
            disabled={true}
          />
        </CallDetail>
      )}
    </>
  );
};

export default index;
