import styled from "@emotion/styled";

export const SafetyPlanArticle = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;

  .step-title {
    align-self: flex-start;
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 1.2;
    white-space: pre-wrap;
    margin-left: 10px;
    margin-bottom: 60px;
  }

  .contact-name {
    margin-bottom: 10px;
  }
`;
