import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

import { ButtonWrapper, ContentWrapper, MainSection } from "./styles";

import { Utils } from "../../../../common/util";
import Button from "../../../../common/component/atom/Button";

const index = () => {
  const step = Utils.getParameterByName("step") || "1";
  const navigate = useNavigate();

  return (
    <MainSection>
      <div className={"title"}>치료방법 및 과정</div>
      <ContentWrapper>
        <ButtonWrapper>
          <Button
            variant={step === "1" ? "painted" : "unpainted"}
            size={"small"}
            label={"약물치료"}
            style={css`
              width: 82px;
              height: 32px;
              margin-right: 10px;
            `}
            onClick={() => navigate("?step=1")}
          />
          <Button
            variant={step === "2" ? "painted" : "unpainted"}
            size={"small"}
            label={"비약물적 치료"}
            style={css`
              width: 106px;
              height: 32px;
            `}
            onClick={() => navigate("?step=2")}
          />
        </ButtonWrapper>
        {step === "1" && (
          <>
            <div className={"main-title"}>
              "정신건강의학과 영역에서의
              <br />
              약물치료는 자살 예방에
              <br />
              중요한 요소 입니다.”
            </div>
            <div className={"question"}>약물치료 꼭 필요한가요?</div>
            <div className={"answer"}>
              정신질환의 다양한 증상에 따른 <span>적절한 약물치료</span>는 자살
              예방에 큰 도움이 됩니다.
              <br />
              우울, 불안, 충동성 등 다양한 증상을 보이는 정신질환은 자살과
              연관이 있습니다.
            </div>
            <div className={"question"}>약물치료 얼마나 이어가야 할까요?</div>
            <div className={"answer"}>
              약물치료를 <span>꾸준히 이어가는 것</span>이 중요합니다.
              <br />
              자살 시도자들 중 자살 시도 직전 정신과 약물치료를 임의 중단하는 등
              비순응적이었다는 보고가 있습니다.
            </div>
            <div className={"question"}>약만 제때 챙겨먹으면 되나요?</div>
            <div className={"answer"}>
              약물치료 중 <span>정신질환 증상</span>을 잘 살펴야 합니다.
              <br />
              가령, 우울증의 경우, 항우울제 처방 이후 기분이 회복되지 않은
              상태에서 무기력감이 나아지면서 자살 시도로 이어질 위험이 있습니다.
            </div>
            <div className={"question"}>
              약으로는 해결 할 수 없을 때는 어떻게 하죠?
            </div>
            <div className={"answer"} style={{ marginBottom: "50px" }}>
              필요 시, 정신건강의학과 입원치료의 도움을 받으실 수 있습니다.
            </div>
          </>
        )}
        {step === "2" && (
          <>
            <div className={"main-title"}>
              "자살고위험군을 위한 <br />
              비약물적 치료인 정신치료의
              <br />
              도움을 받아보세요."
            </div>
            <div className={"question"}>정신치료의 종류</div>
            <div className={"answer"}>
              · 행동치료: 문제가 되는 행동을 직접적으로
              <br />
              변화 시키는데 집중하는 치료 (예, 인지행동치료)
              <br />
              <br />
              · 역동정신치료: 자기 이해를 증가시키는데
              <br /> 집중 하는 치료
              <br />
              <br />
              · 지지정신치료: 충고, 주의, 공감 등의 비판단적인
              <br />
              지지에 집중하는 치료
            </div>
            <div className={"question"}>자살고위험군의 자살위험인자</div>
            <div className={"answer"} style={{ marginBottom: "50px" }}>
              · 사회/인지적 자살위험인자 변화: 장기간 지속되는
              <br />
              무력감, 자존감 및 자기효능감 저하 등
              <br />
              <br />
              · 사회/환경적 자살위험인자 변화: 대인관계 문제
              <br />
              해결 능력의 부족, 가정 내 폭력, 양육방식
            </div>
          </>
        )}
      </ContentWrapper>
      <div className={"source"}>
        출처: 국가정신건강센터 국가트라우마센터.
        <br />
        (2021). 재난 정신건강정보-자살과 자살예방.
        <br />
        https://www.nct.go.kr/distMental/rating/rating01_7_3.do
      </div>
    </MainSection>
  );
};

export default index;
