import React, { useEffect, useState } from "react";

//api
import * as RestApi from "../../../common/context/api";

//util
import { Utils } from "../../../common/util";

const index = () => {
  const mberId = Utils.getParameterByName("mberId") || "";
  const appPw = Utils.getParameterByName("appPw") || "";
  const [email, setEmail] = useState({
    mber_id: "",
    crtfc_no: "",
  });
  const [resultMsg, setResultMsg] = useState({
    error_msg: "",
    error: false,
  });

  useEffect(() => {
    fetchVerifyEmail();
  }, []);

  const onChangeValue = (field, value) => {
    setEmail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchVerifyEmail = () => {
    RestApi.post("eVERFI_EMAIL_CREATE", { email_addr: mberId })
      .then((res) => {
        setEmail((prevState) => ({
          ...prevState,
          verify_no: res.verfi_no,
          mber_id: mberId,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchVerifyNumber = () => {
    const api_params = {
      verfi_no: email.verify_no,
      verfi_code: email.crtfc_no,
    };
    RestApi.get("eVERFI_CHECK", api_params)
      .then((res) => {
        const { available, err, msg } = res;
        if (available) {
          setResultMsg({ error_msg: "인증에 성공했습니다.", error: false });
          if (appPw !== "") {
            RestApi.post(
              "eMBER_USER_APP_PASSWORD_FAILED_CNT_AND_NOT_USE_UPDATE"
            ).then(() => {});
          }
        } else {
          setResultMsg({ error_msg: msg, error: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    appPw,
    email,
    resultMsg,
    onChangeValue,
    fetchVerifyNumber,
    fetchVerifyEmail,
  };
};

export default index;
