import React from "react";

// Component

// Styles
import { Emoticons } from "./styles";
import { yeonseiImages } from "../../../styles";

//Const
import { EmoticonImages } from "../../../common/util/const";

/**
 *  @author 박병훈
 *  @description - 이모티콘들
 *  @param {Object} props - 상위 컴포넌트의 props
 *  @param {String} props.css - custom css
 *  @param {String} props.selected - 선택한 기분 이모티콘
 *  @param {Boolean} props.showDescription - 선택한 기분 이모티콘
 *  @param {function} props.onClick - 클릭시 실행되는 함수
 *
 *  @returns {JSX.Element} *
 */
const index = (props) => {
  const {
    css,
    selected = "SmileSmall",
    showDescription = false,
    onClick = (e) => {},
  } = props;

  return (
    <Emoticons css={css}>
      {EmoticonImages.map((image, index) => (
        <div className={"emoticonBox"} onClick={onClick}>
          <img
            key={index}
            alt={image.selected}
            src={
              yeonseiImages[
                image.selected === selected ? image.selected : image.unselected
              ]
            }
          />
          {showDescription && (
            <div className={"description"}>{image.description}</div>
          )}
        </div>
      ))}
    </Emoticons>
  );
};

export default index;
