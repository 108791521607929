import React, { useState } from "react";

import * as RestApi from "../../../common/context/api";

import { DateUtil } from "../../../common/util";

const index = () => {
  const [dayList, setDayList] = useState([]);
  const [dayUnChangeList, setDayUnChangeList] = useState([]);
  const [nearData, setNearData] = useState({
    before: "",
    after: "",
  });

  const fetchCalendarDayStatistic = (date) => {
    RestApi.get("eCALENDAR_USER_DAY_AVG_LIST", {
      date: date,
    })
      .then((res) => {
        const api_list = res.list?.map((el) => ({
          x: DateUtil.format("onlyTime", el.write_dt),
          y: parseInt(el.avg),
        }));
        setDayList(api_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCalendarDayList = (date) => {
    RestApi.get("eCALENDAR_USER_DAY_LIST", { date: date, my_yn: "Y" })
      .then((res) => {
        setDayUnChangeList(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBeforeData = (date) => {
    RestApi.get("eCALENDAR_USER_BEFORE", { date: date, my_yn: "Y" })
      .then((res) => {
        res.list !== [] &&
          setNearData((prevState) => ({
            before: DateUtil.format("dash", res.list[0]?.write_dt),
            after: prevState.after,
          }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAfterData = (date) => {
    RestApi.get("eCALENDAR_USER_AFTER", { date: date })
      .then((res) => {
        res.list !== [] &&
          setNearData((prevState) => ({
            before: prevState.before,
            after: DateUtil.format("dash", res.list[0]?.write_dt),
          }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    dayList,
    nearData,
    dayUnChangeList,
    fetchBeforeData,
    fetchAfterData,
    fetchCalendarDayList,
    fetchCalendarDayStatistic,
  };
};

export default index;
