import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { FeelingNightManageBox } from "./styles";
import { Colors, yeonseiImages } from "../../../../styles";

// Components
import Button from "../../../../common/component/atom/Button";
import Sliders from "../../../../component/atom/Slider";
import HelpLinePopUp from "../HelpLinePopUp";
import {
  handlerModal,
  OneButtonModal,
} from "../../../../component/organism/Modal";

// Hooks
import useOutsideClick from "../../../../hooks/useOutsideClick";

const index = (props) => {
  const { timeNo, params, onChangeValue, fetchCalendarCreate } = props;
  const navigate = useNavigate();
  const emergencyModalHandler = handlerModal();

  return (
    <FeelingNightManageBox timeNo={timeNo}>
      <div className={"title"}>
        {"오늘 하루를 마무리하며\n내게 일어난 자살생각 정도를 기록해 볼까요?"}
      </div>
      <div className={"description"}>
        <span>0%</span>
        나는 자살을 생각하지 않는다.
        <br />
        <span>100%</span>
        나는 자살을 할 것이다.
      </div>
      <div className={"trialSlider"}>
        <Sliders
          labelMin={"0%"}
          labelMax={"100%"}
          onChangeValue={onChangeValue}
          value={params.suicide}
          field={"suicide"}
        />
      </div>
      <div className={"selectTrial"}>
        <div className={"title"}>오늘 자살 시도 계획</div>
        <div className={"buttonBox"}>
          <Button
            label={"있다"}
            variant={params.suicide_plan === "Y" ? "painted" : "unpainted"}
            size={"medium"}
            style={css`
              font-size: 1.25rem;
              font-weight: 600;
              margin-right: 20px;
            `}
            onClick={() => onChangeValue("suicide_plan", "Y")}
          />
          <Button
            label={"없다"}
            variant={params.suicide_plan === "N" ? "painted" : "unpainted"}
            size={"medium"}
            style={css`
              font-size: 1.25rem;
              font-weight: 600;
            `}
            onClick={() => onChangeValue("suicide_plan", "N")}
          />
        </div>
      </div>
      <div className={"selectTrial"}>
        <div className={"title"}>오늘 자살 시도 행위</div>
        <div className={"buttonBox"}>
          <Button
            label={"있다"}
            variant={params.suicide_try === "Y" ? "painted" : "unpainted"}
            size={"medium"}
            style={css`
              font-size: 1.25rem;
              font-weight: 600;
              margin-right: 20px;
            `}
            onClick={() => onChangeValue("suicide_try", "Y")}
          />
          <Button
            label={"없다"}
            variant={params.suicide_try === "N" ? "painted" : "unpainted"}
            size={"medium"}
            style={css`
              font-size: 1.25rem;
              font-weight: 600;
            `}
            onClick={() => onChangeValue("suicide_try", "N")}
          />
        </div>
      </div>
      <div className={"buttonBox"}>
        <Button
          label={"저장"}
          variant={"painted"}
          size={"xLarge"}
          style={css`
            font-size: 1.25rem;
            font-weight: 600;
            background-color: ${Colors.Primary};
          `}
          onClick={() => {
            emergencyModalHandler.onToggleModal();
          }}
        />
      </div>
      <OneButtonModal
        handler={emergencyModalHandler}
        buttonClick={() => {
          fetchCalendarCreate();
          navigate("/main/feelingCalendar");
        }}
        label={
          params.suicide >= 25 ||
          params.suicide_plan === "Y" ||
          params.suicide_try === "Y"
            ? "알고 있습니다."
            : "확인"
        }
      >
        {params.suicide >= 25 ||
        params.suicide_plan === "Y" ||
        params.suicide_try === "Y" ? (
          <HelpLinePopUp onChangeValue={onChangeValue} />
        ) : (
          <div className={"in-modal-box"}>
            <img src={yeonseiImages.CheckAbleLarge} alt={"check-icon"} />
            지금 당신의 기분작성이
            <br /> 완료되었습니다.
            <br />
            오늘도 고생 많으셨습니다.
          </div>
        )}
      </OneButtonModal>
    </FeelingNightManageBox>
  );
};

export default index;
