import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

//Styles
import { QnaSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Components
import TextArea from "../../../../common/component/atom/TextArea";
import Button from "../../../../common/component/atom/Button";
import InputForm from "../../../../common/component/atom/InputForm";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//Hook
import useQna from "../../hooks/useQna";
import ImgBox from "../../component/ImgBox";

const index = () => {
  const [isClicked, setIsClicked] = useState(true);
  const navigate = useNavigate();
  const oneButtonModalHandler = handlerModal();
  const { params, check, onChangeValue, fetchQnaCreate } = useQna();

  useEffect(() => {}, [params]);

  return (
    <QnaSection>
      <div className={"title"}>문의 제목을 입력해주세요.</div>
      <InputForm
        value={params.inqry_nm}
        onChange={(e) => onChangeValue("inqry_nm", e.target.value)}
      />
      <div className={"title"} style={{ marginTop: "10px" }}>
        문의 유형을 알려주세요.
      </div>
      <div className={"select-box"}>
        <img
          src={isClicked ? yeonseiImages.RadioAble : yeonseiImages.RadioDisable}
          alt={"radio"}
          onClick={() =>
            !isClicked &&
            (setIsClicked(!isClicked), onChangeValue("inqry_typ_cd", isClicked))
          }
        />
        <span>앱 사용관련</span>
        <img
          src={isClicked ? yeonseiImages.RadioDisable : yeonseiImages.RadioAble}
          alt={"radio"}
          onClick={() =>
            isClicked &&
            (setIsClicked(!isClicked), onChangeValue("inqry_typ_cd", isClicked))
          }
        />
        <span>정신건강 관련</span>
      </div>

      <div className={"input-box"}>
        <div className={"title"}>문의 사항을 알려주세요.</div>
        <TextArea
          value={params.inqry_cont}
          onChange={(e) => onChangeValue("inqry_cont", e.target.value)}
          placeholder={
            "해당 질문 내용은 비공개이며\n" +
            "평일은 24시간 이내, 업무 시간에 답변 받을 수 있습니다."
          }
          maxLength={500}
          style={css`
            flex: 1;
            width: 100%;
            height: 210px;
            resize: none;
            margin-bottom: 20px;
          `}
        />
      </div>
      <div className={"title"}>사진 추가</div>
      <ImgBox params={params} onChangeValue={onChangeValue} />
      <div className={"sub-title"}>최대 3장까지 가능</div>
      <Button
        variant={"painted"}
        label={"문의하기"}
        size={"xLarge"}
        style={css`
          font-size: 1.25rem;
          font-weight: 600;
          width: 100%;
          margin-top: 30px;
        `}
        onClick={() => {
          fetchQnaCreate();
          oneButtonModalHandler.onToggleModal();
        }}
      />
      <OneButtonModal
        handler={oneButtonModalHandler}
        label={"확인"}
        buttonClick={() =>
          navigate(check ? "/main/main" : oneButtonModalHandler.onToggleModal())
        }
      >
        <div className={"in-modal-box"}>
          {check ? (
            <img src={yeonseiImages.CheckAbleLarge} alt={"icon"} />
          ) : (
            <img src={yeonseiImages.ExclamationMarkMiddle} alt={"icon"} />
          )}
          {check ? "문의가 작성되었습니다" : "필수값을 입력하세요"}
        </div>
      </OneButtonModal>
    </QnaSection>
  );
};

export default index;
