import React, { useId } from "react";
import { css, cx } from "@emotion/css";

// Styles
import Icon from "../Icon";
import {Colors, Icons, yeonseiImages} from "../../../../styles";
import { RadioBox, RadioBoxSizes } from "./styles";

const index = (props) => {
  const {
    field,
    value,
    options,
    variant,
    style,
    onChange,
    checkImage = "CheckBorderAble",
    unCheckImage = "CheckBorderDisable",
  } = props;
  const radioKey = useId();

  return (
    <RadioBox className={cx(RadioBoxSizes[variant || "medium"], style)}>
      {options.map((item) => {
        const checked = value === item.value;
        return (
          <label
            key={`${radioKey}_${field}_${item.value}`}
            htmlFor={`${radioKey}_${field}_${item.value}`}
          >
            <img
                alt={value + "_check"}
                src={
                  checked ? yeonseiImages[checkImage] : yeonseiImages[unCheckImage]
                }
            />
            <input
              id={`${radioKey}_${field}_${item.value}`}
              type={"radio"}
              checked={checked}
              onChange={() => onChange(item.value)}
              disabled={item.disabled}
            />
            {item.label}
          </label>
        );
      })}
    </RadioBox>
  );
};

export default index;
