import React, { useState } from "react";
import { cx, css } from "@emotion/css";

// Styles
import { SignUpInputArticle } from "./styles";
import { Colors, Icons, yeonseiImages } from "../../../../styles";

// Components
import Button from "../../../../common/component/atom/Button";
import InputForm from "../../../../common/component/atom/InputForm";
import RadioBox from "../../../../common/component/atom/RadioBox";
import {
  handlerModal,
  ListBottomModal,
  OneButtonModal,
} from "../../../../component/organism/Modal";

//Config
import { cityList_config, email_config } from "../../../../common/util/const";

const index = (props) => {
  const {
    isDisplay,
    params,
    errList,
    onChange,
    handleValidation,
    fetchSignUp,
    checkEmailValidate,
  } = props;
  const ListBottomModalHandler = handlerModal();
  const EmailBottomModalHandler = handlerModal();
  const oneButtonModalHandler = handlerModal();
  const finalButtonModalHandler = handlerModal();
  const [checked, setChecked] = useState();
  const gender = {
    field: "mber_sex_cd",
    value: checked,
    opt: [
      {
        value: "SC000001",
        label: "남성",
      },
      {
        value: "SC000002",
        label: "여성",
      },
    ],
  };

  const handleChangeGender = (fieldName, value) => {
    setChecked(value);
    onChange("mber_sex_cd", value);
  };

  return (
    <SignUpInputArticle isDisplay={isDisplay}>
      <div
        className={cx(
          "small-title",
          css`
            margin-top: 0 !important;
          `
        )}
      >
        닉네임
      </div>
      <InputForm
        placeholder={"닉네임을 입력해주세요"}
        value={params.mber_nickname}
        onChange={(e) => onChange("mber_nickname", e.target.value)}
      />
      <div className={"small-title"}>이메일</div>
      <div className={"email-wrapper"}>
        <InputForm
          placeholder={"이메일을 입력해주세요"}
          value={params.email_local}
          onChange={(e) => onChange("email_local", e.target.value)}
          isHalf={"Y"}
        />
        <div className={"at-sign"}>@</div>
        <InputForm
          placeholder={"도메인을 입력해주세요"}
          value={params.email_domain}
          onChange={(e) => onChange("email_domain", e.target.value)}
          isHalf={"Y"}
        />
        <Button
          variant={"select"}
          size={"icon"}
          img={yeonseiImages.ArrowDown}
          onClick={() => EmailBottomModalHandler.onToggleModal()}
          style={css`
            border: none;
            padding: 2px;
          `}
        />
      </div>
      <Button
        label={"중복확인"}
        variant={"unpainted"}
        style={css`
          margin-top: 20px;
          font-size: 1.25rem;
          height: 55px;
        `}
        size={"xLarge"}
        onClick={() => {
          checkEmailValidate();
          setTimeout(() => oneButtonModalHandler.onToggleModal(), "300");
        }}
      />
      <div className={"small-title"}>비밀번호</div>
      <form>
        <InputForm
          placeholder={
            "영문, 숫자 혼합 8자리 이상 32자리 이하 입력(특수문자 불가)"
          }
          type={"password"}
          value={params.mber_pw}
          maxLength={32}
          err_yn={!errList.mber_pw && "Y"}
          help_msg={!errList.mber_pw && "올바른 비밀번호를 입력해주세요."}
          onChange={(e) => onChange("mber_pw", e.target.value)}
          onBlur={(e) => handleValidation("mber_pw", e.target.value)}
          style={css`
            margin-bottom: 10px;
          `}
        />
      </form>
      <div className={"warning-text"}>
        유추가 쉬운 단어 예)love, happy등은 제외하고 입력해주세요.
      </div>
      <form>
        <InputForm
          placeholder={"비밀번호를 다시 입력해주세요"}
          type={"password"}
          value={params.pw_check}
          maxLength={32}
          err_yn={!errList.pw_check && "Y"}
          help_msg={!errList.pw_check && "비밀번호가 일치하지 않습니다."}
          onChange={(e) => onChange("pw_check", e.target.value)}
          onBlur={(e) => handleValidation("pw_check", e.target.value)}
        />
      </form>
      <div className={"small-title"}>성별</div>
      <RadioBox
        variant={"large"}
        value={gender.value}
        options={gender.opt}
        onChange={(e) => handleChangeGender(gender.field, e)}
      />
      <div className={"small-title"}>만 나이</div>
      <InputForm
        placeholder={"만 나이를 입력해주세요."}
        type={"number"}
        pattern={"[0-9]+"}
        value={params.mber_age}
        onChange={(e) => onChange("mber_age", e.target.value)}
      />
      <div className={"small-title"}>지역</div>
      <Button
        label={ListBottomModalHandler.params.mber_loc || "선택옵션"}
        variant={"select"}
        size={"mediumLarge"}
        icon={ListBottomModalHandler.params.mber_loc ? null : Icons.expandMore}
        iconStyle={css`
          margin-left: 250px;
        `}
        style={css`
          color: ${ListBottomModalHandler.params.mber_loc
            ? Colors.Black
            : Colors.Gray60};
        `}
        onClick={() => ListBottomModalHandler.onToggleModal()}
      />
      <Button
        label={"가입하기"}
        variant={"painted"}
        size={"xLarge"}
        style={css`
          bottom: 0;
          margin-top: 120px;
          font-size: 1.25rem;
          font-weight: 600;
          background-color: ${Object.values(params).every(Boolean) &&
          !Object.values(errList).includes(false)
            ? Colors.Primary
            : Colors.Disable};
        `}
        onClick={() =>
          Object.values(params).every(Boolean) &&
          !Object.values(errList).includes(false)
            ? fetchSignUp()
            : finalButtonModalHandler.onToggleModal()
        }
      />
      <ListBottomModal
        handler={ListBottomModalHandler}
        button
        list={cityList_config}
        onChange={(item) => {
          ListBottomModalHandler.onChangeModalValue(
            "mber_loc",
            item.value,
            true
          );
          onChange("mber_loc", item.value);
        }}
      />
      <ListBottomModal
        handler={EmailBottomModalHandler}
        button
        list={email_config}
        onChange={(item) => {
          EmailBottomModalHandler.onChangeModalValue(
            "email_domain",
            item.value,
            true
          );
          onChange("email_domain", item.value);
        }}
      />
      <OneButtonModal handler={oneButtonModalHandler} label={"닫기"}>
        <div className={"in-modal-box"}>
          <img
            src={
              errList.verify === true
                ? yeonseiImages.CheckAbleLarge
                : yeonseiImages.ExclamationMarkMiddle
            }
            alt={"check-duplicate"}
          />
          {errList.mber_id === false
            ? "올바른 이메일을\n입력하세요."
            : errList.verify === true
            ? "사용 가능한 이메일입니다."
            : "이미 사용된 이메일입니다."}
        </div>
      </OneButtonModal>
      <OneButtonModal
        handler={finalButtonModalHandler}
        buttonClick={() => {
          finalButtonModalHandler.onToggleModal();
        }}
      >
        <div className={"in-modal-box"}>
          <img
            src={yeonseiImages.ExclamationMarkMiddle}
            alt={"ExclamationMark"}
          />
          필수값이 틀렸습니다.
          <br /> 다시 한번만 확인해주세요.
        </div>
      </OneButtonModal>
    </SignUpInputArticle>
  );
};

export default index;
