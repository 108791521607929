import React, { useState } from "react";
import { css } from "@emotion/css";

import { SampleMain } from "./styles";
import { Colors, Icons, yeonseiImages } from "../../../../styles";

// Common
import Button from "../../../../common/component/atom/Button";
import RadioBox from "../../../../common/component/atom/RadioBox";
import InputForm from "../../../../common/component/atom/InputForm";
import Select from "../../../../common/component/atom/Select";
import Pagination from "../../../../common/component/atom/Pagination";
import Datepicker from "../../../../common/component/atom/Datepicker";
import DatePeriodShort from "../../../../common/component/atom/template/DatePeriodShort";
import DatePeriodWide from "../../../../common/component/atom/template/DatePeriodWide";
import TabMenuBox from "../../../../common/component/molecule/TabMenuBox";
import Icon from "../../../../common/component/atom/Icon";

//Config
import { cityList_config } from "../../../../common/util/const";

// Component
import FileBoxWithImgList from "../../../../component/molecule/FileBoxWithImgList";
import CheckConfirmBox from "../../../../component/atom/CheckConfirmBox";
import Checkbox from "../../../../component/atom/Checkbox";
import Sliders from "../../../../component/atom/Slider";
import ComponentWithTitle from "../../../../component/molecule/ComponentWithTitle";
import {
  handlerModal,
  ListBottomModal,
  OneButtonModal,
  TermModal,
  TwoButtonModal,
} from "../../../../component/organism/Modal";

const index = () => {
  const [selectedTab, setSelectedTab] = useState("tab2");
  const [select, setSelect] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const termModalHandler = handlerModal();
  const oneButtonModalHandler = handlerModal();
  const twoButtonModalHandler = handlerModal();
  const BottomModalHandler = handlerModal();

  const [params, setParams] = useState({
    happiness: "",
    depression: "",
    anxiety: "",
    emotion_stblty: "",
    stress: "",
  });

  return (
    <SampleMain>
      <section>
        <div>
          <h4>아이콘</h4>
          {Object.keys(Icons).map((icon) => {
            return (
              <div
                className={css`
                  display: flex;
                `}
              >
                <Icon icon={Icons[icon]} />
                {icon}
              </div>
            );
          })}
        </div>
        <div>
          <h4>컬러</h4>
          {Object.keys(Colors).map((color) => {
            return (
              <div
                className={css`
                  display: flex;
                  color: ${Colors[color]};
                  font-size: 1rem;
                `}
              >
                {color}
              </div>
            );
          })}
        </div>
        <div>
          <h4>버튼 size = xLarge</h4>
          <Button label={"로그인"} variant={"painted"} size={"xLarge"} />
          <Button label={"회원가입"} variant={"unpainted"} size={"xLarge"} />
          <h4>버튼 size = large</h4>
          <Button
            label={"응급 연락처 목록 버튼"}
            variant={"emergency"}
            size={"large"}
          />
          <h4>버튼 size = medium</h4>
          <Button label={"수정"} variant={"painted"} size={"medium"} />
          <Button label={"수정"} variant={"unpainted"} size={"medium"} />
          <h4>버튼 size = small</h4>
          <Button label={"수정"} variant={"painted"} size={"small"} />
          <Button label={"수정"} variant={"unpainted"} size={"small"} />
          <h4>버튼 size = small & icon</h4>
          <Button
            label={"버튼입니다"}
            variant={"painted"}
            size={"large"}
            icon={Icons.add}
          />

          <h4>체크 확인 박스</h4>
          <CheckConfirmBox />
          <h4>전문가 도움</h4>
          <div className={"call-box"}>
            <Button
              img={yeonseiImages.SuicidePrevision}
              variant={"call"}
              size={"call"}
            />
            <Button
              img={yeonseiImages.MentalHealth}
              variant={"call"}
              size={"call"}
            />
            <Button
              img={yeonseiImages.Lifeline}
              variant={"call"}
              size={"call"}
            />
            <Button
              img={yeonseiImages.EmergencyRescue}
              variant={"call"}
              size={"call"}
            />
          </div>
        </div>
        <div>
          <h4>중앙정렬 모달 = 약관</h4>
          <Button
            label={"약관 모달"}
            variant={"unpainted"}
            size={"large"}
            icon={Icons.openInNew}
            onClick={() => termModalHandler.onToggleModal()}
          />
          <h4>중앙정렬 모달 = 원 버튼</h4>
          <Button
            label={"원버튼 모달"}
            variant={"unpainted"}
            size={"large"}
            icon={Icons.openInNew}
            onClick={() => oneButtonModalHandler.onToggleModal()}
          />
          <h4>중앙정렬 모달 = 투 버튼</h4>
          <Button
            label={"투버튼 모달"}
            variant={"unpainted"}
            size={"large"}
            icon={Icons.openInNew}
            onClick={() => twoButtonModalHandler.onToggleModal()}
          />
          <h4>하단 리스트 모달</h4>
          <Button
            label={"바텀 모달"}
            variant={"unpainted"}
            size={"large"}
            icon={Icons.openInNew}
            onClick={() => BottomModalHandler.onToggleModal()}
          />

          <TermModal handler={termModalHandler} />
          <OneButtonModal handler={oneButtonModalHandler} label={"원버튼 라벨"}>
            <div>원버튼 라벨 칠드런</div>
          </OneButtonModal>
          <TwoButtonModal
            handler={twoButtonModalHandler}
            topButtonLabel={"투버튼 라벨 위"}
            bottomButtonLabel={"투버튼 라벨 바닥"}
          >
            <div>투버튼 라벨 칠드런</div>
          </TwoButtonModal>
          <ListBottomModal
            handler={BottomModalHandler}
            button
            list={cityList_config}
          />
        </div>
        <div className={"component_column"}>
          <h4>Input 버튼</h4>
          <InputForm value={"텍스트 입력"} />
          <InputForm placeholder={"텍스트를 입력하세요."} />
          <InputForm placeholder={"텍스트를 입력하세요."} disabled={true} />
          <InputForm value={"텍스트를 입력하세요."} err_yn={"Y"} />
          <InputForm placeholder={"텍스트를 입력하세요."} success_yn={"Y"} />
          <InputForm
            placeholder={"텍스트를 입력하세요."}
            timer_yn={"Y"}
            timer_text={"05:20"}
          />
          <InputForm
            placeholder={"텍스트를 입력하세요."}
            help_msg={"help_msg"}
            err_yn={"Y"}
          />
          <h4>Placeholder_삭제</h4>
          <InputForm placeholder={"예) 집 근처 산책하기"} remove_yn={"Y"} />
        </div>
      </section>
      <section>
        <div className={"component-row"}>
          <h4>키패드</h4>
          <Button label={"1"} variant={"keypad"} size={"keypad"} />
          <Button label={"2"} variant={"keypad"} size={"keypad"} />
          <Button label={"3"} variant={"keypad"} size={"keypad"} />
          <Button
            variant={"keypad"}
            size={"keypad"}
            img={yeonseiImages.Backspace}
          />

          <h4>이미지 추가</h4>
          <FileBoxWithImgList
            label={{ main: "", sub: "" }}
            list={[]}
            item={[]}
            limit={3}
          />
        </div>
        <div className={"component-row"}>
          <h4>체크박스 = paintedXlarge</h4>
          <Checkbox
            label={"ID(이메일)를 입력해 주세요. "}
            variant={"paintedXlarge"}
            checked={true}
          />
          <Checkbox
            label={"ID(이메일)를 입력해 주세요. "}
            variant={"paintedXlarge"}
            checked={false}
          />
          <h4>체크박스 = small</h4>
          <Checkbox
            label={"ID(이메일)를 입력해 주세요. "}
            variant={"small"}
            checked={true}
          />
          <Checkbox
            label={"ID(이메일)를 입력해 주세요. "}
            variant={"small"}
            checked={false}
          />
        </div>
        <div className={"component-row"}>
          <h4>라디오 size = medium</h4>
          <RadioBox
            value={"Checked"}
            options={[
              {
                value: "Checked",
                label: "Checked",
              },
              {
                value: "Unchecked",
                label: "Unchecked",
              },
              {
                value: "Disabled",
                label: "Disabled",
                disabled: true,
              },
            ]}
          />
          <h4>라디오 size = small</h4>
          <RadioBox
            variant={"small"}
            value={"Checked"}
            options={[
              {
                value: "Checked",
                label: "Checked",
              },
              {
                value: "Unchecked",
                label: "Unchecked",
              },
              {
                value: "Disabled",
                label: "Disabled",
                disabled: true,
              },
            ]}
          />
        </div>
        <div className={"component-row"}>
          <h4>셀렉트박스 size = large</h4>
          <div>
            <Select
              value={select}
              placeholder={"선택하세요."}
              options={[
                { label: "오렌지", value: "orange" },
                { label: "사과", value: "apple" },
                { label: "바나나", value: "banana" },
              ]}
              size={"large"}
              onChange={(value) => setSelect(value)}
            />
          </div>
          <h4>셀렉트박스 size = small</h4>
          <div>
            <Select
              value={select}
              placeholder={"선택하세요."}
              options={[
                { label: "오렌지", value: "orange" },
                { label: "사과", value: "apple" },
                { label: "바나나", value: "banana" },
              ]}
              size={"small"}
              onChange={(value) => setSelect(value)}
            />
          </div>
          <h4>셀렉트박스 extraOptions</h4>
          <div>
            <Select
              value={select}
              placeholder={"선택하세요."}
              options={[
                { label: "오렌지", value: "orange" },
                { label: "사과", value: "apple" },
                { label: "바나나", value: "banana" },
              ]}
              extraOptions={[
                {
                  label: (
                    <>
                      <Icon
                        icon={Icons.search}
                        color={Colors.Gray60}
                        size={"12px"}
                      />
                      최신순
                    </>
                  ),
                  onClick: () => {},
                },
                {
                  label: "최신순 text",
                  onClick: () => {},
                },
              ]}
              onChange={(value) => setSelect(value)}
            />
          </div>
        </div>
      </section>
      <section>
        <ComponentWithTitle title={{ main: "행복함" }}>
          <Sliders
            labelMin={"0%"}
            labelMax={"100%"}
            onChangeValue={(field, value) =>
              setParams((prevState) => ({ ...prevState, [field]: value }))
            }
            value={params["happiness"]}
            field={"happiness"}
          />
        </ComponentWithTitle>
        <ComponentWithTitle title={{ main: "우울함" }}>
          <Sliders
            labelMin={"전혀 아님"}
            labelMax={"매우 그러함"}
            onChangeValue={(field, value) =>
              setParams((prevState) => ({ ...prevState, [field]: value }))
            }
            value={params["depression"]}
            field={"depression"}
          />
        </ComponentWithTitle>
      </section>
      <section>
        <div style={{ backgroundColor: Colors.Gray20 }}>
          <Pagination
            handler={{
              params: {
                skip: 0,
                limit: 20,
              },
              count: 100,
              onChangePage: () => {},
            }}
          />
          <Datepicker onChange={(date) => {}} />
          <DatePeriodShort
            params={{}}
            from={{
              field: "",
              format: "YYYY-mm-dd",
            }}
            to={{
              field: "",
              format: "YYYY-mm-dd",
            }}
          />
          <DatePeriodWide
            params={{}}
            from={{
              field: "",
              format: "YYYY-mm-dd",
            }}
            to={{
              field: "",
              format: "YYYY-mm-dd",
            }}
            from_to={{
              field: "date_from_to",
              chk_opt: [
                { name: "오늘", value: 0, unit: "days" },
                { name: "내일", value: 1, unit: "days" },
                { name: "일주일", value: 7, unit: "days" },
                { name: "한달", value: 1, unit: "month" },
              ],
            }}
          />
        </div>
        <div style={{ width: "450px" }}>
          <TabMenuBox
            tabSize={"small"}
            size={"middle"}
            tabs={[
              { label: "AM", value: "tab6" },
              { label: "PM", value: "tab7" },
            ]}
            value={selectedTab}
            onChangeValue={(data) => setSelectedTab(data.value)}
          />
          <div />
          <TabMenuBox
            tabSize={"middle"}
            size={"small"}
            tabs={[
              { label: "상세", value: "tab1" },
              { label: "통계", value: "tab2" },
            ]}
            value={selectedTab}
            onChangeValue={(data) => setSelectedTab(data.value)}
          />
          <div />
          <TabMenuBox
            size={"large"}
            tabs={[
              { label: "일주일", value: "tab3" },
              { label: "2주", value: "tab4" },
              { label: "한달", value: "tab5" },
            ]}
            value={selectedTab}
            onChangeValue={(data) => setSelectedTab(data.value)}
          />
        </div>
      </section>
    </SampleMain>
  );
};

export default index;
