import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const CalendarWrapper = styled.div`
  .dot {
    position: absolute;
    top: 21px;
    right: 0;
    width: 6px;
    height: 6px;
    background: linear-gradient(90deg, #f09278 0%, #ee7c5b 86.98%);
    border-radius: 50%;
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: ${Colors.White};

    font-family: Pretendard, serif;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125em;
    color: ${Colors.Gray80};
  }

  .react-calendar--doubleView {
    width: 700px;
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    margin-bottom: 30px;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }

  .react-calendar__navigation button:disabled {
    background-color: #ffffff;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #ffffff;
  }

  // navigation custom
  & div.navigation_custom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div.calendarDate {
      display: flex;
      align-items: center;

      margin-right: 17px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 15px;

      & > img {
        margin-left: 4px;
      }
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 40px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0 1px;

    & > abbr {
      color: ${Colors.Gray80};
      text-decoration: none;
    }
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }

  .react-calendar__month-view__days__day {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-bottom: 0;

    & > div.tileDate {
      font-weight: 700;
      color: ${Colors.Gray80};
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day--weekend {
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 16px;

    position: relative;

    & > img {
      width: 41px;
      height: 41px;
      margin-top: 9px;
      margin-bottom: 10px;
    }
  }

  .react-calendar__tile:disabled {
    background-color: ${Colors.White};
    display: none;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
  }

  .react-calendar__tile--now {
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
  }

  .react-calendar__tile--hasActive {
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
  }

  .react-calendar__tile--active {
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    & div {
      width: 23px;
      height: 16px;
      line-height: 1.3;

      color: ${Colors.White};
      background: ${Colors.Primary};
      border-radius: 5px;
    }
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
  }
`;
