import { Colors } from "../../../../styles";
import { css } from "@emotion/css";

export const DatepickerStyles = css`
  display: flex;
  align-items: center;
  background-color: transparent;

  & input {
    width: 200px;
    height: 32px;
    line-height: 32px;
    background-color: transparent;
    border: none;
    font-weight: 600;
    font-size: 1.063rem;
    color: ${Colors.Gray90};
    text-align: center;

    ::placeholder {
      color: ${Colors.Gray90};
    }

    &:focus {
      outline: none;
    }
  }

  .react-datepicker-popper {
    inset: 3px auto auto -20px;
  }

  & .react-datepicker-popper {
    z-index: 99;
    inset: 3px auto auto -20px;

    &[data-placement^="bottom"] {
      padding-top: 0;
    }

    & .react-datepicker {
      width: 350px;
      border-radius: 4px;
      overflow: hidden;
      border: none;
      /* prettier-ignore */
      box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 3px 6px -4px rgb(0 0 0 / 12%),
      0 9px 28px 8px rgb(0 0 0 / 5%);

      &.react-datepicker--time-only {
        width: 80px;
        margin-left: 95px;
      }

      & .react-datepicker__triangle {
        display: none;
      }

      & .react-datepicker__navigation {
      }

      & .react-datepicker__navigation--previous {
        color: ${Colors.Black};
      }

      & .react-datepicker__navigation--next {
        color: ${Colors.Black};
      }

      & .react-datepicker__header {
        background-color: ${Colors.Primary10};
        padding: 10px;
        border-bottom: 2px solid ${Colors.Primary};
      }

      & .react-datepicker__month-container {
        display: inline-block;
        color: ${Colors.Gray80};
        width: 75%;
      }

      & .react-datepicker__time-container {
        display: inline-block;
        color: ${Colors.Gray80};
        width: 100%;

        & .react-datepicker__time-box {
          width: 100%;
        }
      }

      & .react-datepicker__ & .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected,
      .react-datepicker__day--selected {
        border-radius: 50%;
        background-color: ${Colors.Primary};
        color: ${Colors.White};
      }

      & .react-datepicker__day--keyboard-selected:hover,
      .react-datepicker__month-text--keyboard-selected:hover,
      .react-datepicker__quarter-text--keyboard-selected:hover,
      .react-datepicker__year-text--keyboard-selected:hover {
        background-color: ${Colors.Primary};
        border-radius: 50%;
        color: ${Colors.White};
      }
    }

    & .react-datepicker__time-list-item {
      &:hover {
        background-color: ${Colors.Primary};
        color: ${Colors.White};
      }
    }
  }
`;
