import React from "react";
import { css } from "@emotion/css";
import { useNavigate } from "react-router";

//component
import InputForm from "../../../../common/component/atom/InputForm";
import Button from "../../../../common/component/atom/Button";
import useProfileManage from "../../hooks/useProfileManage";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//style
import { MyPageSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = () => {
  const {
    errList,
    params,
    changePassword,
    errorMessage,
    onChangeValue,
    checkUserPassword,
    handleValidation,
    fetchChangePassword,
  } = useProfileManage();
  const oneButtonModalHandler = handlerModal();
  const changeCheckButtonModalHandler = handlerModal();
  const navigate = useNavigate();

  return (
    <MyPageSection>
      {!errList.verified && (
        <>
          <div className={"input-box"}>
            <div className={"title"}>기존 비밀번호</div>
            <InputForm
              placeholder={"기존 비밀번호를 입력하세요."}
              type={"password"}
              value={params.mber_pw}
              onChange={(e) => onChangeValue("mber_pw", e.target.value)}
            />
          </div>
          <Button
            variant={"unpainted"}
            label={"비밀번호확인"}
            size={"xLarge"}
            style={css`
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 17px;
              margin-bottom: 20px;
            `}
            onClick={() => {
              checkUserPassword();
              oneButtonModalHandler.onToggleModal();
            }}
          />
        </>
      )}
      {errList.verified && (
        <>
          <div className={"input-box"}>
            <div className={"title"}>신규 비밀번호</div>
            <InputForm
              placeholder={
                "영문, 숫자 혼합 8자리 이상 32자리 이하 입력(특수문자 불가)"
              }
              type={"password"}
              maxLength={32}
              err_yn={!errList.new_mber_pw && "Y"}
              help_msg={
                !errList.new_mber_pw && "올바른 비밀번호를 입력해주세요."
              }
              onChange={(e) => onChangeValue("new_mber_pw", e.target.value)}
              onBlur={(e) => handleValidation("new_mber_pw", e.target.value)}
              value={params.new_mber_pw}
            />
            <InputForm
              placeholder={"비밀번호를 다시 입력해주세요."}
              value={params.pw_check}
              type={"password"}
              maxLength={32}
              err_yn={!errList.pw_check && "Y"}
              help_msg={!errList.pw_check && "비밀번호가 일치하지 않습니다."}
              onChange={(e) => onChangeValue("pw_check", e.target.value)}
              onBlur={(e) => handleValidation("pw_check", e.target.value)}
            />
          </div>
          <Button
            variant={"painted"}
            label={"수정"}
            size={"xLarge"}
            style={css`
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 17px;
            `}
            onClick={() => {
              !Object.values(errList).includes(false) &&
                params.new_mber_pw !== "" &&
                params.pw_check !== "" &&
                fetchChangePassword();
              changeCheckButtonModalHandler.onToggleModal();
            }}
          />
        </>
      )}
      <OneButtonModal handler={oneButtonModalHandler}>
        <div className={"in-modal-box"}>
          <img
            src={
              errList.verified
                ? yeonseiImages.CheckAbleLarge
                : yeonseiImages.ExclamationMarkMiddle
            }
            alt={"icon"}
          />
          {errList.verified
            ? "비밀번호가 일치합니다."
            : "비밀번호가 틀렸습니다.\n다시 확인해주세요."}
        </div>
      </OneButtonModal>
      <OneButtonModal
        handler={changeCheckButtonModalHandler}
        buttonClick={() =>
          setTimeout(function () {
            changePassword
              ? navigate("/myPage/myPage/")
              : changeCheckButtonModalHandler.onToggleModal();
          }, 100)
        }
      >
        <div className={"in-modal-box"}>
          <img
            src={
              changePassword
                ? yeonseiImages.CheckAbleLarge
                : yeonseiImages.ExclamationMarkMiddle
            }
            alt={"icon"}
          />
          {changePassword
            ? "비밀번호 변경이\n완료되었습니다."
            : errorMessage !== ""
            ? "기본 비밀번호와 변경 비밀번호가\n 동일합니다."
            : "입력오류\n비밀번호나 중복확인을\n다시 확인해주세요."}
        </div>
      </OneButtonModal>
    </MyPageSection>
  );
};

export default index;
