import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as RestApi from "../../../common/context/api";

const index = () => {
  const mber_no = useSelector((state) => state?.data.eSESSION.account?.mber_no);
  const eSESSION = useSelector((state) => state?.data.eSESSION) || {};
  const [detail, setDetail] = useState({});
  const [mainDetail, setMainDetail] = useState({});
  const [count, setCount] = useState({});

  useEffect(() => {
    fetchUserDetail();
    fetchMainDetail();
    fetchInqryCount();
  }, []);

  useEffect(() => {
    if (
      Object.keys(detail).length > 0 &&
      (eSESSION.account?.mber_img_url !== detail.mber_img_url ||
        eSESSION.account?.mber_bg_img_url !== detail.mber_bg_img_url)
    ) {
      fetchUpdateDetail();
    }
  }, [detail]);

  const onChangeValue = (field, value) => {
    setDetail((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchUpdateContact = () => {
    const api_params = {
      contact_hist_typ_cd: "CHTC00003",
      contact_emergency_yn: "Y",
    };
    RestApi.post("eCONTACT_HIST_USER_CREATE", api_params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserDetail = () => {
    RestApi.get("eMBER_USER_DETAIL", { mber_no: mber_no })
      .then((res) => {
        setDetail({
          ...res.detail,
        });
      })
      .catch((err) => console.log(err));
  };

  const fetchMainDetail = () => {
    RestApi.get("eMBER_USER_MAIN")
      .then((res) => {
        console.log(res);
        setMainDetail(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchInqryCount = () => {
    RestApi.get("eINQRY_USER_ANSWER_UNREAD_COUNT")
      .then((res) => {
        setCount(res.detail.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUpdateDetail = () => {
    const api_params = {
      mber_img_url: detail.mber_img_url,
      mber_bg_img_url: detail.mber_bg_img_url,
    };
    RestApi.post("eMBER_USER_UPDATE", api_params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFiles = (files) => {
    RestApi.upload("eFILE_UPLOAD", { file: [files] })
      .then((fileUrls) => {
        onChangeValue("mber_bg_img_url", fileUrls[0].original);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    fetchUserDetail,
    fetchUpdateDetail,
    fetchUpdateContact,
    onChangeValue,
    uploadFiles,
    mainDetail,
    detail,
    count,
  };
};

export default index;
