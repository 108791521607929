import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

//Components
import ServiceBox from "../../component/ServiceBox";
import Button from "../../../../common/component/atom/Button";

//Styles
import { ItemWrapper, MainSection, ViewDetail } from "./styles";

//Utils
import { Utils } from "../../../../common/util";
import { GvrnmntSrvc_config } from "../../../../common/util/const";

const index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const step = Utils.getParameterByName("step") || "1";

  return (
    <MainSection>
      <ItemWrapper>
        {(GvrnmntSrvc_config || []).map(
          (item, index) =>
            step === "1" && <ServiceBox key={index} item={item} />
        )}
        {step !== "1" && (
          <>
            {(GvrnmntSrvc_config || []).map(
              (item) =>
                item.step === step && (
                  <ViewDetail>
                    <div className={"title"}>{item.title}</div>
                    <div className={"main"}>{item.detail}</div>
                  </ViewDetail>
                )
            )}
            <Button
              variant={"painted"}
              size={"xLarge"}
              label={"목록"}
              onClick={() => navigate("/localResource/governmentService")}
              style={css`
                width: 100%;
                font-size: 1.25rem;
                margin-bottom: 20px;
              `}
            />
          </>
        )}
      </ItemWrapper>
    </MainSection>
  );
};

export default index;
