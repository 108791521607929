import React from "react";

// Styles
import { SafetyPlanArticle } from "./styles";
import { safetyPlan_config } from "../../../../common/util/const";
import { yeonseiImages } from "../../../../styles";

// Components
import CheckConfirmBox from "../../../../component/atom/CheckConfirmBox";
import HelpCallBox from "../HelpCallBox";
import MultiInputForm from "../MultiInputForm";
import InputForm from "../../../../common/component/atom/InputForm";
import CallList from "../../../../component/organism/CallList";

const index = (props) => {
  const {
    step,
    params,
    onChangeValue,
    addInputForm,
    deleteInputForm,
    fetchUpdateContact,
  } = props;

  return (
    <SafetyPlanArticle>
      <p className={"step-title"}>{safetyPlan_config[`step${step}`].title}</p>
      {(step === "2" || step === "5") && (
        <CheckConfirmBox
          variant={"exclamation"}
          img={yeonseiImages.ExclamationMarkMiddle}
          text={safetyPlan_config[`step${step}`].exclamation}
        />
      )}
      {step === "5" && (
        <>
          <HelpCallBox
            titleName={"자살예방상담전화"}
            phoneNumber={"109"}
            img={yeonseiImages.SuicidePrevisionIcon}
            onClick={() => {
              location.href = "tel:109";
              fetchUpdateContact();
            }}
          />
          <HelpCallBox
            titleName={"정신건강상담전화"}
            phoneNumber={"1577-0199"}
            img={yeonseiImages.MentalHealthIcon}
            onClick={() => {
              location.href = "tel:1577-0199";
              fetchUpdateContact();
            }}
          />
          <HelpCallBox
            titleName={"한국생명의전화"}
            phoneNumber={"1588-9191"}
            img={yeonseiImages.LifelineIcon}
            onClick={() => {
              location.href = "tel:1588-9191";
              fetchUpdateContact();
            }}
          />
          <HelpCallBox
            titleName={"응급구조요청"}
            phoneNumber={"119"}
            img={yeonseiImages.EmergencyRescueIcon}
            onClick={() => {
              location.href = "tel:119";
              fetchUpdateContact();
            }}
          />
        </>
      )}
      {step === "4" && <CallList />}
      {step === "1" && (
        <MultiInputForm
          step={step}
          params={params}
          addInputForm={addInputForm}
          deleteInputForm={deleteInputForm}
          onChange={onChangeValue}
        />
      )}
      {step === "2" && (
        <MultiInputForm
          step={step}
          params={params}
          addInputForm={addInputForm}
          deleteInputForm={deleteInputForm}
          onChange={onChangeValue}
        />
      )}
      {step === "3" && (
        <MultiInputForm
          step={step}
          params={params}
          addInputForm={addInputForm}
          deleteInputForm={deleteInputForm}
          onChange={onChangeValue}
        />
      )}
      {step === "6" && (
        <MultiInputForm
          step={step}
          params={params}
          addInputForm={addInputForm}
          deleteInputForm={deleteInputForm}
          onChange={onChangeValue}
        />
      )}
    </SafetyPlanArticle>
  );
};

export default index;
