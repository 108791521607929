import styled from "@emotion/styled";
import { css } from "@emotion/css";

import { Colors, yeonseiImages } from "../../../styles";
export const defaultCheckBoxStyle = css`
  display: inline-block;
  cursor: pointer;

  & > div.checkbox-container {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    & > input {
      display: none;
    }

    & > div {
      margin: 0 4px;
    }
  }
`;

export const CheckBoxSizes = {
  paintedXlarge: css`
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid ${Colors.Primary};
    background-color: ${Colors.PrimaryOpacity};
    padding: 0 20px;
    margin-bottom: 10px;

    & > div.checkbox-container {
      flex-direction: row-reverse;
      justify-content: space-between;

      & > img {
        width: 24px;
        height: 24px;
      }

      & > div {
        width: fit-content;
        color: ${Colors.Black};
        font-weight: 600;
        font-size: 1rem;
        line-height: 14px;
      }
    }
  `,
  small: css`
    & > div.checkbox-container {
      & > img {
        width: 24px;
        height: 24px;
      }
      & > div {
        width: fit-content;
        color: ${Colors.Black};
        font-weight: 600;
        font-size: 1rem;
        line-height: 14px;
      }
    }
  `,
};
