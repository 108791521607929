import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

import { ErrorBoundaryViewContent } from "./styles";
import { Colors, Icons } from "../../../styles";

import Icon from "../../../common/component/atom/Icon";
import Button from "../../../common/component/atom/Button";

function Catch(component, errorHandler) {
  function Inner({ error, props }) {
    return <React.Fragment>{component(props, error)}</React.Fragment>;
  }

  return class extends React.Component {
    state = {
      error: undefined,
    };

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, info) {
      if (errorHandler) {
        errorHandler(error, info);
      }
    }

    render() {
      return <Inner error={this.state.error} props={this.props} />;
    }
  };
}

const index = Catch(function (props, error) {
  const { path, children } = props;
  const navigate = useNavigate();

  if (error) {
    return (
      <ErrorBoundaryViewContent>
        <Icon
          icon={Icons.sentimentDissatisfied}
          color={Colors.PrimaryV01}
          size={"250px"}
        />
        <div>
          <h2>
            CODE : [{error.code}] <p>{error.msg}</p>
          </h2>
          <h1>앗! 문제가 발생했어요..</h1>
          문제를 해결 중에 있습니다. 다시 시도해주세요.
          <Button
            label={"홈으로 돌아가기"}
            variant={"ghost02"}
            style={css`
              margin-top: 20px;
              width: fit-content;
              background-color: transparent;
            `}
            onClick={() => navigate(path)}
          />
        </div>
      </ErrorBoundaryViewContent>
    );
  }
  return children;
});

export default index;
