import styled from "@emotion/styled";
import { Colors } from "../../../styles";

export const FileBox = styled.div`
  display: inline-flex;
  align-items: center;
  flex: 1;

  & label {
    display: none;
    height: 44px;
    padding: 0 16px;
    border: solid 1px ${Colors.Gray30};
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    align-items: center;
  }

  & label > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: none;
  }
`;
