import styled from "@emotion/styled";
import { Colors, yeonseiImages } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;

  overflow-y: auto;

  background-color: ${Colors.Primary10};

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & > div.titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 30px 32px;

    & > div.title {
      font-weight: 700;
      font-size: 1.625rem;
      line-height: 30px;
    }
    & > img {
    }
  }
`;
