import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

//Component
import {
  TwoButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";

//Styles
import { SettingSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

//Config
import {
  SettingNavigateTitle,
  LOGIN_TIME,
  LOGIN_TOKEN,
} from "../../../../common/util/const";

//Hook
import * as RestApi from "../../../../common/context/api";
import useSetting from "../../hooks/useSetting";

//Dispatch
import * as ActionSession from "../../../../common/store/action/eSESSION";

const index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalHandler = handlerModal();
  const { detail } = useSetting();
  const logout = () => {
    RestApi.post("eMBER_LOGOUT", {})
      .then((res) => {
        removeAccessToken();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAccessToken = () => {
    localStorage.removeItem(LOGIN_TOKEN);
    localStorage.removeItem(LOGIN_TIME);

    dispatch(ActionSession.setSession({}));
    navigate("/auth/login");
  };

  return (
    <SettingSection>
      <div className={"profile-box"}>
        <img
          className={"profile-img"}
          src={detail?.mber_img_url || yeonseiImages.SmileLarge}
          alt={"profile-img"}
        />
        {detail?.mber_nickname}님
      </div>
      {SettingNavigateTitle.map((page) => (
        <div
          className={"navigate-button"}
          onClick={() =>
            page.nm === "로그아웃"
              ? modalHandler.onToggleModal()
              : navigate(page.nav)
          }
        >
          <div>{page.nm}</div>
          <img src={yeonseiImages.ArrowRightGray} alt={"arrow-right"} />
        </div>
      ))}
      <TwoButtonModal
        handler={modalHandler}
        topButtonLabel={"로그아웃"}
        topButtonClick={logout}
        bottomButtonClick={() => modalHandler.onToggleModal()}
      >
        <div className={"in-modal-box"}>로그아웃 하시겠습니까?</div>
      </TwoButtonModal>
    </SettingSection>
  );
};

export default index;
