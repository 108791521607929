// Libraries
import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { Fade, Tooltip } from "@mui/material";

// Styles
import { SliderContainer } from "./styles";

const index = (props) => {
  const {
    min = 0,
    max = 100,
    onChangeValue = (field, value) => {},
    value = 0,
    field,
    labelMin,
    labelMax,
    disabled,
  } = props;

  const [marks, setMarks] = useState([
    {
      value: min,
      label: labelMin,
    },
    {
      value: value,
    },
    {
      value: max,
      label: labelMax,
    },
  ]);

  const ValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
      <Tooltip
        title={value}
        placement={"top"}
        open={open}
        arrow
        interactive
        enterTouchDelay={0}
        disableHoverListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={500}
        leaveDelay={200}
      >
        {children}
      </Tooltip>
    );
  };

  return (
    <SliderContainer value={value} target={field}>
      <Slider
        min={min}
        max={max}
        value={value}
        marks={marks}
        step={25}
        disabled={disabled}
        onChange={(event, newValue) => {
          onChangeValue(field, newValue);
          let temp_m = [...marks];
          temp_m.splice(1, 1, { value: newValue });
          setMarks(temp_m);
        }}
        valueLabelDisplay="auto"
        valueLabelComponent={ValueLabelComponent}
        valueLabelFormat={(value) => {
          return (
            <div
              style={{
                width: "45px",
                height: "30px",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "2",
                color: "#FFFFFF",
              }}
            >
              {value + "%"}
            </div>
          );
        }}
      />
    </SliderContainer>
  );
};

export default index;
