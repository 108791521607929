import styled from "@emotion/styled";

export const MultiInputFormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  & Button {
    margin-top: 20px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  
  > * {
    margin-top: 20px;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
`
