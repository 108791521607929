import React from "react";
//style
import { ExceptionVerifySection } from "./styles";
//component
import AuthSignUpEmail from "../../component/AuthSignUpEmail";
//hook
import useExceptionVerify from "../../hooks/useExceptionVerify";

/**
 * @description 특이한 상황에 대한 인증
 *              1. 회원가입 후, 본인인증을 하지 않고 로그인을 시도하려는 경우
 *              2. 앱 비밀번호 5회 이상 틀릴 경우,
 * @returns {JSX.Element}
 */
const index = () => {
  const {
    appPw,
    email,
    resultMsg,
    onChangeValue,
    fetchVerifyNumber,
    fetchVerifyEmail,
  } = useExceptionVerify();

  return (
    <ExceptionVerifySection>
      <AuthSignUpEmail
        isDisplay={true}
        params={email}
        onChange={onChangeValue}
        email={email}
        resultMsg={resultMsg}
        fetchVerifyEmail={fetchVerifyEmail}
        fetchVerifyNumber={fetchVerifyNumber}
        exception={true}
        appPw={appPw}
      />
    </ExceptionVerifySection>
  );
};
export default index;
