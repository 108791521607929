import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const VerifiSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 20px;

  .icon {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .title {
    text-align: center;
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 35px;
    white-space: pre-wrap;
  }

  .sub-title {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: ${Colors.Gray60};
    margin-bottom: 35px;
  }

  .in-modal-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 30px;
    margin-top: 35px;
    margin-bottom: 50px;

    & img {
      width: 102px;
      height: 102px;
      margin-bottom: 38px;
    }
  }

  & > div.buttonBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & > div.resend-button {
      margin-bottom: 7px;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      color: ${Colors.Gray80};
      margin-top: 20px;
    }
  }
`;
