import React, { useState, useEffect, useRef } from "react";
import * as RestApi from "../../../../common/context/api";

const useCallListFunction = (props = {}) => {
  const [list, setList] = useState([{}, {}, {}]);

  const fetchContactPeopleList = async () => {
    const api_params = {
      skip: 0,
      limit: 3,
    };

    await RestApi.get("ePEOPLE_USER_LIST", api_params)
      .then((res) => {
        const newList = [...res.list];
        for (let i = res.count; i < 3; i++) {
          newList.push({
            people_no: "",
            people_nm: "",
            people_contact: "",
          });
        }
        setList(newList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEventFromAndroid = async (event) => {
    const api_params = event.detail;

    if (event.detail) {
      RestApi.post("ePEOPLE_USER_CREATE", api_params)
        .then((res) => {
          alert(
            "내 소중한 사람들로 " +
              api_params.people_nm +
              " 님의 등록이 완료되었어요."
          );
          fetchContactPeopleList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchUpdateContact = () => {
    const path = location.pathname;
    const api_params = {
      contact_hist_typ_cd: path.includes("main/main")
        ? "CHTC00003"
        : "CHTC00004",
      contact_people_yn: "Y",
    };
    RestApi.post("eCONTACT_HIST_USER_CREATE", api_params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    getEventFromAndroid,
    fetchUpdateContact,
    fetchContactPeopleList,
    list,
  };
};
export default useCallListFunction;
