import React from "react";
import { useNavigate } from "react-router";

import { MyPageSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

import { MyPageNavigateTitle } from "../../../../common/util/const";
import useProfileManage from "../../hooks/useProfileManage";

const index = () => {
  const navigate = useNavigate();
  const { params } = useProfileManage();

  return (
    <MyPageSection>
      <div className={"profile-box"}>
        <img
          className={"profile-img"}
          src={params?.mber_img_url || yeonseiImages.SmileLarge}
          alt={"profile-img"}
        />
        {params?.mber_nickname}님
      </div>
      {MyPageNavigateTitle.map((page) => (
        <div className={"navigate-button"} onClick={() => navigate(page.nav)}>
          <div>{page.nm}</div>
          <img src={yeonseiImages.ArrowRightGray} alt={"arrow-right"} />
        </div>
      ))}
    </MyPageSection>
  );
};

export default index;
