import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  height: 100%;

  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  .source {
    width: 100%;
    height: 96px;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.Gray80};
    background-color: ${Colors.Gray20};
  }
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  margin-top: 20px;

  .main-title {
    white-space: pre-wrap;
    font-weight: 300;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .text-box {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid ${Colors.Gray20};

    line-height: 2;
    margin-top: 30px;
    white-space: pre-wrap;

    & span {
      font-weight: 700;
      font-size: 1.1rem;
      color: ${Colors.Primary};
    }

    & div {
      font-weight: 400;
      font-size: 1rem;
    }
  }
`;
