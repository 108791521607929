import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const MainSection = styled.section`
  width: 100%;
  min-height: calc(100vh - 90px);
  height: calc(100% - 90px);

  background-color: ${Colors.Primary10};

  & > div.titleBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 30px 10px;

    & > div.title {
      font-weight: 600;
      font-size: 1.25rem;
    }

    & > img {
      width: 21px;
      height: 21px;

      margin-left: 10px;
    }

    .date-selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .main-title {
        font-weight: 600;
        font-size: 1.25rem;
      }

      & img {
        margin-left: 10px;
      }

      .arrow-left {
        margin-left: 0;
        margin-right: auto;
      }
      .arrow-right {
        margin-left: auto;
      }

      .space-left {
        width: 17px;
        height: 17px;
        margin-right: auto;
      }

      .space-right {
        width: 17px;
        height: 17px;
        margin-left: auto;
      }
    }
  }

  .in-modal-list {
    width: 100%;
    max-height: 240px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    .item-box {
      text-align: center;
      font-weight: 500;
      font-size: 1.375rem;
      height: 60px;
      padding: 20px;

      border-bottom: solid 1px ${Colors.Gray40};
    }
  }
`;
