import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

// Component
import FeelingCalendar from "../../component/FeelingCalendar";

// Styles
import { MainSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

// Hooks
import useFeelingCalendar from "../../hooks/useFeelingCalendar";

const index = (props) => {
  const { eSESSION } = props;
  const navigate = useNavigate();

  const {
    list,
    params,
    dayList,
    onHandleDate,
    onChangeValue,
    fetchMonthList,
    fetchMonthStatisticList,
  } = useFeelingCalendar();

  return (
    <MainSection>
      <div className={"titleBox"}>
        <div className={"title"}>기분 달력</div>
        <img
          src={yeonseiImages.PlusBlack}
          alt={"plusButton"}
          onClick={() => navigate("/main/feelingCalendarManage")}
        />
      </div>
      <FeelingCalendar
        list={list}
        statisticList={dayList}
        params={params}
        onHandleDate={onHandleDate}
        onChangeValue={onChangeValue}
        fetchMonthList={fetchMonthList}
        fetchMonthStatisticList={fetchMonthStatisticList}
      />
    </MainSection>
  );
};

export default connect((state) => {
  return {
    eSESSION: state.data.eSESSION.eSESSION,
    eCOMM_CD: state.data.eCONST.eCOMM_CD,
    eCOMM_CD_TYPE: state.data.eCONST.eCOMM_CD_TYPE,
  };
})(index);
