import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

import { DashboardLayoutMain } from "./styles";

import * as RestApi from "../../../common/context/api";
import * as ActionConst from "../../../common/store/action/eCONST";
import * as ActionSession from "../../../common/store/action/eSESSION";

// Hooks
import useFirebaseToken from "../../../hooks/useFirebaseToken";

/**
 * @description RA 메인 서비스의 레이아웃 구성을 위한 컴포넌트
 * @param props
 * @return {JSX.Element}
 */
const index = (props) => {
  const { dispatch, eCOMM_CD, eSESSION, children } = props;
  const navigate = useNavigate();
  const { refreshFirebaseToken } = useFirebaseToken();

  useLayoutEffect(() => {
    init();
  }, []);

  const init = useCallback(() => {
    RestApi.get("eSESSION", {})
      .then((res) => {
        if (res.account) {
          dispatch(ActionSession.setSession(res));
          refreshFirebaseToken();
        } else if (res.error) {
          resetSession();
        }
      })
      .catch((err) => {
        resetSession();
      });

    if (eCOMM_CD === null) {
      RestApi.get("eCOMM_CD", {})
        .then((res) => {
          dispatch(ActionConst.setCommCd(res.comm_cd));
          dispatch(ActionConst.setCommCdType(res.comm_cd_typ));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const resetSession = () => {
    dispatch(ActionSession.resetSession());
    // navigate("/auth/login");
  };

  return (
    <DashboardLayoutMain>
      <div className={"dashboard-layout__content"}>{children}</div>
    </DashboardLayoutMain>
  );
};

export default React.memo(
  connect((state) => {
    return {
      eSESSION: state.data.eSESSION.account,
      eCOMM_CD: state.data.eCONST.eCOMM_CD,
    };
  })(index)
);
