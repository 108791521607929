import { useState } from "react";
import { useNavigate } from "react-router";

//api
import * as RestApi from "../../../common/context/api";

//
import { safetyPlan_config } from "../../../common/util/const";

export default function (props) {
  const {
    step,
    setExclamation,
    oneButtonModalHandler,
    returnButtonModalHandler,
  } = props;
  const [params, setParams] = useState({
    step1: [
      { placeholder: safetyPlan_config["step1"].example, value: "" },
      { placeholder: safetyPlan_config["step1"].repeat, value: "" },
      { placeholder: safetyPlan_config["step1"].repeat, value: "" },
    ],
    step2: [
      { placeholder: safetyPlan_config["step2"].example, value: "" },
      { placeholder: safetyPlan_config["step2"].repeat, value: "" },
      { placeholder: safetyPlan_config["step2"].repeat, value: "" },
    ],
    step3: [
      { placeholder: safetyPlan_config["step3"].example, value: "" },
      { placeholder: safetyPlan_config["step3"].repeat, value: "" },
      { placeholder: safetyPlan_config["step3"].repeat, value: "" },
    ],
    step6: [
      { placeholder: safetyPlan_config["step6"].example, value: "" },
      { placeholder: safetyPlan_config["step6"].repeat, value: "" },
      { placeholder: safetyPlan_config["step6"].repeat, value: "" },
    ],
  });
  const navigate = useNavigate();

  const onChangeValue = (field, value, index) => {
    setParams((prevState) => {
      const updatedStep = [...prevState[`step${step}`]];
      updatedStep[index] = { ...updatedStep[index], [field]: value };
      return { ...prevState, [`step${step}`]: updatedStep };
    });
  };

  const addInputForm = () => {
    const newInputForm = {
      placeholder: safetyPlan_config[`step${step}`].repeat,
      value: "",
    };
    setParams({
      ...params,
      [`step${step}`]: [...params[`step${step}`], newInputForm],
    });
  };

  const deleteInputForm = (step, index) => {
    params[`step${step}`].splice(index, 1);
    setParams({
      ...params,
    });
  };

  const checkValidation = () => {
    let verified = false;
    for (let i = 0; i < params[`step${step}`].length; i++) {
      params[`step${step}`][i].value === ""
        ? (verified = false)
        : (verified = true);
    }
    return verified;
  };

  const onHandleClick = () => {
    if (step === "5") {
      setExclamation(false);
    }

    if (step === "1" || step === "2" || step === "3") {
      if (checkValidation()) {
        navigate(`/main/safetyPlan?step=${parseInt(step) + 1}`);
      } else {
        returnButtonModalHandler.onToggleModal();
      }
    } else if (step === "6") {
      if (checkValidation()) {
        fetchCreateList();
      } else {
        returnButtonModalHandler.onToggleModal();
      }
    } else {
      navigate(`/main/safetyPlan?step=${parseInt(step) + 1}`);
    }
  };

  const fetchCreateList = () => {
    const updatedParams = {
      ...params,
      step5: params.step6, // step6을 step5로 변경
    };
    delete updatedParams.step6; // step6 제거

    // 각 스텝의 placeholder 제거
    Object.keys(updatedParams).forEach((step) => {
      updatedParams[step] = updatedParams[step].map(
        ({ placeholder, ...rest }) => rest
      );
    });

    RestApi.post("ePLAN_USER_CREATE", updatedParams)
      .then((res) => {
        oneButtonModalHandler.onToggleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUpdateContact = () => {
    const api_params = {
      contact_hist_typ_cd: "CHTC00004",
      contact_emergency_yn: "Y",
    };
    RestApi.post("eCONTACT_HIST_USER_CREATE", api_params)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const checkPlanCount = () => {
    return RestApi.get("eMBER_USER_MAIN")
      .then((res) => {
        return res.plan_count - (res.people_list || []).length > 0;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    params,
    setParams,
    onChangeValue,
    onHandleClick,
    checkPlanCount,
    addInputForm,
    deleteInputForm,
    fetchUpdateContact,
  };
}
