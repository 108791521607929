import React from "react";

import { FeelingStatisticsManage } from "./styles";
import Chart from "../../../../component/atom/StatisticChart";

const index = (props) => {
  const { list } = props;
  const inputData = [
    { id: "date", color: "hsl(8, 70%, 50%)", data: list || [] },
  ];

  return (
    <FeelingStatisticsManage>
      <div className={"title"}>감정 달력 통계</div>
      <Chart data={inputData} />
    </FeelingStatisticsManage>
  );
};

export default index;
