import React, { useState } from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

//Styles
import { SettingSection, WithdrawalArticle } from "./styles";
import { Colors, yeonseiImages } from "../../../../styles";

//Components
import InputForm from "../../../../common/component/atom/InputForm";
import {
  OneButtonModal,
  TermModal,
  handlerModal,
} from "../../../../component/organism/Modal";
import Button from "../../../../common/component/atom/Button";

//Hook
import useWithdrawal from "../../hooks/useWithdrawal";

const index = () => {
  const navigate = useNavigate();
  const { params, onChangeValue, checkUserPassword, fetchUserDelete } =
    useWithdrawal();
  const [isChecked, setIsChecked] = useState(false);
  const termModalHandler = handlerModal();
  const oneButtonModalHandler = handlerModal();

  return (
    <SettingSection>
      <WithdrawalArticle>
        <div className={"checkbox"}>
          <img
            src={
              isChecked ? yeonseiImages.CheckAble : yeonseiImages.CheckDisable
            }
            alt={"check-img"}
            onClick={() => setIsChecked(!isChecked)}
          />
          (필수) 탈퇴시 주의사항 안내
          <div
            className={"modal-button"}
            onClick={() => termModalHandler.onToggleModal()}
          >
            보기
          </div>
        </div>
        <div className={"input-title"}>비밀번호</div>
        <InputForm
          placeholder={"비밀번호를 입력해주세요"}
          type={"password"}
          value={params.mber_pw}
          onChange={(e) => {
            onChangeValue("mber_pw", e.target.value);
          }}
          disabled={!isChecked}
        />
        <div className={"button-box"}>
          <Button
            label={"회원탈퇴"}
            variant={"painted"}
            size={"xLarge"}
            style={css`
              font-weight: 600;
              font-size: 1.25rem;
              margin-bottom: 20px;
              background-color: ${isChecked && params.mber_pw
                ? Colors.Primary
                : Colors.Disable};
            `}
            onClick={() => {
              checkUserPassword();
              oneButtonModalHandler.onToggleModal();
            }}
          />
        </div>
      </WithdrawalArticle>
      <TermModal handler={termModalHandler} type={"term"} />
      <OneButtonModal
        handler={oneButtonModalHandler}
        buttonClick={() =>
          params.mber_del === true
            ? fetchUserDelete()
            : oneButtonModalHandler.onToggleModal()
        }
      >
        <div className={"in-modal-box"}>
          {params.mber_del === true
            ? "회원탈퇴가\n완료되었습니다."
            : "비밀번호가 맞지 않습니다.\n다시 입력해주세요."}
        </div>
      </OneButtonModal>
    </SettingSection>
  );
};

export default index;
