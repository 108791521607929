import React from "react";

import Button from "../../../../common/component/atom/Button";
import InputForm from "../../../../common/component/atom/InputForm";

import { InputWrapper, MultiInputFormBox } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = (props) => {
  const { step, params, addInputForm, deleteInputForm, onChange } = props;

  return (
    <MultiInputFormBox>
      <InputWrapper>
        {params[`step${step}`].map((item, index) => (
          <InputForm
            key={index}
            placeholder={item.value === "" ? item.placeholder : item.value}
            value={item.value}
            onChange={(e) => {
              onChange("value", e.target.value, index);
            }}
            remove_yn={params[`step${step}`].length > 3 ? "Y" : "N"}
            onRemove={() => deleteInputForm(step, index)}
          />
        ))}
      </InputWrapper>
      <Button
        variant={"plusIcon"}
        size={"midIcon"}
        img={yeonseiImages.PlusCircleGray}
        onClick={addInputForm}
      />
    </MultiInputFormBox>
  );
};

export default index;
