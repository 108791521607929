import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

// Styles
import { MainLayoutMain } from "./styles";

// Component
import Header from "../Header";
import Footer from "../Footer";

// Const
import { Const } from "../../../common/util";

// Api
import * as RestApi from "../../../common/context/api";

// Dispatch
import * as ActionSession from "../../../common/store/action/eSESSION";
import * as ActionConst from "../../../common/store/action/eCONST";

// Hooks
import useFirebaseToken from "../../../hooks/useFirebaseToken";

/**
 * @description 메인 및 마이페이지 서비스의 레이아웃 구성을 위한 컴포넌트
 * @param props
 * @return {JSX.Element}
 */

const index = (props) => {
  const { eSESSION = {}, eCOMM_CD = {}, children } = props;
  const { isLogin = false, account = {} } = eSESSION;

  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const { refreshFirebaseToken } = useFirebaseToken();

  useLayoutEffect(() => {
    init();
  }, []);

  const init = useCallback(() => {
    RestApi.get("eSESSION", {})
      .then((res) => {
        if (res.account) {
          dispatch(ActionSession.setSession(res));
          refreshFirebaseToken();
        } else if (res.error) {
          resetSession();
        }
      })
      .catch(() => {
        resetSession();
      });

    if (eCOMM_CD === null) {
      RestApi.get("eCOMM_CD", {})
        .then((res) => {
          dispatch(ActionConst.setCommCd(res.comm_cd));
          dispatch(ActionConst.setCommCdType(res.comm_cd_typ));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const resetSession = () => {
    dispatch(ActionSession.resetSession());
    navigate("/auth/login");
  };

  return (
    <>
      <Header headerMenu={Const.headerMenu} />
      <Footer
        isDisplay={
          !pathname.includes("/main/safetyPlan") &&
          !pathname.includes("/localResource/preventSuicide")
        }
      />
      <MainLayoutMain>{children}</MainLayoutMain>
    </>
  );
};

export default connect((state) => {
  return {
    eSESSION: state.data.eSESSION.eSESSION,
    eCOMM_CD: state.data.eCONST.eCOMM_CD,
  };
})(index);
