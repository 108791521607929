import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const AuthIntroButtons = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 94px;
`;
