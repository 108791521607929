import { useNavigate } from "react-router";
import { css } from "@emotion/css";

// Styles
import { AuthIntroDescription } from "./styles";
import { yeonseiImages } from "../../../../styles";

// Components

const index = () => {
  const navigate = useNavigate();

  return (
    <AuthIntroDescription>
      <img src={yeonseiImages.Logo} alt={"ai voucher desc image"} />
      <h1>eRAPPORT</h1>
      <h2>로그인 후 eRAPPORT를 마음껏 즐겨보세요.</h2>
    </AuthIntroDescription>
  );
};

export default index;
