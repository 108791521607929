import React from "react";
import { SettingSection } from "./styles";
import { yeonseiImages } from "../../../../styles";

const index = () => {
  return (
    <SettingSection>
      <div className={"explain-box"}>
        본 앱은 한국연구재단의 지원으로 연세대학교 간호대학 김희정 연구팀에서
        지역사회 고위험 대상의 자살예방 프로그램으로 개발된 앱입니다.
      </div>
      <img src={yeonseiImages.YeonseiNurse} alt={"yeonsei-logo"} />
    </SettingSection>
  );
};

export default index;
