import styled from "@emotion/styled";
import { Colors } from "../../../../styles";

export const FeelingStatistics = styled.div`
  display: ${(props) => (props.isdisplay === "detail" ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 55px;

  & > div.title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 18px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 36px;
  }

  & > div.dateBox {
    margin-top: 80px;
    & > div.dateBlock {
      width: 335px;
      height: 80px;
      margin-bottom: 20px;

      box-shadow: ${Colors.Shadow};
      border-radius: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 500;
      font-size: 1.375rem;
      line-height: 20px;
    }

    .space {
      height: 30px;
      margin-bottom: 20px;
    }
  }
`;
