import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

//Component
import LeftSlidingView from "../../../../component/template/LeftSlidingView";
import Button from "../../../../common/component/atom/Button";
import Icon from "../../../../common/component/atom/Icon";
import CallList from "../../../../component/organism/CallList";
import {
  OneButtonModal,
  handlerModal,
} from "../../../../component/organism/Modal";
import InputForm from "../../../../common/component/atom/InputForm";

//Styles
import { BodyArticle, HeaderArticle, MainSection } from "./styles";
import { Colors, yeonseiImages, Icons } from "../../../../styles";

//Hooks
import useMain from "../../hooks/useMain";

const index = (props) => {
  const { eSESSION } = props;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {
    count,
    detail,
    mainDetail,
    uploadFiles,
    onChangeValue,
    fetchUpdateContact,
  } = useMain();
  const oneButtonModalHandler = handlerModal();
  const inputRef = useRef(null);

  return (
    <MainSection detail={detail}>
      {Object.keys(detail).length > 0 && (
        <>
          <LeftSlidingView
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            eSESSION={eSESSION}
            detail={detail}
            mainDetail={mainDetail}
            count={count}
          />
          <HeaderArticle>
            <div className={"test"}>
              <label
                ref={inputRef}
                htmlFor={"file_uploader"}
                style={{ marginRight: "8px", flex: 1 }}
                onChange={(e) => {
                  uploadFiles(e.target.files[0]);
                }}
              >
                <InputForm id={"file_uploader"} type="file" accept="image/*" />
              </label>
            </div>

            <div className={"header"}>
              <Button
                variant={"hamburger"}
                size={"icon"}
                img={yeonseiImages.Hamburger}
                style={"hamburger"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                }}
              />
              <Icon
                icon={Icons.addPhotoAlternate}
                style={css`
                  margin-right: 40px;
                  background-color: white;
                  width: 36px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
                `}
                onClick={() => inputRef.current.click()}
              />
            </div>

            <div className={"invisible-profile-box"}>
              <div className={"name-text"}>
                {detail.mber_nickname}님
                <br />
                반갑습니다.
              </div>
              <Button
                variant={"profile"}
                size={"profile"}
                style={css`
                  background: ${detail.mber_img_url
                      ? `url(${detail.mber_img_url})`
                      : `${Colors.Gray20}`}
                    no-repeat center center;
                  background-size: cover;
                `}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/myPage/myPage");
                }}
              />
            </div>
          </HeaderArticle>
          <BodyArticle>
            <div
              className={"safe-banner"}
              onClick={() =>
                mainDetail.plan_count > 0
                  ? navigate("/main/safetyPlanManage")
                  : oneButtonModalHandler.onToggleModal()
              }
            >
              <img src={yeonseiImages.SafeBanner} alt={"safe-banner"} />
              <div>나를 살리는 계획</div>
            </div>
            <div
              className={"feeling-banner"}
              onClick={() =>
                mainDetail.plan_count > 0
                  ? navigate("/main/feelingCalendarManage")
                  : oneButtonModalHandler.onToggleModal()
              }
            >
              <img src={yeonseiImages.FeelingBanner} alt={"feeling-banner"} />
              <div>
                지금 기분은 어떤가요?
                <br />
                <span>지금 당신의 마음을 알려주세요.</span>
              </div>
            </div>
            <div className={"protect-text"}>
              나를 지켜주는 <span>소중한 사람들</span>을 추가 해주세요.
            </div>
            <CallList />
            <div className={"sos-box"}>
              <div className={"invisible-call-box-title"}>
                <img
                  className={"old-phone"}
                  src={yeonseiImages.OldPhone}
                  alt={"old-phone"}
                />
                <div className={"title-text"}>
                  지금 많이 힘든가요? <br />{" "}
                  <span>전문가에게 도움을 청해보세요.</span>
                </div>
              </div>
              <div className={"invisible-call-box"}>
                <img
                  src={yeonseiImages.SuicidePrevision}
                  alt={"call-1"}
                  onClick={() => {
                    location.href = "tel:109";
                    fetchUpdateContact();
                  }}
                />
                <img
                  src={yeonseiImages.MentalHealth}
                  alt={"call-2"}
                  onClick={() => {
                    location.href = "tel:1577-0199";
                    fetchUpdateContact();
                  }}
                />
                <img
                  src={yeonseiImages.Lifeline}
                  alt={"call-3"}
                  onClick={() => {
                    location.href = "tel:1588-9191";
                    fetchUpdateContact();
                  }}
                />
                <img
                  src={yeonseiImages.EmergencyRescue}
                  alt={"call-4"}
                  onClick={() => {
                    location.href = "tel:119";
                    fetchUpdateContact();
                  }}
                />
              </div>
            </div>
            <OneButtonModal
              handler={oneButtonModalHandler}
              label={"작성하러 가기"}
              buttonClick={() => navigate("/main/safetyPlan")}
            >
              <div className={"in-modal-box"}>
                <img src={yeonseiImages.Note} alt={"note-img"} />
                나를 살리는 계획을
                <br />
                먼저 작성해주세요.
              </div>
            </OneButtonModal>
          </BodyArticle>
        </>
      )}
    </MainSection>
  );
};
export default index;
