import React from "react";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";

// Component
import SlidingView from "../../../common/component/template/SlidingView";
import Button from "../../../common/component/atom/Button";
import MenuRow from "./MenuRow";
import EmergencyModalContent from "./EmergencyModalContent";
import SubMenuRow from "./SubMenuRow";
import { handlerModal, OneButtonModal } from "../../organism/Modal";

// Styles
import { LeftSlidingView } from "./styles";
import { yeonseiImages } from "../../../styles";

// Config
import { Config } from "./config";

const index = (props) => {
  const { count, isOpen, setIsOpen, eSESSION = {}, detail, mainDetail } = props;
  const isLogin = useSelector((state) => state?.data.eSESSION.isLogin);
  const navigate = useNavigate();
  const emergencyModalHandler = handlerModal();

  return (
    <SlidingView isOpen={isOpen} setIsOpen={setIsOpen}>
      <LeftSlidingView>
        <div className="header">
          <div className={"controlButtons"}>
            <img
              alt={"homeButton"}
              className="home"
              src={yeonseiImages.Home}
              onClick={() => {
                setIsOpen(false);
                navigate("/main/main");
              }}
            />
            <img
              alt={"closeButton"}
              className="close"
              src={yeonseiImages.Close}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <div className={"profile"}>
            <div className={"greeting"}>반갑습니다</div>
            {isLogin && count > 0 ? (
              <div className={"dot"} />
            ) : (
              <div className={"space"} />
            )}
            <div
              className={"loginButton"}
              onClick={() => {
                navigate(isLogin ? "/myPage/myPage" : "/auth/login");
                setIsOpen(false);
              }}
            >
              <div>
                {isLogin ? detail?.mber_nickname + "님" : "로그인 해주세요"}
              </div>
              <img alt={"rightArrowThin"} src={yeonseiImages.ArrowRight} />
            </div>
          </div>
        </div>
        <div className="content">
          <div className={"menuBox"}>
            <div className={"upperTitle"}>나를 지킴</div>
            <MenuRow
              field={"나를 살리는 계획"}
              link={"/main/safetyPlanManage"}
            />
          </div>
          <div className={"menuBox"}>
            <div className={"upperTitle"}>나를 아낌</div>
            <MenuRow
              field={"기분 기록"}
              link={
                mainDetail.alarm_count > 0
                  ? "/main/feelingCalendarManage"
                  : "/setting/alarm"
              }
            />
            <MenuRow field={"기분 달력"} link={"/main/feelingCalendar"} />
          </div>

          <div className={"menuBox"}>
            <SubMenuRow config={Config.nearResource} />
            <SubMenuRow config={Config.correctInfo} />
          </div>
          <div className={"menuBox"}>
            <MenuRow field={"Q&A"} link={"/myPage/qna"} />
            <MenuRow field={"설정"} link={"/setting/setting"} />
          </div>
          <Button
            label={"응급 연락처 목록 버튼"}
            variant={"emergency"}
            size={"large"}
            style={css`
              font-weight: 600;
              font-size: 1rem;
              line-height: 15px;
            `}
            onClick={() => emergencyModalHandler.onToggleModal()}
          />
        </div>
        <OneButtonModal
          handler={emergencyModalHandler}
          buttonClick={emergencyModalHandler.onToggleModal}
          label={"닫기"}
        >
          <EmergencyModalContent />
        </OneButtonModal>
      </LeftSlidingView>
    </SlidingView>
  );
};

export default index;
