import Intro from "../pages/auth/page/intro";
import Login from "../pages/auth/page/login";
import Signup from "../pages/auth/page/signup";
import Pin from "../pages/auth/page/pin";
import PasswordReset from "../pages/auth/page/passwordReset";
import Verification from "../pages/auth/page/verification";
import ExceptionVerify from "../pages/auth/page/exceptionVerify";

export const authRoutes = [
  {
    path: "/auth/Intro",
    element: <Intro />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/signup",
    element: <Signup />,
  },
  {
    path: "/auth/pin",
    element: <Pin />,
  },
  {
    path: "/auth/passwordReset",
    element: <PasswordReset />,
  },
  {
    path: "/auth/verification",
    element: <Verification />,
  },
  {
    path: "/auth/exceptionVerify",
    element: <ExceptionVerify />,
  },
];
