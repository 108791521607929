import React, { useId } from "react";
import {
  TabBoxSizes,
  TabMenuBoxSizes,
  defaultTabBoxStyle,
  defaultTabMenuBoxStyle,
} from "./styles";
import { cx } from "@emotion/css";

/**
 * @description 탭 메뉴을 표현하는 component
 * @param props
 * @param {string} props.tabSize 탭 전체의 사이즈를 결정하는 속성 (small & big)
 * @param {string} props.size 탭 메뉴의 height를 결정하는 속성 (small & big)
 * @param {object[]} props.tabs 탭 목록
 * @example props.tabs 목록 예시
 * {
 *     [
 *      {
 *        label: "탭 메뉴 제목",
 *        value : "tab1" // 선택값을 지정할 value
 *      }
 *     ]
 * }
 * @param {number || string} props.value tabs 목록과 비교할 선택값(state)
 * @param {function} props.onChangeValue 선택값(state)을 변경하는 함수
 *
 * @return {JSX.Element}
 */
const index = (props) => {
  const { tabSize, size, tabs = [], value, onChangeValue } = props;
  return (
    <ul className={cx(defaultTabBoxStyle, TabBoxSizes[tabSize || "large"])}>
      {tabs.map((tab) => {
        return (
          <li
            id={useId()}
            value={tab.value}
            className={cx(
              defaultTabMenuBoxStyle,
              TabMenuBoxSizes[size || "small"],
              tab.value === value ? "selected" : ""
            )}
            onClick={(e) => onChangeValue(tab, e)}
          >
            {tab.label}
          </li>
        );
      })}
    </ul>
  );
};

export default index;
