import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { css } from "@emotion/css";

// Styles
import { Colors, Icons } from "../../../../styles";
import { PaginationBox, PaginationStyles } from "./styles";

import Icon from "../../../../common/component/atom/Icon";

/**
 * @description 페이지네이션 형태의 테이블을 표현합니다.
 * @param props
 * @param {object} props.handler - 페이지네이션에서 필요한 값을 포함한 object
 * @param {object} props.handler.params - list 의 parameter 정보를 담고 있는 object
 * @example params object 의 예시
 * {
 *     skip: 0,
 *     limit: 20
 * }
 * @param {number} props.handler.count - list 의 total count
 * @param {function} props.handler.onChangePage - 페이지네이션 number 변경 처리 함수
 *
 * @returns {JSX.Element}
 */
const index = (props) => {
  const { handler } = props;
  const { params, count, onChangePage } = handler;
  const { skip, limit } = params;

  const [forcePage, setForcePage] = useState(0);

  useEffect(() => {
    setForcePage(skip / limit);
  }, [skip, limit]);

  const pageCount = count / limit;

  return (
    <PaginationBox>
      <Icon
        icon={Icons.doubleBefore}
        size={"16px"}
        color={Colors.Gray80}
        style={css`
          background-color: ${Colors.White};
          border-radius: 4px;
          padding: 6px;
          margin-right: 8px;
          cursor: pointer;
        `}
        onClick={() => onChangePage(0)}
      />
      <ReactPaginate
        previousLabel={
          <Icon
            icon={Icons.navigationBefore}
            size={"16px"}
            color={Colors.Gray80}
            style={css`
              background-color: ${Colors.White};
              border-radius: 4px;
              padding: 6px;
              cursor: pointer;
            `}
          />
        }
        nextLabel={
          <Icon
            icon={Icons.navigationNext}
            size={"16px"}
            color={Colors.Gray80}
            style={css`
              background-color: ${Colors.White};
              border-radius: 4px;
              padding: 6px;
              cursor: pointer;
            `}
          />
        }
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(data) => onChangePage(data.selected || 0)}
        forcePage={forcePage}
        containerClassName={PaginationStyles.pagination}
        pageClassName={PaginationStyles.paginationItem}
        previousClassName={PaginationStyles.previousArrow}
        nextClassName={PaginationStyles.nextArrow}
        activeClassName={PaginationStyles.active}
      />
      <Icon
        icon={Icons.doubleNext}
        size={"16px"}
        color={Colors.Gray80}
        style={css`
          background-color: ${Colors.White};
          border-radius: 4px;
          padding: 6px;
          margin-left: 8px;
          cursor: pointer;
        `}
        onClick={() => onChangePage(pageCount - 1)}
      />
    </PaginationBox>
  );
};

export default index;
